import React from 'react';
import { DropdownButton } from 'react-bootstrap';
import { withIntl } from '../../wrappers';
import { intlShape, virtualNodeShape } from '../../shapes';
import { withBrowser, withNotifier } from '../../context';
import ListItem from '../List/ListItem';
import { url } from '../../utils';

export function VirtualNodeItem({ virtualNode, intl }) {
  const renderItemMenu = (
    <div className="list-item-menu">
      <DropdownButton
        bsStyle="default"
        title="..."
        noCaret
        disabled
        id="dropdown-menu"
        pullRight
      />
    </div>
  );
  return (
    <ListItem
      id="virtual-nodes-item"
      title={intl.formatMessage({ id: 'node.batch.virtual.name' })}
      icon="lcm-iot-icon-batch-folder"
      description={intl.formatMessage({ id: 'node.batch.virtual.description' })}
      itemType={virtualNode.itemType}
      target={url('/nodes/my-batches')}
      actions={renderItemMenu}
    />
  );
}

VirtualNodeItem.propTypes = {
  virtualNode: virtualNodeShape.isRequired,
  intl: intlShape.isRequired,
};

export default withIntl((withBrowser(withNotifier(VirtualNodeItem))));
