import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  apiShape,
  browserShape,
  displayUserName,
  notifierShape,
  NotFoundError,
  Loader,
  withApi,
  ActionBar,
  isNotEmpty,
  trackingShape,
  withBrowser,
  withNotifier,
  withTracking,
} from 'lcm-iot-commons';

import AssetDetailsItem from './AssetDetailsItem';
import UserDetailsItem from './UserDetailsItem';
import PermissionRequestEdit from './PermissionRequestEdit';
import { extractPermissionRequest } from '../../extractors/permissionRequestsExtractor';
import { extractAsset } from '../../extractors/assetsExtractor';

export function PermissionRequest({
  api, match, notifier, browser, tracking,
}) {
  const [permissionRequest, setPermissionRequest] = useState(undefined);

  const handleOnSubmitted = (newPermissionRequest) => {
    setPermissionRequest(newPermissionRequest);
  };

  const loadPermissionRequest = async () => {
    try {
      const permissionRequestResponse = await api.get(`/permission_requests/${match?.params.id}`);
      const asset = await api.get(`/assets/${permissionRequestResponse.permitable.id}`, { include: 'product.manufacturer,product.pictures' });
      const currentPermissionRequest = extractPermissionRequest(permissionRequestResponse, extractAsset(asset));
      setPermissionRequest(currentPermissionRequest);
      tracking?.trackEvent('PermissionsRequest-Details', { sn: currentPermissionRequest.permitable.serialNumber });
    } catch (error) {
      if ((error instanceof NotFoundError)) {
        browser.navigateTo('/404');
      } else {
        notifier.showError(api.translateError(error));
        setPermissionRequest(undefined);
      }
    }
  };

  useEffect(() => {
    loadPermissionRequest();
  }, [match?.params?.id]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <ActionBar>
            <h1><FormattedMessage id="permission_request.header" /></h1>
          </ActionBar>
        </div>
      </div>
      { permissionRequest ? (
        <div>
          <div className="row">
            <div className="col-sm-12">
              <p><FormattedMessage id="permission_request.info" values={{ requestUser: displayUserName(permissionRequest.requestUser) }} /></p>
              <p><FormattedMessage id={`permission_request.info.${permissionRequest.status}`} values={{ responseUser: displayUserName(permissionRequest.responseUser) }} /></p>
              <h3><FormattedMessage id="permission_request.request_message_header" values={{ user: displayUserName(permissionRequest.requestUser) }} /></h3>
              <p id="permission-request-message">{isNotEmpty(permissionRequest.requestMessage) ? permissionRequest.requestMessage : '-' }</p>
              { permissionRequest.status !== 'open' ? (
                <div>
                  <h3><FormattedMessage id="permission_request.response_message_header" values={{ user: displayUserName(permissionRequest.responseUser) }} /></h3>
                  <p id="permission-response-message">{isNotEmpty(permissionRequest.responseMessage) ? permissionRequest.responseMessage : '-' }</p>
                </div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <AssetDetailsItem asset={permissionRequest.permitable} />
            </div>
            <div className="col-sm-12">
              <UserDetailsItem user={permissionRequest.requestUser} />
            </div>
          </div>
          { permissionRequest.status === 'open' ? (
            <div className="row">
              <div className="col-sm-12">
                <PermissionRequestEdit permissionRequest={permissionRequest} onSubmitted={handleOnSubmitted} />
              </div>
            </div>
          ) : null }
        </div>
      ) : (<Loader loading />) }
    </div>
  );
}

PermissionRequest.propTypes = {
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  notifier: notifierShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }),
  tracking: trackingShape.isRequired,
};

PermissionRequest.defaultProps = {
  match: undefined,
};

export default withApi(withNotifier(withBrowser(withTracking(PermissionRequest))));
