import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import {
  List,
  ListItem,
  Hint,
  intlShape,
} from 'lcm-iot-commons';

export function EdgeDeviceApplications({
  intl,
  edgeDeviceApplications,
}) {
  return (
    <div>
      <h2 id="enabled-features">{ intl.formatMessage({ id: 'label.enabled_features' }) }</h2>
      {edgeDeviceApplications && (
      <List id="edge-device-application-list">
        {edgeDeviceApplications.map((edgeDeviceApplication) => (
          <ListItem
            icon="icon lcm-iot-icon-application"
            id={`edge-device-application-list-item-${edgeDeviceApplication.id}`}
            key={edgeDeviceApplication.id}
            title={edgeDeviceApplication.name}
            description={edgeDeviceApplication.description}
          />
        ))}
      </List>
      )}
      { !edgeDeviceApplications.length && (
      <Hint id="no-edge-device-applications-hint" title={intl.formatMessage({ id: 'edge_device_details.no_enabled_features_found' })} />
      )}
    </div>
  );
}

EdgeDeviceApplications.propTypes = {
  edgeDeviceApplications: PropTypes.arrayOf(
    PropTypes.shape(
      {
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
      },
    ),
  ).isRequired,
  intl: intlShape.isRequired,
};

export default
injectIntl(
  EdgeDeviceApplications,
);
