import PropTypes from 'prop-types';
import React, { useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import { Formik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import { intlShape, usersShape } from '../../shapes';
import UserItem from './UserItem';
import ConfirmationModal from '../ConfirmationModal';

import {
  Button, Form, SubmitButton, TextInput,
} from '../Form';

import { isArray, isEmpty, isSomething } from '../../utils';
import {
  apiShape, notifierShape, withApi, withNotifier,
} from '../../context';
import { NotFoundError } from '../../api';

function AssignableUserList({
  api,
  intl,
  users,
  notifier,
  canUpdate,
  onAssignUser,
  showUserEmail,
  onUnassignUser,
  acceptConnectUsers,
  disableUnassignForLastUser,
}) {
  const [userToUnassign, setUserToUnassign] = useState();

  const validConnectUser = (email) => {
    if (!acceptConnectUsers) {
      return false;
    }

    const regex = /@connect$/;
    return regex.test(email);
  };

  const validateEmail = (values) => {
    const errors = {};
    if (isEmpty(values.email)) {
      errors.email = intl.formatMessage({ id: 'validation.email.mandatory' });
    } else if (!isEmail(values.email) && !validConnectUser(values.email)) {
      errors.email = intl.formatMessage({ id: 'validation.email.invalid' });
    }
    return errors;
  };

  const assignUser = (values, actions) => {
    api.get('/users/lookup', { email: values.email })
      .then((user) => {
        onAssignUser(user)
          .then(() => {
            notifier.showSuccess(intl.formatMessage({ id: 'user_list.assign.notification' }));
            actions.resetForm({});
          })
          .catch((e) => {
            if (e.errors?.find((error) => error.type === 'associations_already_added') || (isArray(e) && e.find((error) => error.type === 'associations_already_added'))) {
              notifier.showError(intl.formatMessage({ id: 'user_group_details.error.already_assigned' }));
            } else {
              notifier.showError(api.translateError(e));
            }
          });
      })
      .catch((error) => {
        if (error instanceof NotFoundError) {
          notifier.showError(intl.formatMessage({ id: 'api.error.email.not_found_no_permission' }));
        } else {
          notifier.showError(api.translateError(error));
        }
      })
      .finally(() => actions.setSubmitting(false));
  };

  const doUnassignUser = () => {
    setUserToUnassign(null);
    onUnassignUser(userToUnassign)
      .then(() => {
        notifier.showSuccess(intl.formatMessage({ id: 'user_list.unassign.notification' }));
      })
      .catch((errors) => {
        notifier.showError(api.translateError(errors));
      });
  };

  const renderForm = (myProps) => {
    const {
      isSubmitting,
      values,
      errors,
    } = myProps;
    return (
      <Form {...myProps}>
        <TextInput
          {...myProps}
          id="user-email"
          name="email"
          label={intl.formatMessage({ id: 'label.email' })}
          hideLabel
        />
        <div className="btn-group">
          <SubmitButton
            id="assign-user-button"
            isMouseUp
            fetching={isSubmitting}
            disabled={!(!errors.email && !isEmpty(values.email))}
            text={intl.formatMessage({ id: 'button.assign' })}
          />
        </div>
      </Form>
    );
  };

  const userItems = users ? users.map((user) => {
    const deleteButton = canUpdate ? (
      <Button
        text={intl.formatMessage({ id: 'user_list.unassign' })}
        mobileIcon="icon-eh-unlink"
        onClick={() => setUserToUnassign(user)}
        disabled={users.length === 1 && disableUnassignForLastUser}
      />
    ) : null;
    return (
      <UserItem
        key={user.id}
        user={user}
        actions={deleteButton}
        showUserEmail={showUserEmail}
      />
    );
  }) : null;

  return users ? (
    <div>
      {canUpdate && (
        <Formik
          onSubmit={(values, actions) => assignUser(values, actions)}
          render={(props) => renderForm(props)}
          validate={(values) => validateEmail(values)}
        />
      )}
      {users.length > 0 ? (
        <ul className="list">
          {userItems}
        </ul>
      ) : <FormattedMessage id="user_list.no_users_assign" />}
      {canUpdate ? (
        <ConfirmationModal
          id="delete-modal"
          show={isSomething(userToUnassign)}
          titleText={intl.formatMessage({ id: 'user_list.unassign.confirmation.title' })}
          messageText={intl.formatMessage({ id: 'user_list.unassign.confirmation.message' })}
          onConfirm={() => doUnassignUser()}
          onClose={() => setUserToUnassign(null)}
        />
      ) : null}
    </div>
  ) : null;
}

AssignableUserList.propTypes = {
  api: apiShape,
  notifier: notifierShape,
  intl: intlShape.isRequired,
  users: usersShape,
  canUpdate: PropTypes.bool,
  onAssignUser: PropTypes.func,
  onUnassignUser: PropTypes.func,
  disableUnassignForLastUser: PropTypes.bool,
  showUserEmail: PropTypes.bool,
  acceptConnectUsers: PropTypes.bool,
};

AssignableUserList.defaultProps = {
  acceptConnectUsers: false,
};

export default injectIntl(withApi(withNotifier(AssignableUserList)));
