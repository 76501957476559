import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  withIntl,
  intlShape,
  Column,
  Row,
  Form,
  TextInput,
  ButtonGroup,
  SubmitButton,
  CancelButton,
  CountriesSelectFormik,
  VatPrefixes, Heading,
} from 'lcm-iot-commons';
import isEmail from 'validator/lib/isEmail';

export function UserContactDetailsForm({
  intl, initialValues, onSubmit, ssoUser,
}) {
  const validateForm = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = intl.formatMessage({ id: 'validation.first_name.mandatory' });
    } else if (values.firstName.length > 255) {
      errors.firstName = intl.formatMessage({ id: 'validation.first_name.too_long' }, { max: '255' });
    }
    if (!values.lastName) {
      errors.lastName = intl.formatMessage({ id: 'validation.last_name.mandatory' });
    } else if (values.lastName.length > 255) {
      errors.lastName = intl.formatMessage({ id: 'validation.last_name.too_long' }, { max: '255' });
    }
    /* istanbul ignore next */
    if (values.company
      || values.streetName
      || values.streetNumber
      || values.zipCode
      || values.city
      || values.regionCode
      || values.country?.code
      || values.phone
      || values.billingEmail
    ) {
      if (!values.streetName) {
        errors.streetName = intl.formatMessage({ id: 'validation.street_name.mandatory' });
      } else if (values.streetName?.length > 255) {
        errors.streetName = intl.formatMessage({ id: 'validation.street_name.too_long' }, { max: '255' });
      }
      if (!values.zipCode) {
        errors.zipCode = intl.formatMessage({ id: 'validation.zip_code.mandatory' });
      } else if (values.zipCode?.length > 20) {
        errors.zipCode = intl.formatMessage({ id: 'validation.zip_code.too_long' }, { max: '20' });
      }
      if (!values.city) {
        errors.city = intl.formatMessage({ id: 'validation.city.mandatory' });
      } else if (values.city?.length > 255) {
        errors.city = intl.formatMessage({ id: 'validation.city.too_long' }, { max: '255' });
      }
      if (!values.country?.code) {
        errors.country = intl.formatMessage({ id: 'validation.country.mandatory' });
      }
      if (values.company?.length > 255) {
        errors.company = intl.formatMessage({ id: 'validation.company.too_long' }, { max: '255' });
      }
      if (values.streetNumber?.length > 255) {
        errors.streetNumber = intl.formatMessage({ id: 'validation.street_number.too_long' }, { max: '255' });
      }
      if (values.regionCode?.length > 255) {
        errors.regionCode = intl.formatMessage({ id: 'validation.region_code.too_long' }, { max: '255' });
      }
      if (values.phone?.length > 15) {
        errors.phone = intl.formatMessage({ id: 'validation.phone.too_long' });
      }
    }
    if (values.billingEmail && !isEmail(values.billingEmail)) {
      errors.billingEmail = intl.formatMessage({ id: 'validation.email.invalid' });
    }
    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting, values } = formProps;
    const vatPrefix = VatPrefixes.forCountryCode(values?.country?.code);
    if (values?.country?.code && !vatPrefix) {
      values.vatNumber = '';
    }
    return (
      <Form {...formProps}>
        <Row id="contact-details-company">
          <Column md={8}>
            <TextInput
              {...formProps}
              id="company"
              name="company"
              label={intl.formatMessage({ id: 'label.company' })}
            />
          </Column>
        </Row>
        <Row id="contact-details-name">
          <Column md={4}>
            <TextInput
              {...formProps}
              id="first-name"
              name="firstName"
              label={intl.formatMessage({ id: 'label.first_name' })}
              disabled={ssoUser}
              required
            />
          </Column>
          <Column md={4}>
            <TextInput
              {...formProps}
              id="last-name"
              name="lastName"
              label={intl.formatMessage({ id: 'label.last_name' })}
              disabled={ssoUser}
              required
            />
          </Column>
        </Row>
        <Row id="contact-details-street">
          <Column md={6}>
            <TextInput
              {...formProps}
              id="street-name"
              name="streetName"
              label={intl.formatMessage({ id: 'label.street_name' })}
              required
            />
          </Column>
          <Column md={2}>
            <TextInput
              {...formProps}
              id="street-number"
              name="streetNumber"
              label={intl.formatMessage({ id: 'label.street_number' })}
            />
          </Column>
        </Row>
        <Row id="contact-details-city">
          <Column md={2}>
            <TextInput
              {...formProps}
              id="zip-code"
              name="zipCode"
              label={intl.formatMessage({ id: 'label.zip_code' })}
              required
            />
          </Column>
          <Column md={6}>
            <TextInput
              {...formProps}
              id="city"
              name="city"
              label={intl.formatMessage({ id: 'label.city' })}
              required
            />
          </Column>
        </Row>
        <Row id="contact-details-country">
          <Column md={2}>
            <TextInput
              {...formProps}
              id="region-code"
              name="regionCode"
              label={intl.formatMessage({ id: 'label.region_code' })}
            />
          </Column>
          <Column md={6}>
            <CountriesSelectFormik
              {...formProps}
              id="country"
              name="country"
              label={intl.formatMessage({ id: 'label.country' })}
              required
              all
            />
          </Column>
        </Row>
        <Row id="contact-details-phone">
          <Column md={8}>
            <TextInput
              {...formProps}
              id="phone"
              name="phone"
              label={intl.formatMessage({ id: 'label.phone' })}
            />
          </Column>
        </Row>
        <Heading level={3} id="user-profile-edit-contact-details-header" title={intl.formatMessage({ id: 'subscription_details.billing_details_header' })} />
        <Row id="user-profile-billing-email">
          <Column md={8}>
            <TextInput
              {...formProps}
              id="billing-email"
              component={TextInput}
              label={intl.formatMessage({ id: 'label.email' })}
              name="billingEmail"
              disabled={isSubmitting}
              help
              helpTitle={intl.formatMessage({ id: 'label.email' })}
              helpText={intl.formatMessage({ id: 'edit_contact_details.billing_details_email.description' })}
            />
          </Column>
        </Row>
        {vatPrefix && (
        <Row id="contact-details-vat">
          <Column md={8}>
            <TextInput
              {...formProps}
              id="vat_number"
              prefix={vatPrefix}
              name="vatNumber"
              label={intl.formatMessage({ id: 'label.vat_number' })}
            />
          </Column>
        </Row>
        )}
        <ButtonGroup className="btn-group">
          <SubmitButton id="contact-details-submit" fetching={isSubmitting} intl={intl} disabled={!formProps.dirty} />
          <CancelButton id="contact-details-cancel" disabled={isSubmitting} intl={intl} target="/profile" />
        </ButtonGroup>
      </Form>
    );
  };

  return (
    <div className="form-group">
      <Formik
        id="user-contact-details-form"
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validateForm}
        render={renderForm}
      />
    </div>
  );
}

UserContactDetailsForm.propTypes = {
  intl: intlShape.isRequired,
  initialValues: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    ssoProvider: PropTypes.string,
    address: PropTypes.shape({
      company: PropTypes.string,
      streetName: PropTypes.string,
      streetNumber: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
      regionCode: PropTypes.string,
      country: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
      }),
      phone: PropTypes.string,
      vatNumber: PropTypes.string,
      billingEmail: PropTypes.string,
    }),
  }),
  onSubmit: PropTypes.func,
  ssoUser: PropTypes.bool,
};

UserContactDetailsForm.defaultProps = {
  initialValues: {
    address: {
      country: {
        code: undefined,
        name: undefined,
      },
    },
  },
  onSubmit: undefined,
  ssoUser: false,
};

export default withIntl(UserContactDetailsForm);
