import PropTypes from 'prop-types';

export const networkInterfaceShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.string,
  number: PropTypes.number,
  ipAddress: PropTypes.string,
  modus: PropTypes.string,
  subnetMask: PropTypes.string,
  defaultGateway: PropTypes.string,
  status: PropTypes.string,
  httpProxyHost: PropTypes.string,
  httpProxyPort: PropTypes.number,
  httpProxyAuth: PropTypes.bool,
  dns1: PropTypes.string,
  dns2: PropTypes.string,
  applyTimestamp: PropTypes.number,
});

export const networkInterfacesShape = PropTypes.arrayOf(networkInterfaceShape);
