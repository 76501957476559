import { isEmpty } from 'lcm-iot-commons';

export function getUpdateScheduleStatusIcon(status) {
  let icon = '';
  if (status.includes('successful')) icon = 'icon2-eh-good';
  else if (status.includes('installing')) icon = 'icon-eh-refresh';
  else if (status.includes('failed')) icon = 'icon2-eh-cancel';
  else icon = 'icon2-eh-radio-button-unchecked';
  return icon;
}

export function renameDownloadingStatus(updateSchedule) {
  const us = updateSchedule;
  us.status.code = 'installing';
  us.status.name = 'installing';
  return us;
}

export function getVersionsWithCertainStatus(allowedStatuses) {
  if (isEmpty(allowedStatuses)) return [];
  const ALLOWED_STATUSES = ['installing', 'downloading', 'successful', 'downloading_update_agent', 'installing_update_agent', 'open', 'failed'];
  const tmp = [];
  allowedStatuses.forEach((updateSchedule) => {
    if (ALLOWED_STATUSES.includes(updateSchedule?.status?.code)) {
      let tmpUS = { ...updateSchedule };
      const toRename = ['downloading', 'installing'];
      if (toRename.some((status) => tmpUS?.status?.code.includes(status))) tmpUS = renameDownloadingStatus(tmpUS);
      tmp.push(tmpUS);
    }
  });
  return tmp;
}
