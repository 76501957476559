import PropTypes from 'prop-types';
import React from 'react';
import { MenuItem } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import ActionDropdownButton from './ActionDropdownButton';
import { intlShape } from '../../shapes';

export function SortActionDropdownButton({
  handleSortClick, sortOptions, isOptionDisabled, intl, defaultCounter, defaultOption,
}) {
  const [state, setState] = React.useState({
    sortOption: defaultOption,
    sortClickCounter: defaultCounter,
    sorting: false,
  });

  React.useEffect(() => {
    const { sorting, sortOption, sortClickCounter } = state;
    if (sorting) {
      handleSortClick(sortOption, sortClickCounter);
      setState({ ...state, sorting: false });
    }
  }, [state]);

  const setSortIcon = () => {
    switch (state.sortClickCounter) {
      case 1:
        return (<span className="lineicon lcm-iot-icon-long-arrow-down" />);
      case 2:
        return (<span className="lineicon lcm-iot-icon-long-arrow-up" />);
      default:
        return null;
    }
  };

  const handleSortOptionSelect = (item) => {
    let sortClickCounterValue;
    let selectedItem;
    if (state.sortOption === item && state.sortClickCounter === 2) {
      sortClickCounterValue = 0;
      selectedItem = undefined;
    } else if (state.sortOption === item && state.sortClickCounter < 2) {
      const counter = state.sortClickCounter + 1;
      sortClickCounterValue = counter;
      selectedItem = item;
    } else {
      sortClickCounterValue = 1;
      selectedItem = item;
    }
    setState({
      sorting: true,
      sortOption: selectedItem,
      sortClickCounter: sortClickCounterValue,
    });
  };

  const renderSortItems = (
    sortOptions.map((item) => (
      <MenuItem
        className={item === state.sortOption ? 'dropdown-sort option-selected' : 'dropdown-sort'}
        key={item}
        eventKey="sorting_content"
        id={'sorting-'.concat(item).concat('-menu-item')}
        disabled={isOptionDisabled}
        onSelect={() => {
          handleSortOptionSelect(item);
        }}
      >
        <span className="dropdown-sort-label"><FormattedMessage id={'label.'.concat(item)} /></span>
        {item === state.sortOption ? setSortIcon() : null}
      </MenuItem>
    ))
  );

  return (
    <ActionDropdownButton
      id="sorting-button"
      icon="icon2-eh-sort"
      title={intl.formatMessage({ id: 'label.sort' })}
    >
      {renderSortItems}
    </ActionDropdownButton>
  );
}

SortActionDropdownButton.propTypes = {
  handleSortClick: PropTypes.func,
  intl: intlShape.isRequired,
  sortOptions: PropTypes.arrayOf(PropTypes.string),
  isOptionDisabled: PropTypes.bool,
  defaultCounter: PropTypes.number,
  defaultOption: PropTypes.string,
};

SortActionDropdownButton.defaultProps = {
  handleSortClick: undefined,
  sortOptions: [],
  isOptionDisabled: false,
  defaultCounter: 0,
  defaultOption: undefined,
};

export default injectIntl(SortActionDropdownButton);
