import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { MenuItem } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { withIntl } from '../../wrappers';

import {
  intlShape,
  nodeShape,
  recipeShape,
} from '../../shapes';

import {
  apiShape,
  browserShape,
  notifierShape,
  withApi,
  withBrowser,
  withNotifier,
} from '../../context';

import {
  url,
  isNotEmpty,
  isSomething,
} from '../../utils';

import {
  ConflictError,
  BadRequestError,
  fetchAccessRights,
} from '../../api';

import ItemMenu from '../ItemMenu';
import ListItem from '../List/ListItem';
import AllObjectsSelectModal from '../AllObjects/AllObjectsSelectModal';
import ConfirmationModal from '../ConfirmationModal';

export function RecipeItem({
  api,
  intl,
  recipe,
  browser,
  onClick,
  disabled,
  notifier,
  parentNode,
  showItemMenu,
  onRecipeRemoved,
}) {
  const [moving, setMoving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [accessRights, setAccessRights] = useState({
    canUpdate: false,
    canDelete: false,
    canPermit: false,
  });

  const formatDetails = () => {
    let details = recipe.typeName ? recipe.typeName : '';
    if (isNotEmpty(details) && isNotEmpty(recipe.description)) {
      details = details.concat(', ');
    }
    if (isNotEmpty(recipe.description)) {
      details = details.concat(recipe.description);
    }
    return details;
  };

  const handleOnItemMenuOpen = async () => {
    try {
      const result = await fetchAccessRights('Recipe', recipe.id);
      setAccessRights(result);
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  const handleOnEditClick = () => {
    browser.navigateTo(`/recipes/${recipe.id}/edit`);
  };

  const handleOnMoveClick = () => {
    setMoving(true);
  };

  const handleOnDeleteClick = () => {
    setDeleting(true);
  };

  const resetActionsState = () => {
    setMoving(false);
    setDeleting(false);
  };

  const handleOnConfirm = async () => {
    try {
      const specification = await api.get(`/recipes/${recipe.id}/specifications`, { key: 'eh.user_config.assigned_systems.*' });
      if (Object.keys(specification).length > 0) {
        throw new ConflictError(
          { method: 'DELETE', url: `/recipes/${recipe.id}/specifications` },
          {
            data: {
              errors: [{
                attribute: 'systems',
                message: 'cannot delete record because dependent systems exist',
                type: 'assigned_restriction',
              }],
            },
          },
        );
      }
      await api.delete(`/recipes/${recipe.id}`);
      onRecipeRemoved(recipe);
      notifier.showSuccess(intl.formatMessage({ id: 'recipe.actions.delete.notification' }));
    } catch (error) {
      if (error instanceof ConflictError && error.contains('assigned_restriction', 'batches')) {
        notifier.showWarning(intl.formatMessage({ id: 'api.error.recipe.assigned_restriction.batch' }));
      } else if (error instanceof ConflictError && error.contains('assigned_restriction', 'systems')) {
        notifier.showWarning(intl.formatMessage({ id: 'api.error.recipe.assigned_restriction.system' }));
      } else {
        notifier.showError(api.translateError(error));
      }
    } finally {
      resetActionsState();
    }
  };

  const handleOnSelectNode = async (node) => {
    try {
      if (node) {
        await api.post(`/recipes/${recipe.id}/nodes`, {
          nodes: [{
            id: node.id,
          }],
        });
      }
      if (parentNode) {
        await api.delete(`/recipes/${recipe.id}/nodes`, {
          nodes: [{
            id: parentNode.id,
          }],
        });
      }
      onRecipeRemoved(recipe);
      notifier.showSuccess(intl.formatMessage({ id: 'recipe.actions.move_assignment.notification' }));
      resetActionsState();
      return Promise.resolve();
    } catch (error) {
      if (error instanceof BadRequestError && error.contains('associations_already_added', 'nodes')) {
        notifier.showError(intl.formatMessage({ id: 'recipe.actions.assign.error' }));
      } else {
        notifier.showError(api.translateError(error));
      }
      return Promise.reject();
    }
  };

  return (
    <ListItem
      id={`recipe-item-${recipe.id}`}
      icon="lcm-iot-icon-recipe-rounded"
      title={recipe.name}
      description={formatDetails()}
      onClick={onClick ? () => onClick(recipe) : null}
      status={recipe.statusCode || 'undefined'}
      target={onClick ? undefined : url(`/recipes/${recipe.id}`)}
      disabled={disabled}
      actions={(
        <>
          {showItemMenu ? (
            <ItemMenu onOpen={handleOnItemMenuOpen}>
              <MenuItem
                id="recipe-item-edit"
                onSelect={handleOnEditClick}
                disabled={!accessRights.canUpdate}
              >
                <FormattedMessage id="button.edit" />
              </MenuItem>
              <MenuItem
                id="recipe-item-move"
                onSelect={handleOnMoveClick}
                disabled={!accessRights.canPermit}
              >
                <FormattedMessage id="button.move" />
              </MenuItem>
              <MenuItem
                id="recipe-item-delete"
                onSelect={handleOnDeleteClick}
                disabled={!accessRights.canDelete}
              >
                <FormattedMessage id="button.delete" />
              </MenuItem>
            </ItemMenu>
          ) : null }
          {moving
            ? (
              <AllObjectsSelectModal
                id="node-select-modal"
                onClose={resetActionsState}
                onSelectNode={handleOnSelectNode}
                canSelectRootNode={isSomething(parentNode?.id)}
              />
            ) : null}
          {deleting
            ? (
              <ConfirmationModal
                id="confirmation-modal"
                onClose={resetActionsState}
                titleText={intl.formatMessage({ id: 'recipe.actions.delete.modal_title' })}
                messageText={intl.formatMessage({ id: 'recipe.actions.delete.modal_message' })}
                onConfirm={handleOnConfirm}
                show
              />
            ) : null}
        </>
      )}
    />
  );
}

RecipeItem.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  recipe: recipeShape.isRequired,
  browser: browserShape.isRequired,
  notifier: notifierShape.isRequired,
  onRecipeRemoved: PropTypes.func.isRequired,
  parentNode: nodeShape,
  onClick: PropTypes.func,
  showItemMenu: PropTypes.bool,
  disabled: PropTypes.bool,
};

RecipeItem.defaultProps = {
  onClick: null,
  parentNode: null,
  showItemMenu: false,
  disabled: false,
};

export default withIntl(withApi(withBrowser(withNotifier(RecipeItem))));
