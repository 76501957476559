import PropTypes from 'prop-types';
import { matchPath } from 'react-router';
import { injectIntl } from 'react-intl';
import React, { useEffect } from 'react';
import { browserShape, withBrowser } from '../../context';

export function RedirectToApp({
  match,
  browser,
}) {
  useEffect(() => {
    const pathWhitelist = [
      '/assets/*',
      '/instrumentations/*',
      '/subscription/*',
      '/subscription',
      '/nodes/*',
      '/map',
      '/support/*',
      '/upgrade',
      '/feedback',
    ];

    const checkPathInWhitelist = (pathToCheck) => pathWhitelist.some((whitelistPath) => matchPath(
      pathToCheck,
      {
        path: whitelistPath,
        exact: true,
        strict: false,
      },
    ));

    const path = match.params[0];
    if (checkPathInWhitelist(`/${path}`)) {
      browser.navigateTo(`/${path}`);
    } else {
      browser.navigateTo('/');
    }
  }, [match]);

  return (<div>...</div>);
}

RedirectToApp.propTypes = {
  match: PropTypes.shape({ params: PropTypes.arrayOf(PropTypes.string) }),
  browser: browserShape.isRequired,
};

export default withBrowser(injectIntl(RedirectToApp));
