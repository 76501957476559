import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { intlShape } from '../../shapes';

export function DetailsHeaderItem({
  id, translationKey, label, value, intl, icon, breakValue,
}) {
  const displayLabel = label || (translationKey && intl.formatMessage({ id: translationKey }));
  const displayIcon = icon ? (<span data-testid="details-item-icon" className={icon} />) : null;
  return (
    <div id={id} className="details-header-item">
      <div className="details-item-name">{displayLabel}</div>
      <div className={breakValue ? 'details-item-value break-all' : 'details-item-value'}>
        {displayIcon}
        {' '}
        {value ?? '-'}
      </div>
    </div>
  );
}

DetailsHeaderItem.propTypes = {
  id: PropTypes.string,
  translationKey: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  intl: intlShape.isRequired,
  breakValue: PropTypes.bool,
};

export default injectIntl(DetailsHeaderItem);
