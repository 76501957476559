import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DetailsItem from '../Details/DetailsItem';
import {
  withRules, rulesShape, withApi, apiShape, withNotifier, notifierShape,
} from '../../context';
import { intlShape } from '../../shapes';

export function AssetStatus({
  asset, api, notifier, rules, intl,
}) {
  const [healthCondition, setHealthCondition] = useState();

  React.useEffect(() => {
    if (rules.asset(asset).get('showAssetStatusWithHealthCondition')) {
      const loadHealthCondition = async () => {
        try {
          const response = await api.get(`/assets/${asset.id}/health_conditions`);
          if (response.health_conditions.length > 0 && response.health_conditions[0].diagnosis_code !== 'OK' && response.health_conditions[0].diagnosis_code.indexOf('XGEN') !== 0) {
            setHealthCondition(response.health_conditions[0]);
          }
        } catch (error) {
          notifier.showError(api.translateError(error));
        }
      };
      loadHealthCondition();
    }
  }, [asset.id]);

  return (
    <DetailsItem
      id="asset-status"
      isNamurIcon
      icon={asset.status?.code}
      translationKey="label.asset_status"
      intl={intl}
    >
      <span id="extended-asset-status">
        {asset.status?.name}
        { healthCondition ? (
          <span>
            (
            <a href={`/app/health/products/${asset.product?.id}/health_conditions/${healthCondition.id}`} target="_new" id="health_link">{healthCondition.diagnosis_code}</a>
            )
          </span>
        ) : null }
      </span>
    </DetailsItem>
  );
}

AssetStatus.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.number.isRequired,
    product: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    status: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  rules: rulesShape.isRequired,
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(withApi(withNotifier(withRules(AssetStatus))));
