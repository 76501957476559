import ApiClientError from './ApiClientError';

class ServiceUnavailableError extends ApiClientError {
  /* istanbul ignore next */
  constructor(request, response, cause) {
    super('ServiceUnavailableError', 503, 'Service is not available. Try in a while.', request, response, cause);
  }
}

export default ServiceUnavailableError;
