import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { backendShape, url } from '../..';
import { Column, Row, Container } from '../Grid';
import { withBackend, withConfiguration } from '../../context';
import Loader from '../Loader';

export function Feedback({ backend, configuration }) {
  const [loading, setLoading] = useState(true);

  /* istanbul ignore next */
  React.useEffect(() => {
    const loadCanny = async () => {
      const cannyToken = await backend.get('/canny_token')
        // eslint-disable-next-line no-console
        .catch(console.log);

      // eslint-disable-next-line
      (function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}})(window,document,"canny-jssdk","script");

      // eslint-disable-next-line no-undef
      Canny('render', {
        boardToken: configuration.appCannyBoardToken,
        basePath: url('/feedback'),
        ssoToken: cannyToken,
      });
      setLoading(false);
    };
    /* istanbul ignore next */
    loadCanny();
  }, []);

  return (
    <Container>
      <Row>
        <Column>
          <h1><FormattedMessage id="feedback.header" /></h1>
          <Loader id="feedback-loader" loading={loading} />
          <div data-canny />
        </Column>
      </Row>
    </Container>
  );
}

Feedback.propTypes = {
  backend: backendShape,
  configuration: PropTypes.shape({ appCannyBoardToken: PropTypes.string }).isRequired,
};

export default withBackend(withConfiguration(Feedback));
