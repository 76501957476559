import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';

class ItemMenu extends Component {
  static propTypes = {
    children: PropTypes.node,
    onOpen: PropTypes.func,
  };

  render() {
    const { children, onOpen } = this.props;
    return (
      <div className="list-item-menu">
        <DropdownButton
          onClick={onOpen}
          ref={this.handleDropdownRef}
          bsStyle="default"
          title="..."
          noCaret
          id="dropdown-menu"
          data-testid="dropdown-menu-button"
          pullRight
        >
          {children}
          <MenuItem className="close-item"><span className="icon icon-eh-show-less" /></MenuItem>
        </DropdownButton>
      </div>
    );
  }
}

export default ItemMenu;
