import { isSomething } from '../utils/langUtils';

const BREADCRUMP_SEPARATOR = '-';

export function extractInstrumentationType(rawInstrumentationType) {
  const tenantId = rawInstrumentationType.tenant ? rawInstrumentationType.tenant.id : null;
  const tenantName = rawInstrumentationType.tenant ? rawInstrumentationType.tenant.name : null;
  const tenantPublic = rawInstrumentationType.tenant ? rawInstrumentationType.tenant.public : null;

  return {
    id: rawInstrumentationType.id,
    name: rawInstrumentationType.name,
    code: rawInstrumentationType.code,
    tenantId,
    tenantPublic,
    tenantName,
    parentId: rawInstrumentationType.parent ? rawInstrumentationType.parent.id : null,
  };
}

function buildDisplayNameForTree(type) {
  if (isSomething(type.parent)) {
    return `${buildDisplayNameForTree(type.parent)} ${BREADCRUMP_SEPARATOR} ${type.name}`;
  }
  return type.name;
}

export function buildDisplayName(type, allTypes) {
  if (isSomething(type.parentId)) {
    const parent = allTypes.find((t) => t.id === type.parentId);
    if (parent.parentId) {
      return `${buildDisplayName(parent, allTypes)} ${BREADCRUMP_SEPARATOR} ${type.name}`;
    }
    return `${parent.name} ${BREADCRUMP_SEPARATOR} ${type.name}`;
  }
  return type.name;
}

export function extractInstrumentationTypes(rawTypes, buildBreadcrumbNames = false) {
  const mappedTypes = rawTypes.instrumentation_types.map((type) => extractInstrumentationType(type));
  if (buildBreadcrumbNames) {
    mappedTypes.forEach((type) => {
      /* eslint-disable no-param-reassign */
      type.displayName = buildDisplayName(type, mappedTypes);
    });
  }
  return mappedTypes;
}

export function extractInstrumentationTypeTree(rawInstrumentationType) {
  const type = extractInstrumentationType(rawInstrumentationType);
  type.displayName = buildDisplayNameForTree(rawInstrumentationType);
  return type;
}
