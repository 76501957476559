import React from 'react';
import PropTypes from 'prop-types';
import {
  TypeaheadBox,
  apiShape,
  notifierShape,
  withApi,
  withNotifier,
} from 'lcm-iot-commons';

export function ProductTypeahead(props) {
  const [products, setProducts] = React.useState();
  const [fetching, setFetching] = React.useState(true);
  const {
    api, notifier, tenantId, manufacturerId, excludeProductId,
  } = props;
  const { name, setFieldValue } = props;

  React.useEffect(() => {
    const loadProducts = async () => {
      if (products) {
        setFieldValue(name, null);
      }
      try {
        if (!tenantId || !manufacturerId) {
          setProducts(undefined);
          return;
        }
        const response = await api.get('/products', { tenant_id: tenantId, manufacturer_id: manufacturerId });
        let newResponse;
        if (excludeProductId) {
          newResponse = response.products.filter((product) => product.id !== excludeProductId);
        } else {
          newResponse = response.products;
        }
        const productsExt = newResponse.map((product) => ({
          id: product.id,
          name: product.name || product.code,
        }));
        setProducts(productsExt);
      } catch (error) {
        notifier.showError(api.translateError(error));
      } finally {
        setFetching(false);
      }
    };
    loadProducts();
  }, [manufacturerId]);

  return fetching ? null : (
    <TypeaheadBox
      {...props}
      options={products}
      labelKey="name"
    />
  );
}

ProductTypeahead.propTypes = {
  name: PropTypes.string.isRequired,
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
  tenantId: PropTypes.number,
  manufacturerId: PropTypes.number,
  excludeProductId: PropTypes.number,
  setFieldValue: PropTypes.func.isRequired,
};

ProductTypeahead.defaultProps = {
  tenantId: null,
  manufacturerId: null,
  excludeProductId: null,
};

export default withNotifier(withApi(ProductTypeahead));
