import React from 'react';
import PropTypes from 'prop-types';

import InfiniteScroll from 'react-infinite-scroller';
import { Tooltip } from 'react-bootstrap';
import AssetItem from '../Assets/AssetItem';
import InstrumentationItem from '../Instrumentations/InstrumentationItem';
import RecipeItem from '../Recipes/RecipeItem';
import NodeItem from '../Nodes/NodeItem';
import SystemItem from '../Systems/SystemItem';

import List from '../List/List';

import {
  accessRightsShape, intlShape,
} from '../../shapes';
import VirtualNodeItem from '../VirtualNodes/VirtualNodeItem';
import { withFlipper, withIntl } from '../../wrappers';
import CustomOverlayTrigger from '../CustomOverlayTrigger';

export function AllObjectsList({
  accessRights,
  fetching,
  hasMore,
  items,
  loadMore,
  node,
  onItemRemoved,
  fermentationAddonAvailable,
  intl,
  flipper,
}) {
  const fermAddonReqTooltip = (
    <Tooltip id="tooltip">{ intl.formatMessage({ id: 'recipe.fermentation_addon_required' }) }</Tooltip>
  );
  return (
    <List id="nodes-list">
      <InfiniteScroll
        initialLoad={false}
        loadMore={loadMore}
        hasMore={!fetching && hasMore}
        threshold={700}
        id="objects"
      >
        {items.map((item) => {
          switch (item.itemType) {
            case 'node':
              return (
                <NodeItem
                  key={`n${item.id}`}
                  node={item}
                  parentNode={node || item}
                  onNodeRemoved={onItemRemoved}
                  showItemMenu
                />
              );
            case 'instrumentation':
              return (
                <InstrumentationItem
                  key={`i${item.id}`}
                  instrumentation={item}
                  parentNode={node}
                  parentAccessRights={accessRights}
                  onInstrumentationRemoved={onItemRemoved}
                  showItemMenu
                />
              );
            case 'asset':
              return (
                <AssetItem
                  key={`a${item.id}`}
                  asset={item}
                  parentNode={node}
                  parentAccessRights={accessRights}
                  onAssetRemoved={onItemRemoved}
                  showItemMenu
                />
              );
            case 'recipe':
              /* istanbul ignore next */
              return !fermentationAddonAvailable
                ? (
                  <CustomOverlayTrigger key={`r${item.id}-overlay`} placement="bottom" overlay={fermAddonReqTooltip}>
                    <span>
                      <RecipeItem
                        key={`r${item.id}`}
                        recipe={item}
                        parentNode={node}
                        onRecipeRemoved={onItemRemoved}
                        disabled
                        onClick={() => {}}
                        showItemMenu={fermentationAddonAvailable}
                      />
                    </span>
                  </CustomOverlayTrigger>
                ) : (
                  <RecipeItem
                    key={`r${item.id}`}
                    recipe={item}
                    parentNode={node}
                    onRecipeRemoved={onItemRemoved}
                    showItemMenu
                  />
                );
            case 'system':
              return (
                <SystemItem
                  key={`s${item.id}`}
                  system={item}
                  parentNode={node}
                  parentAccessRights={accessRights}
                  onSystemRemoved={onItemRemoved}
                  showItemMenu
                />
              );
            case 'virtualNode':
              return !flipper.fermentationDeactivated ? (
                <VirtualNodeItem key={`s${item.id}`} virtualNode={item} />
              ) : null;
            default:
              return null;
          }
        })}
      </InfiniteScroll>
    </List>
  );
}

AllObjectsList.propTypes = {
  accessRights: accessRightsShape,
  fetching: PropTypes.bool,
  hasMore: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  loadMore: PropTypes.func.isRequired,
  node: PropTypes.shape({}),
  onItemRemoved: PropTypes.func,
  fermentationAddonAvailable: PropTypes.bool,
  intl: intlShape,
  flipper: PropTypes.shape({
    fermentationDeactivated: PropTypes.bool,
  }).isRequired,
};

AllObjectsList.defaultProps = {
  accessRights: undefined,
  fetching: false,
  hasMore: false,
  items: [],
  node: undefined,
  fermentationAddonAvailable: true,
};

export default withFlipper(withIntl(AllObjectsList));
