import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import intlShape from '../../shapes/intlShape';

import {
  apiShape, withApi, notifierShape, withNotifier, withBrowser, browserShape,
} from '../../context';
import { capitalizeFirstLetter } from '../../utils';

import {
  SubmitButton,
} from '../Form';

export function SeatInvitationModal({
  seat, appName, intl, browser, api, notifier,
}) {
  const onAccept = async () => {
    try {
      const params = { status: 'accepted' };
      await api.patch(`/subscription/seats/${seat.id}`, params);
      notifier.showSuccess(intl.formatMessage({ id: 'seats.accept_seat_success_notification' }));
      browser.redirectTo('/', true);
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  const onDecline = async () => {
    try {
      await api.delete(`/subscription/seats/${seat.id}`);
      notifier.showSuccess(intl.formatMessage({ id: 'seats.decline_seat_success_notification' }));
      browser.navigateTo('/', true);
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  const subscriptionUser = seat.subscription.user;
  const name = `${subscriptionUser.first_name} ${subscriptionUser.last_name}`;
  const capitalizedAppName = capitalizeFirstLetter(appName);
  return (
    <Modal dialogClassName="seat-invitation" show restoreFocus={false} size="sm" backdrop="static">
      <Modal.Header closeButton={false}>
        <Modal.Title>
          {intl.formatMessage({ id: 'seat.invitation_title' }, { appName: capitalizedAppName })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="center">
          <div className="apps-menu">
            <div className="menu-item">
              <div className="menu-app-icon">
                <span className={`icon lcm-iot-icon-${appName.toLowerCase()}`} />
              </div>
              <span className="title">
                <span>{capitalizedAppName}</span>
              </span>
            </div>
          </div>
        </div>
        <p>
          {intl.formatMessage({ id: 'seat.invitation_message' }, { appName: capitalizeFirstLetter(appName), name })}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="container seat-invitation-footer">
          <div className="row">
            <div className="col-sm-6">
              <SubmitButton
                id="seat-invitation-accept"
                onClick={onAccept}
                text={intl.formatMessage({ id: 'seat.invitation_accept' })}
              />
            </div>
            <div className="col-sm-6">
              <button id="seat-invitation-decline" type="button" className="btn btn-default" onClick={onDecline}>
                {intl.formatMessage({ id: 'seat.invitation_decline' })}
              </button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

SeatInvitationModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  seat: PropTypes.object.isRequired,
  appName: PropTypes.string.isRequired,
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  browser: browserShape.isRequired,
};

export default withBrowser(withNotifier(withApi(injectIntl(SeatInvitationModal))));
