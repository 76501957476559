/* eslint-disable default-param-last */
import ActionType from '../constants/ActionType';
import { isNotEmpty } from '../utils/langUtils';

const initialState = {
  authenticated: false,
  captchaRequired: false,
  errors: null,
  fetching: false,
  sessionDeleted: false,
  sessionExpired: false,
  sessionExpiresAt: null,
  token: null,
  tokenExpiresAt: null,
  activeSubscription: false,
  subscriptionType: null,
  subscriptionStatus: null,
  subscriptionAssetQuota: null,
  subscriptionSeatQuota: null,
  subscriptionOwnerId: null,
  subscriptionStorageQuota: null,
  startUrl: null,
  reseller: null,
  applicationSpecifics: null,
  refreshFailed: false,
};

export default function sessionReducer(state = initialState, action) {
  const {
    type, token, errors, captchaRequired, tokenExpiresAt, sessionExpiresAt, activeSubscription, subscriptionType, subscriptionStatus,
    subscriptionAssetQuota, subscriptionSeatQuota, subscriptionOwnerId, subscriptionStorageQuota, startUrl, applicationSpecifics, reseller,
  } = action;
  switch (type) {
    case ActionType.INITIALIZE_SESSION:
      return {
        ...state,
        authenticated: isNotEmpty(token),
        token,
        tokenExpiresAt,
        sessionExpiresAt,
        captchaRequired,
        activeSubscription,
        subscriptionType,
        subscriptionStatus,
        subscriptionAssetQuota,
        subscriptionSeatQuota,
        subscriptionOwnerId,
        subscriptionStorageQuota,
        startUrl,
        reseller,
        applicationSpecifics,
        refreshFailed: false,
      };
    case ActionType.CREATE_SESSION_SUCCESSFUL:
      return {
        ...state,
        authenticated: isNotEmpty(token),
        token,
        tokenExpiresAt,
        sessionExpiresAt,
        activeSubscription,
        subscriptionType,
        subscriptionStatus,
        subscriptionAssetQuota,
        subscriptionSeatQuota,
        subscriptionOwnerId,
        subscriptionStorageQuota,
        startUrl,
        reseller,
        applicationSpecifics,
        errors: null,
        fetching: false,
        refreshFailed: false,
      };
    case ActionType.REFRESH_SESSION_SUCCESSFUL:
      return {
        ...state,
        authenticated: isNotEmpty(token),
        token,
        tokenExpiresAt,
        sessionExpiresAt,
        activeSubscription,
        subscriptionType,
        subscriptionStatus,
        subscriptionAssetQuota,
        subscriptionSeatQuota,
        subscriptionOwnerId,
        subscriptionStorageQuota,
        startUrl,
        reseller,
        applicationSpecifics,
        errors: null,
        fetching: false,
        refreshFailed: false,
      };
    case ActionType.REFRESH_SESSION_FAILED:
      return {
        ...state, authenticated: false, refreshFailed: true, token: null, tokenExpiresAt: null, errors, fetching: false,
      };
    case ActionType.CREATE_SESSION_FAILED:
      return {
        ...state,
        authenticated: false,
        token: null,
        tokenExpiresAt: null,
        errors,
        fetching: false,
        captchaRequired: errors.map((error) => error.type).includes('too_many_login_attempts'),
        activeSubscription: false,
        subscriptionType: null,
        subscriptionStatus: null,
        subscriptionAssetQuota: null,
        subscriptionSeatQuota: null,
        subscriptionOwnerId: null,
        subscriptionStorageQuota: null,
        applicationSpecifics: null,
        reseller: null,
      };
    case ActionType.CREATE_SESSION_FETCHING:
      return { ...state, fetching: true, refreshFailed: false };
    case ActionType.DELETE_SESSION_SUCCESSFUL:
      return {
        ...state,
        authenticated: false,
        token: null,
        fetching: false,
        tokenExpiresAt: null,
        sessionExpiresAt: null,
        sessionDeleted: true,
        activeSubscription: false,
        subscriptionType: null,
        subscriptionStatus: null,
        subscriptionAssetQuota: null,
        subscriptionSeatQuota: null,
        subscriptionOwnerId: null,
        subscriptionStorageQuota: null,
        applicationSpecifics: null,
        reseller: null,
      };
    case ActionType.SESSION_EXPIRED:
      return { ...state, sessionExpired: true, refreshFailed: false };
    default:
      return state;
  }
}
