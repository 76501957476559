import moment from 'moment';
import { isNumber, isSomething } from './langUtils';

export function formatDate(timestamp, intl, options = null) {
// some month options: 'long', 'short', '2-digit'
  const defaultOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  const mergedOptions = defaultOptions;

  if (options) {
    Object.keys(options).filter((key) => key in defaultOptions).forEach((key) => {
      mergedOptions[key] = options[key];
    });
  }
  return `${intl.formatDate(timestamp, mergedOptions)}`;
}

export function parseISO8601Date(date) {
  // IE can not use Date.parse for 2017-08-10T06:59:49.000+0000
  // this is why we should use moment
  if (date !== null && date !== undefined && (window.document.documentMode)) {
    const m = moment(date, moment.ISO_8601);
    return m.toDate().getTime();
  }
  return Date.parse(date);
}

export function formatTimeIntl(timestamp, intl, options = null) {
  // method name might be strange> reason: formatTime is already defined in langUtils

  const defaultOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };

  const mergedOptions = defaultOptions;

  if (options) {
    Object.keys(options).filter((key) => key in defaultOptions).forEach((key) => {
      mergedOptions[key] = options[key];
    });
  }

  let time = intl.formatDate(timestamp, mergedOptions);

  if (time.split(':')[0].length === 1) {
    time = `0${time}`;
  }
  return time;
}

export function formatDateTime(timestamp, intl, options = null, commaSeparated = false) {
  const date = formatDate(timestamp, intl, options);
  const time = formatTimeIntl(timestamp, intl, options);
  return commaSeparated ? `${date}, ${time}` : `${date} ${time}`;
}

// eslint-disable-next-line no-shadow
export const getDaysOfWeek = ({ formatDate }, weekday = 'long') => [0, 1, 2, 3, 4, 5, 6]
  .map((day) => formatDate(new Date(Date.UTC(2023, 12, day)), { weekday }));

export function formatDateTypes(dateString, intl) {
  const isValidDateFormat = moment(dateString, 'YYYY-MM-DD', true).isValid();
  if (isValidDateFormat) {
    return formatDate(dateString, intl);
  }
  const isValidMonYrFormat = moment(dateString, 'YYYY-MM', true).isValid();
  if (isValidMonYrFormat) {
    const yearMonOptions = {
      month: '2-digit',
      year: 'numeric',
    };
    return `${intl.formatDate(dateString, yearMonOptions)}`;
  }
  const isValidYrFormat = moment(dateString, 'YYYY', true).isValid();
  if (isValidYrFormat) {
    const yearrOption = {
      year: 'numeric',
    };
    return `${intl.formatDate(dateString, yearrOption)}`;
  }
  return undefined;
}

export function dateDifferenceInDays(dateFrom, dateTo) {
  const startDate = moment.utc(dateFrom).startOf('day');
  const endDate = moment.utc(dateTo).startOf('day');
  return endDate.diff(startDate, 'days');
}

export function daysLeftUntil(date) {
  return dateDifferenceInDays(moment(), date);
}

export function convertMomentToISOString(momentDate) {
  return momentDate.toISOString();
}

export function subtractMinutesFromDate(date, durationInMinutes) {
  return moment.utc(date).subtract(durationInMinutes, 'minutes').toDate();
}

export function addMinutesFromDate(date, durationInMinutes) {
  return moment.utc(date).add(durationInMinutes, 'minutes').toDate();
}

export function subtractDateStrings(startDateString, endDateString) {
  if ((moment(startDateString).isValid() && isSomething(startDateString)) && (moment(endDateString).isValid() && isSomething(endDateString))) {
    const startDate = moment(startDateString);
    const endDate = moment(endDateString);
    return endDate - startDate;
  }
  return undefined;
}

export function addTimeToDateString(dateString, timeInMilliSeconds) {
  if (moment(dateString).isValid() && isNumber(timeInMilliSeconds)) {
    const updatedDate = moment(dateString).add(timeInMilliSeconds, 'milliseconds');
    return convertMomentToISOString(updatedDate);
  }
  return undefined;
}
