import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withIntl } from '../../wrappers';
import {
  withNotifier,
  notifierShape,
  withBrowser,
  browserShape,
  withApi,
  apiShape,
  withBackend,
  backendShape,
} from '../../context';
import { ControlledSwitchButton } from '../Form';
import { intlShape } from '../../shapes';
import ConfirmationModal from '../ConfirmationModal';
import {
  htmlSpan, url,
} from '../../utils';
import SpecificationKeys from '../../constants/SpecificationKeys';

export function ConnectedAssetActivation({
  notifier, browser, intl, deviceActive, hasNoInstrumentation, assetId, serialNumber, api, backend,
}) {
  const [showModal, setShowModal] = useState(false);
  const [isDeviceActive, setIsDeviceActive] = useState(deviceActive);

  const updateAssetStatus = async (shouldBeActive) => {
    const shouldBeActiveString = shouldBeActive.toString();
    await api.patch(`/assets/${assetId}/specifications`, {
      [SpecificationKeys.ActivationSettings.SHOULD_BE_ACTIVE]: { value: shouldBeActiveString },
      [SpecificationKeys.ActivationSettings.SIM_SHOULD_BE_ACTIVE]: { value: shouldBeActiveString },
    });
    backend.patch(`/connected_assets_status/${assetId}`);
  };

  const handleOnModalConfirm = () => {
    setShowModal(false);
    return updateAssetStatus(!isDeviceActive)
      .then(() => {
        setIsDeviceActive(!isDeviceActive);
        notifier.showSuccess(intl.formatMessage({ id: 'iiot_radar.update_activation.success_notification' }));
      })
      .catch(() => {
        notifier.showError(intl.formatMessage({ id: 'api.error.unknown' }));
      });
  };

  const onActiveToggleChange = () => {
    setShowModal(true);
  };

  const showActivationModal = () => {
    let modalActivationTitle = 'iiot_radar.radar_activation_title';
    let modalActivationText = (
      <span id="modal-activation-text">
        <b>{intl.formatMessage({ id: 'iiot_radar.radar_activation_text_1' })}</b>
        {intl.formatMessage({ id: 'iiot_radar.radar_activation_text_2' })}
      </span>
    );
    let image = 'FWR30.png';
    let secondConfirmLabel;

    if (isDeviceActive) {
      modalActivationTitle = 'iiot_radar.radar_deactivation_title';
      modalActivationText = (
        <span id="modal-deactivation-text">
          <b>{intl.formatMessage({ id: 'iiot_radar.radar_deactivation_text_1' })}</b>
          {intl.formatMessage({ id: 'iiot_radar.radar_deactivation_text_2' })}
        </span>
      );
      image = 'FWR30_Warning.png';
      secondConfirmLabel = (
        <FormattedMessage
          id="iiot_radar.reenter_serial_number_to_enable_confirm"
          values={{
            serial_number: serialNumber,
            span: htmlSpan({ className: 'strong break-all' }),
          }}
        />
      );
    }

    return (
      <ConfirmationModal
        id="activation-modal-dialog"
        show={showModal}
        titleText={intl.formatMessage({ id: modalActivationTitle })}
        messageText={modalActivationText}
        image={url(`/images/${image}`)}
        buttonConfirmText={intl.formatMessage({ id: 'button.confirm' })}
        onConfirm={() => handleOnModalConfirm()}
        onClose={() => setShowModal(false)}
        secondConfirmText={isDeviceActive ? serialNumber : undefined}
        secondConfirmLabel={secondConfirmLabel}
        showCancel
      />
    );
  };

  return (
    <div id="connected-asset-activation">
      <ControlledSwitchButton
        id="activation-status-switch"
        status={isDeviceActive}
        onChange={() => {
          if (!isDeviceActive && hasNoInstrumentation) {
            return updateAssetStatus(!isDeviceActive)
              .then(() => {
                notifier.showError(intl.formatMessage({ id: 'asset.actions.activation.no_instrument' }));
                const target = `/assets/${assetId}/instrumentations/create`;
                browser.navigateTo(target);
              })
              .catch(() => {
                notifier.showError(intl.formatMessage({ id: 'api.error.unknown' }));
              });
          }
          return onActiveToggleChange();
        }}
      />
      {showActivationModal()}
    </div>
  );
}

ConnectedAssetActivation.propTypes = {
  notifier: notifierShape.isRequired,
  intl: intlShape.isRequired,
  hasNoInstrumentation: PropTypes.bool,
  deviceActive: PropTypes.bool,
  assetId: PropTypes.string,
  serialNumber: PropTypes.string,
  browser: browserShape.isRequired,
  api: apiShape.isRequired,
  backend: backendShape.isRequired,
};

export default withBackend(withApi(withIntl(withNotifier(withBrowser(ConnectedAssetActivation)))));
