import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import { intlShape } from '../../shapes';
import { MAX_AMOUNT } from '../../utils/addonUtils';
import { withConfiguration } from '../../context';

export class PlanVariantAddon extends Component {
  static propTypes = {
    planVariantAddon: PropTypes.shape({
      id: PropTypes.string,
      currency: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.string,
      type: PropTypes.string,
      assets: PropTypes.number,
      storage: PropTypes.number,
      disabled: PropTypes.bool,
    }),
    intl: intlShape.isRequired,
    amount: PropTypes.number,
    minAmount: PropTypes.number,
    maxAmount: PropTypes.number,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    configuration: PropTypes.shape({
      planPriceDisplay: PropTypes.string,
    }),
  };

  static defaultProps = {
    maxAmount: MAX_AMOUNT,
  };

  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = { amount: '' };
  }

  componentDidMount() {
    this.setState({ amount: this.props.amount || '0' });
  }

  onChange() {
    const { minAmount, maxAmount } = this.props;
    let amount = null;
    if (this.input.value) {
      amount = parseInt(this.input.value, 10);
      if (amount > maxAmount) {
        return; // ignore change
      }
      if (minAmount && amount >= minAmount) {
        this.setState({ amount });
        this.dispatchChange(amount);
        return;
      }
      if (!minAmount && amount >= 0) {
        this.setState({ amount });
        this.dispatchChange(amount);
        return;
      }
    }
    if (minAmount) {
      this.setState({ amount: minAmount });
      this.dispatchChange(minAmount);
    } else {
      this.setState({ amount: '' });
      this.dispatchChange(0);
    }
  }

  dispatchChange(amount) {
    const { onChange, planVariantAddon } = this.props;
    onChange(amount, planVariantAddon);
  }

  addItem() {
    const { maxAmount } = this.props;
    const currentAmount = parseInt(this.input.value, 10);
    const amount = currentAmount ? currentAmount + 1 : 1;
    if (amount <= maxAmount) {
      this.setState({ amount });
      this.dispatchChange(amount);
    }
  }

  removeItem() {
    const currentAmount = parseInt(this.input.value, 10);
    const amount = currentAmount && currentAmount > 0 ? currentAmount - 1 : 0;
    this.setState({ amount });
    this.dispatchChange(amount);
  }

  render() {
    const {
      planVariantAddon, disabled, minAmount, maxAmount, configuration, intl,
    } = this.props;
    const price = planVariantAddon.price ? parseFloat(planVariantAddon.price) : null;
    const { amount } = this.state;
    const displayPrice = configuration.planPriceDisplay === 'yearly' ? `${intl.formatNumber((price).toFixed(2), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} / ${intl.formatMessage({ id: 'global.year' })}` : `${intl.formatNumber((price / 12).toFixed(2), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} / ${intl.formatMessage({ id: 'global.month' })}`;

    return (
      <div id={`plan-variant-addon-${planVariantAddon.id}`} className={`plan-variant-addon${planVariantAddon.disabled ? ' disabled' : ''}`}>
        <div className="plan-variant-addon-amount">
          <div className="input-group">
            <span className="input-group-addon">
              <button type="button" className="remove-button" disabled={disabled || amount === minAmount} onClick={this.removeItem}>-</button>
            </span>
            <input
              className="input-control"
              disabled={disabled}
              type="text"
              ref={/* istanbul ignore next */(input) => { this.input = input; }}
              onChange={this.onChange}
              value={amount}
            />
            <span className="input-group-addon"><button type="button" className="add-button" disabled={disabled || amount === maxAmount} onClick={this.addItem}>+</button></span>
          </div>
        </div>
        <div className="plan-variant-addon-details">
          {`${planVariantAddon.name} (${planVariantAddon.currency} ${displayPrice})`}
        </div>
      </div>
    );
  }
}

export default injectIntl(withConfiguration(PlanVariantAddon));
