import React, { Children, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Stepper from './Stepper';

function StepperModal({
  id,
  steps,
  show,
  titleText,
  onClose,
  disableBackStepAt,
  children,
}) {
  const [stepperState, setStepperState] = useState(0);

  const handleNext = async () => {
    if (stepperState !== steps.length - 1) setStepperState((prevStepperState) => prevStepperState + 1);
  };

  const handleBack = () => {
    if (stepperState !== 0) setStepperState((prevStepperState) => prevStepperState - 1);
  };

  const modalHeader = (
    <Modal.Header data-testid="modal-header">
      {titleText && <h2>{titleText}</h2>}
      <Stepper
        steps={steps}
        stepperState={stepperState}
        disableBackStepAt={disableBackStepAt}
        handleStepClicked={(index) => setStepperState(index)}
      />
    </Modal.Header>
  );

  return (
    <Modal data-testid={id} id={id} show={show} onHide={onClose} restoreFocus={false} dialogClassName="stepper-popup-container">
      {modalHeader}
      {Children.map(children.filter((child) => !!child), (child, i) => {
        if (i === stepperState) return React.cloneElement(child, { handleNext, handleBack });
        return undefined;
      })}
    </Modal>
  );
}

StepperModal.propTypes = {
  id: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  show: PropTypes.bool,
  titleText: PropTypes.string,
  onClose: PropTypes.func,
  disableBackStepAt: PropTypes.arrayOf(PropTypes.shape({ stepIndex: PropTypes.number, disabled: PropTypes.arrayOf(PropTypes.number) })),
  children: PropTypes.arrayOf(PropTypes.element),
};

export default StepperModal;
