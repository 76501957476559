import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import React, { Component } from 'react';
import { Formik } from 'formik';
import {
  redirectToExtern,
  isEmpty,
  handleUnknownErrors,
  showError, navigateTo,
} from '../../utils';

import {
  Form,
  TextInput,
  SubmitButton,
  CancelButton,
} from '../Form';

import { ActionBar } from '../ActionBar';
import { planCartShape, errorsShape, intlShape } from '../../shapes';
import ApiErrorsHandler from '../ApiErrorsHandler';
import Loader from '../Loader';
import { withLog, logShape } from '../../context';

import {
  loadSubscriptionPlanCart,
  updatePlanCartParameters,
  planCartCheckout,
} from '../../api';

import { apiErrorsContain } from '../../api/apiErrorUtils';

import SubscriptionInvoiceEstimate from './SubscriptionInvoiceEstimate';

export class SubscriptionCheckoutOverview extends Component {
  static propTypes = {
    /* eslint-disable react/forbid-prop-types */
    planCart: planCartShape,
    updating: PropTypes.bool,
    checkedout: PropTypes.bool,
    handleSubmit: PropTypes.func,
    intl: intlShape.isRequired,
    errors: errorsShape,
    couponCode: PropTypes.string,
    log: logShape,
  };

  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnDeleteDiscountCode = this.handleOnDeleteDiscountCode.bind(this);
    this.onSubmitCoupon = this.onSubmitCoupon.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.state = {
      loading: true,
      isDeleting: false,
      checkingOut: false,
    };
  }

  componentDidMount() {
    const { intl } = this.props;
    this.loadData()
      .catch((apiErrors) => {
        this.setState({ loading: false });
        handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
      });
  }

  async handleOnDeleteDiscountCode() {
    const { intl } = this.props;
    this.setState({ isDeleting: true });
    try {
      const planCartWithoutVoucher = await updatePlanCartParameters({ coupon_code: null, nullify_shipping: false });
      this.setState({ planCart: planCartWithoutVoucher, isDeleting: false });
    } catch (errors) {
      handleUnknownErrors(errors, intl.formatMessage({ id: 'api.error.unknown' }));
      this.setState({ isDeleting: false });
    }
  }

  async handleOnSubmit() {
    const { intl, log } = this.props;
    this.setState({ checkingOut: true });

    planCartCheckout().then((nextPlanCart) => {
      redirectToExtern(nextPlanCart.checkoutUrl);
    }).catch((errors) => {
      this.setState({ checkingOut: false });

      if (apiErrorsContain(errors, 'conflict')) {
        showError(intl.formatMessage({ id: 'api.error.subscription_conflict' }));
        log.error('SubscriptionCheckoutOverview', new Error('cannot create subscription due to conflict'));
        navigateTo('/');
      } else {
        handleUnknownErrors(errors, intl.formatMessage({ id: 'api.error.unknown' }));
      }
    });
  }

  async onSubmitCoupon(data, actions) {
    const { intl } = this.props;
    try {
      const planCartWithVoucher = await updatePlanCartParameters({ coupon_code: data.couponCode, nullify_shipping: false });
      this.setState({ planCart: planCartWithVoucher });
    } catch (errors) {
      const formErrors = {};
      if (apiErrorsContain(errors, 'invalid_input', 'coupon_code')) {
        formErrors.couponCode = intl.formatMessage({ id: 'api.error.coupon_code.invalid_input' });
      } else {
        handleUnknownErrors(errors, intl.formatMessage({ id: 'api.error.unknown' }));
      }
      actions.setErrors(formErrors);
    }
    actions.setSubmitting(false);
  }

  async loadData() {
    const planCart = await loadSubscriptionPlanCart();
    this.setState({ planCart, loading: false });
  }

  validateForm(values) {
    const { intl } = this.props;
    const errors = {};
    if (isEmpty(values.couponCode) || isEmpty(values.couponCode.trim())) {
      errors.couponCode = intl.formatMessage({ id: 'api.error.coupon_code.invalid_input' });
    }
    return errors;
  }

  renderForm(props) {
    const { intl } = this.props;
    const { planCart, isDeleting } = this.state;
    const { isSubmitting } = props;
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.form = props;

    const deleteCouponButton = planCart && planCart.couponCode ? (
      <div style={{ float: 'right' }}>
        <SubmitButton
          id="delete_coupon_code_button"
          fetching={isDeleting}
          hero={false}
          text={intl.formatMessage({ id: 'subscription_checkout.delete_button' })}
          onClick={this.handleOnDeleteDiscountCode}
        />
      </div>
    ) : null;

    const enterCouponCode = planCart && !planCart.couponCode ? (
      <Form {...props}>
        <div className="row">
          <div className="col-xs-12">
            <TextInput
              {...props}
              id="coupon_code"
              name="couponCode"
              label={intl.formatMessage({ id: 'subscription_checkout.coupon_code' })}
            />
          </div>
        </div>
        <div className="btn-group">
          <SubmitButton
            id="apply_coupon_code_button"
            text={intl.formatMessage({ id: 'subscription_checkout.apply_button' })}
            hero={!isEmpty(props.values.couponCode)}
            disabled={!props.dirty}
            fetching={isSubmitting}
          />
        </div>
      </Form>
    ) : null;

    return (
      <div>
        {enterCouponCode}
        {deleteCouponButton}
      </div>
    );
  }

  render() {
    const { planCart, loading, checkingOut } = this.state;
    const { intl } = this.props;
    const errors = [];

    const invoiceEstimateDetails = planCart && !loading ? (
      <div id="subscription-checkout-invoice-estimate" className="row">
        <div className="col-md-12">
          <SubscriptionInvoiceEstimate invoiceEstimate={planCart.invoiceEstimate} perInvoice />
        </div>
      </div>
    ) : null;

    const couponCodeDetailsFormik = planCart && planCart.couponIsAllowed && !loading ? (
      <div className="row">
        <div className="col-md-offset-8 col-md-4">
          <Formik
            onSubmit={this.onSubmitCoupon}
            render={this.renderForm}
            validate={this.validateForm}
            validateOnBlur={false}
            validateOnChange={false}
          />
        </div>
      </div>
    ) : null;

    const submitButton = planCart && !loading ? (
      <div>
        <div className="row">
          <div className="col-md-12 space-before">
            <div className="btn-group">
              <SubmitButton
                id="subscribe-button"
                fetching={checkingOut}
                disabled={checkingOut}
                text={intl.formatMessage({ id: 'subscription_overview.submit_button' })}
                onClick={this.handleOnSubmit}
              />
              <CancelButton id="cancel_button" disabled={checkingOut} />
            </div>
          </div>
        </div>
      </div>
    ) : null;

    return (
      <ApiErrorsHandler errors={errors} submitCallback={this.submitCallback}>
        <div id="subscription-checkout" className="container">
          <ActionBar>
            <h1><FormattedMessage id="subscription_overview.header" /></h1>
          </ActionBar>
          <Loader loading={!planCart} />
          {invoiceEstimateDetails}
          {couponCodeDetailsFormik}
          {submitButton}
        </div>
      </ApiErrorsHandler>
    );
  }
}

export default injectIntl(withLog(SubscriptionCheckoutOverview));
