import { FormattedMessage, injectIntl } from 'react-intl';
import React from 'react';
import { intlShape } from '../../shapes';
import { Container, Column, Row } from '../Grid';

export function PlansMaintenance({
  intl,
}) {
  return (

    <Container
      id="hide_plans"
      className="plan-maintenance"
    >
      <Row>
        <Column sm="12" md="6" className="column-1">
          <strong>
            <div className="title-1" id="plan-maintenance-title-1">
              {intl.formatMessage({ id: 'plan_maintenance.title_1' })}
            </div>
            <div className="title-2" id="plan-maintenance-title-2">
              {intl.formatMessage({ id: 'plan_maintenance.title_2' })}
            </div>
          </strong>
          <div className="body" id="plan-maintenance-body">
            {intl.formatMessage({ id: 'plan_maintenance.body' })}
          </div>
          <div className="footer">
            <FormattedMessage
              id="plan_maintenance.footer"
              values={{
                // serial_number: serialNumber,
                blog: <a href="https://netilion.endress.com/blog/">{intl.formatMessage({ id: 'label.blog' })}</a>,
              }}
            />
          </div>

        </Column>
        <Column sm="12" md="6" className="column-2">
          <img
            src="https://res.cloudinary.com/hpnfc0f7b/image/upload/v1605688933/plan_maintenance_coffee.png"
            alt="Coffee break"
            className="picture"
          />
        </Column>
      </Row>
    </Container>

  );
}

PlansMaintenance.propTypes = {
  intl: intlShape.isRequired,
};
export default injectIntl(PlansMaintenance);
