import PropTypes from 'prop-types';
import React from 'react';
import { isNotEmpty, handleFormikValueChange } from '../../utils';

function ToggleButton(props) {
  const {
    id, name, text, disabled, isSubmitting, values,
  } = props;
  const displayText = isNotEmpty(text) ? text : 'Unknown';
  const toggleStyle = values && values[name] ? 'btn-toggle-on' : 'btn-toggle-off';
  const className = `btn-toggle ${toggleStyle}`;

  return (
    <div>
      <button
        id={id}
        name={name}
        className={className}
        onClick={() => handleFormikValueChange(props, !values[name])}
        disabled={disabled || isSubmitting}
        type="button"
      >
        <span>{displayText}</span>
      </button>
    </div>
  );
}

ToggleButton.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  /* eslint-disable react/forbid-prop-types */
  values: PropTypes.object,
};

export default ToggleButton;
