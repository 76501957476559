import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import uuid from 'uuid';
import { formatAddress } from '../../utils';
import VatPrefixes from '../../constants/VatPrefix';
import { addressShape } from '../../shapes/addressShapes';
import { Details, DetailsItem } from '../Details';

export function SubscriptionBillingDetails({ billingAddress, shippingAddress, customerPurchaseOrder }) {
  const billingAddressDisplayed = billingAddress;
  const shippingAddressDisplayed = shippingAddress;
  const vatRequired = (VatPrefixes.forCountryCode(billingAddressDisplayed.countryCode) !== '');
  const renderedShippingAddress = shippingAddressDisplayed ? (
    <ul className="shipping-billing-address">
      {formatAddress(shippingAddressDisplayed).map((line) => (<li className="shipping-billing-line" key={uuid()}>{line}</li>))}
    </ul>
  ) : (
    <div>
      <FormattedMessage id="address.shipping_is_billing_address" />
    </div>
  );

  const renderVatNumber = vatRequired ? (
    <DetailsItem
      id="subscription-vat-number"
      translationKey="label.vat_number"
      value={`${VatPrefixes.forCountryCode(billingAddressDisplayed.countryCode)}${billingAddressDisplayed.vatNumber}`}
    />
  ) : null;

  return (
    <div className="row">
      <div className="col-md-6">
        <Details>
          <DetailsItem id="subscription-billing-address" translationKey="subscription.billing_address.header">
            <ul className="shipping-billing-address">
              {formatAddress(billingAddressDisplayed).map((line) => (<li className="shipping-billing-line" key={uuid()}>{line}</li>))}
            </ul>
          </DetailsItem>
          {renderVatNumber}
          <DetailsItem id="subscription-purchase-order" translationKey="label.customer_purchase_order" value={customerPurchaseOrder} />
          <DetailsItem id="subscription-shipping-address" translationKey="subscription.shipping_address.header">
            {renderedShippingAddress}
          </DetailsItem>
        </Details>
      </div>
    </div>
  );
}

SubscriptionBillingDetails.propTypes = {
  billingAddress: addressShape.isRequired,
  shippingAddress: addressShape,
  customerPurchaseOrder: PropTypes.string,
};

export default injectIntl(SubscriptionBillingDetails);
