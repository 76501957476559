import { FormattedMessage, injectIntl } from 'react-intl';
import React from 'react';
import ContextualHelp from '../Contextuals/ContextualHelp';
import { intlShape } from '../../shapes';

export function AssetCalibrationHelp({ intl }) {
  return (
    <ContextualHelp title={intl.formatMessage({ id: 'label.offset_settings' })}>
      <p className="space-after"><FormattedMessage id="calibration_help.description" /></p>
      <p>
        <strong><FormattedMessage id="calibration_status.title" /></strong>
        :&nbsp;
        <FormattedMessage id="calibration_status.description" />
      </p>
      <p>
        <strong><FormattedMessage id="calibration.date.time" /></strong>
        :&nbsp;
        <FormattedMessage id="calibration.date.time.description" />
      </p>
      <p>
        <strong><FormattedMessage id="calibration.values" /></strong>
        :&nbsp;
        <FormattedMessage id="calibration.values.description" />
      </p>
    </ContextualHelp>
  );
}

AssetCalibrationHelp.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AssetCalibrationHelp);
