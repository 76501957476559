import { parseISO8601Date } from './dateTimeUtils';

export function fileStorageExceeded(subscription) {
  const exceeded = subscription?.usage?.file_storage
    && subscription?.storage_quota
    && subscription?.storage_quota <= subscription?.usage?.file_storage;
  return exceeded;
}

export function fileStorageAlertExists(subscription) {
  const fileStorageAlert = fileStorageExceeded(subscription)
    && subscription.file_storage_notification_status === 'alert_notification'
    && subscription.file_storage_notification_status_updated_at !== undefined;

  return fileStorageAlert;
}

export function showNewerElementIfFileStorageAlertExist(subscription, attachmentCreatedAt) {
  const notificationStatusUpdatedAtDate = new Date(parseISO8601Date(subscription?.file_storage_notification_status_updated_at));
  const attachmentCreatedAtDate = new Date(parseISO8601Date(attachmentCreatedAt));
  if (fileStorageAlertExists(subscription)
    && notificationStatusUpdatedAtDate < attachmentCreatedAtDate) {
    return false;
  }
  return true;
}

export function dataStorageExceeded(subscription) {
  const exceeded = subscription?.usage?.data_storage
    && !!subscription?.data_storage_quota
    && subscription?.data_storage_quota <= subscription?.usage?.data_storage;
  return exceeded;
}

export function dataStorageAlertExists(subscription) {
  const dataStorageAlert = dataStorageExceeded(subscription)
    && subscription?.data_storage_notification_status === 'alert_notification'
    && !!subscription?.data_storage_notification_status_updated_at;
  return dataStorageAlert;
}

export function replaceNewerElementIfDataStorageAlertExist(subscription, timestamp, currentNode, hiddenNode) {
  if (dataStorageAlertExists(subscription)
    && timestamp
    && new Date(parseISO8601Date(subscription.data_storage_notification_status_updated_at)) < timestamp) {
    return hiddenNode;
  }
  return currentNode;
}

export function filterNewerElementsIfDataStorageAlertExist(subscription, values) {
  if (dataStorageAlertExists(subscription)
    && values
    && values instanceof Array
  ) {
    const subscriptionUntilDate = new Date(parseISO8601Date(subscription.data_storage_notification_status_updated_at));
    return values.filter((value) => !(value.timestamp

      && subscriptionUntilDate < value.timestamp));
  }
  return values;
}
