/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import validator from 'validator';

import {
  Form,
  Loader,
  Heading,
  Container,
  Row,
  Column,
  ActionBar,
  DetailsItem,
  TextArea,
  TextInput,
  SelectBox,
  ButtonGroup,
  SubmitButton,
  CancelButton,
  SwitchInput,
  PermissionsEdit,
  apiShape,
  intlShape,
  accessRightsShape,
  userShape,
  browserShape,
  notifierShape,
  withApi,
  withIntl,
  withUser,
  withBrowser,
  withNotifier,
  withAccessRights,
  handleFormikValueChange,
  capitalizeWords,
  removeNullAttributes,
  setEmptyOrUndefinedToNull,
  pullBooleanFromSpec,
  pullValueFromSpec,
  PermissionType,
} from 'lcm-iot-commons';

import {
  EdgeDeviceTypes,
} from './EdgeDeviceConstants';

import EdgeDeviceApplicationsEdit from './EdgeDeviceApplicationsEdit';
import EdgeNotificationHandler from './EdgeNotificationHandler';

const logLevelOptions = [
  { code: 'trace', name: 'Trace' },
  { code: 'debug', name: 'Debug' },
  { code: 'info', name: 'Info' },
  { code: 'warning', name: 'Warning' },
  { code: 'error', name: 'Error' },
  { code: 'critical', name: 'Critical' },
  { code: 'off', name: 'Off' },
];

export function convertSecondsToMinutes(sec) {
  if (!sec) { return null; }
  return Math.round(sec / 60);
}

export function convertMinutesToSeconds(min) {
  if (!min) { return null; }
  return parseInt(min, 10) * 60;
}

export function extractInitialValues(response) {
  const initialValues = {};
  initialValues.id = response.id;
  initialValues.type = response.type;
  initialValues.serial_number = response.serial_number;
  initialValues.name = response.name;
  initialValues.description = response.description;
  initialValues.is_sgc200 = response.type?.code === EdgeDeviceTypes.SGC200;
  initialValues.has_api_subscription = 'api_subscription' in response;
  initialValues.client_application = response.api_subscription?.client_application?.name;
  initialValues.ethernetip_supported = response.software_version?.ethernetip_supported;
  initialValues.ethernet_ip_active = pullBooleanFromSpec(response.specifications, ['eh.user_config.ethernet_ip_active']);
  initialValues.data_collection_interval = convertSecondsToMinutes(pullValueFromSpec(response.specifications, ['eh.user_config.data_collection_interval'])); // they come in in seconds!!
  initialValues.data_send_interval = convertSecondsToMinutes(pullValueFromSpec(response.specifications, ['eh.user_config.data_send_interval']));
  initialValues.log_level = {
    code: response.log_level,
    name: capitalizeWords(response.log_level),
  };
  return initialValues;
}

export function createPayload(values) {
  const {
    name, description, log_level, is_sgc200, data_collection_interval, data_send_interval, ethernetip_supported, ethernet_ip_active,
  } = values;
  let payload = {
    name: name?.trim(),
    description: description?.trim(),
    log_level: log_level.code,
  };

  if (is_sgc200) {
    payload = {
      ...payload,
      specifications: {
        'eh.user_config.data_collection_interval': { value: convertMinutesToSeconds(data_collection_interval)?.toString() },
        'eh.user_config.data_send_interval': { value: convertMinutesToSeconds(data_send_interval)?.toString() },
      },
    };
  }
  if (ethernetip_supported) {
    payload.specifications = {
      ...payload.specifications,
      'eh.user_config.ethernet_ip_active': { value: ethernet_ip_active?.toString() },
    };
  }
  return setEmptyOrUndefinedToNull(payload);
}

export const validateAccessRights = (accessRights) => accessRights.canUpdate;

export function EdgeDeviceEdit(props) {
  const {
    api, intl, browser, match, notifier, accessRights, user,
  } = props;
  const { id } = match.params;
  const [initialValues, setInitialValues] = useState();
  const edgeDeviceAdminRole = user?.userRoles.find((role) => role.name === 'edge_device_admin');
  const notEdgeDeviceAdmin = !(edgeDeviceAdminRole);

  const loadData = async () => {
    try {
      const response = await api.get(`/edm/edge_devices/${id}`, { include: 'type,specifications,software_version' }, false);
      setInitialValues(extractInitialValues(response, intl));
    } catch (error) {
      notifier.showError(api.translateError(error));
      setInitialValues({});
    }
  };

  React.useEffect(() => {
    loadData();
  }, [id]);

  const onSubmit = async (values, actions) => {
    const payload = createPayload(values);
    try {
      await api.patch(`/edm/edge_devices/${id}`, payload);
      /* istanbul ignore else */
      if (payload.specifications) {
        await api.patch(`/edm/edge_devices/${id}/specifications`, payload.specifications);
      }
      notifier.showSuccess(intl.formatMessage({ id: 'edge_device_edit.success_notification' }));
      browser.goBack();
    } catch (error) {
      notifier.showError(api.translateError(error));
    } finally {
      actions.setSubmitting(false);
    }
  };

  const validateForm = (values) => {
    const {
      name, data_collection_interval, data_send_interval, is_sgc200,
    } = values;
    const errors = {};
    if (name) {
      errors.name = validator.isLength(name, { max: 255 }) ? null : intl.formatMessage({ id: 'validation.too_long' }, { length: 255 });
    }
    if (is_sgc200) {
      if (data_send_interval) {
        errors.data_send_interval = validator.isInt(data_send_interval, { min: 15 }) ? null : intl.formatMessage({ id: 'edge_device.validation.send_interval_to_small' });
      }
      if (data_collection_interval) {
        errors.data_collection_interval = validator.isInt(data_collection_interval, { min: 0 }) ? null : intl.formatMessage({ id: 'validation.whole_number' });
      }
      if (data_collection_interval && data_send_interval) {
        errors.data_collection_interval = validator.isInt(data_collection_interval, { min: 0, max: parseInt(data_send_interval, 10) })
          ? null : intl.formatMessage({ id: 'edge_device.validation.send_vs_collection' });
      }
    }
    return removeNullAttributes(errors);
  };

  const renderForm = (formProps) => {
    const { isSubmitting, values } = formProps;
    const {
      is_sgc200, ethernetip_supported, client_application, serial_number,
    } = values;
    return (
      <Form {...formProps}>
        <DetailsItem id="serial-number" translationKey="label.serial_number" value={serial_number} />
        { client_application && (<DetailsItem id="netilion-connect-client-application-name" translationKey="label.connect_client_application" value={client_application} />)}
        <SelectBox
          {...formProps}
          id="log-level"
          name="log_level"
          valueKey="code"
          labelKey="name"
          label={intl.formatMessage({ id: 'label.log_level' })}
          options={logLevelOptions}
          handleChange={(event) => handleFormikValueChange({ ...formProps, name: 'log_level' }, event.target.value)}
          disabled={notEdgeDeviceAdmin}
        />
        <TextInput
          {...formProps}
          id="name"
          name="name"
          label={intl.formatMessage({ id: 'label.name' })}
        />
        <TextArea
          {...formProps}
          id="description"
          name="description"
          label={intl.formatMessage({ id: 'label.description' })}
        />
        { is_sgc200 && (
          <div>
            <h2 id="connectivity_settings-header">{intl.formatMessage({ id: 'edge_devices.connectivity_settings' })}</h2>
            <TextInput
              {...formProps}
              id="data-collection-interval"
              name="data_collection_interval"
              label={intl.formatMessage({ id: 'label.data_collection_interval' })}
              placeholder={intl.formatMessage({ id: 'label.minutes' })}
            />
            <TextInput
              {...formProps}
              id="data-send-interval"
              name="data_send_interval"
              label={intl.formatMessage({ id: 'label.data_send_interval' })}
              placeholder={intl.formatMessage({ id: 'label.minutes' })}
            />
          </div>
        )}
        { ethernetip_supported && (
          <div>
            <h3 id="eip-switch-section">{intl.formatMessage({ id: 'label.eip.activation_status' })}</h3>
            <SwitchInput
              {...formProps}
              id="eip-switch"
              name="ethernet_ip_active"
            />
          </div>
        )}
        <div className="space-after" />
        <ButtonGroup>
          <SubmitButton
            id="edit-submit-button"
            fetching={!initialValues || isSubmitting}
            disabled={!formProps.dirty || isSubmitting}
          />
          <CancelButton
            id="edit-cancel-button"
            fetching={!initialValues || isSubmitting}
            disabled={isSubmitting}
          />
        </ButtonGroup>
      </Form>
    );
  };

  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <Heading id="edit-edge-device-header" title={intl.formatMessage({ id: 'edge_device_edit.header' })} />
          </ActionBar>
        </Column>
      </Row>
      { initialValues && (
      <Row>
        <Column>
          <Formik
            id="edge-device-edit-form"
            onSubmit={onSubmit}
            validate={validateForm}
            initialValues={initialValues}
            render={renderForm}
          />
        </Column>
      </Row>
      )}
      <Row>
        <Column sm="12">
          <EdgeNotificationHandler edgeId={parseInt(id, 10)} />
        </Column>
      </Row>
      <Row>
        <Column sm="12">
          { initialValues && initialValues.has_api_subscription && (
            <EdgeDeviceApplicationsEdit edgeDevice={initialValues} />
          )}
        </Column>
      </Row>
      <Row>
        <Column sm="12">
          {initialValues && (
            <PermissionsEdit
              id="edm-edgedevice-edit-permissions"
              permitableType="EDM::EdgeDevice"
              permitableId={initialValues.id}
              accessRights={accessRights}
              allowedPermissions={[PermissionType.READ, PermissionType.UPDATE]}
            />
          )}
        </Column>
      </Row>
      <Loader loading={!initialValues} />
    </Container>
  );
}

EdgeDeviceEdit.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  browser: browserShape.isRequired,
  notifier: notifierShape.isRequired,
  accessRights: accessRightsShape,
  user: userShape,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

EdgeDeviceEdit.defaultProps = {
  user: undefined,
  accessRights: accessRightsShape,
};

export default withUser(withBrowser(withApi(withIntl(withNotifier(withAccessRights(EdgeDeviceEdit, 'EDM::EdgeDevice', validateAccessRights))))));
