import ApiClientError from './ApiClientError';

class ForbiddenError extends ApiClientError {
  /* istanbul ignore next */
  constructor(request, response, cause) {
    super('ForbiddenError', 403, 'The user has no permissions to request or modify the requested object.', request, response, cause);
  }
}

export default ForbiddenError;
