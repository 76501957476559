import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  ButtonGroup, Clue, url, intlShape,
} from 'lcm-iot-commons';

export function NoProducts({ intl }) {
  return (
    <Clue
      id="no-products-found"
      title={intl.formatMessage({ id: 'products.no_products_found' })}
      details={intl.formatMessage({ id: 'products.no_products_found.ask_create' })}
      className="notification"
      highlighted
    >
      <ButtonGroup>
        <Link className="btn btn-primary" to={url('/products/create')}>{intl.formatMessage({ id: 'products.create_product' })}</Link>
      </ButtonGroup>
    </Clue>
  );
}

NoProducts.propTypes = {
  intl: intlShape.isRequired,
};

NoProducts.defaultProps = {};

export default injectIntl(NoProducts);
