import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useApi } from '../../../hooks';
import { Button, ButtonGroup } from '../../Form';
import DocumentExportAllOrMultipleSelect from './DocumentExportAllOrMultipleSelect';

function CategoriesSelectionStep({
  setStepsState, stepsState, onClose, handleNext,
}) {
  const api = useApi();
  const intl = useIntl();
  const { data: documentCategories } = api.getAll.useQuery('/document/categories', { include: 'tenant' });

  const setCategoriesState = (newState) => {
    setStepsState({ ...stepsState, categoriesSelectionStep: newState });
  };

  return (
    <>
      <Modal.Body>
        <DocumentExportAllOrMultipleSelect
          title={intl.formatMessage({ id: 'export_files_dialog.file_categories.title' })}
          itemKey="categories"
          allItems={documentCategories?.categories}
          parentState={stepsState.categoriesSelectionStep}
          setParentState={setCategoriesState}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button
            text={intl.formatMessage({ id: 'button.close' })}
            onClick={onClose}
          />
          <Button
            hero
            text={intl.formatMessage({ id: 'button.next' })}
            onClick={handleNext}
            disabled={stepsState.categoriesSelectionStep.selectedItems.length === 0 && stepsState.categoriesSelectionStep.selectedSwitch === 'specific_selection'}
          />
        </ButtonGroup>
      </Modal.Footer>
    </>
  );
}

CategoriesSelectionStep.propTypes = {
  stepsState: PropTypes.shape({
    categoriesSelectionStep: PropTypes.shape({
      selectedSwitch: PropTypes.string,
      selectedItems: PropTypes.arrayOf(PropTypes.string),
    }),
    languagesSelectionStep: PropTypes.shape({
      selectedSwitch: PropTypes.string,
      selectedItems: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  setStepsState: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  handleNext: PropTypes.func,
};

export default CategoriesSelectionStep;
