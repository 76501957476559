import lcmApiClient from './lcmApiClient';
import { extractAccessRights } from '../extractors/accessRightsExtractor';

// @deprecated please do not use
/* istanbul ignore next */
export async function fetchAccessRights(type, id) {
  const param = {
    permitable_type: type,
    permitable_id: id,
  };
  return extractAccessRights(type, id, await lcmApiClient().get('/users/current/access_rights', param));
}
