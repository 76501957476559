/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom-v5-compat';
import { searchResultShape } from '../../shapes';
import Picture from '../Pictures/Picture';
import { convertSpecificationKeyToLabel, url } from '../../utils';
import { trackingShape, withTracking } from '../../context';
import Chips from '../Chips/Chips';
import CustomOverlayTrigger from '../CustomOverlayTrigger';

export const itemIcon = ({ type = '' }) => ({
  Asset: 'icon icon-eh-device',
  Node: 'icon-primary icon-eh-folder',
  Instrumentation: 'icon lcm-iot-icon-instrumentation',
  Batch: 'icon lcm-iot-icon-batch',
  Recipe: 'icon lcm-iot-icon-recipe',
  System: 'icon lcm-iot-icon-system',
})[type] || 'icon icon-eh-folder';

export function SearchResultItem({
  disabled, item, tracking, index = 0, onClick, searchOrigin, showSpecifications = true,
}) {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleOnClick = () => {
    const path = item.type === 'Batch' ? `${item.type.toLowerCase()}e` : item.type.toLowerCase();
    tracking?.trackEvent('Search-Result-selected', {
      itemType: item.type, index, isDisabled: disabled, searchOrigin,
    });
    navigate(url(`/${path}s/${item.id}`));
  };

  const isChipsVisible = showSpecifications && ['Asset', 'Node', 'Instrumentation'].includes(item.type) && !disabled;
  const getChips = () => {
    const items = Object.keys(item.specifications || {})?.map((specificationKey) => (`${convertSpecificationKeyToLabel(specificationKey)}: ${item.specifications[specificationKey].value}`));
    if (items?.length === 0) return [intl.formatMessage({ id: 'search.results.no_specifications' })];
    return items;
  };
  const chips = isChipsVisible ? getChips() : undefined;

  const SearchResultsItem = (
    <li id={`search-result-item-${item.id}`} className={`list-item${disabled ? ' disabled' : ''} clickable node-item`}>
      <div className="list-item-image" role="link" onClick={handleOnClick}>
        <Picture width={50} height={50} src={item.pictureUrl} placeholder={itemIcon(item)} />
      </div>
      <div
        className="list-item-content"
        role="link"
        onClick={() => {
          handleOnClick();
          onClick?.();
        }}
      >
        <div className="list-item-header">{item.title}</div>
        <div className="list-item-details">{item.description}</div>
        {isChipsVisible && <Chips items={chips} />}
      </div>
    </li>
  );

  const displayTooltip = (
    <Tooltip id="tooltip">{intl.formatMessage({ id: 'subscription.asset_inactive_because_not_assigned_message' })}</Tooltip>
  );

  return disabled
    ? (
      <CustomOverlayTrigger placement="bottom" overlay={displayTooltip}>
        <span>
          {SearchResultsItem}
        </span>
      </CustomOverlayTrigger>
    ) : SearchResultsItem;
}

SearchResultItem.propTypes = {
  item: searchResultShape.isRequired,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  tracking: trackingShape,
  onClick: PropTypes.func,
  searchOrigin: PropTypes.string,
  showSpecifications: PropTypes.bool,
};

export default withTracking(SearchResultItem);
