import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Formik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';

import { ActionBar } from '../ActionBar';

import { intlShape } from '../../shapes';

import {
  apiErrorsContain,
  createNode,
  createNodeType,
  createTenant,
  findDefaultAdminTenant,
  findNodeTypes,
  isNotFoundError,
  loadCurrentUser,
  loadCurrentUserAdminTenants,
  loadNode,
} from '../../api';

import {
  CancelButton,
  SelectBox,
  SubmitButton,
  TextArea,
  TextInput,
  TypeaheadBox,
  Form,
} from '../Form';

import {
  goBack,
  isEmpty,
  navigateTo,
  showSuccess,
  handleUnknownErrors,
} from '../../utils';

import {
  withRules, rulesShape,
} from '../../context/RulesContext';

import { NodeTypeRules } from '../../rules';
import { apiShape, withApi } from '../../context';
import { Column, Container, Row } from '../Grid';
import BackButton from '../BackButton';

export class NodeCreate extends Component {
  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.validateForm = this.validateForm.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.api = props.api;
    this.loadNode = loadNode.bind(this);

    this.state = {
      nodeTypes: undefined,
      tenants: undefined,
      parent: undefined,
      defaultAdminTenant: undefined,
      user: undefined,
    };
  }

  componentDidMount() {
    const { intl, match } = this.props;
    this.loadData(match?.params?.id).catch((apiErrors) => {
      if (isNotFoundError(apiErrors)) {
        navigateTo('/404');
      } else {
        handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
      }
    });
  }

  onSubmit(values, actions) {
    const { intl } = this.props;
    this.submitData(values, actions).catch((apiErrors) => {
      const formErrors = {};

      if (apiErrorsContain(apiErrors, 'taken', 'name')) {
        formErrors.name = intl.formatMessage({ id: 'api.error.node.taken' });
      }

      if (Object.keys(formErrors).length < apiErrors.length) {
        handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
      }

      actions.setErrors(formErrors);
      actions.setSubmitting(false);
    });
  }

  async submitData(values, actions) {
    let tenantId = null;
    let parentId = null;
    const { intl } = this.props;
    const { user, parent } = this.state;
    if (parent) {
      parentId = parent.id;
    }
    if (values.type.new) {
      if (this.state.tenants.length === 0) {
        tenantId = await createTenant({ name: `${user.firstName} ${user.lastName}` });
      } else if (values.tenant) {
        tenantId = values.tenant.id;
      } else {
        tenantId = this.state.defaultAdminTenant.id;
      }
      /* eslint-disable no-param-reassign */
      values.type.id = await createNodeType(values.type, tenantId);
    } else if (!values.type.tenantPublic) {
      // eslint-disable-next-line prefer-destructuring
      tenantId = values.type.tenantId;
    }
    await createNode(values, tenantId, parentId);
    actions.setSubmitting(false);
    showSuccess(intl.formatMessage({ id: 'node_create.success_notification' }));
    goBack();
  }

  async loadData(parentNodeId) {
    const user = await loadCurrentUser();
    const tenants = await loadCurrentUserAdminTenants(user);
    const defaultAdminTenant = findDefaultAdminTenant(user, tenants);
    const nodeTypes = (await findNodeTypes({ include: 'tenant,parent' }))
      .filter((nodeType) => !nodeType.tenantPublic || (NodeTypeRules.specialNodeTypeCodes.indexOf(nodeType.code) < 0 && NodeTypeRules.specialNodeTypeCodes.indexOf(nodeType.parentCode) < 0));

    if (parentNodeId) {
      const parent = await this.loadNode(parentNodeId);
      this.setState({ parent });
    }
    this.setState({
      user, nodeTypes, tenants, defaultAdminTenant,
    });
    if (tenants && tenants.length > 1) {
      this.formActions.setFieldValue('tenant', defaultAdminTenant);
    }
    this.formActions.setFieldValue('type', nodeTypes.find((t) => t.code === 'undefined'));
  }

  validateForm(values) {
    const { intl } = this.props;
    const errors = {};

    if (isEmpty(values.name) || isEmpty(values.name.trim())) {
      errors.name = intl.formatMessage({ id: 'validation.name.mandatory' });
    } else if (values.name.length > 255) {
      errors.name = intl.formatMessage({ id: 'validation.name.too_long' }, { characters: 255 });
    } else if (/[,;/\\$?<>]/.test(values.name)) {
      errors.name = intl.formatMessage({ id: 'validation.node_name.special_chars' });
    }

    if (!values.type || isEmpty(values.type.name) || isEmpty(values.type.name.trim())) {
      errors.type = intl.formatMessage({ id: 'validation.type.mandatory' });
    } else if (values.type.name.length > 255) {
      errors.type = intl.formatMessage({ id: 'validation.type.too_long' });
    }
    return errors;
  }

  renderForm(props) {
    const { intl } = this.props;
    const { nodeTypes, tenants } = this.state;
    const { isSubmitting, values } = props;
    const typeInfo = (values.type && values.type.new) ? intl.formatMessage({ id: 'node.info_type.new' }) : null;

    this.formActions = props;

    const tenant = (tenants && tenants.length > 1 && values.type && values.type.new) ? (
      <SelectBox
        {...props}
        id="node-tenant"
        name="tenant"
        label={intl.formatMessage({ id: 'label.tenant' })}
        options={tenants}
      />
    ) : null;

    return (
      <Form {...props}>
        <TextInput
          {...props}
          id="node-name"
          name="name"
          label={intl.formatMessage({ id: 'label.name' })}
          required
          autoFocus
        />
        <TypeaheadBox
          {...props}
          id="node-type"
          name="type"
          info={typeInfo}
          label={intl.formatMessage({ id: 'label.type' })}
          options={nodeTypes}
          canCreate
          required
        />
        {tenant}
        <TextArea
          {...props}
          name="description"
          label={intl.formatMessage({ id: 'label.description' })}
        />
        <div className="btn-group">
          <SubmitButton id="create-node-submit" fetching={isSubmitting} />
          <CancelButton id="create-node-cancel" disabled={isSubmitting} />
        </div>
      </Form>
    );
  }

  render() {
    return (
      <Container>
        <Row>
          <Column>
            <BackButton />
          </Column>
        </Row>
        <Row>
          <Column>
            <ActionBar>
              <h1><FormattedMessage id="node_create.header" /></h1>
            </ActionBar>
          </Column>
        </Row>
        <Row>
          <Column sm="6">
            <Formik
              validate={this.validateForm}
              onSubmit={this.onSubmit}
              render={this.renderForm}
            />
          </Column>
        </Row>
      </Container>
    );
  }
}

NodeCreate.propTypes = {
  api: apiShape,
  intl: intlShape.isRequired,
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) }),
  rules: rulesShape,
};

export default withApi(withRules(injectIntl(NodeCreate)));
