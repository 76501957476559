/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { MenuItem } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { withIntl } from '../../wrappers';

import {
  accessRightsShape,
  assetShape,
  instrumentationShape,
  intlShape,
  nodeShape,
  systemShape,
} from '../../shapes';

import {
  apiShape, backendShape,
  browserShape,
  notifierShape,
  withApi, withBackend,
  withBrowser,
  withNotifier,
} from '../../context';

import {
  url,
  isNotEmpty,
  isSomething, showSuccess,
} from '../../utils';

import {
  apiErrorsContain,
  fetchAccessRights,
  unassignSystemFromNode,
} from '../../api';

import ItemMenu from '../ItemMenu';
import ListItem from '../List/ListItem';
import AllObjectsSelectModal from '../AllObjects/AllObjectsSelectModal';
import ConfirmationModal from '../ConfirmationModal';
import SubscriptionAssignmentMenuItem from '../Subscriptions/SubscriptionAssigmentMenuItem';

export function SystemItem({
  api,
  intl,
  system,
  browser,
  onClick,
  backend,
  notifier,
  parentNode,
  parentAsset,
  showItemMenu,
  onSystemRemoved,
  parentAccessRights,
  parentInstrumentation,
  hideStatus,
}) {
  const [moving, setMoving] = useState(false);
  const [assigning, setAssigning] = useState(false);
  const [unassigning, setUnassigning] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [accessRights, setAccessRights] = useState({
    canUpdate: false,
    canDelete: false,
    canPermit: false,
  });

  const assignNodeToSystem = async (systemId, nodeId) => api.post(`/systems/${systemId}/nodes`, {
    nodes: [{ id: nodeId }],
  });

  const unassignNodeFromSystem = async (systemId, nodeId) => api.delete(`/systems/${systemId}/nodes`, {
    nodes: [{ id: nodeId }],
  });

  const moveSystemNodeAssignment = async (systemId, fromNodeId, toNodeId) => {
    await assignNodeToSystem(systemId, toNodeId);
    await unassignNodeFromSystem(systemId, fromNodeId);
  };

  const formatDetails = () => {
    let details = system.typeName ? system.typeName : '';
    if (isNotEmpty(details) && isNotEmpty(system.description)) {
      details = details.concat(', ');
    }
    if (isNotEmpty(system.description)) {
      details = details.concat(system.description);
    }
    return details;
  };

  const handleOnItemMenuOpen = async () => {
    try {
      const result = await fetchAccessRights('system', system.id);
      setAccessRights(result);
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  const handleOnEditClick = () => {
    browser.navigateTo(`/systems/${system.id}/edit`);
  };

  const handleOnAssignClick = () => {
    setAssigning(true);
  };

  const handleOnUnassignClick = () => {
    setUnassigning(true);
  };

  const handleOnMoveAssignmentClick = () => {
    setMoving(true);
  };

  const handleOnMoveClick = () => {
    setAssigning(true);
  };

  const handleOnDeleteClick = () => {
    setDeleting(true);
  };

  const resetActionsState = () => {
    setMoving(false);
    setDeleting(false);
    setAssigning(false);
    setUnassigning(false);
  };

  const handleOnConfirm = async () => {
    try {
      if (deleting) {
        await api.delete(`/systems/${system.id}`);
        onSystemRemoved(system);
        notifier.showSuccess(intl.formatMessage({ id: 'system.actions.delete.notification' }));
      }

      if (unassigning && parentNode) {
        await unassignSystemFromNode(system.id, parentNode.id);
        onSystemRemoved(system);
        notifier.showSuccess(intl.formatMessage({ id: 'system.actions.unassign.notification' }));
      }

      if (unassigning && (parentAsset || parentInstrumentation)) {
        if (parentAsset) {
          await api.delete(`/systems/${system.id}/assets`, { assets: [{ id: parentAsset.id }] });
          await backend.delete(`/connected_systems/${system.id}`);
        }
        if (parentInstrumentation) {
          await api.delete(`/systems/${system.id}/instrumentations`, { instrumentations: [{ id: parentInstrumentation.id }] });
        }
        onSystemRemoved(system);
        notifier.showSuccess(intl.formatMessage({ id: 'system.actions.unassign.notification' }));
      }
    } catch (error) {
      if (apiErrorsContain(error, 'assigned_restriction', 'children')) {
        notifier.showWarning(intl.formatMessage({ id: 'api.error.system.assigned_restriction' }));
      } else {
        notifier.showError(api.translateError(error));
      }
    } finally {
      resetActionsState();
    }
  };

  const handleOnSelectNode = async (node) => {
    try {
      if (assigning && system && node) {
        await assignNodeToSystem(system.id, node.id);
        if (!parentNode) {
          onSystemRemoved(system);
          showSuccess(intl.formatMessage({ id: 'system.actions.move_assignment.notification' }));
        } else {
          notifier.showSuccess(intl.formatMessage({ id: 'system.actions.assign.notification' }));
        }
      }

      if (moving && system && node && parentNode) {
        await moveSystemNodeAssignment(system.id, parentNode.id, node.id);
        onSystemRemoved(system);
        notifier.showSuccess(intl.formatMessage({ id: 'system.actions.move_assignment.notification' }));
      }

      resetActionsState();
    } catch (error) {
      if (apiErrorsContain(error, 'associations_already_added', 'nodes')) {
        notifier.showError(intl.formatMessage({ id: 'system.actions.assign.error' }));
      } else {
        notifier.showError(api.translateError(error));
      }
    }
  };

  let status = system.worstStatusCode || 'undefined';
  if (system.statusCode === 'inactive') {
    status = status.concat(' inactive');
  }

  status = hideStatus ? 'hidden' : status;

  return (
    <ListItem
      id={`system-item-${system.id}`}
      icon="lcm-iot-icon-system"
      title={system.name}
      description={formatDetails()}
      onClick={onClick ? () => onClick(system) : null}
      status={status}
      target={onClick ? undefined : url(`/systems/${system.id}`)}
      actions={(
        <>
          {showItemMenu && parentNode ? (
            <ItemMenu onOpen={handleOnItemMenuOpen}>
              <MenuItem onSelect={handleOnEditClick} disabled={!accessRights.canUpdate}><FormattedMessage id="button.edit" /></MenuItem>
              <MenuItem onSelect={handleOnAssignClick} disabled={!accessRights.canPermit}><FormattedMessage id="button.assign" /></MenuItem>
              <MenuItem onSelect={handleOnUnassignClick} disabled={!parentAccessRights.canUpdate || !accessRights.canPermit}>
                <FormattedMessage id="button.unassign" />
              </MenuItem>
              <MenuItem onSelect={handleOnMoveAssignmentClick} disabled={!accessRights.canPermit}><FormattedMessage id="button.move" /></MenuItem>
              <MenuItem onSelect={handleOnDeleteClick} disabled={!accessRights.canDelete}><FormattedMessage id="button.delete" /></MenuItem>
              <SubscriptionAssignmentMenuItem id={system.id} type="system" assign />
              <SubscriptionAssignmentMenuItem id={system.id} type="system" assign={false} />
            </ItemMenu>
          ) : null}
          {showItemMenu && !parentNode && !parentAsset && !parentInstrumentation ? (
            <ItemMenu onOpen={handleOnItemMenuOpen}>
              <MenuItem onSelect={handleOnEditClick} disabled={!accessRights.canUpdate}><FormattedMessage id="button.edit" /></MenuItem>
              <MenuItem onSelect={handleOnMoveClick} disabled={!accessRights.canPermit}><FormattedMessage id="button.move" /></MenuItem>
              <MenuItem onSelect={handleOnDeleteClick} disabled={!accessRights.canDelete}><FormattedMessage id="button.delete" /></MenuItem>
              <SubscriptionAssignmentMenuItem id={system.id} type="system" assign />
              <SubscriptionAssignmentMenuItem id={system.id} type="system" assign={false} />
            </ItemMenu>
          ) : null}
          {showItemMenu && (parentAsset || parentInstrumentation) ? (
            <ItemMenu onOpen={handleOnItemMenuOpen}>
              <MenuItem onSelect={handleOnEditClick} disabled={!accessRights.canUpdate}><FormattedMessage id="button.edit" /></MenuItem>
              <MenuItem onSelect={handleOnUnassignClick} disabled={!parentAccessRights.canPermit || !accessRights.canUpdate}><FormattedMessage id="button.unassign" /></MenuItem>
              <MenuItem onSelect={handleOnDeleteClick} disabled={!accessRights.canDelete}><FormattedMessage id="button.delete" /></MenuItem>
              <SubscriptionAssignmentMenuItem id={system.id} type="system" assign />
              <SubscriptionAssignmentMenuItem id={system.id} type="system" assign={false} />
            </ItemMenu>
          ) : null}
          {assigning || moving
            ? (
              <AllObjectsSelectModal
                id="node-select-modal"
                onClose={resetActionsState}
                onSelectNode={handleOnSelectNode}
                canSelectRootNode={isSomething(parentNode?.id)}
                type={(parentNode && assigning && 'assign') || 'move'}
              />
            ) : null}
          {deleting || unassigning
            ? (
              <ConfirmationModal
                id="confirmation-modal"
                onClose={resetActionsState}
                titleText={intl.formatMessage({ id: deleting ? 'system.actions.delete.modal_title' : 'system.actions.unassign.modal_title' })}
                messageText={intl.formatMessage({ id: deleting ? 'system.actions.delete.modal_message' : 'system.actions.unassign.modal_message' })}
                onConfirm={handleOnConfirm}
                show
              />
            ) : null}
        </>
      )}
    />
  );
}

SystemItem.propTypes = {
  api: apiShape.isRequired,
  backend: backendShape.isRequired,
  intl: intlShape.isRequired,
  system: systemShape.isRequired,
  browser: browserShape.isRequired,
  notifier: notifierShape.isRequired,
  onSystemRemoved: PropTypes.func,
  parentAccessRights: accessRightsShape,
  parentAsset: assetShape,
  parentInstrumentation: instrumentationShape,
  parentNode: nodeShape,
  onClick: PropTypes.func,
  showItemMenu: PropTypes.bool,
  hideStatus: PropTypes.bool,
};

SystemItem.defaultProps = {
  onClick: null,
  parentNode: null,
  showItemMenu: false,
  onSystemRemoved: null,
  hideStatus: false,
};

export default withIntl(withBackend(withApi(withBrowser(withNotifier(SystemItem)))));
