import PropTypes from 'prop-types';
import React, { Component } from 'react';
import InputGroup from './InputGroup';
import Select from './Select';
import { handleFormikValueChange } from '../../utils';

class SelectBox extends Component {
  static propTypes = {
    name: PropTypes.string,
    handleChange: PropTypes.func,
    disabled: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    labelKey: PropTypes.string,
    /* eslint-disable react/forbid-prop-types */
    values: PropTypes.object,
    renderOption: PropTypes.func,
    input: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    onChange: PropTypes.func,
  };

  static defaultProps = {
    labelKey: 'name',
  };

  constructor() {
    super();
    /* istanbul ignore next */
    this.renderOption = this.renderOption.bind(this);
  }

  renderOption(option) {
    const { labelKey } = this.props;
    const verified = option.tenantName && option.tenantPublic ? (<span className="select-option-icon"><span className="icon icon-eh-check2" /></span>) : null;
    const tenant = option.tenantName && !option.tenantPublic ? (` (${option.tenantName})`) : null;
    return (
      <div>
        <span className="select-option-value">{option[labelKey]}</span>
        {tenant}
        {verified}
      </div>
    );
  }

  render() {
    const {
      name, values, disabled, isSubmitting, renderOption, input, onChange,
    } = this.props;
    const props = { ...this.props }; // React: Spread children are not supported in React
    // redux form
    if (input) {
      return (
        <InputGroup {...props}>
          <Select
            {...props}
            disabled={disabled || isSubmitting}
            onChange={input.onChange}
            selectedOption={values && values[name]}
            renderOption={renderOption || this.renderOption}
          />
        </InputGroup>
      );
      //  FORMIK
    }
    return (
      <InputGroup {...props}>
        <Select
          {...props}
          disabled={disabled || isSubmitting}
          onChange={onChange || ((value) => handleFormikValueChange(this.props, value))}
          selectedOption={values && name.split('.').reduce((p, c) => p?.[c], values)}
          renderOption={renderOption || this.renderOption}
        />
      </InputGroup>
    );
  }
}

export default SelectBox;
