import lcmApiClient from './lcmApiClient';
import { extractAsset, extractAssets } from '../extractors/assetsExtractor';
import { extractInstrumentations } from '../extractors/instrumentationsExtractor';
import { extractNodes } from '../extractors/nodesExtractor';

/* istanbul ignore next */
export async function loadAsset(id, parameters) {
  const assetResponse = await lcmApiClient(false).get(`/assets/${id}`, parameters);
  return extractAsset(assetResponse);
}

/* istanbul ignore next */
export async function loadExtendedOrderCodeLookup(asset) {
  let response = null;
  response = (await lcmApiClient()
    .get('/endress/extended_order_code_lookup', { order_code: asset.specifications['eh.pcps.tmp.ordercode'].value })).extended_order_code;
  return response;
}

/* istanbul ignore next */
export async function loadAssets(parameters) {
  const response = await lcmApiClient().get('/assets', parameters);
  return {
    assets: extractAssets(response),
    totalCount: response.pagination.total_count,
    nextPageUrl: response.pagination.next,
  };
}

/* istanbul ignore next */
export async function loadNextAssets(url) {
  const response = await lcmApiClient().get(url);
  return {
    assets: extractAssets(response),
    totalCount: response.pagination.total_count,
    nextPageUrl: response.pagination.next,
  };
}

/* istanbul ignore next */
export async function deleteAsset(id) {
  return lcmApiClient().delete(`/assets/${id}`);
}

/* istanbul ignore next */
export async function assignNodeToAsset(assetId, nodeId) {
  return lcmApiClient().post(`/assets/${assetId}/nodes`, {
    nodes: [{ id: nodeId }],
  });
}

/* istanbul ignore next */
export async function unassignNodeFromAsset(assetId, nodeId) {
  return lcmApiClient().delete(`/assets/${assetId}/nodes`, {
    nodes: [{ id: nodeId }],
  });
}

/* istanbul ignore next */
export async function moveAssetNodeAssignment(assetId, fromNodeId, toNodeId) {
  await assignNodeToAsset(assetId, toNodeId);
  return unassignNodeFromAsset(assetId, fromNodeId);
}

/* istanbul ignore next */
export async function loadAssetNodes(id, parameters) {
  const response = await lcmApiClient().getAll(`/assets/${id}/nodes`, parameters);
  return extractNodes(response);
}

/* istanbul ignore next */
export async function loadAssetInstrumentations(id, parameters) {
  const response = await lcmApiClient().getAll(`/assets/${id}/instrumentations`, parameters);
  return extractInstrumentations(response);
}

/* istanbul ignore next */
export async function assignInstrumentationToAsset(assetId, instrumentationId) {
  return lcmApiClient().post(`/assets/${assetId}/instrumentations`, {
    instrumentations: [{ id: instrumentationId }],
  });
}

/* istanbul ignore next */
export async function assignSystemToAsset(assetId, systemId) {
  return lcmApiClient().post(`/systems/${systemId}/assets`, {
    assets: [{ id: assetId }],
  });
}

export async function unassignInstrumentationFromAsset(assetId, instrumentationId) {
  return lcmApiClient().delete(`/assets/${assetId}/instrumentations`, {
    instrumentations: [{ id: instrumentationId }],
  });
}

/* istanbul ignore next */
export async function moveAssetFromNodeToInstrumentation(assetId, nodeId, instrumentationId) {
  await assignInstrumentationToAsset(assetId, instrumentationId);
  return unassignNodeFromAsset(assetId, nodeId);
}
/* istanbul ignore next */
export async function moveAssetFromNodeToSystem(assetId, nodeId, systemId) {
  await assignSystemToAsset(assetId, systemId);
  return unassignNodeFromAsset(assetId, nodeId);
}

/* istanbul ignore next */
export async function assignAssetToSubscription(assetId, subscriptionId) {
  return lcmApiClient().post(`/subscriptions/${subscriptionId}/assets`, {
    assets: [{ id: assetId }],
  });
}
