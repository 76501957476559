/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  intlShape,
  ActionBar,
  ActionBarButtons,
  handleUnknownErrors,
  CreateActionButton,
  isSomething,
  url,
} from 'lcm-iot-commons';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';

import { loadConnectSubscriptions, loadNextConnectSubscriptions } from '../../api/subscriptionApi';
import ConnectSubscriptionItem from './ConnectSubscriptionItem';

export class ConnectSubscriptions extends Component {
  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.loadData = this.loadData.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.doHandleLoadMore = this.doHandleLoadMore.bind(this);
    this.state = {
      fetching: true,
      initialized: false,
      totalCount: null,
      subscriptions: null,
    };
  }

  /* istanbul ignore next */
  componentDidMount() {
    /* istanbul ignore next */
    this.loadData();
  }

  async loadData() {
    const { intl } = this.props;
    try {
      const displayedStatuses = { status: 'open,confirmed,payment_required,non_renewing' };
      const subscriptions = await loadConnectSubscriptions(displayedStatuses);
      subscriptions.connectSubscriptions = this.prepareSubscriptions(subscriptions.connectSubscriptions);
      /* istanbul ignore next */
      this.setState({
        subscriptions, fetching: false, initialized: true, totalCount: subscriptions.totalCount,
      });
    } catch (apiErrors) {
      handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
    }
  }

  handleLoadMore() {
    const { intl } = this.props;
    this.doHandleLoadMore(false).catch((errors) => {
      handleUnknownErrors(errors, intl.formatMessage({ id: 'api.error.unknown' }));
    });
  }

  async doHandleLoadMore() {
    const { subscriptions } = this.state;
    this.setState({ fetching: true });

    const subscriptionsResult = await loadNextConnectSubscriptions(subscriptions.nextPageUrl);
    const preparedConnectSubscriptions = this.prepareSubscriptions(subscriptionsResult.connectSubscriptions);
    /* istanbul ignore next */
    subscriptionsResult.connectSubscriptions = subscriptions.connectSubscriptions.concat(preparedConnectSubscriptions);
    /* istanbul ignore next */
    this.setState({ subscriptions: subscriptionsResult, fetching: false });
  }

  prepareSubscriptions(connectSubscriptions) {
    const subs = [];
    connectSubscriptions.forEach((connectSubscription) => {
      connectSubscription.pricingModel = this.freeOrPaid(connectSubscription);
      subs.push(connectSubscription);
    });
    return subs;
  }

  freeOrPaid(connectSubscription) {
    if (connectSubscription.externalReference || connectSubscription.status === 'open') {
      return 'paid';
    }
    return 'free';
  }

  renderItems(connectSubscriptions) {
    return connectSubscriptions.map((connectSubscription) => (<ConnectSubscriptionItem subscription={connectSubscription} key={connectSubscription.id} />));
  }

  render() {
    const {
      subscriptions, fetching, initialized, totalCount,
    } = this.state;
    const actionBarButtons = (
      <ActionBarButtons>
        <div>
          <CreateActionButton id="create-subscription-button" disabled={!initialized} target="/plan_variants" />
        </div>
      </ActionBarButtons>
    );

    const badge = !initialized ? (
      <span className="loading">
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </span>
    ) : totalCount;

    const renderBadge = totalCount > 0 ? (
      <span id="subscriptions-count" className="badge">{badge}</span>
    ) : null;

    const noSubscriptionConnectFound = initialized && totalCount === 0 ? (
      <div id="no-subscription-found" className="clue">
        <div className="clue-header"><FormattedMessage id="subscription.no_connect_found" /></div>
        <div className="clue-details"><FormattedMessage id="subscription.no_connect_found.ask_create" /></div>
        <Link className="btn btn-primary" to={url('/plan_variants')}><FormattedMessage id="button.create_subscription" /></Link>
      </div>
    ) : null;

    const loader = fetching ? (
      <div id="subscriptions-list-loader" className="loader">
        <FormattedMessage id="general.loading" />
        <div className="icon-spinner-dark" />
      </div>
    ) : null;

    const hasMore = isSomething(subscriptions) && isSomething(subscriptions.nextPageUrl);

    const subscriptionsListItems = subscriptions ? (
      <ul id="subscription-list" className="list">
        <InfiniteScroll
          initialLoad={false}
          loadMore={this.handleLoadMore}
          hasMore={!fetching && hasMore}
        >
          {this.renderItems(subscriptions.connectSubscriptions)}
        </InfiniteScroll>
      </ul>
    ) : null;

    return (
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <ActionBar>
              <h1 id="subscriptions-header">
                <FormattedMessage id="label.connect_subscriptions" />
                {renderBadge}
              </h1>
              {actionBarButtons}
            </ActionBar>
          </div>
        </div>
        {noSubscriptionConnectFound}
        {subscriptionsListItems}
        {loader}
      </div>
    );
  }
}

ConnectSubscriptions.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ConnectSubscriptions);
