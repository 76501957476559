import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Column from '../Grid/Column';
import Row from '../Grid/Row';

export function SubClue({
  message,
}) {
  return (
    <div>
      <Row>
        <Column>
          <div id="sub-clue-box" className="clue-box">
            <div>
              <h5><span className="clue-box-icon icon2-eh-notification ninja-icon" /></h5>
            </div>
            <div className="clue-box-content">
              <h5 id="clue-content" className="clue-box-content">
                {message}
              </h5>
            </div>
          </div>
        </Column>
      </Row>
    </div>
  );
}

SubClue.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
};

SubClue.defaultProps = {
};

export default injectIntl(SubClue);
