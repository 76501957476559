import {
  parseISO8601Date,
} from 'lcm-iot-commons';

import {
  extractFieldGateways,
} from './fieldGatewaysExtractor';
import {
  extractNetworkInterfaces,
} from './networkInterfacesExtractor';
import EdgeDeviceSpecificationKeys from '../constants/specificationLabels';

function extractSpecification(specifications, key) {
  if (specifications && specifications[key]) {
    return specifications[key].value;
  }
  return null;
}

function extractSoftwareVersion(rawSoftwareVersion) {
  return {
    id: rawSoftwareVersion.id,
    versionName: rawSoftwareVersion.version_name ? rawSoftwareVersion.version_name : null,
    statusId: rawSoftwareVersion.status ? rawSoftwareVersion.status.id : null,
    ethernetIPSupported: rawSoftwareVersion.ethernetip_supported ? rawSoftwareVersion.ethernetip_supported : false,
  };
}

function convertToMinutes(specifications, key) {
  const value = extractSpecification(specifications, key);
  if (value) {
    return Math.round(parseInt(value, 10) / 60);
  }
  return null;
}

export function extractEdgeDevice(rawEdgeDevice, rawFieldGateways = null, rawNetworkInterfaces = null, rawEdgeDeviceApplications = null) {
  return {
    id: rawEdgeDevice.id,
    serialNumber: rawEdgeDevice.serial_number,
    name: rawEdgeDevice.name,
    apiSubscription: rawEdgeDevice.api_subscription ? rawEdgeDevice.api_subscription : null,
    description: rawEdgeDevice.description,
    logLevel: rawEdgeDevice.log_level,
    status: rawEdgeDevice.status,
    type: rawEdgeDevice.type,
    softwareVersion: rawEdgeDevice.software_version ? extractSoftwareVersion(rawEdgeDevice.software_version) : null,
    lastSeenAt: rawEdgeDevice.last_seen_at ? rawEdgeDevice.last_seen_at : null,
    fieldGateways: rawFieldGateways ? extractFieldGateways(rawFieldGateways) : null,
    networkInterfaces: rawNetworkInterfaces ? extractNetworkInterfaces(rawNetworkInterfaces) : null,
    edgeDeviceApplications: rawEdgeDeviceApplications ? rawEdgeDeviceApplications.edge_device_applications : null,
    applyTimestamp: rawEdgeDevice.apply_timestamp ? parseISO8601Date(rawEdgeDevice.apply_timestamp) : null,
    clientApplicationName: rawEdgeDevice.api_subscription && rawEdgeDevice.api_subscription.client_application ? rawEdgeDevice.api_subscription.client_application.name : null,
    dataCollectionInterval: convertToMinutes(rawEdgeDevice.specifications, EdgeDeviceSpecificationKeys.DATA_COLLECTION_INTERVAL),
    dataSendInterval: convertToMinutes(rawEdgeDevice.specifications, EdgeDeviceSpecificationKeys.DATA_SEND_INTERVAL),
    dataSendStartTime: extractSpecification(rawEdgeDevice.specifications, EdgeDeviceSpecificationKeys.DATA_SEND_START_TIME),
    dataSendEnvironment: extractSpecification(rawEdgeDevice.specifications, EdgeDeviceSpecificationKeys.DATA_SEND_ENVIRONMENT),
    ethernetIPActiveStatus: extractSpecification(rawEdgeDevice.specifications, EdgeDeviceSpecificationKeys.ETHERNET_IP_ACTIVE) === 'true',
  };
}

export function extractEdgeDevices(rawEdgeDevices) {
  return rawEdgeDevices.edge_devices.map((edgeDevice) => extractEdgeDevice(edgeDevice));
}
