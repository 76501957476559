import PropTypes from 'prop-types';

export const planVariantAddonShape = PropTypes.shape({
  id: PropTypes.string,
  currency: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.number,
  type: PropTypes.string,
  assets: PropTypes.number,
  storage: PropTypes.number,
});

export const planVariantAddonsShape = PropTypes.arrayOf(planVariantAddonShape);

export const planVariantShape = PropTypes.shape({
  id: PropTypes.string,
  currency: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.number,
  type: PropTypes.string,
  sort: PropTypes.number,
  popular: PropTypes.bool,
  button: PropTypes.string,
  assets: PropTypes.number,
  storage: PropTypes.number,
  features: PropTypes.arrayOf(PropTypes.string),
  addons: planVariantAddonsShape,
});

export const planVariantsShape = PropTypes.arrayOf(planVariantShape);
