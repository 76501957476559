import { isNil } from 'lodash-es';
import ApiClientError from './ApiClientError';

class ConflictError extends ApiClientError {
  /* istanbul ignore next */
  constructor(request, response, cause) {
    super(
      'ConflictError',
      409,
      'Failed to create, update or delete a resource because of a conflict with an existing resource or due to existing assignments on the resource.',
      request,
      response,
      cause,
    );
    if (this.response && this.response.data) {
      this.errors = this.response.data.errors;
    }
  }

  contains = (type, attribute = null) => {
    if (attribute) {
      return !isNil(this.errors) && !isNil(this.errors.find((error) => error.type === type && error.attribute === attribute));
    }
    return !isNil(this.errors) && !isNil(this.errors.find((error) => error.type === type));
  };
}

ConflictError.create = (errors) => new ConflictError(null, { data: { errors } });

export default ConflictError;
