import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import {
  apiShape,
  browserShape,
  notifierShape,
  rulesShape,
  subscriptionShape,
  withApi,
  withBrowser,
  withNotifier,
  withRules,
  withSubscription,
} from '../../context';
import { intlShape } from '../../shapes';
import Clue from '../Clue/Clue';

import SpecificationKeys from '../../constants/SpecificationKeys';

export function ConnectedAssetSetupClue({
  intl, rules, browser, api, notifier, subscription,
}) {
  const [visible, setVisible] = useState(false);

  React.useEffect(() => {
    const loadData = async () => {
      try {
        if (subscription?.hasAddon('connected-asset')) {
          const products = await api.getAll('/products', { specifications_key: SpecificationKeys.ProductSpecs.CONNECTED_ASSET, specifications_value: 'true' });
          const response = await api.head('/assets', { product_id: products.products?.map((product) => product.id).join(',') });
          const numberOfConnectedAssets = parseInt(response['total-count'], 10);
          setVisible(numberOfConnectedAssets === 0);
        }
      } catch (error) {
        notifier.showError(api.translateError(error));
      }
    };
    if (rules.application().get('supportsConnectedAssets')) {
      loadData();
    }
  }, [subscription]);

  return visible && (
    <Clue
      id="connected-asset-setup"
      title={intl.formatMessage({ id: 'label.connected_asset' })}
      details={intl.formatMessage({ id: 'connected_asset.notification' })}
      className="notification"
      action={intl.formatMessage({ id: 'button.learn_more' })}
      onClick={() => { browser.redirectToExtern('https://help.netilion.endress.com/hc/en-us/sections/360002152220-Value'); }}
      highlighted
    />
  );
}

ConnectedAssetSetupClue.propTypes = {
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  rules: rulesShape.isRequired,
  subscription: subscriptionShape,
};

ConnectedAssetSetupClue.defaultProps = {
  subscription: undefined,
};

export default withSubscription(withNotifier(withApi(withBrowser(withRules(injectIntl(ConnectedAssetSetupClue))))));
