import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  ActionBar,
  ActionBarButtons,
  ActionDropdownButton,
  DeleteActionButton,
  EditActionButton,
  DetailsActionButton,
} from '../ActionBar';

import {
  accessRightsShape,
  intlShape,
  nodeShape,
} from '../../shapes';

import {
  browserShape,
  rulesShape,
  subscriptionShape,
  withBrowser,
  withRules,
  withSubscription,
} from '../../context';

import {
  isNotEmpty,
  isSomething,
} from '../../utils';

import Heading from '../Heading';
import AllObjectsBadge from './AllObjectsBadge';
import { withFlipper } from '../../wrappers';

export function AllObjectsHeader({
  accessRights,
  additionalCreateMenuItems,
  browser,
  fetching,
  intl,
  match,
  node,
  rules,
  subscription,
  onConfirmDelete,
  nodes,
  flipper,
}) {
  const handleOnCreateClick = (key) => {
    switch (key) {
      case 'create_asset':
        browser.navigateTo(match.params.id ? `/nodes/${match.params.id}/assets/create` : '/assets/create');
        break;
      case 'create_node':
        browser.navigateTo(match.params.id ? `/nodes/${match.params.id}/create` : '/nodes/create');
        break;
      case 'create_tag':
        browser.navigateTo(match.params.id ? `/nodes/${match.params.id}/instrumentations/create` : '/instrumentations/create');
        break;
      case 'scan_asset':
        browser.navigateTo(match.params.id ? `/nodes/${match.params.id}/assets/create_scan` : '/assets/create_scan');
        break;
      case 'create_recipe':
        browser.navigateTo(match.params.id ? `/nodes/${match.params.id}/recipes/create` : '/recipes/create');
        break;
      case 'create_batch':
        browser.navigateTo('/batches/create');
        break;
      case 'create_system':
        browser.navigateTo(match.params.id ? `/nodes/${match.params.id}/systems/create` : '/systems/create');
        break;
      default:
        /* istanbul ignore next */
        break;
    }
  };

  const editTarget = match.params?.id ? `/nodes/${match.params.id}/edit` : '/404';
  const detailsTarget = match.params?.id ? `/nodes/${match.params.id}/details` : '/404';

  const nodeDetailsButton = node ? (
    <DetailsActionButton
      id="details-node-button"
      target={detailsTarget}
    />
  ) : null;

  const editButton = node ? (
    <EditActionButton
      id="edit-node-button"
      disabled={!accessRights.canUpdate || !rules.node(node).get('editable')}
      target={editTarget}
    />
  ) : null;

  const deleteButton = node ? (
    <DeleteActionButton
      id="delete-node-button"
      disabled={isNotEmpty(nodes) || !accessRights.canDelete || fetching}
      modalTitle={intl.formatMessage({ id: 'node.actions.delete.modal_title' })}
      modalMessage={intl.formatMessage({ id: 'node.actions.delete.modal_message' })}
      onConfirm={onConfirmDelete}
    />
  ) : null;

  const scanAssetButton = rules.application().get('showScanAssetButton') ? (
    <MenuItem
      className="icon icon-eh-show-less"
      eventKey="scan_asset"
      id="scan-asset-menu-item"
      disabled={subscription?.asset_quota > 0 && subscription?.number_assigned_assets >= subscription?.asset_quota}
      onSelect={handleOnCreateClick}
    >
      <FormattedMessage id="dropdown_scan.asset" />
    </MenuItem>
  ) : null;

  return (
    <ActionBar>
      <Heading level={1} className="heading-badge" title={node?.name ?? intl.formatMessage({ id: 'nodes.header' })}>
        <AllObjectsBadge />
      </Heading>
      <ActionBarButtons>
        {nodeDetailsButton}
        {deleteButton}
        {editButton}
        <ActionDropdownButton id="create-button" disabled={node ? !accessRights.canUpdate : false} icon="icon-eh-new" title={intl.formatMessage({ id: 'button.create' })}>
          <MenuItem
            className="icon icon-eh-show-less"
            eventKey="create_asset"
            id="create-asset-menu-item"
            disabled={subscription?.asset_quota > 0 && subscription?.number_assigned_assets >= subscription?.asset_quota}
            onSelect={handleOnCreateClick}
          >
            <FormattedMessage id="dropdown_create.asset" />
          </MenuItem>
          {scanAssetButton}
          <MenuItem
            className="icon icon-eh-show-less"
            eventKey="create_tag"
            id="create-tag-menu-item"
            onSelect={handleOnCreateClick}
          >
            <FormattedMessage id="dropdown_create.tag" />
          </MenuItem>
          {additionalCreateMenuItems}
          <MenuItem
            className="icon icon-eh-show-less"
            eventKey="create_node"
            id="create-node-menu-item"
            disabled={node && !accessRights.canUpdate}
            onSelect={handleOnCreateClick}
          >
            <FormattedMessage id="dropdown_create.node" />
          </MenuItem>
          {rules.application().get('supportsRecipes') && !flipper.fermentationDeactivated
            ? (
              <MenuItem
                className="icon icon-eh-show-less"
                eventKey="create_recipe"
                id="create-recipe-menu-item"
                disabled={!subscription?.hasAddon('fermentation')}
                onSelect={handleOnCreateClick}
              >
                <FormattedMessage id="dropdown_create.recipe" />
              </MenuItem>
            ) : null}
          {rules.application().get('supportsBatches') && !flipper.fermentationDeactivated
            ? (
              <MenuItem
                className="icon icon-eh-show-less"
                eventKey="create_batch"
                id="create-batch-menu-item"
                disabled={!subscription?.hasAddon('fermentation') || isSomething(node)}
                onSelect={handleOnCreateClick}
              >
                <FormattedMessage id="dropdown_create.batch" />
              </MenuItem>
            ) : null}
          {rules.application().get('supportsSystems') && !flipper.fermentationDeactivated
            ? (
              <MenuItem
                className="icon icon-eh-show-less"
                eventKey="create_system"
                id="create-system-menu-item"
                disabled={!subscription?.hasAddon('fermentation')}
                onSelect={handleOnCreateClick}
              >
                <FormattedMessage id="dropdown_create.system" />
              </MenuItem>
            ) : null}
        </ActionDropdownButton>
      </ActionBarButtons>
    </ActionBar>
  );
}

AllObjectsHeader.propTypes = {
  accessRights: accessRightsShape,
  additionalCreateMenuItems: PropTypes.node,
  browser: browserShape.isRequired,
  fetching: PropTypes.bool,
  intl: intlShape.isRequired,
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) }),
  node: nodeShape,
  rules: rulesShape,
  subscription: subscriptionShape,
  onConfirmDelete: PropTypes.func,
  nodes: PropTypes.shape({}),
  flipper: PropTypes.shape({
    fermentationDeactivated: PropTypes.bool,
  }).isRequired,
};

AllObjectsHeader.defaultProps = {

};

export default withFlipper(withBrowser(withRules(withSubscription(injectIntl(AllObjectsHeader)))));
