import React from 'react';
import PropTypes from 'prop-types';

function Details({ id, children }) {
  return (
    <div id={id} className="details">
      {children}
    </div>
  );
}

Details.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
};

export default Details;
