import { combineReducers } from 'redux';

import {
  accessRightsReducer,
  currentUserReducer,
} from 'lcm-iot-commons';

import sessionReducer from './sessionReducer';

const lastAction = (state, action) => action;

const rootReducer = combineReducers({
  accessRights: accessRightsReducer,
  currentUser: currentUserReducer,
  lastAction,
  session: sessionReducer,
});

export default rootReducer;
