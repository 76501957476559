import React from 'react';
import PropTypes from 'prop-types';
import { trackingShape, withTracking } from '../../context';

export function SearchOptionItem({
  label,
  disabled,
  onChange,
  checked,
  option,
  tracking,
}) {
  const handleOnChange = (event) => {
    onChange(option, event.target.checked);
    const eventProps = { optionItemName: label, actionType: event.target.checked ? 'checked' : 'unchecked' };
    tracking?.trackEvent('Search-Option-Item-clicked', eventProps);
  };

  return (
    <li className="list-inline-item">
      <input
        id={`${label}-checkbox`}
        name={label}
        checked={checked}
        type="checkbox"
        disabled={disabled}
        onChange={handleOnChange}
      />
      <label htmlFor={`${label}-checkbox`}>{label}</label>
    </li>
  );
}

SearchOptionItem.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  option: PropTypes.string.isRequired,
  tracking: trackingShape.isRequired,
};
export default withTracking(SearchOptionItem);
