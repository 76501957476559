import React from 'react';
import PropTypes from 'prop-types';
import { isNumber } from '../utils/index';
import Colors from '../constants/Colors';

function ProgressBar({
  percent,
  maxValue,
  actValue,
  unit,
  withColorCoding,
  id,
}) {
  let realPercent = isNumber(percent) ? percent : 0;

  if (realPercent < 0) {
    realPercent = 0;
  } else if (realPercent > 100) {
    realPercent = 100;
  }

  const progressBarColor = () => {
    if (!withColorCoding) return Colors.EH_CYAN_3;
    switch (true) {
      case realPercent >= 90:
        return Colors.EH_RED_ORANGE_3;
      case realPercent < 90 && realPercent >= 80:
        return Colors.EH_SUN_YELLOW_2;
      default:
        return Colors.EH_CYAN_3;
    }
  };
  const actValueWithUnit = `${actValue} ${unit}`;
  const maxValueWithUnit = `${maxValue} ${unit}`;

  const progressBarValues = actValue ? (
    <div id="progress-bar-values" className="progress-bar-values">
      <span id="actual-value">{actValueWithUnit}</span>
      <span id="maximum-value">{maxValueWithUnit}</span>
    </div>
  ) : null;

  return (
    <div id={id} className="progress-bar-group">
      {progressBarValues}
      <div className="progress" aria-valuenow={realPercent}>
        <div id="progress-bar" className="progress-bar" role="progressbar" style={{ width: `${realPercent}%`, backgroundColor: progressBarColor() }} />
      </div>
    </div>
  );
}

ProgressBar.defaultProps = {
  maxValue: undefined,
  actValue: undefined,
  unit: '',
  withColorCoding: false,
  id: 'progress-bar-group',
};

ProgressBar.propTypes = {
  percent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  actValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unit: PropTypes.string,
  withColorCoding: PropTypes.bool,
  id: PropTypes.string,
};

export default ProgressBar;
