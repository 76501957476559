import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isNotEmpty, handleFormikValueChange, isNothing } from '../../utils';
import InputAlert from './InputAlert';
import { AlertType } from '../../constants';

class RadioSelect extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    status: PropTypes.string,
    isSubmitting: PropTypes.bool,
    /* eslint-disable react/forbid-prop-types */
    /* eslint-disable react/forbid-prop-types */
    errors: PropTypes.object,
    /* eslint-disable react/forbid-prop-types */
    values: PropTypes.object,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      contextualHelp: PropTypes.element,
    })),
  };

  constructor() {
    super();
    /* istanbul ignore next */
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(event) {
    const { value } = event.target;
    handleFormikValueChange(this.props, value);
  }

  render() {
    const {
      id, name, isSubmitting, values, errors, status, options,
    } = this.props;

    const error = status === 'submitted' && isNotEmpty(errors[name]) && isNothing(values[name]);
    const className = error ? 'form-group has-error' : 'form-group';
    const errorMessage = error ? errors[name] : null;

    return (
      <div className={className}>
        { options.map((option) => (
          <div className="radio" key={option.value}>
            <input
              id={`${id}-${option.value}`}
              disabled={isSubmitting}
              type="radio"
              name={id}
              onChange={this.handleOnChange}
              value={option.value}
              checked={values[name] === option.value}
            />
            <label htmlFor={`${id}-${option.value}`}>{option.label}</label>
            {option.contextualHelp || null}
          </div>
        )) }
        <InputAlert message={errorMessage} type={AlertType.ERROR} />
      </div>
    );
  }
}

export default RadioSelect;
