import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isSomething } from '../../utils';

function PermissionEdit({
  permissionType,
  permitableId,
  onDelete,
  onCreate,
  assignableId,
  assignableType,
  permissionId,
  disabled,
}) {
  const id = `${permissionType}_${permitableId}_${assignableType}_${assignableId}`;
  const labelId = `permission_type.${permissionType}`;

  const handleOnChange = () => {
    if (isSomething(permissionId)) {
      onDelete(permissionId, assignableType, assignableId);
    } else {
      onCreate(assignableType, assignableId, permissionType);
    }
  };

  return (
    <div className="permission-item">
      <input
        id={id}
        type="checkbox"
        className={isSomething(permissionId) ? 'checked' : 'unchecked'}
        checked={isSomething(permissionId)}
        onChange={handleOnChange}
        disabled={disabled}
      />
      <label htmlFor={id}><FormattedMessage id={labelId} /></label>
    </div>
  );
}
PermissionEdit.propTypes = {
  assignableId: PropTypes.number.isRequired,
  assignableType: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  permissionId: PropTypes.number,
  permissionType: PropTypes.string.isRequired,
  permitableId: PropTypes.number.isRequired,
};

export default PermissionEdit;
