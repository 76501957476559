import { isNotEmpty } from './langUtils';

const ehRegExp = /^[0-9A-Z][0-9A-C][0-9A-F]{4}([0-9]{2}|SW)[0-9A-F]{3}$/;

export function getAssetLinkTarget(asset) {
  let target = `/assets/${asset.id}`;
  let title = asset.serialNumber;
  let subTitle = null;
  if (asset.systems?.length > 0) {
    const [system] = asset.systems;
    title = system.name;
    subTitle = system.recipeName;
    target = `/systems/${system.id}`;
  } else if (asset.instrumentations?.length > 0) {
    const [instrumentation] = asset.instrumentations;
    title = instrumentation.tag;
    target = `/instrumentations/${instrumentation.id}`;
  }

  return { title, subTitle, target };
}

export function formatAssetDetails(asset) {
  let details = asset.productCode;
  if (isNotEmpty(asset.productName)) {
    details = details.concat(' - ').concat(asset.productName);
  }
  details = details.concat(', ').concat(asset.manufacturerName);
  return details;
}

export function formatProductDetails(product) {
  let details = '';
  if (isNotEmpty(product.name)) {
    details = product.name.concat(', ');
  }
  details = details.concat(product.manufacturerName);
  return details;
}

export function isEHSerialNumber(sn) {
  return ehRegExp.test(sn);
}

export const extractCode = (code) => (code && code.indexOf('-') > 0
  ? code.substring(code.indexOf('-') + 1) : undefined);
