/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';
import { FormattedMessage } from 'react-intl';

import {
  isEmpty,
  prefixObjectKeys,
  isObjectNullOrUndefined,
  unprefixObjectKeys,
  firstToUpperCase,
  isTouchDevice,
  isSomething, handleFormikValueChange,
} from '../../utils';

import { TextInput, SelectBox } from '../Form';
import VatPrefixes from '../../constants/VatPrefix';
import { countryShape, intlShape } from '../../shapes';
import RegionCodes from '../../constants/RegionCodes';
import { withIntl, withFlipper } from '../../wrappers';
import { luhnCheck } from '../../utils/validationUtils';

export class AddressForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    autoFocus: PropTypes.bool,
    id: PropTypes.string,
    prefix: PropTypes.string,
    countries: PropTypes.arrayOf(countryShape),
    showEmail: PropTypes.bool,
    showVat: PropTypes.bool,
    showCustomerPurchaseOrder: PropTypes.bool,
    firstLastNameRequired: PropTypes.bool,
    /* eslint-disable react/forbid-prop-types */
    initialValues: PropTypes.object,
    /* eslint-disable react/forbid-prop-types */
    reference: PropTypes.object,
    countryChangeAction: PropTypes.func,
    intl: intlShape,
    flipper: PropTypes.shape({
      allStatesAvailable: PropTypes.bool,
    }),
  };

  static defaultProps = {
    firstLastNameRequired: false,
  };

  constructor(props) {
    super();

    const { flipper, reference } = props;
    this.validateForm = this.validateForm.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.setErrors = this.setErrors.bind(this);
    this.setSubmitting = this.setSubmitting.bind(this);
    this.onCountryChange = this.onCountryChange.bind(this);
    this.onCountryCodeChange = this.onCountryCodeChange.bind(this);

    // temp hack till we refactored this completly
    /* istanbul ignore next */
    if (reference) {
      /* istanbul ignore next */
      reference.current = this;
    }

    const regionCodes = new RegionCodes();
    let regionCodesBillingUS = regionCodes.billingUS;
    const regionCodesShippingUS = regionCodes.shippingUS;
    if (flipper.allStatesAvailable) {
      regionCodesBillingUS = regionCodesBillingUS.concat({ code: 'NM', name: 'New Mexico' }, { code: 'PA', name: 'Pennsylvania' });
    }

    this.state = {
      allStatesAvailable: flipper.allStatesAvailable,
      regionCodesBillingUS,
      regionCodesShippingUS,
    };
  }

  onSubmit() {
    // currently not needed, but formik wants it
  }

  onCountryChange(value) {
    if (!this.props.initialValues || !this.props.initialValues.country || (value && value.code !== this.props.initialValues.country.code)) {
      if (this.props.countryChangeAction) {
        this.props.countryChangeAction(value);
      }
      handleFormikValueChange({ ...this.form, name: this.prefix('country') }, value);
      handleFormikValueChange({ ...this.form, name: this.prefix('vatNumber') }, null);
      handleFormikValueChange({ ...this.form, name: this.prefix('regionCode') }, null);
      handleFormikValueChange({ ...this.form, name: this.prefix('region') }, null);
    }
  }

  onCountryCodeChange(value) {
    if (value && value.code !== this.props.initialValues.regionCode) {
      handleFormikValueChange({ ...this.form, name: this.prefix('regionCode') }, value.code);
      handleFormikValueChange({ ...this.form, name: this.prefix('region') }, value);
    }
  }

  setErrors(errors) {
    const { prefix } = this.props;
    this.form.setErrors(prefixObjectKeys(errors, prefix));
  }

  setSubmitting(value) {
    this.form.setSubmitting(value);
  }

  validateForm(prefixedValues) {
    const {
      prefix, showVat, intl, firstLastNameRequired,
    } = this.props;

    const errors = {};
    const values = unprefixObjectKeys(prefixedValues, prefix);

    let vatRequired = true;
    let regionCodeRequired = true;
    if (isSomething(values.country)) {
      vatRequired = !(VatPrefixes.forCountryCode(values.country.code) === '');
      regionCodeRequired = (values.country.code === 'US');
    }

    if (isEmpty(values.firstName) && firstLastNameRequired) {
      errors.firstName = intl.formatMessage({ id: 'validation.first_name.mandatory' });
    } else if (values.firstName && values.firstName.length > 150) {
      errors.firstName = intl.formatMessage({ id: 'validation.first_name.too_long' });
    }

    if (isEmpty(values.lastName) && firstLastNameRequired) {
      errors.lastName = intl.formatMessage({ id: 'validation.last_name.mandatory' });
    } else if (values.lastName && values.lastName.length > 150) {
      errors.lastName = intl.formatMessage({ id: 'validation.last_name.too_long' });
    }

    if (isEmpty(values.companyName)) {
      errors.companyName = intl.formatMessage({ id: 'validation.company.mandatory' });
    } else if (values.companyName && values.companyName.length > 250) {
      errors.companyName = intl.formatMessage({ id: 'validation.company.too_long' });
    }

    if (isEmpty(values.streetName)) {
      errors.streetName = intl.formatMessage({ id: 'validation.street_name.mandatory' });
    } else if (values.streetName.length > 140) {
      errors.streetName = intl.formatMessage({ id: 'validation.street_name.too_long' });
    }

    if (values.streetNumber && values.streetNumber.length > 10) {
      errors.streetNumber = intl.formatMessage({ id: 'validation.street_number.too_long' });
    }

    if (values.regionCode && values.regionCode.length > 50) {
      errors.regionCode = intl.formatMessage({ id: 'validation.region_code.too_long' });
    }

    if (regionCodeRequired && isObjectNullOrUndefined(values.region)) {
      errors.region = intl.formatMessage({ id: 'validation.region.mandatory' });
    }

    if (isEmpty(values.city)) {
      errors.city = intl.formatMessage({ id: 'validation.city.mandatory' });
    } else if (values.city.length > 50) {
      errors.city = intl.formatMessage({ id: 'validation.city.too_long' });
    }

    if (isEmpty(values.zipCode)) {
      errors.zipCode = intl.formatMessage({ id: 'validation.zip_code.mandatory' });
    } else if (values.zipCode.length > 20) {
      errors.zipCode = intl.formatMessage({ id: 'validation.zip_code.too_long' });
    }

    if (!values.country) {
      errors.country = intl.formatMessage({ id: 'validation.country.mandatory' });
    }

    if (values.phone && values.phone.length > 50) {
      errors.phone = intl.formatMessage({ id: 'validation.phone.too_long' });
    }

    if (values.email && !isEmail(values.email)) {
      errors.email = intl.formatMessage({ id: 'validation.email.invalid' });
    }
    if (values.email && values.email.length > 70) {
      errors.email = intl.formatMessage({ id: 'validation.email.too_long' });
    }

    if (showVat && vatRequired && isEmpty(values.vatNumber)) {
      errors.vatNumber = intl.formatMessage({ id: 'validation.vat_number.mandatory' });
    } else if (showVat && vatRequired && values.vatNumber.length > 20) {
      errors.vatNumber = intl.formatMessage({ id: 'validation.vat_number.too_long' });
    }

    if (values.customerPurchaseOrder) {
      if (values.customerPurchaseOrder.length > 100) {
        errors.customerPurchaseOrder = intl.formatMessage({ id: 'validation.customer_purchase_order.too_long' });
      } else if (luhnCheck(values.customerPurchaseOrder)) {
        errors.customerPurchaseOrder = intl.formatMessage({ id: 'validation.customer_purchase_order.valid_luhn' });
      }
    }

    return prefixObjectKeys(errors, prefix);
  }

  prefix(name) {
    const { prefix } = this.props;
    if (isSomething(prefix)) {
      return `${prefix}${firstToUpperCase(name)}`;
    }
    return name;
  }

  submit() {
    this.form.setStatus('submitted');
    this.form.handleSubmit({});
    return Object.keys(this.validateForm(this.form.values)).length === 0;
  }

  values() {
    const { prefix } = this.props;
    return unprefixObjectKeys(this.form.values, prefix);
  }

  renderForm(props) {
    const {
      onSubmit, showEmail, id, showVat, showCustomerPurchaseOrder, countries, autoFocus, intl, firstLastNameRequired,
    } = this.props;
    const { regionCodesBillingUS, allStatesAvailable, regionCodesShippingUS } = this.state;
    this.form = props;
    let vatPrefix;
    const country = props.values[this.prefix('country')];
    if (isSomething(country)) {
      vatPrefix = VatPrefixes.forCountryCode(country.code);
      // get the region object by region code to display in a selectbox
      if (props.values[this.prefix('country')].code === 'US' && props.values[this.prefix('regionCode')]) {
        const neededRegion = regionCodesBillingUS.find((c) => c.code === props.values[this.prefix('regionCode')]);
        if (neededRegion) {
          /* eslint-disable no-param-reassign */
          props.values[this.prefix('region')] = regionCodesBillingUS.find((c) => c.code === props.values[this.prefix('regionCode')]);
        }
      }
    }

    let regionCodeInput = (
      <TextInput
        {...props}
        id={`${id}-region-code`}
        name={this.prefix('regionCode')}
        label={intl.formatMessage({ id: 'label.region_code' })}
      />
    );
    let regionCodeInfo = null;

    if (isSomething(country) && country.code === 'US') {
      if (id === 'billing-address' && !this.state.allStatesAvailable) {
        regionCodeInfo = (
          <div className="row">
            <div className="col-md-12">
              <h4 style={{ color: '#A8005C' }}>
                <FormattedMessage id="susbcription.billing_address.us_state_info" />
              </h4>
            </div>
          </div>
        );
      }
      let regionCodes;
      if (id === 'shipping-address') {
        regionCodes = regionCodesShippingUS;
      } else {
        regionCodes = regionCodesBillingUS;
        if (allStatesAvailable && (!regionCodes.find((element) => element.code === 'NM'))) {
          regionCodes.push({ code: 'NM', name: 'New Mexico' });
          regionCodes.push({ code: 'PA', name: 'Pennsylvania' });
        }
      }

      regionCodeInput = (
        <SelectBox
          {...props}
          id={`${id}-region-code`}
          options={regionCodes}
          onChange={this.onCountryCodeChange}
          name={this.prefix('region')}
          labelKey="name"
          valueKey="code"
          label={intl.formatMessage({ id: 'label.region_code' })}
        />
      );
    }

    return (
      <form onSubmit={onSubmit} id={id} noValidate>
        <button className="hidden" type="submit" />
        <div className="row">
          <div className="col-md-12">
            <TextInput
              {...props}
              autoFocus={!isTouchDevice() && autoFocus}
              id={`${id}-company-name`}
              name={this.prefix('companyName')}
              label={intl.formatMessage({ id: 'label.company' })}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <TextInput
              {...props}
              id={`${id}-firstname`}
              name={this.prefix('firstName')}
              label={intl.formatMessage({ id: 'label.first_name' })}
              required={firstLastNameRequired}
            />
          </div>
          <div className="col-md-6">
            <TextInput
              {...props}
              id={`${id}-lastname`}
              name={this.prefix('lastName')}
              label={intl.formatMessage({ id: 'label.last_name' })}
              required={firstLastNameRequired}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <TextInput
              {...props}
              id={`${id}-street-name`}
              name={this.prefix('streetName')}
              label={intl.formatMessage({ id: 'label.street_name' })}
              required
            />
          </div>
          <div className="col-md-4">
            <TextInput
              {...props}
              id={`${id}-street-number`}
              name={this.prefix('streetNumber')}
              label={intl.formatMessage({ id: 'label.street_number' })}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <TextInput
              {...props}
              id={`${id}-zip-code`}
              name={this.prefix('zipCode')}
              label={intl.formatMessage({ id: 'label.zip_code' })}
              required
            />
          </div>
          <div className="col-md-8">
            <TextInput
              {...props}
              id={`${id}-city`}
              name={this.prefix('city')}
              label={intl.formatMessage({ id: 'label.city' })}
              required
            />
          </div>
        </div>
        {regionCodeInfo}
        <div className="row">
          <div className="col-md-4">
            {regionCodeInput}
          </div>
          <div className="col-md-8">
            <SelectBox
              {...props}
              id={`${id}-country`}
              options={countries}
              onChange={this.onCountryChange}
              name={this.prefix('country')}
              labelKey="name"
              valueKey="code"
              label={intl.formatMessage({ id: 'label.country' })}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <TextInput
              {...props}
              id={`${id}-phone`}
              name={this.prefix('phone')}
              label={intl.formatMessage({ id: 'label.phone' })}
            />
          </div>
          {showEmail ? (
            <div className="col-md-6">
              <TextInput
                {...props}
                id={`${id}-email`}
                name={this.prefix('email')}
                label={intl.formatMessage({ id: 'label.email' })}
                required
              />
            </div>
          ) : null}
        </div>
        {showVat || showCustomerPurchaseOrder ? (
          <div className="row">
            {showVat && vatPrefix ? (
              <div className="col-md-6">
                <TextInput
                  {...props}
                  id={`${id}-vat-id`}
                  prefix={vatPrefix}
                  name={this.prefix('vatNumber')}
                  label={intl.formatMessage({ id: 'label.vat_number' })}
                  required
                />
              </div>
            ) : null}
            {showCustomerPurchaseOrder ? (
              <div className="col-md-6">
                <TextInput
                  {...props}
                  id={`${id}-customer-purchase-order`}
                  name={this.prefix('customerPurchaseOrder')}
                  label={intl.formatMessage({ id: 'label.customer_purchase_order' })}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </form>
    );
  }

  render() {
    const { initialValues, prefix } = this.props;
    return (
      <Formik
        initialValues={prefixObjectKeys(initialValues, prefix)}
        validate={this.validateForm}
        onSubmit={this.onSubmit}
        render={this.renderForm}
      />
    );
  }
}

export default withIntl(withFlipper(AddressForm));
