import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import {
  intlShape,
  browserShape,
  backendShape,
  sessionShape,
  isEmpty,
  withSession,
  withIntl,
  withBackend,
  withBrowser,
  withFlipper,
  isTouchDevice,
  ContextualHelp,
  Column,
  Form,
  Row,
  Checkbox,
  RadioSelect,
  Container,
  TextInput,
  SubmitButton,
  BadRequestError,
  withNotifier,
  notifierShape,
} from 'lcm-iot-commons';
import validator from 'validator';

import { withSignUp, signUpShape } from '../../context/SignUpContext';
import withZxcvbn, { zxcvbnShape } from '../../wrappers/withZxcvbn';
import Captcha from '../Captcha/Captcha';
import PasswordInput from '../Form/PasswordInput';
import SignUpBreadcrumb from './SignUpBreadcrumb';
import { SignUpTileSlider } from './SignUpTileSlider';

export function SignUp({
  intl, session, browser, backend, signUp, notifier, zxcvbn, flipper,
}) {
  const captcha = React.useRef();
  const { t } = intl;

  if (session.authenticated) {
    browser.redirectTo(session.startUrl);
  }

  const onSubmit = async (values, actions) => {
    try {
      const newsletterValue = values.newsletter ? 'confirmation_pending' : 'false';
      const user = {
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        password: values.password,
        captcha: values.captcha,
        newsletter: newsletterValue,
      };
      await backend.post('/users/register', user);
      signUp.updateSignUpDetails(values);
      browser.navigateTo('/sign_up_success');
    } catch (apiErrors) {
      const formErrors = {};
      if (apiErrors instanceof BadRequestError) {
        if (apiErrors.contains('taken', 'email')) {
          formErrors.email = intl.formatMessage({ id: 'api.error.email.taken' });
          actions.setFieldValue('captcha', undefined, false);
          // eslint-disable-next-line no-unused-expressions
          captcha.current?.reset();
        } else if (apiErrors.contains('invalid_captcha', 'captcha')) {
          formErrors.captcha = intl.formatMessage({ id: 'validation.captcha.invalid' });
          // eslint-disable-next-line no-unused-expressions
          captcha.current?.reset();
        } else if (apiErrors.contains('weak_password', 'password')) {
          formErrors.password = intl.formatMessage({ id: 'validation.password.invalid' });
        } else {
          notifier.showError(backend.translateError(apiErrors));
          // eslint-disable-next-line no-unused-expressions
          captcha.current?.reset();
        }
      } else {
        notifier.showError(backend.translateError(apiErrors));
      }

      actions.setErrors(formErrors);
      actions.setSubmitting(false);
      actions.setStatus('');
    }
  };

  const validateForm = (values) => {
    const errors = {};
    if (isEmpty(values.firstName)) {
      errors.firstName = t`validation.first_name.mandatory`;
    }

    if (isEmpty(values.lastName)) {
      errors.lastName = t`validation.last_name.mandatory`;
    }

    if (isEmpty(values.email)) {
      errors.email = intl.formatMessage({ id: 'validation.email.mandatory' });
    } else if (!validator.isEmail(values.email, { allow_utf8_local_part: false })) {
      errors.email = intl.formatMessage({ id: 'validation.email.invalid' });
    }

    if (isEmpty(values.password)) {
      errors.password = intl.formatMessage({ id: 'validation.password.mandatory' });
    } else {
      const passwordError = zxcvbn.checkPassword(values.password, zxcvbn.extractUserInputs(values, ['password', 'captcha'])).error;
      if (passwordError) {
        errors.password = passwordError;
      }
    }

    if (!values.captcha) {
      errors.captcha = intl.formatMessage({ id: 'validation.captcha.mandatory' });
    }

    if (!values.terms) {
      errors.terms = intl.formatMessage({ id: 'validation.terms.mandatory' });
    }

    if (isEmpty(values.newsletter)) {
      errors.newsletter = intl.formatMessage({ id: 'validation.option.invalid' });
    }

    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;

    const newletterHelp = (
      <ContextualHelp title={intl.formatMessage({ id: 'signup.newsletter.title' })}>
        <p>{intl.formatMessage({ id: 'signup.newsletter.help_info_1' })}</p>
        <p>{intl.formatMessage({ id: 'signup.newsletter.help_info_2' })}</p>
      </ContextualHelp>
    );

    const newsletterOptions = [
      { value: 'true', label: intl.formatMessage({ id: 'signup.newsletter.yes' }), contextualHelp: newletterHelp },
      { value: 'false', label: intl.formatMessage({ id: 'signup.newsletter.no' }) },
    ];

    const termsLabel = (
      <span>
        <FormattedMessage id="signup.accept_terms" />
&nbsp;
        <a
          href="/legal/terms-of-service"
          target="_new"
          id="signup_tos_url"
        >
          <FormattedMessage id="signup.terms" />
        </a>
        &nbsp;
        <FormattedMessage id="signup.and" />
&nbsp;
        <a href="/legal/privacy-policy" target="_new" id="signup_privacy_url">
          <FormattedMessage id="signup.privacy_policy" />
        </a>
      </span>
    );

    return (
      <Form {...formProps}>
        <TextInput
          {...formProps}
          name="firstName"
          label={intl.formatMessage({ id: 'label.first_name' })}
          autoFocus={!isTouchDevice()}
          required
        />
        <TextInput
          {...formProps}
          name="lastName"
          label={intl.formatMessage({ id: 'label.last_name' })}
          required
        />
        <TextInput
          {...formProps}
          name="email"
          label={intl.formatMessage({ id: 'label.email' })}
          required
          autoCorrect="off"
          autoComplete="username"
        />
        <PasswordInput
          {...formProps}
          name="password"
          label={intl.formatMessage({ id: 'label.password' })}
          autoComplete="current-password"
          required
        />
        <RadioSelect
          {...formProps}
          id="newsletter"
          name="newsletter"
          label="Radio Select"
          options={newsletterOptions}
        />
        <br />
        <Checkbox
          {...formProps}
          id="terms"
          name="terms"
          labelNode={termsLabel}
        />
        <Captcha {...formProps} name="captcha" ref={captcha} />
        <SubmitButton id="sign-up" text={intl.formatMessage({ id: 'signup.button.signup' })} fetching={isSubmitting} />
      </Form>
    );
  };

  return (
    <Container>
      <Row>
        <div className="col-md-12"><SignUpBreadcrumb current="sign-up" action="signup" /></div>
        <Column sm="12">
          <h1 id="header"><FormattedMessage id="signup.header" /></h1>
          <p className="space-after">{t`signup.introduction`}</p>
        </Column>
      </Row>
      {flipper.loginWithMyEndressHauser ? (
        <Row>
          <Column md="5">
            <p>
              {intl.formatMessage({ id: 'signin.or_sso_provider' })}
            </p>
            <form method="post" action="/app/id/api/auth/myendress" id="myendress-signin-form">
              <div className="button-group">
                <button className="btn" type="submit">{intl.formatMessage({ id: 'signin.button.signin.myendress' })}</button>
              </div>
            </form>
            <br />
          </Column>
        </Row>
      ) : null}
      <Row>
        <Column md="7">
          <Formik
            validate={validateForm}
            onSubmit={onSubmit}
            render={renderForm}
          />
        </Column>
        <Column md="4">
          <div id="signup_customer_feedback" className="sign-up-customer-feedback-block">
            <SignUpTileSlider intl={intl} />
          </div>
        </Column>
      </Row>
    </Container>
  );
}

SignUp.propTypes = {
  intl: intlShape.isRequired,
  browser: browserShape.isRequired,
  backend: backendShape.isRequired,
  signUp: signUpShape.isRequired,
  session: sessionShape,
  notifier: notifierShape.isRequired,
  zxcvbn: zxcvbnShape.isRequired,
  flipper: PropTypes.shape({
    loginWithMyEndressHauser: PropTypes.bool,
  }),
};

SignUp.defaultProps = {
  session: {
    authenticated: false,
    startUrl: '',
  },
  flipper: {
    loginWithMyEndressHauser: false,
  },
};

export default withZxcvbn(withSignUp(withFlipper(withNotifier(withIntl(withBackend(withBrowser(withSession(SignUp))))))));
