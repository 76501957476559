import uuid from 'uuid/v4';
import ApiClient from './ApiClient';
import ApiError from './ApiError';

import { refreshSession, deleteSession } from '../actions/sessionActions';
import { store } from '../store';

import { redirectTo } from '../utils/browserUtils';
import { isFunction } from '../utils/langUtils';

/*
  SORRY FOR THE ISTANBUL IGNORIES, see:
  https://github.com/gotwarlost/istanbul/issues/690
*/

export class PrivateLcmApiClient extends ApiClient {
  constructor(baseUrl, options, getState, sessionRefresh, sessionCleaner, useNewErrors) /* istanbul ignore next */ {
    super(baseUrl, options);
    this.sessionRefresher = sessionRefresh;
    this.getState = getState;
    this.sessionCleaner = sessionCleaner;
    this.useNewErrors = useNewErrors;
  }

  execute(request) {
    if (this.sessionRefresher && this.shouldRefreshSession()) {
      request.refreshSessionIfNeeded = false;
      return this.executeAfterSessionRefresh(request);
    }
    request.refreshSessionIfNeeded = true;
    /* istanbul ignore next */
    return super.execute(request);
  }

  // this executes the request after the session was refreshed.
  executeAfterSessionRefresh(request) {
    const self = this;
    return this.refreshSession().then(() => {
      request.refreshSessionIfNeeded = false;
      const { token } = this.getState().session;
      if (token) {
        request.headers.Authorization = `Bearer ${token}`;
        /* istanbul ignore next */
        return super.execute(request);
      } if (self.getState().session.errors) {
        throw new ApiError(self.getState().session.errors);
      } else {
        // this correct the bug LIH-8760 given by Sentry if user tries to send a request to the Hub but session is timed out
        this.sessionCleaner().then(() => {
          redirectTo('/');
        });
        // this correct the bug LIH-8760 given by Sentry if user tries to send a request to the Hub but session is timed out
        return new Promise(() => true);
      }
    });
  }

  handleError(error, request) {
    if (error.response && error.response.status === 401 && this.sessionRefresher && request.refreshSessionIfNeeded) {
      return this.executeAfterSessionRefresh(request);
    }
    /* istanbul ignore next */
    return super.handleError(error, request);
  }

  cleanSession() {
    return Promise.resolve(this.sessionCleaner());
  }

  refreshSession() {
    return Promise.resolve(this.sessionRefresher());
  }

  shouldRefreshSession() {
    return this.getState().session.tokenExpiresAt === null || this.getState().session.tokenExpiresAt - Date.now() <= 0;
  }
}

export default function lcmApiClient(dispatchOrCache = store().dispatch, getStateOrUseNewErrors = store().getState, cache = true) {
  const dispatch = isFunction(dispatchOrCache) ? dispatchOrCache : store().dispatch;
  /* istanbul ignore next */
  const getState = isFunction(getStateOrUseNewErrors) ? getStateOrUseNewErrors : store().getState;
  const useCache = (isFunction(dispatchOrCache) && cache) || (!isFunction(dispatchOrCache) && dispatchOrCache);

  const baseUrl = global.configuration.lcmApiUrl;
  const { token } = getState().session;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  if (!useCache) {
    headers['If-None-Match'] = uuid();
    headers['If-Modified-Since'] = 0;
  }

  return new PrivateLcmApiClient(
    baseUrl,
    { headers },
    getState,
    () => dispatch(refreshSession()),
    () => dispatch(deleteSession()),
    getStateOrUseNewErrors && !isFunction(getStateOrUseNewErrors),
  );
}
