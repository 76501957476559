import React from 'react';
import filesize from 'filesize';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import { iconForFileName } from '../../utils/fileUtils';
import { intlShape } from '../../shapes';
import { ActionButton } from '../../index';
import { handleFormikValueChange } from '../../utils/formikUtils';
import { ActionBar } from '../ActionBar';

export function FilesUploadList(props) {
  const {
    intl, handleChange, handleBlur, values, filesUploadNameReference, uploading,
  } = props;
  const attachments = values[filesUploadNameReference]?.acceptedFiles || [];
  const fileIcon = uploading ? 'icon-eh-load' : 'icon-eh-delete';

  const onDeleteFile = (file) => {
    const newAcceptedFilesList = values[filesUploadNameReference]?.acceptedFiles.filter((acceptedFile) => acceptedFile.path !== file.path); // ? better identification of file?
    handleFormikValueChange({ handleChange, handleBlur, name: filesUploadNameReference }, { acceptedFiles: newAcceptedFilesList });
  };

  const renderFileList = (file, index) => (
    <div key={index} className="list-item">
      <div className="list-item-icon">
        <span className={` icon ${iconForFileName(file.name)}`} />
      </div>
      <div className="list-item-content">
        <div className="list-item-header-only">
          {file.name}
        </div>
      </div>
      <div id="file-size">{filesize(file.size)}</div>
      <div className="btn-group" style={{ marginBottom: '0px', height: '40px' }}>
        <ActionButton id="button-delete" defaultLabel="" icon={fileIcon} onClick={() => onDeleteFile(file)} disabled={uploading} />
      </div>
    </div>
  );

  const fileItems = attachments?.map((file, index) => renderFileList(file, index));

  return (
    <div id="uploaded-files-list" className="space-after">
      <ActionBar>
        <h3 className="file-list-header">{intl.formatMessage({ id: 'add.attachments.header' })}</h3>
      </ActionBar>
      <div className="list">
        {fileItems}
      </div>
    </div>
  );
}

FilesUploadList.propTypes = {
  uploading: PropTypes.bool,
  filesUploadNameReference: PropTypes.string, // name of the form field of <FilesUpload />
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  intl: intlShape,
};

export default
injectIntl(
  FilesUploadList,
);
