import ProductRules from './ProductRules';
import SpecificationKeys from '../constants/SpecificationKeys';

const DEFAULT_VALUE_KEY_SORTING = ['pv', 'sv', 'tv'];

class AssetRules {
  constructor(asset) {
    this.asset = asset;
    this.productRules = ProductRules.find(asset.manufacturerName, asset.productCode, asset.productTenantPublic);
  }

  displayValues(multipleValues = true) {
    if (!this.asset.values) {
      return [];
    }

    const valueKeys = this.asset.values.map((value) => value.key).sort((a, b) => {
      if (DEFAULT_VALUE_KEY_SORTING.indexOf(a) === -1 && DEFAULT_VALUE_KEY_SORTING.indexOf(b) === -1) return a.localeCompare(b);
      if (DEFAULT_VALUE_KEY_SORTING.indexOf(a) === -1) return 1;
      if (DEFAULT_VALUE_KEY_SORTING.indexOf(b) === -1) return -1;
      return DEFAULT_VALUE_KEY_SORTING.indexOf(a) - DEFAULT_VALUE_KEY_SORTING.indexOf(b);
    });

    const productSpecifications = this.asset.product?.specifications;
    const primaryValueKeys = productSpecifications && productSpecifications[SpecificationKeys.MultipleAssetValues.PRIMARY_VALUE_KEYS]
      ? productSpecifications[SpecificationKeys.MultipleAssetValues.PRIMARY_VALUE_KEYS].value.split(',') : undefined;

    const displayValueKeys = primaryValueKeys ? primaryValueKeys.filter((key) => valueKeys.indexOf(key) >= 0) : valueKeys;
    const displayValues = displayValueKeys.map((key) => this.asset.values.find((value) => value.key === key));
    const values = this.productRules.convertDisplayValues(this.asset, this.asset.instrumentations, displayValues);

    if (!multipleValues && values.length > 1) {
      return [values[0]];
    }

    return values;
  }

  get(rule, a, b, c) {
    if (this[rule]) {
      return this[rule](a, b, c);
    }
    return this.productRules.get(rule, a, b, c);
  }
}

export default AssetRules;
