import backendApiClient from './backendApiClient';
import { extractCountriesWithUserCountry } from '../extractors/countriesExtractor';

/* istanbul ignore next */
export async function loadAllowedCountries() {
  return extractCountriesWithUserCountry(await backendApiClient().get('/countries', { all: false, language: global.configuration.language }));
}

/* istanbul ignore next */
export async function loadAllCountries() {
  return extractCountriesWithUserCountry(await backendApiClient().get('/countries', { all: true, language: global.configuration.language }));
}
