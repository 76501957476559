import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { intlShape } from '../shapes';

function withIntl(Component) {
  function Wrapper(properties) {
    const { innerRef, ...props } = properties;
    const t = (id, ...args) => props.intl.formatMessage({ id }, ...args);
    const newProps = { ...props, intl: { ...props.intl, t } };
    return (
      <Component
        ref={innerRef}
        {...newProps}
      />
    );
  }

  Wrapper.propTypes = {
    innerRef: PropTypes.func,
    intl: intlShape,
  };

  const IntlWrapper = injectIntl(Wrapper);
  const WrappedComponent = forwardRef((props, ref) => (
    <IntlWrapper {...props} innerRef={ref} />
  ));

  WrappedComponent.displayName = Component.displayName || Component.name;

  return WrappedComponent;
}

export default withIntl;
