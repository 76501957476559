export function extractPlanVariantAddon(rawPlanVariantAddon) {
  return {
    id: rawPlanVariantAddon.id,
    currency: rawPlanVariantAddon.currency,
    name: rawPlanVariantAddon.name,
    price: rawPlanVariantAddon.price ? parseFloat(rawPlanVariantAddon.price) : undefined,
    type: rawPlanVariantAddon.type,
    assets: rawPlanVariantAddon.assets,
    storage: rawPlanVariantAddon.storage,
    countryBlacklist: rawPlanVariantAddon.country_blacklist ? rawPlanVariantAddon.country_blacklist : [],
    disabled: rawPlanVariantAddon.disabled,
  };
}

export function extractPlanVariantAddons(rawPlanVariantAddons) {
  return rawPlanVariantAddons.map((planVariantAddon) => extractPlanVariantAddon(planVariantAddon));
}
