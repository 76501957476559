import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/de';

import { isNotEmpty } from '../utils/langUtils';

const TRANSLATIONS = {
  de: {},
  en: {},
  zh: {},
};

export function translationKeyExists(key, language = global.configuration.language) {
  return isNotEmpty(TRANSLATIONS[language][key]);
}

// Warning! unsafe function
export const addTranslations = (language, translations, overwrite = false) => {
  Object.keys(translations).forEach((key) => {
    if (overwrite || !translationKeyExists(key, language)) {
      TRANSLATIONS[language][key] = translations[key];
    }
  });
};

async function mergeMessages(userLanguage) {
  const messages = (await import(
    /* webpackChunkName: "translations" */
    `./${userLanguage}.js`
  )).default;
  addTranslations(userLanguage, messages);
  return messages;
}

// getTranslations :: (en|de|zh) -> Exception<Promise<Translation>> | Translation
export function getTranslations(userLanguage) {
  if (TRANSLATIONS[userLanguage]['general.loading']) {
    return TRANSLATIONS[userLanguage];
  }
  throw mergeMessages(userLanguage);
}

export default TRANSLATIONS;
