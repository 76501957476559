import { isNotEmpty } from './langUtils';

export function displayUserName(user, fallbackWithEmail = false, useRawLabels = false) {
  if (!user) {
    return null;
  }

  if (useRawLabels && (isNotEmpty(user.first_name) || isNotEmpty(user.last_name))) {
    return `${user.first_name ? user.first_name : ''} ${user.last_name ? user.last_name : ''}`.trim();
  } if (!useRawLabels && (isNotEmpty(user.firstName) || isNotEmpty(user.lastName))) {
    return `${user.firstName ? user.firstName : ''} ${user.lastName ? user.lastName : ''}`.trim();
  }

  if (fallbackWithEmail) {
    return user.email;
  }

  return null;
}
