import { isArray } from 'lodash-es';

class ApiError extends Array {
  constructor(errors, request) {
    super();
    /* istanbul ignore next */
    this.request = request;
    /* Remove when solved: https://github.com/gotwarlost/istanbul/issues/690 */
    if (errors && isArray(errors)) {
      errors.forEach((error) => this.push(error));
    } else if (errors) {
      this.push(errors);
    }
  }
}

export default ApiError;
