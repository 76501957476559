import { locale } from './browserUtils';

export function findAttachmentWithLanguage(attachments, language) {
  let attachmentWithLanguage = null;
  const languageWithoutRegion = language?.split('-')[0];

  // eslint-disable-next-line no-unused-expressions
  attachments?.forEach((attachment) => {
    if (attachmentWithLanguage === null && attachment.languages && attachment.languages.includes(languageWithoutRegion)) {
      attachmentWithLanguage = attachment;
    }
  });

  return attachmentWithLanguage;
}

export function selectAttachment(attachments) {
  let attachment = null;
  attachment = findAttachmentWithLanguage(attachments, locale());
  // Fallback to English if the Browser Language is not available
  if (!attachment) {
    attachment = findAttachmentWithLanguage(attachments, 'en');
  }

  // Fallback to first attachment if English is not available
  if (!attachment) {
    attachment = attachments[0];
  }

  return attachment;
}

export const mapDocumentsDefaults = (documents) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  documents.map((document) => ({
    ...document,
    defaultAttachment: selectAttachment(document.attachments),
    defaultCategory: document.categories?.[0] ?? {},
  }));
