import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  TextArea,
  TextInput,
  CancelButton,
  SubmitButton,
  Form,
  isEmpty,
  ButtonGroup,
  apiShape,
  notifierShape,
  intlShape,
  browserShape,
  withApi,
  withNotifier,
  withIntl,
  withBrowser,
  TenantTypeahead,
  ProductCategoryTypeahead,
  CompanyTypeaheadWithTenant,
  SelectBox,
} from 'lcm-iot-commons';

import ProductStatusSelect from './ProductStatusSelect';
import ProductTypeahead from './ProductTypeahead';
import ProductFormLogic from './ProductFormLogic';

export function ProductForm({
  api,
  notifier,
  intl,
  browser,
  edit,
  initialValues,
}) {
  const validateForm = (values) => {
    const errors = {};
    if (!edit && !values.tenant) {
      errors.tenant = intl.formatMessage({ id: 'validation.product.tenant.mandatory' });
    }
    if (!values.manufacturer) {
      errors.manufacturer = intl.formatMessage({ id: 'validation.product.manufacturer.mandatory' });
    }
    if (isEmpty(values.code)) {
      errors.code = intl.formatMessage({ id: 'validation.product.code.mandatory' });
    }
    if (isEmpty(values.name)) {
      errors.name = intl.formatMessage({ id: 'validation.product.name.mandatory' });
    }
    if (!values.status) {
      errors.status = intl.formatMessage({ id: 'validation.product.status.mandatory' });
    }
    return errors;
  };

  const riskOfMaintainability = [
    { id: 'low', name: intl.formatMessage({ id: 'enum.risk_of_maintainability.low' }) },
    { id: 'medium', name: intl.formatMessage({ id: 'enum.risk_of_maintainability.medium' }) },
    { id: 'high', name: intl.formatMessage({ id: 'enum.risk_of_maintainability.high' }) },
    { id: 'undefined', name: intl.formatMessage({ id: 'enum.risk_of_maintainability.undefined' }) },
  ];
  const displayedValues = edit ? {
    ...initialValues, riskOfMaintainability: riskOfMaintainability.find((r) => r.id === initialValues?.riskOfMaintainability),
  } : initialValues;

  const renderForm = (formProps) => {
    const { isSubmitting, values } = formProps;
    return (
      <Form id="product-form" {...formProps}>
        <TenantTypeahead
          {...formProps}
          id="product-tenant"
          name="tenant"
          disabled={edit}
          label={intl.formatMessage({ id: 'label.tenant' })}
          canCreate
          required={!edit}
        />
        <CompanyTypeaheadWithTenant
          {...formProps}
          id="product-manufacturer"
          name="manufacturer"
          label={intl.formatMessage({ id: 'label.manufacturer' })}
          tenantId={values.tenant?.id}
          onlyCreate={values.tenant?.new || !values.tenant}
          canCreate
          required
        />
        <TextInput
          {...formProps}
          id="product-code"
          name="code"
          label={intl.formatMessage({ id: 'label.product_code' })}
          required
        />
        <TextInput
          {...formProps}
          id="product-name"
          name="name"
          label={intl.formatMessage({ id: 'label.name' })}
          required
        />
        <TextArea
          {...formProps}
          id="product-description"
          name="description"
          label={intl.formatMessage({ id: 'label.description' })}
        />
        <ProductStatusSelect
          {...formProps}
          id="product-status"
          name="status"
          label={intl.formatMessage({ id: 'label.status' })}
          required
        />
        <ProductTypeahead
          {...formProps}
          id="product-parent"
          name="parent"
          excludeProductId={values.id}
          tenantId={values.tenant?.id}
          manufacturerId={values.manufacturer?.id}
          label={intl.formatMessage({ id: 'label.product_parent' })}
        />
        <SelectBox
          {...formProps}
          id="product-risk-of-maintainability"
          name="riskOfMaintainability"
          label={intl.formatMessage({ id: 'label.risk_of_maintainability' })}
          options={riskOfMaintainability}
        />
        <ProductCategoryTypeahead
          {...formProps}
          id="product-category-typeahead"
          name="category"
          tenantId={values.tenant?.id}
          label={intl.formatMessage({ id: 'label.category' })}
          canCreate
        />
        <ButtonGroup>
          <SubmitButton
            id="product-form-submit-button"
            fetching={isSubmitting}
            disabled={!formProps.dirty || isSubmitting}
            intl={intl}
          />
          <CancelButton
            id="product-form-cancel-button"
            fetching={isSubmitting}
            intl={intl}
          />
        </ButtonGroup>
      </Form>
    );
  };

  const productFormLogic = new ProductFormLogic(api, notifier, intl, browser, edit, displayedValues);

  return (
    <Formik
      id="product-form"
      initialValues={displayedValues}
      onSubmit={productFormLogic.handleOnSubmit}
      render={renderForm}
      validate={validateForm}
    />
  );
}

ProductForm.propTypes = {
  intl: intlShape.isRequired,
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
  browser: browserShape.isRequired,
  edit: PropTypes.bool,
  initialValues: PropTypes.shape({
    id: PropTypes.number.isRequired,
    tenant: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    manufacturer: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    code: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    category: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    riskOfMaintainability: PropTypes.string,
    parent: PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
    }),
  }),
};

ProductForm.defaultProps = {
  edit: false,
  initialValues: undefined,
};

export default withBrowser(withIntl(withApi(withNotifier(ProductForm))));
