import React, { Component } from 'react';
import { Formik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import { intlShape } from '../../shapes';
import { ActionBar } from '../ActionBar';

import {
  apiErrorsContain,
  createUserGroup,
} from '../../api';

import {
  Form,
  CancelButton,
  SubmitButton,
  TextInput,
  TextArea,
} from '../Form';

import {
  handleUnknownErrors,
  isEmpty,
  navigateTo,
  showSuccess,
} from '../../utils';
import { Column, Container, Row } from '../Grid';
import BackButton from '../BackButton';

class UserGroupCreate extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.validateForm = this.validateForm.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values, actions) {
    const { intl } = this.props;

    createUserGroup(values)
      .then((id) => {
        actions.setSubmitting(false);
        showSuccess(intl.formatMessage({ id: 'user_group_create.success_notification' }));
        navigateTo(`/usergroups/${id}`);
      })
      .catch((errors) => {
        const formErrors = {};
        if (apiErrorsContain(errors, 'taken', 'name')) {
          formErrors.name = intl.formatMessage({ id: 'api.error.user_group.taken' });
        }

        if (Object.keys(formErrors).length < errors.length) {
          handleUnknownErrors(errors, intl.formatMessage({ id: 'api.error.unknown' }));
        }

        actions.setErrors(formErrors);
        actions.setSubmitting(false);
      });
  }

  validateForm(values) {
    const { intl } = this.props;
    const errors = {};

    if (isEmpty(values.name) || isEmpty(values.name.trim())) {
      errors.name = intl.formatMessage({ id: 'validation.name.mandatory' });
    } else if (values.name.length > 60) {
      errors.name = intl.formatMessage({ id: 'validation.name.too_long' }, { characters: 60 });
    }
    return errors;
  }

  renderForm(props) {
    const { intl } = this.props;
    const { isSubmitting } = props;

    // eslint-disable-next-line react/no-unused-class-component-methods
    this.form = props;

    return (
      <Form {...props}>
        <TextInput
          {...props}
          name="name"
          label={intl.formatMessage({ id: 'label.name' })}
          required
        />
        <TextArea
          {...props}
          name="description"
          label={intl.formatMessage({ id: 'label.description' })}
        />
        <div className="btn-group">
          <SubmitButton id="create-user-group-submit" fetching={isSubmitting} />
          <CancelButton id="create-user-group-cancel" disabled={isSubmitting} />
        </div>
      </Form>
    );
  }

  render() {
    return (
      <Container>
        <Row>
          <Column>
            <BackButton />
          </Column>
        </Row>
        <Row>
          <Column>
            <ActionBar>
              <h1 id="create-user-group-header"><FormattedMessage id="user_group_create.header" /></h1>
            </ActionBar>
          </Column>
        </Row>
        <Row>
          <Column sm="6">
            <Formik
              validate={this.validateForm}
              onSubmit={this.onSubmit}
              render={this.renderForm}
            />
          </Column>
        </Row>
      </Container>
    );
  }
}

export default injectIntl(UserGroupCreate);
