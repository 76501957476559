import EXIF from 'exif-js';

export class ImageScaler {
  constructor() {
    this.canvas = document.createElement('canvas');
    this.context = this.canvas.getContext('2d');
    this.image = document.createElement('img');
    this.image.addEventListener('load', this.imageLoaded.bind(this), false);
  }

  static calculateScaledSize(width, height, maxSize, callback) {
    if (height > maxSize || width > maxSize) {
      let scaleFactor = 1;
      if (width > height) {
        scaleFactor = maxSize / width;
      } else {
        scaleFactor = maxSize / height;
      }
      callback(width * scaleFactor, height * scaleFactor);
    } else {
      callback(width, height);
    }
  }

  imageLoaded() {
    ImageScaler.calculateScaledSize(
      this.image.naturalWidth,
      this.image.naturalHeight,
      this.maxSize,
      (width, height) => {
        this.canvas.width = width;
        this.canvas.height = height;
        this.context.drawImage(this.image, 0, 0, width, height);

        this.callback(this.canvas.toDataURL(this.mimeType, this.quality));
      },
    );
  }

  scaleImage(image, maxSize, mimeType, quality, orientation, callback) {
    this.orientation = orientation;
    this.maxSize = maxSize;
    this.mimeType = mimeType;
    this.quality = quality;
    this.callback = callback;
    this.image.src = image;
  }

  extractImageOrientation(file, callback) {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const exif = EXIF.readFromBinaryFile(fileReader.result);
      callback(exif.Orientation);
    };
    fileReader.readAsArrayBuffer(file);
  }

  scaleImageFile(file, maxSize, quality) {
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      return new Promise((resolve) => {
        this.extractImageOrientation(file, (orientation) => {
          this.scaleImage(file.preview, maxSize, file.type, quality, orientation, (scaledImage) => {
            const byteString = atob(scaledImage.split(',')[1]);
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            let i = 0;
            for (; i < byteString.length; i += 1) {
              ia[i] = byteString.charCodeAt(i);
            }
            resolve(new File([new Blob([ia])], file.name));
          });
        });
      });
    }
    return new Promise((resolve) => resolve(file));
  }
}

export function scaleImageFile(file, maxSize, quality) {
  const imageScaler = new ImageScaler();
  return imageScaler.scaleImageFile(file, maxSize, quality);
}
