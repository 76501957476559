import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import {
  FilesUpload, ConfirmationModal, withIntl, intlShape,
} from 'lcm-iot-commons';
import filesize from 'filesize';

export function ModbusImport(props) {
  const {
    intl, values, onImport, setFieldValue, deviceTypeCode,
  } = props;

  const { t } = intl;
  const [showImportModal, setShowImportModal] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState();
  const [rejectedErrors, setRejectedErrors] = useState([]);

  const whiteList = ['.mbconf'];
  const maxFileSize = 1024 * 1024 * 10;

  const updateUIWithLoadedData = ({ data, file }) => {
    try {
      const importedData = onImport(JSON.parse(data), deviceTypeCode);

      if (importedData.uploadError) {
        setRejectedErrors([{ uploadError: 'incompatible_config_format_error', file }]);
        return;
      }

      if (importedData.byte_order !== undefined) {
        setFieldValue('byte_order', importedData.byte_order);
      }
      if (importedData.unit_identifier !== undefined) {
        setFieldValue('unit_identifier', importedData.unit_identifier);
      }
      if (importedData.modbusConfig) {
        setFieldValue('modbusConfig', importedData.modbusConfig);
      }
      if (importedData.genericModbusConfig) {
        setFieldValue('genericModbusConfig', importedData.genericModbusConfig);
      }
    } catch (e) {
      setRejectedErrors([{ uploadError: 'incompatible_json_format_error', file }]);
    }
  };

  const loadConfigFromFile = (accepted) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve({ data: reader.result, file: accepted });
    };
    reader.onerror = reject;
    reader.readAsText(accepted);
  });

  const onDrop = (
    accepted,
    /* istanbul ignore next */
    rejected = [],
  ) => {
    if (accepted.length > 1) {
      accepted.forEach((f) => {
        rejected.push({ uploadError: 'dropzone.mutiple_drops_forbidden', file: f });
      });
    }

    const validFile = accepted.pop();
    if (!whiteList.find((suffix) => validFile.name.toLowerCase().endsWith(suffix))) {
      rejected.push({ uploadError: 'incompatible_type_error', file: validFile });
    }

    if (maxFileSize && validFile.size > Number(maxFileSize)) {
      rejected.push({ uploadError: 'size_limit_exceeded', file: validFile });
    }

    setRejectedErrors(rejected);

    if (validFile && rejected.length === 0) {
      if (values.modbusConfig?.length !== 1 || (values.genericModbusConfig?.length !== 0)) {
        setAcceptedFile(validFile);
        setShowImportModal(true);
      } else {
        setAcceptedFile(undefined);
        loadConfigFromFile(validFile).then((value) => updateUIWithLoadedData(value));
      }
    }
  };
  const onConfirmImport = () => {
    setShowImportModal(false);
    loadConfigFromFile(acceptedFile).then((value) => updateUIWithLoadedData(value));
  };

  const onCloseImport = () => {
    setShowImportModal(false);
  };

  const rejectedFilesRendered = rejectedErrors.map((error) => {
    const whiteListReadable = `( ${whiteList.join(', ')} )`;

    const mapErrorToMessage = (err) => {
      switch (err) {
        case 'size_limit_exceeded':
          return intl.formatMessage({ id: 'modbus_import_dropzone.size_error' }, { limit: filesize(maxFileSize) });
        case 'incompatible_json_format_error':
          return t`modbus_import_dropzone.modbus_incompatible_json_format`;
        case 'incompatible_config_format_error':
          return t`modbus_import_dropzone.modbus_incompatible_config_format`;
        default:
          return intl.formatMessage({ id: 'modbus_import_dropzone.incompatible_type_error' }, { whitelist: whiteListReadable });
      }
    };

    return (
      <li id="rejected-file" key={uuid()}>
        <strong>{error.file.name}</strong>
        :
        {' '}
        {mapErrorToMessage(error.uploadError)}
      </li>
    );
  });

  let rejectionError = null;
  if (rejectedErrors?.length > 0) {
    rejectionError = (
      <div id="rejection-input-alert" className="files-rejection-full-width">
        <ul>
          {rejectedErrors.length > 1
            ? t`dropzone.mutiple_drops_forbidden`
            : rejectedFilesRendered}
        </ul>
      </div>
    );
  }

  return (
    <div id="section_cm44">
      <label htmlFor="Modbus Configuration Import" data-testid="modbus-config-import">
        {t`field_gateway.modbus.config.import`}
      </label>
      <FilesUpload
        {...props}
        name="configUpload"
        multiple
        onDrop={onDrop}
        data-testid="fileUploader"
      />
      {rejectionError}
      <ConfirmationModal
        id="import-modal"
        show={showImportModal}
        titleText={t`field_gateway.modbus.config.import.modal.title`}
        messageText={t`field_gateway.modbus.config.import.modal.text`}
        onConfirm={() => onConfirmImport()}
        onClose={onCloseImport}
      />
    </div>
  );
}

ModbusImport.propTypes = {
  intl: intlShape.isRequired,
  values: PropTypes.shape(
    {
      modbusConfig: PropTypes.arrayOf(PropTypes.shape({})),
      genericModbusConfig: PropTypes.arrayOf(PropTypes.shape({})),
    },
  ).isRequired,
  onImport: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  deviceTypeCode: PropTypes.string.isRequired,
};

export default withIntl(ModbusImport);
