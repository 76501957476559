import { showError } from './notificationUtils';

export const createSentryError = (error, notification) => {
  const errorName = error?.name || error?.type || error?.error?.type;
  if (errorName) {
    return Error(errorName, { cause: JSON.stringify({ error, notification }) });
  }
  return Error(JSON.stringify({ error, notification }));
};

export function handleUnknownErrors(errors, notification) {
  /* eslint-disable no-console */
  console.error(errors);
  if (notification) {
    showError(notification);
  }

  // incoming errors can be an API Error Array or a Javascript Error
  let error = errors;
  if (error.constructor === Array) { error = errors[0]; }

  if (error.type?.match(/too_many_requests|access_denied|force_refresh=true|service_unavailable/)) return;
  if (error?.error?.type?.match(/too_many_requests|access_denied|force_refresh=true|service_unavailable/)) return;

  if (error instanceof Error) {
    global.log.error('handleUnknownErrors', error);
  } else {
    // when error is sometimes POJO
    global.log.error('handleUnknownErrors', createSentryError(error, notification));
  }
}
