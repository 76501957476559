/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  browserShape,
  withBrowser,
} from 'lcm-iot-commons';

import { administrationShape } from '../../shapes/administrationsShape';

export function AdministrationItem({ administration, browser }) {
  const iconClassname = `icon lcm-iot-icon-${administration.type}`;
  const {
    titleId, descriptionId, href, external,
  } = administration;

  const handleOnClick = () => {
    if (external) {
      browser.redirectToExtern(href);
    } else {
      browser.navigateTo(href);
    }
  };
  return (
    <li className="list-item clickable overflow" onClick={handleOnClick}>
      <div className="list-item-image">
        <span className={iconClassname} />
      </div>
      <div className="list-item-content">
        <div className="list-item-header"><FormattedMessage id={titleId} /></div>
        <div>
          <FormattedMessage id={descriptionId} />
        </div>
      </div>
    </li>
  );
}

AdministrationItem.propTypes = {
  administration: administrationShape,
  browser: browserShape.isRequired,
};

AdministrationItem.defaultProps = {
  administration: undefined,
};

export default withBrowser(AdministrationItem);
