import { startsWith, endsWith } from 'lodash-es';
import Rules from './Rules';

class ProductRules extends Rules {
  static rules = {};

  static find = (manufacturerName, productCode, isPublicTenant) => {
    if (isPublicTenant && ProductRules.rules[manufacturerName]) {
      if (ProductRules.rules[manufacturerName][productCode]) {
        return new ProductRules.rules[manufacturerName][productCode]();
      }

      const wildcardKeys = Object.keys(ProductRules.rules[manufacturerName]).filter((key) => endsWith(key, '*'))?.map((key) => key.replace('*', ''));
      const matchingKey = wildcardKeys?.find((key) => startsWith(productCode, key));

      if (matchingKey) {
        return new ProductRules.rules[manufacturerName][`${matchingKey}*`]();
      }
    }

    return new ProductRules();
  };

  showEditValueSettingsButton() {
    return true;
  }

  supportHeartBeatVerificationDirectly() {
    return false;
  }

  convertDisplayValues(asset, instrumentations, displayValues) {
    return displayValues;
  }

  nameForValueKey(key) {
    return key;
  }

  hasValueKeyTranslation() {
    return false;
  }
}

ProductRules.register = (manufacturerName, productCode, clazz) => {
  if (!ProductRules.rules[manufacturerName]) {
    ProductRules.rules[manufacturerName] = {};
  }
  ProductRules.rules[manufacturerName][productCode] = clazz;
};

export default ProductRules;
