import lcmApiClient from './lcmApiClient';
import { extractSystems } from '../extractors';

/* istanbul ignore next */
export async function unassignAssetFromSystem(assetId, systemId) {
  return lcmApiClient().delete(`/systems/${systemId}/assets`, {
    assets: [{ id: assetId }],
  });
}

/* istanbul ignore next */
export async function unassignInstrumentationFromSystem(instrumentationId, systemId) {
  return lcmApiClient()
    .delete(`/systems/${systemId}/instrumentations`, {
      instrumentations: [{ id: instrumentationId }],
    });
}

/* istanbul ignore next */
export async function loadSystems(parameters) {
  const response = await lcmApiClient().get('/systems', parameters);
  return {
    systems: extractSystems(response),
    totalCount: response.pagination.total_count,
    nextPageUrl: response.pagination.next,
  };
}

/* istanbul ignore next */
export async function loadNextSystems(nextPageUrl) {
  const response = await lcmApiClient().get(nextPageUrl);
  return {
    systems: extractSystems(response),
    totalCount: response.pagination.total_count,
    nextPageUrl: response.pagination.next,
  };
}
