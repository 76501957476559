import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import {
  intlShape,
  backendShape,
  withBackend,
  Container,
  notifierShape,
  withNotifier,
  isSomething,
  NotFoundError,
} from 'lcm-iot-commons';
import RequestPasswordResetForm from './RequestPasswordResetForm';
import RequestPasswordResetResponse from './RequestPasswordResetResponse';

export function RequestPasswordReset({
  intl, backend, notifier,
}) {
  const [responseType, setResponseType] = useState();

  const handleOnSubmit = async (values, actions) => {
    try {
      const resetPasswordRequest = {
        email: values.email,
        captcha: values.captcha,
      };
      const response = await backend.post('/users/request_password_reset', resetPasswordRequest);
      setResponseType(response.type);
    } catch (error) {
      if (error instanceof NotFoundError) {
        notifier.showError(intl.formatMessage({ id: 'api.error.resetpassword_account_not_found' }));
      } else {
        notifier.showError(backend.translateError(error));
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Container>
      {isSomething(responseType) ? (
        <RequestPasswordResetResponse id="request-password-response" type={responseType} />
      ) : (
        <RequestPasswordResetForm id="request-password-form" onSubmit={handleOnSubmit} />
      )}
    </Container>
  );
}

RequestPasswordReset.propTypes = {
  intl: intlShape.isRequired,
  backend: backendShape.isRequired,
  notifier: notifierShape.isRequired,
};

export default injectIntl(withNotifier(withBackend(RequestPasswordReset)));
