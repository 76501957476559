import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom-v5-compat';
import { isMobileView, isTouchDevice, url } from '../../utils';
import SearchBar from '../Search/SearchBar';
import SearchResultItem from '../Search/SearchResultItem';
import SearchFilters from '../Search/SearchFilters';
import { useApi } from '../../hooks';
import { useRules } from '../../context';
import useClickOutside from '../../hooks/useClickOutside';

export const MAX_HEADER_SEARCH_RESULTS = 8;

export default function HeaderSearch() {
  const api = useApi();
  const intl = useIntl();
  const rules = useRules();
  const navigate = useNavigate();

  const transition = React.useRef(null);
  const outsideCompRef = React.useRef(null);

  const allowedFilters = rules.application().get('searchScope');
  const [filters, setFilters] = React.useState(allowedFilters);
  const [searchActive, setSearchActive] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [term, setTerm] = React.useState('');

  const { data, isInitialLoading } = api.get.useQuery('/search', {
    q: term.trim(),
    scope: filters?.sort().join(','),
    per_page: MAX_HEADER_SEARCH_RESULTS + 1,
  }, { enabled: !!term.trim(), refetchOnWindowFocus: false });

  const searchCount = data?.pagination.total_count;
  const results = data?.results.slice(0, MAX_HEADER_SEARCH_RESULTS);

  React.useEffect(() => {
    if (!searchActive) setExpanded(false);
  }, [searchActive]);

  useClickOutside(outsideCompRef, () => setSearchActive(false));

  const loadMoreClicked = () => {
    setSearchActive(false);
    navigate(url(`/nodes?search=${term}&filter=${encodeURIComponent(filters.join(','))}`));
  };

  const handleTransitionExpanded = () => setExpanded(searchActive);

  const onSearchIconClick = () => {
    const searchInput = document.getElementById('headerSearchInputField');
    searchInput.focus({ focusVisible: true });
    setSearchActive(true);
  };

  const searchResultsCount = searchCount ? `${intl.formatNumber(searchCount)}${searchCount >= 10_000 ? '+' : ''}` : undefined;

  const resultCountBadge = isInitialLoading
    ? <div className="icon-spinner-dark" data-testid="spinner" />
    : <span id="search-results-count" className="totalCountBadge badge">{searchResultsCount}</span>;

  return (
    <div className="header-search" ref={outsideCompRef}>
      {searchActive && (
        <div
          role="button"
          data-testid="mobile-back"
          className="search-back icon icon2-eh-arrow-left visible-xs"
          onClick={() => {
            setSearchActive(false);
            setTerm('');
          }}
          aria-hidden="true"
        />
      )}
      <div
        data-testid="header-search-container"
        className={`header-search-container ${searchActive ? 'expanded' : 'collapsed'}`}
        ref={transition}
        onTransitionEnd={handleTransitionExpanded}
      >
        <span role="button" className="icon icon3-eh-search" data-testid="search-icon" onClick={onSearchIconClick} aria-hidden="true" />
        <SearchBar
          id="headerSearchInputField"
          autoFocus={!isTouchDevice()}
          onSearch={setTerm}
          placeholder={`${intl.formatMessage({ id: 'search.searchbox_placeholder' })}`}
          minChars={1}
          timeout={300}
          resettable
          filterWildcard={false}
          onClick={() => setSearchActive(true)}
          inputName="header-search"
          searchCount={searchCount}
          onInputChange={() => setSearchActive(true)}
          hideSubmit
        />
        {(searchActive && (expanded || isMobileView())) && (
          <div className="overlay-container">
            <div className="overlay-content" />
            <SearchFilters onChange={setFilters} filters={filters} />
            {resultCountBadge}
            <div className="header-search-dropdown">
              <ul data-testid="search-result-list" id="search-result-list" className="list" aria-hidden="true">
                {results?.map((item, index) => (
                  <SearchResultItem
                    key={`${item.type}${item.id}`}
                    item={item}
                    index={index}
                    disabled={item.type === 'Asset' && !item.in_subscription}
                    searchOrigin="HeaderSearch"
                    onClick={() => setSearchActive(false)}
                    showSpecifications={false}
                  />
                ))}
                {results?.length === 0 && (
                  <div className="no-search-results-text">
                    <FormattedMessage id="search.no_results_found" />
                  </div>
                )}
                {data?.results?.length > MAX_HEADER_SEARCH_RESULTS && (
                  <button id="load-more" type="button" className="btn-link btn-action" onClick={loadMoreClicked}>
                    <FormattedMessage id="header_search.more" />
                  </button>
                )}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
