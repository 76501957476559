import PropTypes from 'prop-types';

export const connectSubscriptionShape = PropTypes.shape({
  id: PropTypes.number,
  applicationName: PropTypes.string,
  subscriptionName: PropTypes.string,
  apiCallQuota: PropTypes.number,
  storageQuota: PropTypes.number,
  // UploadDownloadQuota: PropTypes.number,
  pricingModel: PropTypes.string,
  externalReference: PropTypes.string,
  externalPlanVariantReference: PropTypes.string,
  appIconName: PropTypes.string,
  billedUntil: PropTypes.number,
  status: PropTypes.string,
});
