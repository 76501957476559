import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { intlShape, ContextualHelp } from 'lcm-iot-commons';

export function OpcUaServerHelp({ intl }) {
  return (
    <ContextualHelp title={intl.formatMessage({ id: 'subscription_details.opc_ua_server.header' })}>
      <p><FormattedMessage id="subscription_details.opc_ua_server.intro" /></p>
      <h3><FormattedMessage id="subscription_details.opc_ua_server.connection_title" /></h3>
      <p>
        <FormattedMessage id="subscription_details.opc_ua_server.connection_details" />
      </p>
      <h3><FormattedMessage id="subscription_details.opc_ua_server.technical_user_info_title" /></h3>
      <p>
        <FormattedMessage id="subscription_details.opc_ua_server.technical_user_info_details" />
      </p>
      <h3><FormattedMessage id="subscription_details.opc_ua_server.status_title" /></h3>
      <p>
        <FormattedMessage id="subscription_details.opc_ua_server.status_details" />
      </p>
      <p>
        <strong><FormattedMessage id="enum.opc_ua_server.initializing" /></strong>
        {': '}
        <br />
        <FormattedMessage id="subscription_details.opc_ua_server.status_initializing" />
      </p>
      <p>
        <strong><FormattedMessage id="enum.opc_ua_server.active" /></strong>
        {': '}
        <br />
        <FormattedMessage id="subscription_details.opc_ua_server.status_active" />
      </p>
      <p>
        <strong><FormattedMessage id="enum.opc_ua_server.failure" /></strong>
        {': '}
        <br />
        <FormattedMessage id="subscription_details.opc_ua_server.status_failure" />
      </p>
    </ContextualHelp>
  );
}

OpcUaServerHelp.propTypes = {
  intl: intlShape,
};

OpcUaServerHelp.defaultProps = {
  intl: undefined,
};

export default injectIntl(OpcUaServerHelp);
