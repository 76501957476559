import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, ButtonGroup } from '../../Form';
import { useConfiguration } from '../../../context';

function ExportInitiatedStep({
  onClose,
}) {
  const intl = useIntl();
  const { applications } = useConfiguration();
  return (
    <>
      <Modal.Body>
        <h3><FormattedMessage id="export_files_dialog.final_step.title" /></h3>
        <p>
          <FormattedMessage id="export_files_dialog.final_step.text" />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button
            text={intl.formatMessage({ id: 'button.go_to_exports' })}
            onClick={() => { window.location.href = `${applications.id}/profile`; }}
          />
          <Button
            hero
            text={intl.formatMessage({ id: 'button.close' })}
            onClick={onClose}
          />
        </ButtonGroup>
      </Modal.Footer>
    </>
  );
}

ExportInitiatedStep.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ExportInitiatedStep;
