import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Heading from '../Heading';
import ActionBar from '../ActionBar/ActionBar';
import AssetForm from './AssetForm';
import Loader from '../Loader';
import { Column, Container, Row } from '../Grid';
import {
  apiShape,
  backendShape,
  browserShape,
  notifierShape,
  rulesShape,
  withApi,
  withBackend,
  withBrowser,
  withNotifier,
  withRules,
  withUser,
} from '../../context';
import {
  accessRightsShape, intlShape, userShape,
} from '../../shapes';
import { BadRequestError } from '../../api';
import { withAccessRights, enforceAccessRightCanUpdate } from '../../wrappers';

import PermissionsEdit from '../Permissions/PermissionsEdit';
import EditPictures from '../Pictures/EditPictures';
import {
  createEnforcedTenant,
  extractCode,
  isNotEmpty,
  isSomething,
  convertSpecificationKeyToLabel,
  convertLabelToSpecificationKey,
  parseIntlNumber,
  isEmpty,
} from '../../utils';
import ConnectedAssetSubscriptionClue from './ConnectedAssetSubscriptionClue';
import SpecificationKeys from '../../constants/SpecificationKeys';
import ConnectedAssetActivation from './ConnectedAssetActivation';
import AssetAddOns from './AssetAddOns';
import * as gpsUtils from '../../utils/gpsUtils';
import BackButton from '../BackButton';

function assetRules(rules, values) {
  return rules.asset({
    manufacturerName: values.manufacturer.name,
    productCode: values.product.code,
    productTenantPublic: values.manufacturer.tenantPublic,
  });
}

export function AssetEdit({
  intl, api, match, notifier, accessRights, user, rules, backend, browser,
}) {
  const [initialValues, setInitialValues] = useState();
  const [formValues, setFormValues] = useState();
  const [subscriptionValid, setSubscriptionValid] = useState();
  const [showConnectedAssetActivation, setConnectedAssetActivation] = useState();
  const [calibrationSpecificationsInitial, setCalibrationSpecificationsInitial] = useState([]);
  const [sensorValues, setSensorValues] = useState([]);

  const [gpsDevSettings, setGPSDevSettings] = useState(false);
  const [gpsSpecAvailable, setGPSSpecAvailable] = useState(false);
  const initialSpecificationKeys = useRef([]);
  const specifications = {};
  const calibrationSpecificationsForDelete = [];

  // eslint-disable-next-line camelcase
  const getExtendedOrderCode = async (order_code) => {
    try {
      // eslint-disable-next-line camelcase
      const response = await api.get('/endress/extended_order_code_lookup', { order_code });
      return response;
    } catch (e) {
      return null;
    }
  };

  const setInitialGPSValues = (values, response, showMeasuringInterval) => {
    const needsGPSInterval = assetRules(rules, values).get('needsGPSInterval');
    const gpsDevSettingsValue = response.specifications[SpecificationKeys.GPSSettings.GPS_DEV_SETTINGS]?.value === 'true';
    setGPSDevSettings(gpsDevSettingsValue);
    const basicSettings = showMeasuringInterval && needsGPSInterval && isSomething(values.transmissionInterval);
    const enableGPSSettings = assetRules(rules, values).get('enableGPSForOrderCode', basicSettings, values.extendedOrderCode, gpsDevSettingsValue);

    if (enableGPSSettings) {
      const gpsSendInterval = response.specifications[SpecificationKeys.GPSSettings.GPS_SEND_INTERVAL];
      const gpsSendLatestInterval = response.specifications[SpecificationKeys.GPSSettings.GPS_SEND_LATEST_INTERVAL];
      const gpsAllIntervalOptions = assetRules(rules, values).get('gpsIntervalOptions');
      const gpsIntervalOptions = gpsAllIntervalOptions && gpsAllIntervalOptions.find((label) => label.name === values.transmissionInterval?.name)?.gpsOptions;

      let gpsInterval;
      let gpsActive;
      if (gpsSendInterval && gpsSendLatestInterval) {
        setGPSSpecAvailable(true);
        if (Number(gpsSendInterval.value) !== 0) {
          gpsInterval = { id: gpsSendInterval.value, name: gpsIntervalOptions.find((label) => label.id === gpsSendInterval.value).name };
          gpsActive = true;
        } else {
          gpsInterval = gpsSendLatestInterval.value !== '0' ? {
            id: gpsSendLatestInterval.value,
            name: gpsIntervalOptions.find((label) => label.id === gpsSendLatestInterval.value).name,
          }
            : null;
          gpsActive = false;
        }
      }
      return { gpsInterval, gpsActive };
    }
    return undefined;
  };

  const setGPSValesOnSubmit = async (values) => {
    const showMeasuringInterval = assetRules(rules, values).get('needsMeasuringInterval');
    const needsGPSInterval = assetRules(rules, values).get('needsGPSInterval');
    const basicSettings = showMeasuringInterval && needsGPSInterval && isSomething(values.transmissionInterval);
    const enableGPSSettings = assetRules(rules, values).get('enableGPSForOrderCode', basicSettings, values.extendedOrderCode, gpsDevSettings);

    if (enableGPSSettings) {
      const gpsSpecifications = gpsUtils.setGPSInterval(values);
      specifications[[SpecificationKeys.GPSSettings.GPS_SEND_INTERVAL]] = gpsSpecifications[[SpecificationKeys.GPSSettings.GPS_SEND_INTERVAL]];
      specifications[[SpecificationKeys.GPSSettings.GPS_SEND_LATEST_INTERVAL]] = gpsSpecifications[[SpecificationKeys.GPSSettings.GPS_SEND_LATEST_INTERVAL]];
    } else if (gpsSpecAvailable) {
      await api.delete(`/assets/${initialValues.id}/specifications`, ['eh.user_config.gps_send_interval', 'eh.user_config.latest_gps_send_interval']);
    }
  };

  const onCalibrationSensorValueChange = (assetValue) => {
    const existingDeltaValue = sensorValues.filter((value) => value.name === assetValue.name);
    if (existingDeltaValue.length !== 0) {
      return setSensorValues(sensorValues.filter((value) => value.name !== assetValue.name).concat(assetValue));
    }
    return setSensorValues((state) => [...state, assetValue]);
  };

  const setCalibrationValuesOnLoad = (values, calibrationPropertyName, response) => {
    const calibrationVal = assetRules(rules, values).get('setCalibration', calibrationPropertyName);
    const calibrationDeltaVal = assetRules(rules, values).get('setCalibration', calibrationPropertyName, true);

    if (response.specifications[calibrationDeltaVal.specification]) {
      setCalibrationSpecificationsInitial((state) => [...state, calibrationDeltaVal.specification]);
    }
    if (response.specifications[calibrationVal.specification]) {
      setCalibrationSpecificationsInitial((state) => [...state, calibrationVal.specification]);
      return response.specifications[calibrationVal.specification].value;
    }

    return null;
  };

  const setCalibrationValuesOnSubmit = async (values, calibrationProperty, calibrationValue) => {
    const calibrationUnitSpec = assetRules(rules, values).get('setCalibration', calibrationProperty);
    const calibrationUnitDeltaSpec = assetRules(rules, values).get('setCalibration', calibrationProperty, true);
    const sensorValue = sensorValues.filter((value) => value.name === calibrationProperty);
    if (calibrationValue && sensorValue.length === 1) {
      const deltaValue = parseIntlNumber(calibrationValue) - sensorValue[0].value;
      specifications[[calibrationUnitSpec.specification]] = { value: calibrationValue, unit: calibrationUnitSpec.unit_id };
      specifications[[calibrationUnitDeltaSpec.specification]] = { value: deltaValue.toString(), unit: calibrationUnitDeltaSpec.unit_id };
    } else {
      calibrationSpecificationsForDelete.push(calibrationUnitSpec.specification);
      calibrationSpecificationsForDelete.push(calibrationUnitDeltaSpec.specification);
    }
  };

  const setCalibrationOnSubmit = async (values) => {
    const enableSensorReCalibration = assetRules(rules, values).get('enableSensorReCalibration');

    if (enableSensorReCalibration) {
      if (values.calibration_time) {
        const calibrationTime = assetRules(rules, values).get('setCalibration', 'calibration_time');
        specifications[[calibrationTime.specification]] = { value: values.calibration_time.toISOString() };
      }
      const calibrationStatus = assetRules(rules, values).get('setCalibration', 'calibration_status');
      specifications[[calibrationStatus.specification]] = { value: values.calibration_status ? values.calibration_status.toString() : 'false' };

      await setCalibrationValuesOnSubmit(values, 'calibration_density', values.calibration_density);
      await setCalibrationValuesOnSubmit(values, 'calibration_viscosity', values.calibration_viscosity);
      await setCalibrationValuesOnSubmit(values, 'calibration_temperature', values.calibration_temperature);
      await setCalibrationValuesOnSubmit(values, 'calibration_speed_of_sound', values.calibration_speed_of_sound);
      const filteredInitialSpecifications = calibrationSpecificationsInitial.filter((elem, pos) => calibrationSpecificationsInitial.indexOf(elem) === pos);
      const calibrationForDelete = filteredInitialSpecifications.filter((specification) => calibrationSpecificationsForDelete.some((specificationDelete) => specification === specificationDelete));
      if (calibrationForDelete.length !== 0) {
        await api.delete(`/assets/${values.id}/specifications`, calibrationForDelete);
      }
    }
  };

  const handleOnSubmit = async (values, actions) => {
    try {
      let { manufacturer } = values;
      let { product } = values;
      let { tenant } = values;

      const isEndress = manufacturer && manufacturer.name === 'Endress+Hauser';

      // create tenant if needed
      // New manufacturer, user has no tenant yet
      if (!values.tenant && values.manufacturer.new) {
        tenant = { id: (await createEnforcedTenant(api, [user.firstName, user.lastName].join(' ').trim())).id };
        // Existing private manufacturer
      } else if (!values.manufacturer.new && !values.manufacturer.tenantPublic) {
        tenant = { id: values.manufacturer.tenantId };
        // Create completely new tenant
      } else if (values.tenant?.new) {
        tenant = { id: (await api.post('/tenants', { name: values.tenant.name })).id };
      }

      // create manufacturer if needed
      if (values.manufacturer.new) {
        try {
          manufacturer = { ...await api.post('/companies', { name: values.manufacturer.name, tenant: { id: tenant.id } }), tenant };
        } catch (error) {
          if (error instanceof BadRequestError
            && error.contains('taken')) {
            actions.setErrors({
              manufacturer: intl.formatMessage({ id: 'api.error.manufacturer.taken' }),
            });
            return;
          }
          throw error;
        }
      }

      // create product if needed
      if (values.product.new) {
        product = {
          ...await api.post('/products', {
            manufacturer: { id: manufacturer.id },
            name: product.name,
            product_code: product.code,
            tenant: { id: tenant.id },
          }),
          code: product.code,
          manufacturerId: manufacturer.id,
          tenant,
        };
      }

      const payload = {
        serial_number: values.serial_number,
        product: { id: product.id },
        status: { id: values.status.id },
        description: values.description,
        production_date: isNotEmpty(values.production_date) ? values.production_date : null,
        tenant,
      };

      await api.patch(`/assets/${initialValues.id}`, payload);
      if (values.transmissionInterval) {
        specifications[[SpecificationKeys.ConnectivitySettings.TRANSMISSION_INTERVAL]] = {
          value: values.transmissionInterval.id,
        };
      }
      if (values.measuringInterval) {
        specifications[[SpecificationKeys.ConnectivitySettings.MEASURING_INTERVAL]] = { value: values.measuringInterval.id };
      }
      if (values.firstMeasurement) {
        specifications[[SpecificationKeys.ConnectivitySettings.FIRST_MEASUREMENT]] = { value: values.firstMeasurement.toISOString() };
      }

      if (isEndress && values.orderCode && values.product.code) {
        const orderCodeSpecValue = `${values.product.code}-${values.orderCode}`;
        specifications[[SpecificationKeys.GeneralSettings.ORDER_CODE]] = { value: orderCodeSpecValue };
      }
      await setGPSValesOnSubmit(values);
      await setCalibrationOnSubmit(values);

      values.specifications?.forEach((specification) => {
        if (specification.key !== '' && specification.key?.name) {
          specifications[convertLabelToSpecificationKey(specification.key.name)] = { value: specification.value, ui_visible: true };
        }
      });
      if (Object.keys(specifications).length > 0) {
        await api.patch(`/assets/${initialValues.id}/specifications`, specifications);
      }

      if (isNotEmpty(initialValues.orderCode) && isEmpty(values.orderCode)) {
        await api.delete(`/assets/${initialValues.id}/specifications`, ['eh.pcps.tmp.ordercode']);
      }

      if (initialSpecificationKeys.current.length > 0) {
        const specificationKeysToDelete = initialSpecificationKeys.current
          .filter((key) => !values.specifications.find((specification) => specification.name !== '' && convertLabelToSpecificationKey(specification.key?.name) === key));
        if (specificationKeysToDelete.length > 0) {
          await api.delete(`/assets/${initialValues.id}/specifications`, specificationKeysToDelete);
        }
      }

      // patch on connected asset is only triggered when it is triggered from application offering connected asset addon
      if (assetRules(rules, values).get('connectedAsset') && rules.application().get('hasConnectedAssetAddon')) {
        await backend.patch(`/connected_assets/${initialValues.id}`);
      }

      notifier.showSuccess(intl.formatMessage({ id: 'asset_edit.success_notification' }));
      browser.navigateTo(`/assets/${initialValues.id}`);
    } catch (error) {
      if (error instanceof BadRequestError
        && error.contains('not_unique_in_scope', 'serial_number')) {
        actions.setErrors({
          serial_number: intl.formatMessage({ id: 'api.error.serial_number.not_unique_in_scope' }),
        });
      } else {
        notifier.showError(api.translateError(error));
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  React.useEffect(() => {
    const loadAsset = async () => {
      try {
        const response = await api.get(
          `/assets/${match.params.id}`,
          {
            include: 'tenant,status,status.tenant,product.tenant,product.specifications,product.manufacturer,product.manufacturer.tenant,instrumentations,specifications,add_ons',
          },
          false,
        );

        const values = {
          ...response,
          manufacturer: {
            id: response.product.manufacturer.id,
            name: response.product.manufacturer.name,
            tenantId: response.product.manufacturer.tenant.id,
            tenantPublic: response.product.manufacturer.tenant.public,
            tenantName: response.product.manufacturer.tenant.name,
          },
          product: {
            id: response.product.id,
            manufacturerId: response.product.manufacturer.id,
            name: response.product.name,
            code: response.product.product_code,
            tenantId: response.product.tenant.id,
            tenantPublic: response.product.tenant.public,
            tenantName: response.product.tenant.name,
            specifications: response.product.specifications || {},
          },
          status: {
            id: response.status.id,
            name: response.status.name,
            tenantId: response.status.tenant.id,
            tenantPublic: response.status.tenant.public,
            tenantName: response.status.tenant.name,
          },
          instrumentations: {
            totalCount: response.instrumentations.total_count,
          },
          specifications: [],
        };

        if (response.specifications) {
          const deviceActiveSpec = response.specifications[[SpecificationKeys.ActivationSettings.DEVICE_ACTIVE]];
          values.deviceActive = !!(deviceActiveSpec && deviceActiveSpec.value === 'true'); // !! ensure deviceActive is true or false, not undefined or null

          const orderCodeSpec = response.specifications[SpecificationKeys.GeneralSettings.ORDER_CODE];
          const orderCode = orderCodeSpec ? orderCodeSpec.value : null;
          values.orderCode = orderCode && orderCode.indexOf('-') > 0 ? orderCode.substring(orderCode.indexOf('-') + 1) : null;
          const lookupResponse = await getExtendedOrderCode(orderCode);
          // eslint-disable-next-line camelcase
          values.extendedOrderCode = lookupResponse?.extended_order_code ? extractCode(lookupResponse.extended_order_code) : null;
          const showMeasuringInterval = assetRules(rules, values).get('needsMeasuringInterval');
          const showTransmissionInterval = assetRules(rules, values).get('needsTransmissionInterval');
          const showTimeOfFirstMeasurement = assetRules(rules, values).get('needsTimeOfFirstMeasurement');
          const measuringIntervalOptions = assetRules(rules, values).get('measuringIntervalOptions');
          const enableSensorReCalibration = assetRules(rules, values).get('enableSensorReCalibration');

          if (showMeasuringInterval) {
            const measuringIntervalSpec = response.specifications[SpecificationKeys.ConnectivitySettings.MEASURING_INTERVAL];
            values.measuringInterval = measuringIntervalSpec ? measuringIntervalOptions?.find((label) => label.id === measuringIntervalSpec.value) : null;

            const transmissionIntervalSpec = response.specifications[SpecificationKeys.ConnectivitySettings.TRANSMISSION_INTERVAL];
            values.transmissionInterval = transmissionIntervalSpec && measuringIntervalSpec
              ? measuringIntervalOptions?.find((label) => label.id === measuringIntervalSpec.value).transmissionOptions.find((label) => label.id === transmissionIntervalSpec.value)
              : null;
          }

          if (showTransmissionInterval && !showMeasuringInterval) {
            const transmissionIntervalOptions = assetRules(rules, values).get('transmissionIntervalOptions');
            const transmissionIntervalSpec = response.specifications[SpecificationKeys.ConnectivitySettings.TRANSMISSION_INTERVAL];
            values.transmissionInterval = transmissionIntervalSpec ? transmissionIntervalOptions?.find((label) => label.id === transmissionIntervalSpec.value) : null;
            values.measuringInterval = null;
          }

          if (showTimeOfFirstMeasurement) {
            const firstMeasurementSpec = response.specifications[SpecificationKeys.ConnectivitySettings.FIRST_MEASUREMENT];
            values.firstMeasurement = firstMeasurementSpec ? new Date(Date.parse(firstMeasurementSpec.value)) : null;
          } else {
            values.firstMeasurement = null;
          }
          const gpsSettings = setInitialGPSValues(values, response, showMeasuringInterval);
          if (gpsSettings) {
            values.gpsInterval = gpsSettings?.gpsInterval;
            values.gpsActive = gpsSettings?.gpsActive;
          }

          if (enableSensorReCalibration) {
            const calibrationStatus = assetRules(rules, values).get('setCalibration', 'calibration_status');
            values.calibration_status = response.specifications[calibrationStatus.specification] ? response.specifications[calibrationStatus.specification].value === 'true'
              : false;
            const calibrationTime = assetRules(rules, values).get('setCalibration', 'calibration_time');
            values.calibration_time = response.specifications[calibrationTime.specification] ? new Date(response.specifications[calibrationTime.specification].value) : null;
            values.calibration_density = setCalibrationValuesOnLoad(values, 'calibration_density', response);
            values.calibration_viscosity = setCalibrationValuesOnLoad(values, 'calibration_viscosity', response);
            values.calibration_temperature = setCalibrationValuesOnLoad(values, 'calibration_temperature', response);
            values.calibration_speed_of_sound = setCalibrationValuesOnLoad(values, 'calibration_speed_of_sound', response);
          }

          initialSpecificationKeys.current = Object.keys(response.specifications).filter((key) => response.specifications[key].ui_visible).sort();
          values.specifications = initialSpecificationKeys.current.map((key) => ({ key: { id: key, name: convertSpecificationKeyToLabel(key) }, value: response.specifications[key].value }));
        }

        setInitialValues(values);
        setConnectedAssetActivation(assetRules(rules, values).get('showActivationStatus'));
      } catch (error) {
        notifier.showError(api.translateError(error));
      }
    };

    loadAsset();
  }, [match.params]);

  return (
    <Loader loading={!initialValues}>
      <Container>
        <Row>
          <Column>
            <BackButton />
          </Column>
        </Row>
        <Row>
          <Column>
            <ConnectedAssetSubscriptionClue initialValues={initialValues} formValues={formValues} setSubscriptionValid={setSubscriptionValid} />
          </Column>
        </Row>
        <Row>
          <Column>
            <ActionBar>
              <Heading title={intl.formatMessage({ id: 'asset_edit.header' })} />
            </ActionBar>
          </Column>
        </Row>
        <Row>
          <Column lg="7">
            <Heading title={intl.formatMessage({ id: 'asset_edit.details' })} level={2} />
            {initialValues
            && (
            <AssetForm
              onSubmit={handleOnSubmit}
              onChange={setFormValues}
              initialValues={initialValues}
              canSubmit={subscriptionValid}
              showAssetStatus
              gpsDevSettings={gpsDevSettings}
              skipFirstProductLookup
              onCalibrationSensorValueChange={onCalibrationSensorValueChange}
              isEdit
            />
            )}
            {initialValues && isSomething(formValues) ? <AssetAddOns assetId={match.params.id} currentValues={formValues} initialValues={initialValues} /> : null}
            {initialValues && showConnectedAssetActivation ? (
              <ConnectedAssetActivation
                assetId={match.params.id}
                serialNumber={initialValues.serial_number}
                deviceActive={initialValues.deviceActive}
                hasNoInstrumentation={initialValues.instrumentations.totalCount === 0}
              />
            ) : null}
          </Column>
          <Column lg="5">
            {initialValues
              && (
                <EditPictures
                  id="edit-pictures"
                  model="assets"
                  modelId={initialValues.id}
                  placeholder="icon-eh-device"
                />
              )}
          </Column>
        </Row>
        <Row>
          <Column>
            {initialValues
              && (
                <PermissionsEdit
                  id="permissions"
                  permitableType="Asset"
                  permitableId={initialValues.id}
                  accessRights={accessRights}
                  targetOnDeleteOwnReadPermission="/nodes"
                />
              )}
          </Column>
        </Row>
      </Container>
    </Loader>
  );
}

AssetEdit.propTypes = {
  intl: intlShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }),
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  backend: backendShape.isRequired,
  notifier: notifierShape.isRequired,
  user: userShape,
  accessRights: accessRightsShape,
  rules: rulesShape,
};

export default injectIntl(withRules(withNotifier(withBrowser(withBackend(withApi(withUser(withAccessRights(AssetEdit, 'Asset', enforceAccessRightCanUpdate))))))));
