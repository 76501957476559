import PropTypes from 'prop-types';
import React, { useState } from 'react';
import moment from 'moment';
import {
  ActionBar,
  Column,
  Container,
  Heading,
  Loader,
  Row,
  BadRequestError,
  formatNumber,
  isNotFoundError,
  isNothing,
  parseIntlNumber,
  withApi,
  withBrowser,
  withIntl,
  withNotifier,
  withUser,
  apiShape,
  browserShape,
  intlShape,
  notifierShape,
  userShape,
} from 'lcm-iot-commons';
import TaxForm from './TaxForm';

export function TaxEdit({
  api, browser, intl, notifier, match, user,
}) {
  const [tax, setTax] = useState();

  const loadTax = async () => {
    try {
      const response = await api.get(`/taxes/${match.params.id}`);
      const taxItem = {
        type: { id: response.type },
        rate: formatNumber(response.rate),
        startDate: new Date(response.start_date),
        endDate: response.end_date ? new Date(response.end_date) : null,
        country: { code: response.country_code.toUpperCase() },
      };
      setTax(taxItem);
    } catch (error) {
      if (isNotFoundError(error)) {
        browser.navigateTo('/404');
      }
      notifier.showError(api.translateError(error));
    }
  };

  React.useEffect(() => {
    const controllingDirector = user?.userRoles?.find((ur) => ur.name === 'controlling_director');
    if (user && isNothing(controllingDirector)) {
      browser.navigateTo('/403');
    } else {
      loadTax();
    }
  }, [match]);

  const handleOnSubmit = async (values, actions) => {
    try {
      const editTax = {
        type: values.type.id,
        rate: parseIntlNumber(values.rate),
        start_date: moment(values.startDate).format('YYYY-MM-DD'),
        end_date: values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : null,
        country_code: values.country.code,
      };
      await api.patch(`/taxes/${match.params.id}`, editTax);
      notifier.showSuccess(intl.formatMessage({ id: 'tax_edit.success_notification' }));
      actions.resetForm(values);
      browser.navigateTo('/taxes');
    } catch (error) {
      if (error instanceof BadRequestError && error.contains('overlap', 'start_date')) {
        actions.setErrors({
          endDate: intl.formatMessage({ id: 'api.error.taxes_overlap' }),
          startDate: intl.formatMessage({ id: 'api.error.taxes_overlap' }),
        });
      } else {
        notifier.showError(api.translateError(error));
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  const header = (
    <Row>
      <Column>
        <ActionBar>
          <Heading title={intl.formatMessage({ id: 'tax_edit.header' })} />
        </ActionBar>
      </Column>
    </Row>
  );

  const editForm = tax ? (
    <Row>
      <Column>
        <TaxForm onSubmit={handleOnSubmit} initialValues={tax} />
      </Column>
    </Row>
  ) : null;

  return user ? (
    <Loader loading={!tax}>
      <Container>
        {header}
        {editForm}
      </Container>
    </Loader>
  ) : null;
}

TaxEdit.propTypes = {
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }).isRequired,
  user: userShape,
};

TaxEdit.defaultProps = {
  user: undefined,
};

export default withUser(withApi(withBrowser(withNotifier(withIntl(TaxEdit)))));
