import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import TypeaheadBox from '../Form/TypeaheadBox';
import intlShape from '../../shapes/intlShape';
import {
  withApi, apiShape, withNotifier, notifierShape,
} from '../../context';

export function CompanyTypeaheadWithTenant(props) {
  const [companies, setCompanies] = React.useState();
  const [fetching, setFetching] = React.useState();
  const {
    api, notifier, intl, values, name, assetId, tenantId, onlyCreate, setFieldValue,
  } = props;

  const loadCompanies = async (searchText = '') => {
    try {
      if (!onlyCreate) {
        setFetching(true);

        const endpoint = assetId ? `/assets/${assetId}/company-options` : '/companies';

        const parameters = {
          include: 'tenant',
          name: `${searchText}*`,
        };
        if (tenantId) {
          parameters.tenant_id = tenantId;
        }

        const response = await api.getAll(endpoint, parameters);

        setCompanies(response.companies.map((company) => ({
          id: company.id,
          name: company.name,
          tenantId: company?.tenant?.id,
          tenantPublic: company?.tenant?.public,
          tenantName: company?.tenant?.name,
        })));
      } else {
        setCompanies(undefined);
      }
    } catch (error) {
      notifier.showError(api.translateError(error));
    } finally {
      setFetching(false);
    }
  };

  React.useEffect(() => {
    if (companies) {
      setFieldValue(name, null);
    }
    loadCompanies();
  }, [tenantId]);

  const selectedCompany = values[name];

  return (
    <TypeaheadBox
      {...props}
      info={selectedCompany?.new ? intl.formatMessage({ id: 'company_typeahead.new_info' }) : null}
      options={companies}
      fetching={fetching}
      loadOptions={loadCompanies}
      loadOptionsOnMount={!values[name]}
    />
  );
}

CompanyTypeaheadWithTenant.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  name: PropTypes.string.isRequired,
  notifier: notifierShape.isRequired,
  assetId: PropTypes.number,
  tenantId: PropTypes.number,
  onlyCreate: PropTypes.bool,
  values: PropTypes.shape({
    product: PropTypes.shape({}),
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

CompanyTypeaheadWithTenant.defaultProps = {
  onlyCreate: false,
};

export default injectIntl(withNotifier(withApi(CompanyTypeaheadWithTenant)));
