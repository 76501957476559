export function extractInstrumentationStatus(rawInstrumentationStatus) {
  const tenantId = rawInstrumentationStatus.tenant ? rawInstrumentationStatus.tenant.id : null;
  const tenantName = rawInstrumentationStatus.tenant ? rawInstrumentationStatus.tenant.name : null;
  const tenantPublic = rawInstrumentationStatus.tenant ? rawInstrumentationStatus.tenant.public : null;

  return {
    id: rawInstrumentationStatus.id,
    name: rawInstrumentationStatus.name,
    code: rawInstrumentationStatus.code,
    tenantId,
    tenantPublic,
    tenantName,
  };
}

export function extractInstrumentationStatuses(rawInstrumentationStatus) {
  return rawInstrumentationStatus.instrumentation_statuses.map((instrumentationStatus) => extractInstrumentationStatus(instrumentationStatus));
}
