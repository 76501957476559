import ApiClientError from './ApiClientError';

class PaymentRequiredError extends ApiClientError {
  /* istanbul ignore next */
  constructor(request, response, cause) {
    super('PaymentRequiredError', 402, 'The users quota has exceeded.', request, response, cause);
  }
}

export default PaymentRequiredError;
