import React, { useState } from 'react';
import {
  Row,
  Column,
  Container,
  ActionBar,
  Loader,
  notifierShape,
  apiShape,
  withNotifier,
  withApi,
  isNotEmpty,
  withAccessRights, withBrowser, browserShape, NotFoundError,
} from 'lcm-iot-commons';

import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import DocumentForm from '../Documents/DocumentForm';

export const validateAccessRights = (accessRights) => accessRights.canUpdate;

export function AttachmentAdd({
  match, notifier, browser, api,
}) {
  const [initialValues, setInitialValues] = useState(null);

  React.useEffect(() => {
    const loadData = async () => {
      try {
        const response = await api.get(`/documents/${match.params.documentId}`, { include: 'attachments,categories' }, false);
        setInitialValues({
          name: response.name,
          category: { name: response.categories.length > 0 ? response.categories[0].name : '-' },
          validFrom: isNotEmpty(response.valid_from) ? response.valid_from : '-',
          validUntil: isNotEmpty(response.valid_until) ? response.valid_until : '-',
          version: isNotEmpty(response.document_version) ? response.document_version : '-',
          attachments: response.attachments.length > 0 ? response.attachments : [],
        });
      } catch (error) {
        if (error instanceof NotFoundError) {
          browser.navigateTo('/404');
        } else {
          notifier.showError(api.translateError(error));
        }
      }
    };
    loadData();
  }, [match.params]);

  return (
    <Loader loading={!initialValues}>
      <Container>
        <Row>
          <Column>
            <ActionBar>
              <h1><FormattedMessage id="attachment_add.header" /></h1>
            </ActionBar>
          </Column>
        </Row>
        <Row>
          <Column lg="7">
            {initialValues
            && (
              <DocumentForm
                match={match}
                addMode
                initialValues={initialValues}
              />
            )}
          </Column>
        </Row>
      </Container>
    </Loader>
  );
}

AttachmentAdd.propTypes = {
  browser: browserShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      documentId: PropTypes.string,
    }),
  }).isRequired,
  notifier: notifierShape.isRequired,
  api: apiShape.isRequired,
};

export default withNotifier(withApi(withBrowser(withAccessRights(AttachmentAdd, 'Product', validateAccessRights))));
