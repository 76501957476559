import React from 'react';
import PropTypes from 'prop-types';
import Step from './Step';

function Stepper({
  steps, stepperState, disableBackStepAt = [], handleStepClicked,
}) {
  const getStepClassName = (stepIndex) => {
    if (stepIndex < stepperState) return ' is-prev';
    if (stepIndex === stepperState) return ' is-current';
    return ' is-next';
  };

  function handleClicked(stepIndex) {
    if (stepIndex < stepperState) {
      handleStepClicked(stepIndex);
    }
  }

  return (
    <div className="c-process-wrapper">
      <ol className="c-process">
        {steps.map((step, index) => {
          const stepClassName = getStepClassName(index);
          const stepsToDisable = disableBackStepAt.find(({ stepIndex }) => stepIndex === stepperState)?.disabled;

          return (
            <Step
              key={step}
              handleClicked={!stepsToDisable?.includes(index) ? () => handleClicked(index) : null}
              stepClassName={stepClassName}
              step={step}
            />
          );
        })}
      </ol>
    </div>
  );
}

Stepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  stepperState: PropTypes.number.isRequired,
  handleStepClicked: PropTypes.func,
  disableBackStepAt: PropTypes.arrayOf(PropTypes.shape({ stepIndex: PropTypes.number, disabled: PropTypes.arrayOf(PropTypes.number) })),
};

export default Stepper;
