import React from 'react';
import {
  withIntl,
  withBrowser,
  intlShape,
  browserShape,
  Container,
  Heading, htmlLink, CONFIGURATION,
} from 'lcm-iot-commons';
import { withSignUp, signUpShape } from '../../context/SignUpContext';
import SignUpBreadcrumb from './SignUpBreadcrumb';

export function SignUpSuccess({
  intl, browser, signUp,
}) {
  const handleOnStartClick = () => {
    switch (signUp.signUpDetails.redirectUrl) {
      case '/':
        browser.redirectTo(signUp.signUpDetails.redirectUrl);
        break;
      case undefined:
        browser.redirectTo('/');
        break;
      default:
        browser.redirectToExtern(signUp.signUpDetails.redirectUrl);
        break;
    }
  };

  return (
    <Container>
      <SignUpBreadcrumb current="success" action="signup" />
      <Heading
        id="sign-up-success-header"
        title={intl.formatMessage({ id: 'signup.success.header' }, { username: signUp.signUpDetails?.firstName })}
      />
      <p id="sign-up-congrats-message" className="space-after">
        {intl.formatMessage(
          { id: 'signup.success.message' },
          { a: htmlLink({ href: CONFIGURATION.SIGN_UP_SURVEY, target: '_blank' }) },
        )}
      </p>
      <div className="sign-up-success-info-section">
        <div id="sign-up-success-video" className="sign-up-success-video-block">
          <Heading
            id="sign-up-success-video-header"
            title={intl.formatMessage({ id: 'signup.success.video_header' })}
            level={2}
          />
          <video controls preload="auto" width="100%">
            <source
              src={CONFIGURATION.SIGN_UP_VIDEO}
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <button id="sign-up-success-start-button" type="button" className="btn btn-primary space-before" onClick={() => handleOnStartClick()}>
        {intl.formatMessage({ id: 'signup.success.start_button' })}
      </button>
    </Container>
  );
}

SignUpSuccess.propTypes = {
  intl: intlShape.isRequired,
  browser: browserShape.isRequired,
  signUp: signUpShape.isRequired,
};

export default withIntl(withSignUp(withBrowser(SignUpSuccess)));
