import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Clue from '../Clue/Clue';
import { intlShape } from '../../shapes';
import {
  withBrowser,
  withRules,
  rulesShape,
  apiShape,
  withApi,
  backendShape,
  withBackend,
  withNotifier,
  notifierShape,
  withSubscription,
  browserShape,
  subscriptionShape,
} from '../../context';
import { withIntl } from '../../wrappers';
import { htmlLinebreak, htmlLink, isNothing } from '../../utils';
import CONFIGURATION from '../../configuration';

export function ConnectedAssetSubscriptionClue({
  api,
  backend,
  formValues,
  intl,
  browser,
  rules,
  setSubscriptionValid,
  notifier,
  initialValues,
  subscription,
}) {
  const [status, setStatus] = useState();
  const isOneSubscription = subscription?.client_application?.name === 'Netilion';

  React.useEffect(() => {
    const updateState = async () => {
      let tmpStatus;

      if (isNothing(initialValues) || initialValues?.product?.code !== formValues?.product?.code) {
        if (formValues && rules.asset({
          manufacturerName: formValues?.manufacturer?.name,
          productCode: formValues?.product?.code,
          productTenantPublic: formValues?.manufacturer?.tenantPublic,
        }).get('connectedAsset')) {
          if (!rules.application().get('hasConnectedAssetAddon') && !isOneSubscription) {
            tmpStatus = 'connected_asset_no_valid_application';
          } else {
            const addonConnectResponse = await backend.get('/subscription/addon/connected');
            if (isNothing(addonConnectResponse?.addon)) {
              tmpStatus = 'connected_asset_no_addon';
            } else {
              const quantityOfAddonsBought = addonConnectResponse.addon.quantity;
              const assignedToAddonAssetCount = addonConnectResponse.addon.assigned_assets_count;
              if (assignedToAddonAssetCount >= quantityOfAddonsBought) {
                tmpStatus = 'connected_asset_not_enough_addons';
              }
            }
          }
        }

        if (formValues && rules.asset({
          manufacturerName: formValues?.manufacturer?.name,
          productCode: formValues?.product?.code,
          productTenantPublic: formValues?.manufacturer?.tenantPublic,
        }).get('requiresFermentationAddOn')) {
          if (tmpStatus === 'connected_asset_no_valid_application' && !isOneSubscription) {
            // if there is no connected addon for the current app, there are also no fermentation addon.
            tmpStatus = 'fermentation_no_valid_application';
          } else {
            const addonFermentationResponse = await backend.get('/subscription/addon/fermentation');
            if (isNothing(addonFermentationResponse?.addon)) {
              tmpStatus = `${tmpStatus ? `${tmpStatus}_` : ''}fermentation_no_addon`;
            } else {
              const quantityOfAddonsBought = addonFermentationResponse.addon.quantity;
              const assignedToAddonAssetCount = addonFermentationResponse.addon.assigned_assets_count;
              if (assignedToAddonAssetCount >= quantityOfAddonsBought) {
                tmpStatus = `${tmpStatus ? `${tmpStatus}_` : ''}fermentation_not_enough_addons`;
              }
            }
          }
        }
      }

      if (tmpStatus) {
        setSubscriptionValid(false);
        setStatus(tmpStatus);
      } else if (formValues?.manufacturer?.name && formValues?.product?.code) {
        setSubscriptionValid(true);
        setStatus(undefined);
      } else {
        setSubscriptionValid(false);
        setStatus(undefined);
      }
    };
    updateState().catch((error) => {
      notifier.showError(api.translateError(error));
      setSubscriptionValid(true);
      setStatus(undefined);
    });
  }, [formValues?.manufacturer, formValues?.product]);

  if (status && formValues?.product?.name) {
    return status.includes('no_valid_application') ? (
      <Clue
        id="no-valid-application-notification"
        title={intl.formatMessage({ id: 'connected_asset_subscription_clue.title' }, { productName: formValues?.product?.name })}
        details={(
          <FormattedMessage
            id={status.includes('fermentation')
              ? 'connected_asset_subscription_clue.fermentation_no_valid_application.notification'
              : 'connected_asset_subscription_clue.connected_asset_no_valid_application.notification'}
            values={{
              productName: formValues?.product?.name,
              a: htmlLink({ href: CONFIGURATION.HELP_URL, target: '_blank' }),
              br: htmlLinebreak(),
            }}
          />
          )}
        className="notification"
        highlighted
      />
    )
      : (
        <Clue
          id="upgrade-notification"
          title={intl.formatMessage({ id: 'connected_asset_subscription_clue.title' }, { productName: formValues?.product?.name })}
          details={intl.formatMessage({
            id: status.includes('no_addon') ? 'connected_asset_subscription_clue.no_valid_subscription_details'
              : 'connected_asset_subscription_clue.upgrade_remove_details',
          }, { productName: formValues?.product?.name, condition: status })}
          className="notification"
          action={intl.formatMessage({ id: 'connected_asset_subscription_clue.upgrade_button' })}
          onClick={() => {
            browser.navigateTo('/upgrade');
          }}
          highlighted
        />
      );
  }
  return null;
}

ConnectedAssetSubscriptionClue.propTypes = {
  browser: browserShape.isRequired,
  intl: intlShape.isRequired,
  backend: backendShape.isRequired,
  rules: rulesShape,
  notifier: notifierShape.isRequired,
  api: apiShape.isRequired,
  formValues: PropTypes.shape({
    manufacturer: PropTypes.shape({
      name: PropTypes.string,
      tenantPublic: PropTypes.bool,
    }),
    product: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
    }),
  }),
  setSubscriptionValid: PropTypes.func,
  initialValues: PropTypes.shape({
    product: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
    }),
  }),
  subscription: subscriptionShape.isRequired,
};

ConnectedAssetSubscriptionClue.defaultProps = {
  initialValues: undefined,
};

export default withBackend(withNotifier(withApi(withRules(withBrowser(withIntl(withSubscription(ConnectedAssetSubscriptionClue)))))));
