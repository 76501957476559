import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import {
  ActionBar,
  Details,
  Heading,
  withIntl,
  intlShape,
} from 'lcm-iot-commons';
import SignInItem from './SignInItem';

export function LoginDetails({ intl, signIns, ssoProvider }) {
  const singInItem = signIns?.length > 0 ? signIns.map((s) => (<SignInItem key={uuid()} signIn={s} />)) : null;

  const renderSsoProvider = ssoProvider ? (
    <Details>
      <div className="details-item padding-remove">
        <div className="details-item-name">{intl.formatMessage({ id: 'user_profile.login.sso_provider' })}</div>
        <div id="sso-provider" className="login-provider">
          <i className="icon-eh-check2 status-success sso-active-icon" />
          <p className="sso-provider">{intl.formatMessage({ id: `sso_provider.${ssoProvider}` })}</p>
        </div>
      </div>
    </Details>
  ) : null;

  const renderLogins = ssoProvider ? (
    <div id="sign-ins-group" className="details-item padding-remove">
      <div className="details-item-name">{intl.formatMessage({ id: 'user_profile.login.logins' })}</div>
      <ul id="sign-ins-list" className="list">
        {singInItem}
      </ul>
    </div>
  ) : (
    <ul id="sign-ins-list" className="list">
      {singInItem}
    </ul>
  );

  return (
    <>
      <ActionBar>
        <Heading id="user-profile-login-infos" level={2} title={intl.formatMessage({ id: 'user_profile.login.header' })} />
      </ActionBar>
      {renderSsoProvider}
      {renderLogins}
    </>
  );
}

LoginDetails.propTypes = {
  intl: intlShape.isRequired,
  signIns: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string,
      application: PropTypes.string,
      ip: PropTypes.string,
      browser_name: PropTypes.string,
      browser_version: PropTypes.string,
      device_type: PropTypes.string,
      platform_name: PropTypes.string,
      platform_version: PropTypes.string,
    }),
  ),
  ssoProvider: PropTypes.string,
};

LoginDetails.defaultProps = {
  signIns: undefined,
  ssoProvider: undefined,
};

export default withIntl(LoginDetails);
