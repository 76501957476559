/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-unused-state */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isNotEmpty, equalsByAttribute, isTouchDevice } from '../../utils';

class Select extends Component {
  static propTypes = {
    allowEmpty: PropTypes.bool,
    disabled: PropTypes.bool,
    labelKey: PropTypes.string,
    onChange: PropTypes.func,
    onSelectChange: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    options: PropTypes.arrayOf(PropTypes.object),
    placeholder: PropTypes.string,
    renderOption: PropTypes.func,
    selectedOption: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    valueKey: PropTypes.string,
    disableOptionsSort: PropTypes.bool,
  };

  static defaultProps = {
    valueKey: 'id',
    labelKey: 'name',
  };

  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleInputOnBlur = this.handleInputOnBlur.bind(this);
    this.handleInputOnClick = this.handleInputOnClick.bind(this);
    this.handleInputOnChange = this.handleInputOnChange.bind(this);
    this.handleRenderSelectedOptionOnMouseDown = this.handleRenderSelectedOptionOnMouseDown.bind(this);
    this.handleRenderArrowOnMouseDown = this.handleRenderArrowOnMouseDown.bind(this);
    this.state = {
      optionsVisible: false,
      selectedOption: null,
      options: null,
      focused: false,
    };
  }

  componentDidMount() {
    this.update(this.props);
  }

  componentDidUpdate(prevProps) {
    const watchedProps = ['options', 'selectedOption', 'optionsVisible'];
    if (watchedProps.find((prop) => !equalsByAttribute(prevProps, this.props, prop))) {
      // watch for changes
      this.update(this.props);
    }
  }

  handleSelection(selectedOption, optionVisible = false) {
    this.updateInputValue(null);
    this.setState({ optionsVisible: optionVisible, selectedOption, focused: false }, () => {
      this.selectedValueChanged(selectedOption);
    });
  }

  handleChange() {
    const { labelKey } = this.props;
    const { options } = this.state;

    clearTimeout(this.keyboadInputTimer);

    const selectedOption = this.input && isNotEmpty(this.input.value) ? options.find((o) => o[labelKey].toLowerCase().indexOf(this.input.value.toLowerCase()) === 0) : null;

    if (selectedOption) {
      this.setState({
        optionsVisible: true,
        selectedOption,
      });
      setTimeout(() => { this.scrollToFocusedOption(true); }, 100);
    }

    this.keyboadInputTimer = setTimeout(() => {
      this.updateInputValue(null);
    }, 800);
  }

  handleInputOnBlur() {
    let optionVisible = false;
    /* istanbul ignore next */
    if (document.activeElement && document.activeElement.className === 'select-options') {
      /* istanbul ignore next */
      optionVisible = true;
      /* istanbul ignore next */
      this.input.focus();
    }
    this.handleSelection(this.state.selectedOption, optionVisible);
  }

  handleInputOnChange() {
    this.handleChange();
  }

  handleInputOnClick() {
    const { selectedOption, optionsVisible } = this.state;
    if (!selectedOption) {
      this.setState({ optionsVisible: !optionsVisible });
    }
    this.handleChange();
  }

  handleEnterKey(event) {
    if (this.state.optionsVisible) {
      event.preventDefault();
    }
    this.handleSelection(this.state.selectedOption);
  }

  handleEscapeKey() {
    this.setState({ selectedOption: this.props.selectedOption, optionsVisible: false });
    this.updateInputValue(null);
  }

  handleKeyDown(event) {
    switch (event.keyCode) {
      case 13: // enter
        this.handleEnterKey(event);
        break;
      case 27: // escape
        this.handleEscapeKey();
        break;
      case 38: // up
        this.focusPreviousOption();
        break;
      case 40: // down
        this.focusNextOption();
        break;
        /* istanbul ignore next */
      default:
        /* istanbul ignore next */
        // ignore
        break;
    }
  }

  handleRenderArrowOnMouseDown(event) {
    const { disabled } = this.props;
    const { optionsVisible } = this.state;
    if (disabled) {
      return;
    }

    if (optionsVisible) {
      this.setState({ optionsVisible: false });
    } else {
      this.setState({ optionsVisible: true });
    }
    this.input.focus();
    event.preventDefault();
  }

  handleRenderSelectedOptionOnMouseDown(event) {
    const { disabled } = this.props;
    const { optionsVisible } = this.state;

    if (disabled) {
      return;
    }
    this.setState({ optionsVisible: !optionsVisible });
    this.input.focus();
    event.preventDefault();
  }

  updateInputValue(value = '') {
    const inputValue = value || '';
    if (this.input && this.input.value !== inputValue) {
      this.input.value = inputValue;
    }
  }

  updateFocusedOptionRef(element, focused) {
    if (focused) {
      this.selectedOptionRef = element;
    }
  }

  updateInputRef(input) {
    this.input = input;
  }

  scrollToFocusedOption(top) {
    if (this.selectedOptionRef && this.selectedOptionRef.scrollIntoView) {
      this.selectedOptionRef.scrollIntoView(top);
    }
  }

  update(nextProps) {
    const { selectedOption } = this.state;
    const {
      allowEmpty, labelKey, valueKey, disableOptionsSort,
    } = this.props;

    let relevantOptions = this.state.options;
    let newSelectedOption = this.state.selectedOption;

    let updateState = false;

    if ((nextProps.options) || this.props.options !== nextProps.options) {
      if (selectedOption) {
        newSelectedOption = this.findOption(selectedOption, nextProps.options);
      }

      const optionsState = nextProps.options && isNotEmpty(nextProps.options) ? [...nextProps.options] : [];

      if (allowEmpty) {
        optionsState.unshift(this.createEmptyOption());
      }

      if (!disableOptionsSort) {
        optionsState.sort((a, b) => a[labelKey].localeCompare(b[labelKey]));
      }
      relevantOptions = optionsState;
      updateState = true;
    }

    // ¯\_(ツ)_/¯
    const componentShouldUpdate = (nextProps.selectedOption)
      || !equalsByAttribute(this.props.selectedOption, nextProps.selectedOption, valueKey)
      || nextProps.selectedOption === null;

    if (componentShouldUpdate) {
      if (nextProps.options) {
        newSelectedOption = this.findOption(nextProps.selectedOption, relevantOptions);
      } else {
        newSelectedOption = nextProps.selectedOption;
      }
      updateState = true;
    }

    if (updateState) {
      this.setState({
        selectedOption: newSelectedOption,
        options: relevantOptions,
      });
    }
  }

  createEmptyOption() {
    const { labelKey, valueKey } = this.props;
    const emptyOption = { empty: true };
    emptyOption[valueKey] = -1;
    emptyOption[labelKey] = '--';
    return emptyOption;
  }

  selectedValueChanged(value) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(!value || value.empty ? null : value);
    }
  }

  focusPreviousOption() {
    const { selectedOption, options } = this.state;
    if (options && selectedOption) {
      const index = options.indexOf(selectedOption);
      if (index > 0) {
        this.setState({ selectedOption: options[index - 1], optionsVisible: true });
      }
      this.scrollToFocusedOption(false);
    }
  }

  focusNextOption() {
    const { selectedOption, options, optionsVisible } = this.state;
    let newSelectedOption = selectedOption;
    if (options) {
      if (optionsVisible) {
        const index = options.indexOf(selectedOption);
        if (index + 1 < options.length) {
          newSelectedOption = options[index + 1];
        }
      }
      this.setState({ selectedOption: newSelectedOption, optionsVisible: true });
      this.scrollToFocusedOption(true);
    }
  }

  findOption(option, options) {
    const { valueKey } = this.props;
    if (options && option) {
      return options.find((o) => o[valueKey] === option[valueKey]) || null;
    }
    return null;
  }

  renderArrow() {
    const { disabled } = this.props;
    const { optionsVisible, options } = this.state;
    const arrowDisabled = disabled || (!options || options.length === 0);
    const arrowClassName = arrowDisabled ? 'select-arrow-disabled' : 'select-arrow';
    const iconClassName = optionsVisible && !arrowDisabled ? 'icon icon-eh-show-less' : 'icon icon-eh-show-more';
    return (<div className={arrowClassName} role="button" onMouseDown={this.handleRenderArrowOnMouseDown}><span className={iconClassName} /></div>);
  }

  renderOption(option) {
    const { renderOption, labelKey } = this.props;
    if (renderOption) {
      return renderOption(option);
    }
    return (<span className="select-option-value">{option[labelKey]}</span>);
  }

  renderSelectedOption() {
    const { disabled } = this.props;
    const { selectedOption } = this.state;

    if (selectedOption && !selectedOption.empty) {
      const className = disabled ? 'select-option-selected disabled' : 'select-option-selected';
      return (<div className={className} role="button" onMouseDown={this.handleRenderSelectedOptionOnMouseDown}>{this.renderOption(selectedOption)}</div>);
    }
    return null;
  }

  renderOptionsOption(option, focused) {
    const { labelKey } = this.props;
    const { valueKey } = this.props;
    const className = focused ? 'select-option select-option-focused' : 'select-option';

    const onClick = (event) => {
      this.handleSelection(option);
      event.preventDefault();
    };

    return (
      <div
        role="button"
        key={option[valueKey]}
        className={className}
        onMouseDown={onClick}
        summary={option[labelKey]}
        ref={(element) => this.updateFocusedOptionRef(element, focused)}
      >
        {this.renderOption(option)}
      </div>
    );
  }

  renderOptions(options) {
    const { selectedOption } = this.state;
    const renderedOptions = options.map((option) => this.renderOptionsOption(option, selectedOption === option));
    return (<div id="select-options" className="select-options">{renderedOptions}</div>);
  }

  render() {
    const { disabled, placeholder, onSelectChange } = this.props;
    const { optionsVisible, options, selectedOption } = this.state;
    const renderedSelectedOption = this.renderSelectedOption();
    const renderedArrow = this.renderArrow();
    const renderedOptions = optionsVisible && options && options.length > 0 ? this.renderOptions(options) : null;
    const renderedPlaceholder = selectedOption && !selectedOption.empty ? null : placeholder;

    return (
      <div className="select" role="button" onKeyDown={this.handleKeyDown}>
        {renderedArrow}
        <input
          className="form-control select-input"
          placeholder={renderedPlaceholder}
          disabled={disabled}
          type="text"
          onFocus={() => this.setState({ focused: true })}
          ref={(input) => this.updateInputRef(input)}
          onBlur={this.handleInputOnBlur}
          onClick={this.handleInputOnClick}
          onChange={onSelectChange || this.handleInputOnChange}
          readOnly={isTouchDevice()}
        />
        {renderedSelectedOption}
        {renderedOptions}
      </div>
    );
  }
}

export default Select;
