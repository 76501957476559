export function extractTenant(tenant) {
  return {
    id: tenant.id,
    name: tenant.name,
    description: tenant.description,
    public: tenant.public,
  };
}

export function extractTenants(rawTenants) {
  return rawTenants.tenants.map((tenant) => extractTenant(tenant));
}
