/* eslint-disable no-console */
import React, { useMemo } from 'react';
import {
  MutationCache, QueryCache, QueryClient, QueryClientProvider,
} from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom-v5-compat';
import { withNotifier } from './NotifierContext';
import { withLog } from './LogContext';
import { withApi } from './ApiContext';
import { url } from '../utils';

const MAX_RETRIES = 3;
const HTTP_STATUS_TO_RETRY = [408, 429, 500, 502, 503, 504];
const REDIRECTS = {
  403: '/403',
  404: '/404',
};

// eslint-disable-next-line react/function-component-definition
export const CustomQueryClientProvider = withApi(
  withLog(
    withNotifier(({
      children, notifier, api,
    }) => {
      const isProdEnv = process.env.NODE_ENV === 'production';
      const isTestEnv = process.env.NODE_ENV === 'test';
      const navigate = useNavigate();
      const client = useMemo(() => new QueryClient({
        queryCache: new QueryCache({
          onError: (error, query) => {
            if (query.meta?.useGlobalErrorHandling === false) {
              return;
            }
            const redirect = REDIRECTS[error?.response?.status];
            if (redirect !== undefined) {
              navigate(url(redirect));
              return;
            }
            notifier.showError(api.translateError(error, isProdEnv));
          },
        }),
        mutationCache: new MutationCache({
          onError: (error, _variables, _context, mutation) => {
            if (mutation.options.onError) return;
            notifier.showError(api.translateError(error, false));
          },
        }),
        defaultOptions: {
          queries: {
            ...(/* istanbul ignore next */ isTestEnv ? { retryDelay: 0 } : {}),
            retry: (failureCount, error) => {
              // TEST_ACTIVATE_QUERY_RETRIES should only be used to unit-test the retry logic
              if (isTestEnv && process.env.TEST_ACTIVATE_QUERY_RETRIES !== 'true') { return false; }
              if (failureCount === MAX_RETRIES) { return false; }
              if (![...HTTP_STATUS_TO_RETRY, undefined].includes(error?.response?.status)) { return false; }
              return true;
            },
            refetchOnWindowFocus: false,
          },
        },
      }), [api, notifier]);

      return (
        <QueryClientProvider client={client}>
          {children}
        </QueryClientProvider>
      );
    }),
  ),
);
