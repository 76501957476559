import PropTypes from 'prop-types';

export const addressShape = PropTypes.shape({
  companyName: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  streetName: PropTypes.string,
  streetNumber: PropTypes.string,
  city: PropTypes.string,
  zipCode: PropTypes.string,
  regionCode: PropTypes.string,
  countryCode: PropTypes.string,
  countryName: PropTypes.string,
  phone: PropTypes.string,
  vatNumber: PropTypes.string,
});
