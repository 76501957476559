import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isNotEmpty } from '../../utils';

class ToggleLabel extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    text: PropTypes.string,
    name: PropTypes.string,
    initial: PropTypes.bool,
    /* eslint-disable react/forbid-prop-types */
    values: PropTypes.object,
  };

  static defaultProps = {
    initial: false,
  };

  render() {
    const {
      id, name, text, initial, values,
    } = this.props;
    const displayText = isNotEmpty(text) ? text : 'Unknown';

    const initialState = values ? values[name] : initial;
    this.state = { isToggleOn: values ? values[name] : false };

    const toggleStyle = initialState ? 'btn-toggle-on' : 'btn-toggle-off';
    const className = `btn-toggle ${toggleStyle}`;
    const result = (
      <div id={id} name={name} className={className}>
        <span>{displayText}</span>
      </div>
    );

    return (
      <div>
        {result}
      </div>
    );
  }
}

export default ToggleLabel;
