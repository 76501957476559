import PropTypes from 'prop-types';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Modal } from 'react-bootstrap';
import { withIntl } from '../wrappers';
import { intlShape } from '../shapes';
import Column from './Grid/Column';
import Row from './Grid/Row';

export function CopySavedModal({
  show,
  title,
  message,
  image,
  showClose,
  onSave,
  copyValues,
  onCopyValue,
  onClose,
  backdrop,
  intl,
}) {
  const modalHeader = (
    <Modal.Header closeButton>
      <Modal.Title>
        {title}
      </Modal.Title>
    </Modal.Header>
  );

  const modalBody = (
    <Modal.Body>
      {image ? (
        <Row>
          <Column>{image && <img alt="device" src={image} />}</Column>
        </Row>
      ) : null }
      <Row>
        <Column>
          {message}
        </Column>
      </Row>
    </Modal.Body>
  );

  const renderCloseButton = showClose ? (
    <button
      id="button-close"
      type="button"
      className="btn btn-default"
      onClick={() => onClose()}
    >
      {intl.formatMessage({ id: 'button.close' })}
    </button>
  ) : null;

  const modalFooter = (
    <Modal.Footer>
      <Row>
        <Column>
          <div className="btn-group justify-middle">
            <CopyToClipboard text={copyValues} onCopy={() => onCopyValue()}>
              <button
                id="button-copy"
                type="button"
                className="btn btn-primary"
              >
                {intl.formatMessage({ id: 'button.copy' })}
              </button>
            </CopyToClipboard>
            <button
              id="button-save"
              type="button"
              className="btn btn-default"
              onClick={() => onSave()}
            >
              {intl.formatMessage({ id: 'button.download' })}
            </button>
            {renderCloseButton}
          </div>
        </Column>
      </Row>
    </Modal.Footer>
  );

  return (
    <Modal show={show} onHide={onClose} restoreFocus={false} backdrop={backdrop}>
      {modalHeader}
      {modalBody}
      {modalFooter}
    </Modal>
  );
}

CopySavedModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  backdrop: PropTypes.string,
  image: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  copyValues: PropTypes.string,
  onCopyValue: PropTypes.func,
  showClose: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  intl: intlShape.isRequired,
};

export default withIntl(CopySavedModal);
