import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Switch from '../Switch';
import Heading from '../Heading';
import ContextualHelp from '../Contextuals/ContextualHelp';
import SpecificationKeys from '../../constants/SpecificationKeys';
import Loader from '../Loader';
import { Row } from '../Grid';

import {
  apiShape,
  backendShape,
  subscriptionShape,
  notifierShape,
  withApi,
  withBackend,
  withSubscription,
  withNotifier,
} from '../../context';

import {
  intlShape,
} from '../../shapes';

import {
  url,
} from '../../utils/browserUtils';
import AlertType from '../../constants/AlertType';
import InputAlert from '../Form/InputAlert';
import { isNotEmpty } from '../../utils';

export function AssetAddOns({
  api, assetId, backend, notifier, intl, subscription, currentValues, initialValues,
}) {
  const [state, setState] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  let allowSwitching = true;
  const loadData = async () => {
    try {
      const productFilter = currentValues.manufacturer?.id ? { manufacturer_id: currentValues.manufacturer.id } : {};
      const clientApplicationName = subscription?.client_application?.name?.toLowerCase();
      const [subscriptionAddOnsResponse, assetAddons, currentProduct, planVariantsResponse] = await Promise.all([
        api.get(`/subscriptions/${subscription.id}/add_ons`),
        api.get(`/assets/${assetId}/add_ons`),
        api.get('/products', {
          ...productFilter,
          product_code: currentValues?.product?.code,
          include: 'specifications,manufacturer,tenant',
        }),
        backend.get('/plan_variants', { current_client_application: clientApplicationName }),
      ]);
      const filteredProductSpecs = currentProduct?.products[0]?.specifications?.[SpecificationKeys.ProductSpecs.ASSIGNABLE_ADD_ONS]?.value.split(',')
        .filter((productSpec) => [`${clientApplicationName}`].find((allowedProductSpec) => productSpec.indexOf(allowedProductSpec) === 0));
      if (filteredProductSpecs) {
        const addOns = filteredProductSpecs
          .filter((addOnId) => planVariantsResponse.plan_variants?.find((planVariant) => planVariant.addons?.find((addon) => addon.id === addOnId)))
          .filter((addOnId) => !['connected-asset', 'fermentation'].find((unallowedAddOnId) => addOnId.indexOf(unallowedAddOnId) >= 0))
          .map((addOnId) => {
            const assetAddon = assetAddons?.add_ons?.find((addon) => addon.external_reference.split('-').slice(1).join('-') === addOnId.split('-').slice(1).join('-'));
            const subscriptionAddon = subscriptionAddOnsResponse.add_ons.find((addon) => addon.external_reference === addOnId);
            const planVariantAddon = planVariantsResponse.plan_variants.find((planVariant) => planVariant.addons?.find((addon) => addon.id === addOnId))?.addons.find((addon) => addon.id === addOnId);

            const currentIsEndress = currentProduct?.products[0]?.manufacturer?.name === 'Endress+Hauser' && currentProduct?.products[0]?.tenant?.public;
            const initialIsEndress = initialValues?.manufacturer.name === 'Endress+Hauser' && initialValues?.manufacturer?.tenantPublic;
            allowSwitching = currentIsEndress === initialIsEndress;
            if (currentProduct?.products[0]?.product_code !== initialValues?.product?.code) {
              const filteredInitialAddOnId = initialValues?.product?.specifications[SpecificationKeys.ProductSpecs.ASSIGNABLE_ADD_ONS]?.value.split(',')
                .filter((initialAddOnId) => addOnId === initialAddOnId) || [];
              allowSwitching = isNotEmpty(filteredInitialAddOnId) && filteredInitialAddOnId[0] === addOnId;
            }

            return {
              id: subscriptionAddon?.id,
              externalReference: addOnId,
              name: planVariantAddon.name,
              quantity: subscriptionAddon?.quantity || 0,
              assignedAssetsCount: subscriptionAddon?.assigned_assets_count || 0,
              active: !!assetAddon,
              alienSubscription: assetAddon && (!subscriptionAddon || subscriptionAddon.id !== assetAddon.id),
              allowSwitching,
            };
          });
        setState({ addOns, disabled: false });
      } else {
        setState({});
      }
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (subscription.id) {
      loadData();
    }
  }, [subscription, currentValues?.product?.code]);

  const handleOnChange = async (addOn) => {
    setState({ ...state, disabled: true });
    try {
      if (addOn.active) {
        await api.delete(`/assets/${assetId}/add_ons`, { add_ons: [{ id: addOn.id }] });
        notifier.showSuccess(intl.formatMessage({ id: 'asset_add_ons.deactivation_success_notification' }));
      } else {
        await api.patch(`/assets/${assetId}/add_ons`, { add_ons: [{ id: addOn.id }] });
        notifier.showSuccess(intl.formatMessage({ id: 'asset_add_ons.activation_success_notification' }));
      }
      await loadData();
    } catch (error) {
      setState({ ...state, disabled: false });
      notifier.showError(api.translateError(error));
    }
  };

  return state.addOns?.length > 0 || isLoading ? (
    <Row className="asset-add-on-row">
      <Loader loading={isLoading}>
        {!isLoading
          ? (
            <>
              <Heading title={intl.formatMessage({ id: 'asset_add_ons.title' })} level={2} />
              {state.addOns?.map((addOn) => (
                <div key={addOn.externalReference}>
                  <div className="asset-add-on">
                    {state.addOns.id}
                    <Switch
                      id={`${addOn.externalReference}-switch`}
                      label={addOn.alienSubscription ? addOn.name : `${addOn.name} (${addOn.assignedAssetsCount}/${addOn.quantity})`}
                      value={addOn.active}
                      disabled={!addOn.allowSwitching || state.disabled || addOn.alienSubscription || (!addOn.active && addOn.assignedAssetsCount === addOn.quantity)}
                      onChange={() => handleOnChange(addOn)}
                    />
                    {!addOn.active && addOn.assignedAssetsCount === addOn.quantity && (
                    <Link
                      to={url('/upgrade')}
                    >
                      {intl.formatMessage({ id: 'asset_add_ons.upgrade_subscription' })}
                    </Link>
                    )}
                    {addOn.alienSubscription && (
                    <ContextualHelp
                      title={intl.formatMessage({ id: 'asset_add_ons.alien_subscription.title' })}
                    >
                      <p>{intl.formatMessage({ id: 'asset_add_ons.alien_subscription.description' })}</p>
                    </ContextualHelp>
                    )}
                  </div>
                  {!addOn.allowSwitching ? (
                    <InputAlert
                      message={intl.formatMessage({ id: 'asset_add_ons.activation_not_allowed.info' })}
                      type={AlertType.INFO}
                      className="no-margin"
                    />
                  ) : null}
                </div>
              ))}
            </>
          )
          : null}
      </Loader>
    </Row>
  ) : null;
}

AssetAddOns.defaultProps = {
  currentValues: {},
  initialValues: {},
};

AssetAddOns.propTypes = {
  api: apiShape.isRequired,
  assetId: PropTypes.string.isRequired,
  backend: backendShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  subscription: subscriptionShape.isRequired,
  currentValues: PropTypes.shape({
    manufacturer: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      tenantPublic: PropTypes.bool,
    }),
    product: PropTypes.shape({
      code: PropTypes.string,
    }),
  }),
  initialValues: PropTypes.shape({
    manufacturer: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      tenantPublic: PropTypes.bool,
    }),
    product: PropTypes.shape({
      code: PropTypes.string,
      specifications: PropTypes.shape({}),
    }),
  }),
};

export default withBackend(withApi(withSubscription(withNotifier(injectIntl(AssetAddOns)))));
