import React from 'react';
import PropTypes from 'prop-types';

export function NavigationItemExternalLink({
  label, target, href, className,
}) {
  return (
    <li>
      <a
        role="button"
        onClick={(e) => e.target?.blur()}
        href={href}
        target={target}
        className={className}
      >
        {label}
      </a>
    </li>
  );
}

NavigationItemExternalLink.propTypes = {
  label: PropTypes.string.isRequired,
  target: PropTypes.string,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
};

NavigationItemExternalLink.defaultProps = {
  target: '_new',
  className: '',
};

export default NavigationItemExternalLink;
