import React from 'react';
import PropTypes from 'prop-types';

function Breadcrumb({ id, className, children }) {
  return (
    <nav role="navigation" aria-label="Breadcrumb" id={id} className={['breadcrumb', className].filter((c) => c).join(' ')}>
      {children}
    </nav>
  );
}

Breadcrumb.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

Breadcrumb.defaultProps = {
  id: undefined,
  className: undefined,
  children: undefined,
};

export default Breadcrumb;
