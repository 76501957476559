import PropTypes from 'prop-types';
import { tenantShape } from './tenantsShape';

// Type
export const systemTypeShape = PropTypes.shape({
  id: PropTypes.number,
  code: PropTypes.string,
  name: PropTypes.string,
  tenant: tenantShape,
});

// System
export const systemShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  statusName: PropTypes.string,
  statusCode: PropTypes.string,
  typeName: PropTypes.string,
  typeCode: PropTypes.string,
  itemType: PropTypes.string,
});

export const systemTypesShape = PropTypes.arrayOf(systemTypeShape);

export const systemsShape = PropTypes.arrayOf(systemShape);
