import ActionType from '../constants/ActionType';
import lcmApiClient from '../api/lcmApiClient';
import { extractUser } from '../extractors/usersExtractor';
import { extractTenants } from '../extractors/tenantsExtractor';

export function fetchCurrentUserSuccessful(user) {
  return {
    type: ActionType.FETCH_CURRENT_USER_SUCCESSFUL,
    user,
  };
}

export function fetchCurrentUserFailed(errors) {
  return (dispatch) => {
    dispatch({
      type: ActionType.FETCH_CURRENT_USER_FAILED,
      errors,
    });
  };
}

export function fetchCurrentUser(options = undefined) {
  return async (dispatch, getState) => {
    const includes = ['userroles', 'details'];

    if (options && options.specifications && options.specifications.length) {
      includes.push(`specifications[${options.specifications.join(',')}]`);
    }

    try {
      const response = await Promise.all([
        lcmApiClient(dispatch, getState, false).get('/users/current', { include: includes.join(',') }),
      ]);

      const extractedUser = extractUser(response[0]);
      dispatch(fetchCurrentUserSuccessful(extractedUser));
    } catch (errors) {
      dispatch(fetchCurrentUserFailed(errors));
    }
  };
}

export function fetchCurrentUserAdminTenantsSuccessfull(adminTenants) {
  return {
    type: ActionType.FETCH_CURRENT_USER_ADMIN_TENANTS_SUCCESSFUL,
    adminTenants: extractTenants(adminTenants),
  };
}

export function fetchCurrentUserAdminTenantsFailed(errors) {
  return (dispatch) => {
    dispatch({
      type: ActionType.FETCH_CURRENT_USER_ADMIN_TENANTS_FAILED,
      errors,
    });
  };
}

export function updateCurrentUser(user) {
  return {
    type: ActionType.UPDATE_CURRENT_USER,
    user,
  };
}

export function fetchCurrentUserAdminTenants() {
  return (dispatch, getState) => lcmApiClient(dispatch, getState).getAll('/tenants', { admin_user_id: getState().currentUser.user.id })
    .then((adminTenants) => dispatch(fetchCurrentUserAdminTenantsSuccessfull(adminTenants)))
    .catch((errors) => dispatch(fetchCurrentUserAdminTenantsFailed(errors)));
}
