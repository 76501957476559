export function extractAddress(rawAddress) {
  return {
    companyName: rawAddress.company_name,
    firstName: rawAddress.first_name,
    lastName: rawAddress.last_name,
    email: rawAddress.email,
    streetName: rawAddress.street,
    streetNumber: rawAddress.street_number,
    city: rawAddress.city,
    zipCode: rawAddress.zip_code,
    regionCode: rawAddress.region_code,
    countryCode: rawAddress.country_code,
    countryName: rawAddress.country_name,
    phone: rawAddress.phone,
    vatNumber: rawAddress.vat_number,
  };
}
