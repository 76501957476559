import React from 'react';
import PropTypes from 'prop-types';

function Icon({ name, color, style }) {
  return <i className={`icon ${name}`} data-testid={`icon ${name}`} style={style ? { ...style, color } : { color }} />;
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

Icon.defaultProps = {
  style: undefined,
  color: undefined,
};

export default Icon;
