// same as <NavigationBar/> but without dependencies to session, location, subscription, user, browser
import PropTypes from 'prop-types';
import React from 'react';
import {
  Navbar, Nav,
} from 'react-bootstrap';

import {
  sessionShape,
  locationShape,
} from '../../shapes';
import CONFIGURATION from '../../configuration';

import { NavigationAppSwitcher } from '../Navigation/NavigationAppSwitcher';

function ErrorNavigationBar({
  name,
  session = {},
  location,
  mobileName,
  configuration = {},
  showAppSwitcher = true,
}) {
  const navigationItems = null;
  const stripeShadowClassName = session.authenticated ? 'cyan-stripe navigation-shadow' : 'cyan-stripe';

  return (
    <div className="navigation">
      <div className={stripeShadowClassName}>
        <div className="container">
          <Navbar collapseOnSelect>
            <div className="row">
              <div className="navigation-header">
                <div className="navigation-toggle">
                  <Navbar.Toggle disabled={!session.authenticated} />
                </div>
                <div className="navigation-title">
                  <a href={`${CONFIGURATION.BASE_URL}`}>
                    <span className="hidden-xs hidden-sm">{name}</span>
                    {configuration.environment === 'staging' && <span className="hidden-xs hidden-sm staging-hint"> Staging</span>}
                    <span className="hidden-md hidden-lg">{mobileName}</span>
                    {configuration.environment === 'staging' && <span className="hidden-md hidden-lg staging-hint"> Staging</span>}
                  </a>
                </div>
                <div className="navigation-logo" />
              </div>
            </div>
            <Navbar.Collapse>
              <div className="row">
                <Nav id="app-navigation">{navigationItems}</Nav>
                {showAppSwitcher && (
                  <Nav id="apps-navigation" pullRight>
                    {session.authenticated && (<NavigationAppSwitcher location={location} />)}
                  </Nav>
                )}
              </div>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
      <div className="navigation-separator" />
    </div>
  );
}

ErrorNavigationBar.propTypes = {
  name: PropTypes.string,
  mobileName: PropTypes.string,
  session: sessionShape,
  location: locationShape,
  showAppSwitcher: PropTypes.bool,
  configuration: PropTypes.shape({ environment: PropTypes.string }), // not required but recommended
};

export default ErrorNavigationBar;
