import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { isEmpty } from 'lodash-es';
import { injectIntl } from 'react-intl';

import {
  Form, SubmitButton, TextInput, ButtonGroup, intlShape,
} from 'lcm-iot-commons';
import validator from 'validator';
import Captcha from '../Captcha/Captcha';

export function SignInForm({
  intl, onSubmit, captchaRequired, captchaRefresh,
}) {
  const validateForm = (values) => {
    const errors = {};

    if (isEmpty(values.email)) {
      errors.email = intl.formatMessage({ id: 'validation.email.mandatory' });
    } else if (!validator.isEmail(values.email)) {
      errors.email = intl.formatMessage({ id: 'validation.email.invalid' });
    }
    if (isEmpty(values.password)) {
      errors.password = intl.formatMessage({ id: 'validation.password.mandatory' });
    }
    if (captchaRequired && isEmpty(values.captcha)) {
      errors.captcha = intl.formatMessage({ id: 'validation.captcha.mandatory' });
    }
    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting, setFieldValue } = formProps;

    const onPasswordPaste = (e) => {
      const clipboardData = e.clipboardData || window.clipboardData;
      if (!clipboardData) {
        return; // Edge implemented clipboardData, but are not compliant with the standard
      }
      e.preventDefault();
      const text = clipboardData
        .getData('text')
        .replace(/[\n\r]/g, '');
      setFieldValue('password', text);
    };

    return (
      <Form {...formProps}>
        <TextInput
          {...formProps}
          id="email"
          name="email"
          label={intl.formatMessage({ id: 'label.email' })}
          required
          autoFocus
          autoCorrect="off"
          autoComplete="username"
        />
        <TextInput
          {...formProps}
          id="password"
          name="password"
          label={intl.formatMessage({ id: 'label.password' })}
          required
          type="password"
          onPaste={onPasswordPaste}
          autoComplete="current-password"
        />
        {captchaRequired ? (<Captcha {...formProps} name="captcha" ref={captchaRefresh} />) : null}
        <ButtonGroup>
          <SubmitButton
            id="sign-in"
            text={intl.formatMessage({ id: 'signin.button.signin' })}
            fetching={isSubmitting}
          />
        </ButtonGroup>
      </Form>
    );
  };

  return (
    <Formik
      validateOnBlur={false}
      id="sign-in-form"
      onSubmit={onSubmit}
      validate={validateForm}
      render={renderForm}
    />
  );
}
SignInForm.defaultProps = {
  captchaRequired: false,
  captchaRefresh: undefined,
};

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  captchaRequired: PropTypes.bool,
  /* eslint-disable react/forbid-prop-types */
  captchaRefresh: PropTypes.object,
  intl: intlShape.isRequired,
};

export default injectIntl(SignInForm);
