import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { nodeShape } from '../../shapes/nodesShape';
import { url } from '../../utils';
import Breadcrumb from './Breadcrumb';
import BreadcrumbItem from './BreadcrumbItem';

const buildBreadCrumb = (onClick, node, current, prefix, breadcrumb) => {
  if (!breadcrumb && current) {
    return buildBreadCrumb(onClick, node, current, prefix, [(
      <BreadcrumbItem key={current} title={current} />
    )]);
  }
  if (node) {
    if (!breadcrumb) {
      return buildBreadCrumb(onClick, node.parent, current, prefix, [(
        <BreadcrumbItem key={node.id} title={node.name} />
      )]);
    }
    const targetUrl = prefix ? `${prefix}/nodes/${node.id}` : `/nodes/${node.id}`;

    breadcrumb.unshift((
      <BreadcrumbItem key={node.id} title={node.name} onClick={() => onClick(node)} target={url(targetUrl)} />
    ));

    if (node.parent) {
      buildBreadCrumb(onClick, node.parent, current, prefix, breadcrumb);
    }
  }
  return breadcrumb;
};

function NestedNodeBreadcrumb({
  id, node, current, onClick, firstItemTitleId, firstItemTargetUrl, breadCrumbTargetUrlPrefix,
}) {
  const firstItemTitle = (
    <FormattedMessage id={firstItemTitleId || 'nodes.header'} />
  );
  const breadcrumb = buildBreadCrumb(onClick, node, current, breadCrumbTargetUrlPrefix);

  if (breadcrumb) {
    breadcrumb.unshift(<BreadcrumbItem key="all" title={firstItemTitle} onClick={() => onClick()} target={url(firstItemTargetUrl)} />);
  }

  return node || current ? (
    <Breadcrumb id={id}>
      {breadcrumb}
    </Breadcrumb>
  ) : (
    <Breadcrumb id={id}>
      <BreadcrumbItem
        key="all"
        title={firstItemTitle}
      />
    </Breadcrumb>
  );
}

NestedNodeBreadcrumb.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  current: PropTypes.string,
  node: nodeShape,
  firstItemTitleId: PropTypes.string,
  firstItemTargetUrl: PropTypes.string,
  breadCrumbTargetUrlPrefix: PropTypes.string,
};

export default NestedNodeBreadcrumb;
