import md5 from 'js-md5';

import { isSomething, snakeToCamel } from '../utils/langUtils';

function extractSpecifications(rawUser, user) {
  if (rawUser.specifications) {
    Object.keys(rawUser.specifications).forEach((key) => {
      if (key.indexOf('eh.pcps') === 0) {
        const propertyKey = key.indexOf('eh.pcps.user') === 0
          ? snakeToCamel(key.split('eh.pcps.user.')[1].replace('.', '_'))
          : snakeToCamel(key.split('eh.pcps.')[1].replace('.', '_'));
        /* eslint-disable no-param-reassign */
        user[propertyKey] = rawUser.specifications[key].value;
      }
    });
  }
}

function extractRoles(userRoles) {
  let roles = [];
  if (userRoles) {
    roles = userRoles.map((role) => ({ name: role.name, id: role.id }));
  }
  return roles;
}

export function extractUser(rawUser) {
  let user = {
    id: rawUser.id,
    email: rawUser.email,
    firstName: rawUser.first_name,
    lastName: rawUser.last_name,
    userRoles: extractRoles(rawUser.userroles),
    avatar: rawUser.email ? `https://www.gravatar.com/avatar/${md5(rawUser.email)}?s=160&d=mm` : undefined,
    demoDataActive: rawUser.demo_data ? rawUser.demo_data : null,
    openPermissionRequests: rawUser.open_permission_requests ? rawUser.open_permission_requests : null,
    hasEdgeDevices: rawUser.edge_devices ? rawUser.edge_devices : null,
    unreadNotifications: rawUser.notifications ? rawUser.notifications : null,
  };

  extractSpecifications(rawUser, user);

  if (isSomething(rawUser.address)) {
    user = {
      ...user,
      company: rawUser.address.company_name,
      streetName: rawUser.address.street,
      streetNumber: rawUser.address.street_number,
      city: rawUser.address.city,
      zipCode: rawUser.address.zip_code,
      regionCode: rawUser.address.region_code,
      countryCode: rawUser.address.country_code,
      countryName: rawUser.address.country_name,
      phone: rawUser.address.phone,
      billingEmail: rawUser.address.email,
    };
  }

  return user;
}

export function extractUsers(rawUsers) {
  return rawUsers.users.map((user) => extractUser(user));
}

export function extractSeatUser(rawSeatUser) {
  return {
    id: rawSeatUser.user ? rawSeatUser.user.id : null,
    email: rawSeatUser.email ? rawSeatUser.email : null,
    status: rawSeatUser.status,
    name: rawSeatUser.user ? `${rawSeatUser.user.first_name} ${rawSeatUser.user.last_name}` : rawSeatUser.email,
  };
}

export function extractSeatUsers(rawSeatUsers) {
  return rawSeatUsers.map((seatUser) => extractSeatUser(seatUser));
}
