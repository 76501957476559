/* eslint-disable no-use-before-define */
import { Milliseconds } from 'lcm-iot-commons';
import Status from '../constants/Status';
import NetworkStatus from '../constants/NetworkStatus';
import FieldGatewayStatus from '../constants/FieldGatewayStatus';
import OpcUaServerStatus from '../constants/OpcUaServerStatus';
import OpcUaServerCertificateStatus from '../constants/OpcUaServerCertificateStatus';

const networkInterfaceStatusBubble = {
  undefined: 'undefined',
  [NetworkStatus.ACTIVE]: 'success',
  [NetworkStatus.INACTIVE]: 'failure',
};

const fieldGatewayStatusBubble = {
  undefined: 'undefined',
  [FieldGatewayStatus.UNDEFINED]: 'undefined',
  [FieldGatewayStatus.ONLINE]: 'success',
  [FieldGatewayStatus.NOT_FOUND]: 'failure',
  [FieldGatewayStatus.CONFIGURATION_ERROR]: 'failure',
};

function isEdgeDeviceOffline(lastSeenAt, since) {
  return !lastSeenAt || (Date.now() - lastSeenAt > since);
}

function isEdgeDeviceOfflineInternal(edgeDevice) {
  return edgeDevice?.status?.code === Status.OFFLINE || (edgeDevice && isEdgeDeviceOffline(edgeDevice.last_seen_at, Milliseconds.DAY));
}

export function statusBubbleNetworkInterface(status, edgeDevice = null) {
  if (isEdgeDeviceOfflineInternal(edgeDevice)) {
    return 'undefined';
  }
  return networkInterfaceStatusBubble[status];
}

export function statusBubbleFieldGateway(status, edgeDevice = null) {
  if (isEdgeDeviceOfflineInternal(edgeDevice)) {
    return 'undefined';
  }
  return fieldGatewayStatusBubble[status];
}

export function edgeDeviceStatus(status, lastSeenAt) {
  switch (status) {
    case (Status.UNDEFINED):
      return 'undefined';
    case (Status.SHIPPED):
      return 'warning';
    case (Status.REGISTERED): {
      if (lastSeenAt) {
        return lastSeenStatus(lastSeenAt);
      }
      return 'undefined';
    }
    case (Status.OFFLINE):
      return 'critical';
    default:
      return 'undefined';
  }
}

export function lastSeenStatus(lastSeenAt) {
  if (lastSeenAt) {
    if (isEdgeDeviceOffline(lastSeenAt, Milliseconds.DAY)) {
      return 'critical';
    }
    return 'success';
  }
  return 'undefined';
}

export function edgeDeviceStatusIconClassName(status, lastSeenAt) {
  switch (edgeDeviceStatus(status, lastSeenAt)) {
    case ('critical'):
      return 'icon lcm-iot-icon-circle text-danger';
    case ('warning'):
      return 'icon lcm-iot-icon-circle text-warning';
    case ('success'):
      return 'icon lcm-iot-icon-circle text-success';
    default:
      return 'icon lcm-iot-icon-circle text-muted';
  }
}

export function networkStatusIconClassName(status, edgeDevice = null) {
  if (isEdgeDeviceOfflineInternal(edgeDevice)) {
    return 'lcm-iot-icon-circle status-undefined icon';
  }

  switch (status) {
    case (NetworkStatus.ACTIVE):
      return 'lcm-iot-icon-circle status-success icon';
    case (NetworkStatus.INACTIVE):
      return 'lcm-iot-icon-circle status-error icon';
    default:
      return 'lcm-iot-icon-circle status-undefined icon';
  }
}

export function fieldGatewayStatusIconClassName(status, edgeDevice = null) {
  if (isEdgeDeviceOfflineInternal(edgeDevice)) {
    return 'lcm-iot-icon-circle status-undefined icon';
  }
  switch (status) {
    case (FieldGatewayStatus.ONLINE):
      return 'lcm-iot-icon-circle status-success icon';
    case (FieldGatewayStatus.NOT_FOUND):
      return 'lcm-iot-icon-circle status-error icon';
    case (FieldGatewayStatus.CONFIGURATION_ERROR):
      return 'lcm-iot-icon-circle status-error icon';
    default:
      return 'lcm-iot-icon-circle status-undefined icon';
  }
}

export function fieldGatewayEnableStatusIconClassName(isEIPAvailable) {
  if (isEIPAvailable) {
    return 'lcm-iot-icon-circle status-success icon';
  }
  return 'lcm-iot-icon-circle status-error icon';
}

export function apiKeyStatusIconClassName(disabled) {
  if (disabled) {
    return 'lcm-iot-icon-circle status-error icon';
  }
  return 'lcm-iot-icon-circle status-success icon';
}

export function opcUaServerStatusIconClassName(status) {
  switch (status) {
    case (OpcUaServerStatus.INITIALIZING):
      return 'lcm-iot-icon-circle status-warning icon';
    case (OpcUaServerStatus.ACTIVE):
      return 'lcm-iot-icon-circle status-success icon';
    case (OpcUaServerStatus.FAILURE):
      return 'lcm-iot-icon-circle status-error icon';
    default:
      return 'lcm-iot-icon-circle status-undefined icon';
  }
}

export function edgeDeviceIconClassName() {
  return 'lcm-iot-icon-edge icon';
}

export function gatewayIconClassName() {
  return 'lcm-iot-icon-gateway icon';
}

export function networkInterfaceIconClassName() {
  return 'lcm-iot-icon-network-interface icon';
}

export function apiKeyIconClassName() {
  return 'lcm-iot-icon-key icon';
}

export function opcUaServerIconClassName() {
  return 'lcm-iot-icon-opc-ua icon';
}

export function opcUaServerCertificateStatusClassName(status) {
  switch (status) {
    case (OpcUaServerCertificateStatus.TRUSTED):
      return 'lcm-iot-icon-circle status-success icon';
    case (OpcUaServerCertificateStatus.REJECTED):
      return 'lcm-iot-icon-circle status-error icon';
    default:
      return 'lcm-iot-icon-circle status-undefined icon';
  }
}

export function opcUaServerCertificateIconClassName() {
  return 'lcm-iot-icon-certificate icon';
}

export function lcmIotIconCirculeRedGreenIcon(isEnabled) {
  if (isEnabled) {
    return 'lcm-iot-icon-circle status-success icon';
  }
  return 'lcm-iot-icon-circle status-error icon';
}
