import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import React, { Component } from 'react';
import { Formik } from 'formik';
import {
  ActionBar,
  handleUnknownErrors,
  isNotFoundError,
  isEmpty,
  Loader,
  goBack,
  showSuccess,
  SubmitButton,
  CancelButton,
  TextArea,
  SelectBox,
  Details,
  DetailsItem,
  intlShape,
} from 'lcm-iot-commons';

import { loadConnectSubscription } from '../../api/subscriptionApi';
import { createApiKey } from '../../api/apiKeyApi';
import AuthenticationTypes from '../../constants/AuthenticationTypes';

export class ApiKeyCreate extends Component {
  constructor(props) {
    super();
    const { intl } = props;
    /* istanbul ignore next */
    this.validateForm = this.validateForm.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      loading: true,
      subscription: undefined,
    };
    this.state.authenticationTypes = [
      { id: AuthenticationTypes.OAUTH, name: intl.formatMessage({ id: `enum.authentication_type.${AuthenticationTypes.OAUTH}` }) },
      { id: AuthenticationTypes.BASIC, name: intl.formatMessage({ id: `enum.authentication_type.${AuthenticationTypes.BASIC}` }) },
    ];
  }

  componentDidMount() {
    const { intl } = this.props;
    this.loadData()
      .catch((apiErrors) => {
        this.setState({ loading: false });
        if (isNotFoundError(apiErrors)) {
          goBack();
        } else {
          handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
        }
      });
  }

  onSubmit(values, actions) {
    const { intl } = this.props;
    this.submitData(values, actions).catch((apiErrors) => {
      const formErrors = {};

      if (Object.keys(formErrors).length < apiErrors.length) {
        handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
      }

      actions.setErrors(formErrors);
      actions.setSubmitting(false);
    });
  }

  async loadData() {
    const { match } = this.props;
    const subscription = await loadConnectSubscription(match.params.id);

    this.setState({ subscription, loading: false });
  }

  async submitData(values, actions) {
    const { intl } = this.props;
    const { subscription } = this.state;
    await createApiKey(subscription.clientApplicationId, values);
    actions.setSubmitting(false);
    showSuccess(intl.formatMessage({ id: 'api_key_create.success_notification' }));
    goBack();
  }

  validateForm(values) {
    const { intl } = this.props;
    const errors = {};

    if (!values.authenticationType || (values.authenticationType && isEmpty(values.authenticationType.id))) {
      errors.authenticationType = intl.formatMessage({ id: 'validation.authentication_type.mandatory' });
    }

    return errors;
  }

  renderForm(props) {
    const { intl } = this.props;
    const {
      handleSubmit, isSubmitting, setStatus,
    } = props;
    const { authenticationTypes } = this.state;
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.formActions = props;

    const beforeHandleSubmit = (event) => {
      setStatus('submitted');
      handleSubmit(event);
    };

    return (
      <form onSubmit={beforeHandleSubmit} id="api-key-form" noValidate>
        <div className="row">
          <div className="col-xs-12">
            <SelectBox
              {...props}
              id="api-key-authentication-type"
              name="authenticationType"
              label={intl.formatMessage({ id: 'label.authentication_type' })}
              options={authenticationTypes}
              required
            />
            <TextArea
              {...props}
              id="api-key-description"
              name="description"
              label={intl.formatMessage({ id: 'label.description' })}
            />
          </div>
        </div>
        <div className="btn-group">
          <SubmitButton id="create-api-key-submit" fetching={isSubmitting} />
          <CancelButton id="create-api-key-cancel" disabled={isSubmitting} />
        </div>
      </form>
    );
  }

  render() {
    const { loading, subscription } = this.state;

    const createApiKeyForm = subscription && !loading ? (
      <Formik
        validate={this.validateForm}
        onSubmit={this.onSubmit}
        render={this.renderForm}
      />
    ) : null;

    const clientApp = subscription && !loading ? (
      <Details>
        <DetailsItem
          className="no-bottom-padding"
          translationKey="label.client_application"
          value={subscription.clientApplicationName}
        />
      </Details>
    ) : null;

    return (
      <div id="api-key-create" className="container">
        <div className="row">
          <div className="col-md-7">
            <div>
              <ActionBar>
                <h1><FormattedMessage id="api_key_create.header" /></h1>
              </ActionBar>
            </div>
            {clientApp}
            {createApiKeyForm}
            <Loader loading={loading} />
          </div>
        </div>
      </div>
    );
  }
}

ApiKeyCreate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  intl: intlShape,
};

ApiKeyCreate.defaultProps = {
  match: undefined,
  intl: undefined,
};

export default injectIntl(ApiKeyCreate);
