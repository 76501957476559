import axios from 'axios';
import URLSearchParams from 'url-search-params';
import ApiError from './ApiError';
import { isArray, isString } from '../utils/langUtils';

import {
  BadRequestError,
  ConflictError,
  ConnectionError,
  ForbiddenError,
  InternalServerError,
  NetworkError,
  NotFoundError,
  PaymentRequiredError,
  ServiceUnavailableError,
  TooManyRequestsError,
  UnauthorizedError,
  UnknownError,
} from './errors';

export default class ApiClient {
  constructor(baseUrl, options) {
    this.baseUrl = baseUrl;
    this.requestOptions = options;
  }

  get(url, parameters, signal) {
    let requestUrl = this.buildUrl(url);

    if (parameters) {
      const searchParams = new URLSearchParams();
      Object.keys(parameters).forEach((key) => searchParams.append(key, parameters[key]));
      requestUrl = `${requestUrl}?${searchParams.toString()}`;
    }

    return this.execute({
      ...this.requestOptions,
      method: 'GET',
      url: requestUrl,
      signal,
    });
  }

  getAll(url, parameters, signal) {
    return this.get(url, parameters, signal).then(async (firstGetResponse) => {
      if (!firstGetResponse.pagination) {
        return firstGetResponse;
      }
      const finalResult = firstGetResponse;
      let nextUrl = firstGetResponse.pagination.next;
      if (nextUrl === undefined) {
        return finalResult;
      }
      const numPages = firstGetResponse.pagination.page_count;
      const objKey = Object.keys(firstGetResponse).filter((key) => Array.isArray(firstGetResponse[key]) && key !== 'pagination')[0];

      if (numPages > 1) {
        // there is next and page count known, so we can read the pages in parallel
        const promises = [];
        for (let pageIdx = 2; pageIdx <= numPages; pageIdx += 1) {
          promises.push(this.get(url, { ...parameters, page: pageIdx, include_total_count: false }).then((getResponse) => getResponse[objKey]));
        }
        return Promise.all(promises).then((promiseResults) => {
          finalResult[objKey] = finalResult[objKey].concat(promiseResults.flat(1));
          return finalResult;
        });
      }
      // there is next but no page count known, so we have to read the pages sequentially
      while (nextUrl !== undefined) {
        // eslint-disable-next-line no-await-in-loop
        const nextGetResponse = await this.get(nextUrl);
        nextUrl = nextGetResponse.pagination.next;
        finalResult[objKey] = finalResult[objKey].concat(nextGetResponse[objKey]);
      }
      return finalResult;
    });
  }

  post(url, parametersOrObject, objectOrNull = null) {
    let requestUrl = this.buildUrl(url);

    const parameters = objectOrNull ? parametersOrObject : null;
    const object = objectOrNull || parametersOrObject;

    if (parameters) {
      const searchParams = new URLSearchParams();
      Object.keys(parameters).forEach((key) => searchParams.append(key, parameters[key]));
      requestUrl = `${requestUrl}?${searchParams.toString()}`;
    }

    return this.execute({
      ...this.requestOptions,
      method: 'POST',
      url: requestUrl,
      data: JSON.stringify(object),
    });
  }

  patch(url, parametersOrObject, objectOrNull = null) {
    let requestUrl = this.buildUrl(url);
    const parameters = objectOrNull ? parametersOrObject : null;
    const object = objectOrNull || parametersOrObject;

    if (parameters) {
      const searchParams = new URLSearchParams();
      Object.keys(parameters).forEach((key) => searchParams.append(key, parameters[key]));
      requestUrl = `${requestUrl}?${searchParams.toString()}`;
    }

    return this.execute({
      ...this.requestOptions,
      method: 'PATCH',
      url: requestUrl,
      data: JSON.stringify(object),
    });
  }

  delete(url, parametersOrObject, objectOrNull = null) {
    let requestUrl = this.buildUrl(url);
    const parameters = objectOrNull ? parametersOrObject : null;
    const object = objectOrNull || parametersOrObject;

    if (parameters) {
      const searchParams = new URLSearchParams();
      Object.keys(parameters).forEach((key) => searchParams.append(key, parameters[key]));
      requestUrl = `${requestUrl}?${searchParams.toString()}`;
    }

    const data = object ? JSON.stringify(object) : null;

    return this.execute({
      ...this.requestOptions,
      url: requestUrl,
      method: 'DELETE',
      data,
    });
  }

  head(url, parameters, signal) {
    let requestUrl = this.buildUrl(url);

    if (parameters) {
      const searchParams = new URLSearchParams();
      Object.keys(parameters).forEach((key) => searchParams.append(key, parameters[key]));
      requestUrl = `${requestUrl}?${searchParams.toString()}`;
    }

    return this.execute({
      ...this.requestOptions,
      method: 'HEAD',
      url: requestUrl,
      signal,
    });
  }

  upload(url, parameters) {
    delete this.requestOptions.headers['Content-Type'];
    return this.execute({
      ...this.requestOptions,
      method: url.match(/.*\/[\d]+$/) ? 'PATCH' : 'POST',
      url: this.buildUrl(url),
      data: this.createFormData(parameters),
    });
  }

  /* istanbul ignore next */
  createFormData(parameters) {
    /* istanbul ignore next */
    const data = new FormData();
    /* istanbul ignore next */
    Object.keys(parameters).forEach((key) => data.append(key, parameters[key]));
    /* istanbul ignore next */
    return data;
  }

  buildUrl(url) {
    if (url.startsWith(this.baseUrl)) {
      return url;
    }
    return this.baseUrl + url;
  }

  execute(request) {
    return axios.request(request)
      .then((response) => new Promise((resolve) => {
        if (request.method === 'HEAD') {
          resolve(response.headers);
        } else if (response.status === 204) {
          resolve();
        } else {
          resolve(response.data);
        }
      })).catch((error) => this.handleError(error, request));
  }

  handleError(error, request) {
    if (request.signal?.aborted) throw error;
    if (this.useNewErrors) {
      if (error.message === 'Network Error') {
        throw new NetworkError(request, error.response, error);
      }
      if (['ECONNABORTED', 'ECONNRESET', 'ECONNREFUSED'].includes(error.code)) {
        throw new ConnectionError(request, error.response, error);
      }
      switch (error.response?.status) {
        case 400:
          throw new BadRequestError(request, error.response, error);
        case 401:
          throw new UnauthorizedError(request, error.response, error);
        case 402:
          throw new PaymentRequiredError(request, error.response, error);
        case 403:
          throw new ForbiddenError(request, error.response, error);
        case 404:
          throw new NotFoundError(request, error.response, error);
        case 409:
          throw new ConflictError(request, error.response, error);
        case 429:
          throw new TooManyRequestsError(request, error.response, error);
        case 500:
          throw new InternalServerError(request, error.response, error);
        case 503:
          throw new ServiceUnavailableError(request, error.response, error);
        default:
          throw new UnknownError(request, error.response, error);
      }
    }

    let requestData;
    if (isString(request.data)) {
      requestData = request.data && request.data.replace(/"password":"[^"]*"/, '"password:"[FILTERED]"');
    } else {
      requestData = request.data;
    }
    const requestForError = {
      method: request.method,
      url: request.url,
      data: requestData,
    };

    if (error.response && error.response.data && isArray(error.response.data.errors)) {
      throw new ApiError(error.response.data.errors, requestForError);
    } else {
      throw new ApiError([{
        type: 'unknown',
        method: request.method,
        url: request.url,
        requestData,
        responseStatus: error?.response?.status,
        responseData: error?.response?.data,
      }], requestForError);
    }
  }
}
