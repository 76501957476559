import PropTypes from 'prop-types';
import { assetsShape } from './assetsShape';

export const serviceSubscriptionShape = PropTypes.shape({
  id: PropTypes.number,
  applicationName: PropTypes.string,
  subscriptionName: PropTypes.string,
  assetQuota: PropTypes.number,
  storageQuota: PropTypes.number,
  dataStorageQuota: PropTypes.number,
  pricingModel: PropTypes.string,
  externalReference: PropTypes.string,
  externalPlanVariantReference: PropTypes.string,
  interval: PropTypes.string,
  appIconName: PropTypes.string,
  billedUntil: PropTypes.number,
  status: PropTypes.string,
  assets: assetsShape,
});
