import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactImageGallery from 'react-image-gallery';
import { picturesShape } from '../../shapes/picturesShape';
import { isSomething, isMobileDevice, url } from '../../utils';

import { withConfiguration, withSession } from '../../context';

export class PictureGallery extends Component {
  static propTypes = {
    pictures: picturesShape,
    placeholder: PropTypes.string,
    configuration: PropTypes.shape({
      lcmApiUrl: PropTypes.string,
    }),
    session: PropTypes.shape({
      token: PropTypes.string,
    }),
    onPictureChange: PropTypes.func,
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.renderItem = this.renderItem.bind(this);
    this.onScreenChange = this.onScreenChange.bind(this);
    this.onImageClick = this.onImageClick.bind(this);
    this.onSlide = this.onSlide.bind(this);
    this.state = { fullscreen: false, pictureItems: null };
    this.selectedPictureIndex = 0;
  }

  componentDidMount() {
    this.updatePictureItems(this.props);
  }

  componentDidUpdate(prevProps) {
    const { pictures, onPictureChange } = this.props;

    if (pictures !== prevProps.pictures) {
      this.updatePictureItems(this.props);
    }

    if (prevProps.pictures !== pictures && pictures && onPictureChange) {
      onPictureChange(this.props.pictures[this.selectedPictureIndex]);
    }

    if (this.gallery && pictures && prevProps.pictures && pictures.length > prevProps.pictures.length) {
      this.gallery.slideToIndex(pictures.length - 1);
    }
  }

  onImageClick() {
    if (this.gallery.state.isFullscreen) {
      this.gallery.exitFullScreen();
      this.setState({ fullscreen: false });
    } else {
      this.gallery.fullScreen();
      this.setState({ fullscreen: true });
    }
  }

  onSlide(index) {
    const { onPictureChange, pictures } = this.props;
    if (onPictureChange) {
      this.selectedPictureIndex = index;
      onPictureChange(pictures[index]);
    }
  }

  onScreenChange(fullscreenElement) {
    this.setState({ fullscreen: isSomething(fullscreenElement) });
  }

  updatePictureItems(props) {
    const { pictures, additionalPictureUrls } = props;

    const pictureItems = [];

    if (additionalPictureUrls) {
      additionalPictureUrls.forEach((picUrl) => pictureItems.push({ original: picUrl }));
    }

    if (pictures) {
      pictures.sort((a, b) => a.id - b.id);
      pictures.forEach((picture) => pictureItems.push({ original: picture.url }));
    }

    if (pictureItems.length > 0) {
      this.setState({ pictureItems });
    } else {
      this.setState({ pictureItems: null });
    }
  }

  renderItem(item) {
    const { session, configuration } = this.props;
    const pictureUrl = item.original?.startsWith(configuration.lcmApiUrl) ? `${item.original}?access_token=${session.token}` : item.original;
    const picture = this.state.fullscreen ? (
      <img src={pictureUrl} alt="" />
    ) : (
      <div data-testid="picture-gallery-image" className="picture" style={{ backgroundImage: `url(${pictureUrl}), url(${url('/images/no-picture.png')}` }} />
    );

    return (
      <div className="pictures-picture sentry-mask">
        {picture}
      </div>
    );
  }

  render() {
    const { placeholder, children } = this.props;
    const { pictureItems } = this.state;
    const renderedPlaceholder = pictureItems ? null : (
      <div className="pictures-placeholder centralize">
        <span data-testid={placeholder} className={`icon ${placeholder}`} />
      </div>
    );

    const gallery = pictureItems ? (
      <ReactImageGallery
        ref={/* istanbul ignore next */ (e) => { /* istanbul ignore next */ this.gallery = e; }}
        items={pictureItems}
        slideInterval={2000}
        slideDuration={300}
        showThumbnails={false}
        showNav={pictureItems.length > 1 && !isMobileDevice()}
        renderItem={this.renderItem}
        showFullscreenButton={false}
        showIndex={pictureItems.length > 1}
        onScreenChange={this.onScreenChange}
        showPlayButton={false}
        onClick={this.onImageClick}
        onSlide={this.onSlide}
        infinite
        lazyLoad
      />
    ) : null;

    return (
      <div className="pictures sentry-block">
        {children}
        {renderedPlaceholder}
        {gallery}
      </div>
    );
  }
}

export default withConfiguration(withSession(PictureGallery));
