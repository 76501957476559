import React from 'react';
import { useIntl } from 'react-intl';
import { useSearch } from '../../hooks/useSearch';

export function AllObjectsBadge() {
  const intl = useIntl();
  const search = useSearch();

  const totalCount = `${intl.formatNumber(search.totalCount)}${search.totalCount >= 10_000 ? '+' : ''}`;
  const resultCount = search.filters?.length === 0 ? 0 : totalCount;

  const badge = search.isSearching ? (
    <span className="loading" data-testid="allObjectsBadge-loading">
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  ) : resultCount;
  if (search.results !== undefined || search.isSearching) {
    return (<span id="search-results-count" className="badge">{badge}</span>);
  }
  return null;
}

export default AllObjectsBadge;
