import React from 'react';
import PropTypes from 'prop-types';

import {
  apiShape,
  withApi,
  withConfiguration,
  withUser,
  withSession,
  withBackend,
  backendShape,
} from '../../context';
import { userShape, sessionShape } from '../../shapes';
import { NotFoundError } from '../../api';
import SeatInvitationModal from './SeatInvitationModal';

export function SeatInvitationCheck({
  api, user, configuration, session, backend,
}) {
  const [seatInvitation, setSeatInvitation] = React.useState();

  const canAcceptInvitation = (subscription) => {
    if (subscription.type === 'free' || subscription.status === 'open' || subscription.status === 'cancelled') { return true; }
    return false;
  };

  React.useEffect(() => {
    const loadData = async () => {
      const response = await api.get('/subscription/seats', {
        include: 'subscription.user',
        status: 'pending,invited',
        client_application_id: configuration.appId,
      }, false);
      const subscriptionSeat = response.subscription_seats.find((seat) => seat.user?.id === user.id || seat.email?.toLowerCase() === user.email?.toLowerCase());
      /* istanbul ignore next */
      if (subscriptionSeat) {
        try {
          const subscription = await backend.get('/subscription');
          if (canAcceptInvitation(subscription)) {
            setSeatInvitation(subscriptionSeat);
          }
        } catch (error) {
          if (error instanceof NotFoundError) {
            setSeatInvitation(subscriptionSeat);
          }
        }
      }
    };
    if (session.activeSubscription === false || session.subscriptionType !== 'paid') {
      loadData();
    }
  }, []);

  return (
    <div>
      { seatInvitation && (<SeatInvitationModal id="seat-popup" seat={seatInvitation} appName={configuration.appName} />)}
    </div>
  );
}

SeatInvitationCheck.propTypes = {
  api: apiShape,
  user: userShape,
  configuration: PropTypes.shape({
    appId: PropTypes.number,
    appName: PropTypes.string,
  }).isRequired,
  session: sessionShape,
  backend: backendShape,
};

export default
withApi(
  withUser(
    withConfiguration(
      withSession(
        withBackend(
          SeatInvitationCheck,
        ),
      ),
    ),
  ),
);
