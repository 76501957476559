import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import uuid from 'uuid';
import {
  formatNumberInText,
} from '../../utils';
import {
  addonMaxAmount,
  addonVisibleForUserrole,
} from '../../utils/addonUtils';
import {
  intlShape,
  planCartShape,
  userShape,
} from '../../shapes';

import PlanVariantAddon from './PlanVariantAddon';

import {
  withConfiguration,
  withUser,
} from '../../context';

export class PlanVariant extends Component {
  static propTypes = {
    planVariant: PropTypes.shape({
      id: PropTypes.string,
      currency: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.string,
      type: PropTypes.string,
      sort: PropTypes.number,
      popular: PropTypes.bool,
      button: PropTypes.string,
      assets: PropTypes.number,
      storage: PropTypes.number,
      short_description: PropTypes.string,
      features: PropTypes.arrayOf(PropTypes.string),
      // eslint-disable-next-line react/forbid-prop-types
      addons: PropTypes.arrayOf(PropTypes.any),
    }),
    planCart: planCartShape,
    intl: intlShape.isRequired,
    onSelect: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    creating: PropTypes.bool,
    configuration: PropTypes.shape({
      planPriceDisplay: PropTypes.string,
    }),
    user: userShape.isRequired,
  };

  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.onAddonChange = this.onAddonChange.bind(this);
    this.handleMouseClick = this.handleMouseClick.bind(this);
    this.state = {
      addons: {},
      total: 0,
    };
  }

  componentDidMount() {
    this.setState({ total: this.calculateTotal() });
  }

  handleMouseClick(planVariant) {
    const { onSelect } = this.props;
    onSelect(planVariant, this.state.addons);
  }

  onAddonChange(value, addon) {
    const tmpAddons = this.state.addons;
    const addons = { ...tmpAddons };
    addons[addon.id] = value;
    this.setState({ addons, total: this.calculateTotal(this.props.planVariant, addons) });
  }

  calculateTotal(plan = this.props.planVariant, addons = this.state.addons) {
    let total = plan.price ? parseFloat(plan.price) : undefined;
    Object.keys(addons).forEach((addonId) => {
      const planVariantAddon = plan.addons.find((addon) => addon.id === addonId);
      total += parseFloat(planVariantAddon.price) * addons[addonId];
    });
    return total;
  }

  render() {
    const {
      planVariant, planCart, disabled, creating, intl, configuration, user,
    } = this.props;
    const { total } = this.state;

    const panelClass = planVariant.popular ? 'panel panel-primary' : 'panel panel-default';
    const buttonClass = planVariant.popular ? 'btn btn-primary select-button' : 'btn select-button';
    const planVariantPrice = parseFloat(planVariant.price);

    const planUnit = intl.formatMessage({ id: 'planvariant.per_user' });

    const features = planVariant.features.map((item) => (<li className="list-item-checked" key={uuid()}>{formatNumberInText(item)}</li>));
    const displayPrice = configuration.planPriceDisplay === 'yearly' ? `${intl.formatNumber(planVariantPrice.toFixed(2), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} / ${planUnit} / ${intl.formatMessage({ id: 'global.year' })}` : `${intl.formatNumber((planVariantPrice / 12).toFixed(2), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} / ${planUnit} / ${intl.formatMessage({ id: 'global.month' })}`;
    let price;
    if (planVariant.name.toLowerCase().includes('fermentation')) {
      const yearlyPrice = parseFloat(planVariant.addons.find((addon) => addon.type === 'connectivity')?.price);
      price = `${planVariant.currency} ${intl.formatNumber((yearlyPrice / 12).toFixed(2), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} / ${intl.formatMessage({ id: 'planvariant.per_qwx43' })} / ${intl.formatMessage({ id: 'global.month' })}`;
    } else {
      price = planVariant.type === 'free' || planVariantPrice === 0
        ? intl.formatMessage({ id: 'planvariant.free' })
        : `${planVariant.currency} ${displayPrice}`;
    }

    const outcome = planVariant.addons ? planVariant.addons.filter((addon) => addon.type === 'outcome' && addonVisibleForUserrole(user.userRoles, addon.limited_for_roles)).map((addon) => {
      const planCartAddon = planCart
        && planCart.plan_variant
        && planCart.plan_variant.id === planVariant.id
        && planCart.addons
        && planCart.addons.find((a) => a.plan_variant_addon.id === addon.id);
      const amount = planCartAddon ? planCartAddon.amount : 0;
      const maxAmount = addonMaxAmount(addon, planVariant);
      return (<PlanVariantAddon disabled={disabled} key={addon.id} planVariantAddon={addon} onChange={this.onAddonChange} amount={amount} maxAmount={maxAmount} intl={intl} />);
    }) : null;

    const connectivity = planVariant.addons ? planVariant.addons.filter((addon) => addon.type === 'connectivity' && addonVisibleForUserrole(user.userRoles, addon.limited_for_roles)).map((addon) => {
      const planCartAddon = planCart
        && planCart.plan_variant
        && planCart.plan_variant.id === planVariant.id
        && planCart.addons
        && planCart.addons.find((a) => a.plan_variant_addon.id === addon.id);
      const amount = planCartAddon ? planCartAddon.amount : 0;
      const maxAmount = addonMaxAmount(addon, planVariant);
      const minAmmount = planVariant.name.toLowerCase().includes('fermentation') ? 1 : 0;
      return (<PlanVariantAddon disabled={disabled} key={addon.id} planVariantAddon={addon} onChange={this.onAddonChange} amount={amount} maxAmount={maxAmount} minAmount={minAmmount} intl={intl} />);
    }) : null;

    const addons = planVariant.addons ? planVariant.addons.filter((addon) => (addon.type === 'addon' || addon.type === 'extension')
        && addonVisibleForUserrole(user.userRoles, addon.limited_for_roles)).map((addon) => {
      const planCartAddon = planCart
        && planCart.plan_variant
        && planCart.plan_variant.id === planVariant.id
        && planCart.addons
        && planCart.addons.find((a) => a.plan_variant_addon.id === addon.id);
      const amount = planCartAddon ? planCartAddon.amount : 0;
      const maxAmount = addonMaxAmount(addon, planVariant);
      return (<PlanVariantAddon disabled={disabled} key={addon.id} planVariantAddon={addon} onChange={this.onAddonChange} amount={amount} maxAmount={maxAmount} intl={intl} />);
    }) : null;

    const totalPrice = configuration.planPriceDisplay === 'yearly' ? `${intl.formatNumber(total.toFixed(2), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} / ${intl.formatMessage({ id: 'global.year' })}` : `${intl.formatNumber((total / 12).toFixed(2), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} / ${intl.formatMessage({ id: 'global.month' })}`;
    const totalRendered = (
      <div className="plan-variant-total">
        <div className="plan-variant-total-header"><FormattedMessage id="planvariant.total" /></div>
        <div className="plan-variant-total-value">
          {`${planVariant.currency} ${totalPrice}`}
        </div>
        <div className="plan-variant-total-value-note"><FormattedMessage id="planvariant.billed_annually" /></div>
      </div>
    );

    const shortDescription = planVariant.short_description ? (
      <div id="short-description" className="space-after short-description">
        {planVariant.short_description}
      </div>
    ) : null;

    const spinner = creating ? (<i className="btn-spinner" />) : null;
    const addonKey = planVariant.currency === 'USD' ? 'fermentation-monitor-connected-asset-usd' : 'fermentation-monitor-connected-asset';
    const submitDisabledForFermentation = (planVariant.name.toLowerCase().includes('fermentation')) && (!this.state.addons[addonKey] || this.state.addons[addonKey] < 1);

    return (
      <div id={`plan-variant-${planVariant.id}`} className={panelClass}>
        <div className="panel-heading">
          {planVariant.name}
          <div id="price" className="panel-heading panel-subtitle">
            {price}
          </div>
          { planVariant.type === 'free' || planVariantPrice === 0 ? null : (<p className="panel-subtitle">{intl.formatMessage({ id: 'planvariant.billed_annually' })}</p>)}
        </div>
        <div className="panel-body">
          {shortDescription}
          <ul id="plan-variant-features" className="list-checked">
            {features}
          </ul>
          {outcome && outcome.length > 0
            ? (
              <>
                <h4 id="planvariant-outcome"><FormattedMessage id="planvariant.outcome" /></h4>
                <div id="planvariant-outcome-addons">
                  {outcome}
                </div>
              </>
            ) : null}
          {connectivity && connectivity.length > 0
            ? (
              <>
                <h4 id="planvariant-connectivity"><FormattedMessage id="planvariant.connectivity" /></h4>
                <div id="planvariant-connectivity-addons">
                  {connectivity}
                </div>
              </>
            ) : null}
          {addons && addons.length > 0
            ? (
              <>
                <h4 id="planvariant-addons"><FormattedMessage id="planvariant.addons" /></h4>
                <div id="planvariant-standard-addons">
                  {addons}
                </div>
              </>
            ) : null}
          <div>
            {totalRendered}
          </div>
        </div>
        <div className="panel-footer">
          <button
            type="button"
            disabled={disabled || submitDisabledForFermentation}
            onMouseDown={() => this.handleMouseClick(planVariant)}
            className={buttonClass}
          >
            {planVariant.button}
            {spinner}
          </button>
        </div>
      </div>
    );
  }
}

export default injectIntl(withConfiguration(withUser(PlanVariant)));
