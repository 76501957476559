import { useSearchParams, useLocation } from 'react-router-dom-v5-compat';
import { useApi } from './useApi';
import { useRules } from '../context';
import { useUrlState } from './useUrlState';

export const useSearch = (options) => {
  const api = useApi();
  const rules = useRules();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchTerm, setSearchTerm] = useUrlState('search', searchParams, setSearchParams);
  const [filterValue, setFilterValue] = useUrlState('filter', searchParams, setSearchParams);
  const [optionValue, setOptionValue] = useUrlState('option', searchParams, setSearchParams);
  const [follow] = useUrlState('follow', searchParams, setSearchParams);

  const allowedFilters = rules.application().get('searchScope');
  const filters = (searchParams.get('filter') !== null ? filterValue : allowedFilters.join(','))
    .split(',')
    .filter((f) => allowedFilters.includes(f));

  const allowedOptions = ['specifications'];
  const searchOptions = (searchParams.get('option') !== null ? optionValue : allowedOptions.join(','))
    .split(',')
    .filter((f) => allowedOptions.includes(f));

  const match = /\/nodes\/(\d+)/.exec(location.pathname);
  const nodeId = match ? match[1] : undefined;

  const parameters = {
    q: searchTerm.trim(),
    scope: filters?.sort().join(','),
    per_page: 100,
    ...(nodeId && { node_id: nodeId }),
    ...(searchOptions.length && { include: searchOptions.join(',') }),
  };
  const {
    data, fetchNextPage, isFetchingNextPage, isFetching, hasNextPage, isInitialLoading,
  } = api.get.useInfiniteQuery('/search', {
    ...parameters,
  }, {
    enabled: !!parameters.q,
    select: (d) => ({
      results: d?.pages?.map((page) => page.results.map((result) => ({ ...result, pictureUrl: result.picture_url }))).flat(),
      pagination: d?.pages[d.pages.length - 1].pagination,
    }),
    refetchOnWindowFocus: false,
    ...options,
  });

  const results = data?.results;
  const totalCount = data?.pagination?.total_count;
  const isSearching = isInitialLoading || isFetching || isFetchingNextPage;
  const followFirstResult = follow === 'true';

  return {
    results,
    totalCount,
    isSearching,
    hasNextPage,
    filters,
    options: searchOptions,
    searchTerm,
    fetchNextPage,
    isFetchingNextPage,
    setSearchTerm,
    setFilterValue,
    setOptionValue,
    followFirstResult,
  };
};
