const NamurType = {
  OK: 'ok',
  FAILURE: 'failure',
  CHECK_FUNCTION: 'check_function',
  OUT_OF_SPECIFICATION: 'out_of_specification',
  MAINTENANCE_REQUIRED: 'maintenance_required',
  UNKNOWN: 'unknown',
  UNDEFINED: 'undefined',
};

export default NamurType;
