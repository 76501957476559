import React from 'react';
import PropTypes from 'prop-types';
import
{
  intlShape,
  DynamicFieldArray,
  ContextualHelp,
  withIntl,
} from 'lcm-iot-commons';

import { FieldGatewayModbusChannelSelect } from './FieldGatewayModbusChannelSelect';
import { FieldGatewayModbusVariableSelect } from './FieldGatewayModbusVariableSelect';
import { FieldGatewayModbusValueTextInput } from './FieldGatewayModbusValueTextInput';

export function FieldGatewaySpecificationsInput(props) {
  const { intl, disabled, isSubmitting } = props;
  return (
    <>
      <label htmlFor="Modbus Configuration" data-testid="modbus-config-specifications-input">{intl.formatMessage({ id: 'field_gateway.modbus.config.item.ai_di_channel' })}</label>
      <ContextualHelp title={intl.formatMessage({ id: 'field_gateway.modbus.config.edit.help' })}>
        <p>
          {intl.formatMessage({ id: 'field_gateway.modbus.config.edit.description.line1' })}
          <br />
          {intl.formatMessage({ id: 'field_gateway.modbus.config.edit.description.line2' })}
          <br />
          {intl.formatMessage({ id: 'field_gateway.modbus.config.edit.description.line3' })}
          <br />
          {intl.formatMessage({ id: 'field_gateway.modbus.config.edit.description.line4' })}
          <br />
          {intl.formatMessage({ id: 'field_gateway.modbus.config.edit.description.line5' })}
          <br />
          {intl.formatMessage({ id: 'field_gateway.modbus.config.edit.description.line6' })}
        </p>
      </ContextualHelp>
      <DynamicFieldArray
        {...props}
        id="gateway-specifications"
        valueArrayName="modbusConfig"
        placeholders={[
          intl.formatMessage({ id: 'field_gateway.modbus.config.item.valuename' }),
          intl.formatMessage({ id: 'field_gateway.modbus.config.item.channel' }),
          intl.formatMessage({ id: 'field_gateway.modbus.config.item.variable' }),
        ]}
        colSizeArray={[6, 2, 2]}
        fieldNames={['valueName', 'channel', 'regName']}
        fieldComponents={[FieldGatewayModbusValueTextInput, FieldGatewayModbusChannelSelect, FieldGatewayModbusVariableSelect]}
        disabled={disabled || isSubmitting}
      />
    </>
  );
}

FieldGatewaySpecificationsInput.propTypes = {
  intl: intlShape.isRequired,
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

FieldGatewaySpecificationsInput.defaultProps = {
  disabled: false,
  isSubmitting: false,
};

export default withIntl(FieldGatewaySpecificationsInput);
