import React, { useState } from 'react';
import {
  Container,
  Row,
  Column,
  ActionBar,
  withApi,
  apiShape,
  withNotifier,
  notifierShape,
  NotFoundError,
  withAccessRights,
} from 'lcm-iot-commons';

import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import DocumentForm from './DocumentForm';

export const validateAccessRights = (accessRights) => accessRights.canUpdate;

export function DocumentCreate({
  api,
  notifier,
  match,
}) {
  const [documentCategories, setDocumentCategories] = useState([]);
  const loadData = async () => {
    try {
      const response = await api.getAll('/document/categories');
      setDocumentCategories(response.categories);
    } catch (error) {
      if (error instanceof NotFoundError) {
        // don't do anything
      } else {
        notifier.showError(api.translateError(error));
      }
    }
  };

  React.useEffect(() => {
    loadData();
  }, [match]);

  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <h1><FormattedMessage id="document_create.header" /></h1>
          </ActionBar>
        </Column>
      </Row>
      <Row>
        <Column lg="7">
          <DocumentForm match={match} createMode documentCategories={documentCategories} />
        </Column>
      </Row>
    </Container>
  );
}

DocumentCreate.propTypes = {
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default withApi(withNotifier(withAccessRights(DocumentCreate, 'Product', validateAccessRights)));
