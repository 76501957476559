import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { withBrowser, browserShape } from '../../context';
import { intlShape } from '../../shapes';
import { isNotEmpty } from '../../utils';

export function CancelButton({
  target, handleOnClick, id, text, fetching, intl, browser,
}) {
  const onClick = () => {
    if (handleOnClick) {
      handleOnClick();
    } else if (target) {
      browser.navigateTo(target);
    } else {
      browser.goBack();
    }
  };

  const displayText = isNotEmpty(text) ? text : intl.formatMessage({ id: 'button.cancel' });

  return (
    <button
      id={id}
      data-testid="cancel-button-testid"
      name="submit"
      onClick={onClick}
      className="btn btn-default"
      disabled={fetching}
      type="button"
    >
      {displayText}
    </button>
  );
}

CancelButton.propTypes = {
  id: PropTypes.string.isRequired,
  fetching: PropTypes.bool,
  browser: browserShape.isRequired,
  text: PropTypes.string,
  target: PropTypes.string,
  intl: intlShape.isRequired,
  handleOnClick: PropTypes.func,
};

export default injectIntl(withBrowser(CancelButton));
