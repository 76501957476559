import lcmApiClient from './lcmApiClient';
import { extractInstrumentationTypes } from '../extractors/instrumentationTypesExtractor';
import { extractInstrumentations } from '../extractors/instrumentationsExtractor';
import { packInstrumentationSpecifications } from '../packmans/instrumentationPackman';

/* istanbul ignore next */
export async function findInstrumentationTypes(filter, buildDisplayNames = false) {
  const response = await lcmApiClient().getAll('/instrumentation/types', filter);
  return extractInstrumentationTypes(response, buildDisplayNames);
}

/* istanbul ignore next */
export async function loadInstrumentationSpecifications(id) {
  const response = await lcmApiClient().get(`/instrumentations/${id}/specifications`);
  return response;
}

export async function patchInstrumentationSpecification(instrumentationId, specifications) {
  await lcmApiClient().patch(`/instrumentations/${instrumentationId}/specifications`, specifications);
}

/* istanbul ignore next */
export async function assignNodeToInstrumentation(instrumentationId, nodeId) {
  return lcmApiClient().post(`/instrumentations/${instrumentationId}/nodes`, {
    nodes: [{ id: nodeId }],
  });
}

/* istanbul ignore next */
export async function createInstrumentation(instrumentation, nodeId) {
  const newInstrumentation = {
    tag: instrumentation.tag,
    description: instrumentation.description,
    status: { id: instrumentation.status.id },
    type: { id: instrumentation.type.id },
    criticality: instrumentation.criticality ? instrumentation.criticality.id.toLowerCase() : null,
    accessibility: instrumentation.accessibility ? instrumentation.accessibility.id.toLowerCase() : null,
  };
  if (!instrumentation.type.tenantPublic) {
    // FIXME: this assigns the tenant of the type when creating a new instrumentation. We need real tenant handling instead here!
    newInstrumentation.tenant = { id: instrumentation.type.tenantId };
  }
  const response = await lcmApiClient().post('/instrumentations', newInstrumentation);
  await patchInstrumentationSpecification(response.id, packInstrumentationSpecifications(instrumentation));
  if (nodeId) { await assignNodeToInstrumentation(response.id, nodeId); }
  return response.id;
}

export async function updateInstrumentation(id, instrumentation) {
  const updatedInstrumentation = {
    tag: instrumentation.tag,
    description: instrumentation.description,
    status: { id: instrumentation.status.id },
    type: { id: instrumentation.type.id },
    criticality: instrumentation.criticality.id.toLowerCase(),
    accessibility: instrumentation.accessibility.id.toLowerCase(),
  };
  if (!instrumentation.type.tenantPublic && instrumentation.tenantId !== instrumentation.type.tenantId) {
    // FIXME: this assigns the tenant of the type when creating a new instrumentation. We need real tenant handling instead here!
    updatedInstrumentation.tenant = { id: instrumentation.type.tenantId };
  }
  await lcmApiClient().patch(`/instrumentations/${id}`, updatedInstrumentation);

  await patchInstrumentationSpecification(id, packInstrumentationSpecifications(instrumentation));
}

/* istanbul ignore next */
export async function loadInstrumentations(parameters) {
  const response = await lcmApiClient().get('/instrumentations', parameters);
  return {
    instrumentations: extractInstrumentations(response),
    totalCount: response.pagination.total_count,
    nextPageUrl: response.pagination.next,
  };
}

/* istanbul ignore next */
export async function loadNextInstrumentations(nextPageUrl) {
  const response = await lcmApiClient().get(nextPageUrl);
  return {
    instrumentations: extractInstrumentations(response),
    totalCount: response.pagination.total_count,
    nextPageUrl: response.pagination.next,
  };
}

/* istanbul ignore next */
export async function unassignAssetFromInstrumentation(assetId, instrumentationId) {
  return lcmApiClient().delete(`/instrumentations/${instrumentationId}/assets`, {
    assets: [{ id: assetId }],
  });
}
