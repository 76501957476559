import ApiClientError from './ApiClientError';

class UnauthorizedError extends ApiClientError {
  /* istanbul ignore next */
  constructor(request, response, cause) {
    super('UnauthorizedError', 401, 'Authentication failed, missing or wrong credentials.', request, response, cause);
  }
}

export default UnauthorizedError;
