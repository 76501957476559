import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputGroup from './InputGroup';
import Typeahead from './Typeahead';

import {
  apiShape,
  notifierShape,
  withApi,
  withNotifier,
} from '../../context';

import {
  sortBy,
  isSomething,
  formatAssetDetails,
  formatSystemDetails,
  handleFormikValueChange,
  formatInstrumentationDetails,
} from '../../utils';

import {
  extractNodes,
  extractAssets,
  extractSystems,
  extractInstrumentations,
} from '../../extractors';

import { ObjectsType } from '../../constants';
import Picture from '../Pictures/Picture';

export function ObjectsTypeahead(props) {
  const {
    api,
    name,
    label,
    values,
    disabled,
    notifier,
    canCreate,
    autoFocus,
    objectsType,
    placeholder,
    isSubmitting,
    objectsToIgnore,
    filteredObjects,
  } = props;

  const [filter, setFilter] = useState('');
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);

  const loadOptions = async () => {
    try {
      setFetching(true);
      let objects = [];
      switch (objectsType) {
        case ObjectsType.Assets:
          objects = extractAssets(await api.get(
            '/assets',
            { include: 'product,product.manufacturer,product.tenant,product.pictures,status', permission: 'can_permit', serial_number: `${filter}*` },
          ));
          break;
        case ObjectsType.Nodes:
          objects = extractNodes(await api.get(
            '/nodes',
            { include: 'type', permission: 'can_permit', name: `${filter}*` },
          ), true);
          break;
        case ObjectsType.Instrumentations:
          objects = sortBy(extractInstrumentations(await api.get(
            '/instrumentations',
            { include: 'type,pictures,worst_asset_status', permission: 'can_permit', tag: `${filter}*` },
          )), 'tag');
          break;
        case ObjectsType.FilteredInstrumentations:
          objects = sortBy(filteredObjects, 'tag');
          break;
        case ObjectsType.Systems:
          objects = extractSystems(await api.get(
            '/systems',
            { include: 'parent,type,worst_asset_status ', permission: 'can_permit', name: `${filter}*` },
          ));
          break;
          /* istanbul ignore next */
        default:
          break;
      }
      if (objectsToIgnore?.length > 0) {
        objects = objects.filter((object) => !objectsToIgnore.find((o) => o.id === object.id));
      }
      setOptions(objects);
    } catch (error) {
      notifier.showError(api.translateError(error));
    } finally {
      setFetching(false);
    }
  };

  React.useEffect(() => {
    loadOptions();
  }, [filter]);

  const handleOnChange = (value) => { handleFormikValueChange(props, value); };

  const renderAsset = (asset) => {
    const picture = (asset.thumbnailUrl) ? (
      <Picture src={asset.thumbnailUrl} alt={asset.serialNumber} width={50} height={50} />
    ) : (
      <span className="icon icon-eh-device" />
    );

    return (
      <div className="select-item">
        <div className="select-item-image">
          {picture}
        </div>
        <div className="select-item-content">
          <div className="select-item-header">{asset.serialNumber}</div>
          <div className="select-item-details">{formatAssetDetails(asset)}</div>
        </div>
      </div>
    );
  };

  const renderOption = (object) => {
    switch (objectsType) {
      case ObjectsType.Assets:
        return renderAsset(object);
      case ObjectsType.Nodes:
        return (
          <div className="select-item">
            <div className="select-item-content">
              <div className="select-item-header">{object.name}</div>
              <div className="select-item-details">{object.description}</div>
            </div>
          </div>
        );
      case ObjectsType.Instrumentations:
      case ObjectsType.FilteredInstrumentations:
        return (
          <div className="select-item">
            <div className="select-item-image">
              <span className="icon lcm-iot-icon-instrumentation" />
            </div>
            <div className="select-item-content">
              <div className="select-item-header">{object.tag}</div>
              <div className="select-item-details">{formatInstrumentationDetails(object)}</div>
            </div>
          </div>
        );
      case ObjectsType.Systems:
        return (
          <div className="select-item">
            <div className="select-item-image">
              <span className="icon lcm-iot-icon-system" />
            </div>
            <div className="select-item-content">
              <div className="select-item-header">{object.name}</div>
              <div className="select-item-details">{formatSystemDetails(object)}</div>
            </div>
          </div>
        );
      /* istanbul ignore next */
      default:
        return (
          <div className="select-item">
            {object.name}
          </div>
        );
    }
  };

  const filterOption = (searchString, object) => {
    switch (objectsType) {
      case ObjectsType.Assets:
        return isSomething(object.serialNumber)
            && object.serialNumber.toLowerCase().startsWith(searchString.toLowerCase())
            && (!objectsToIgnore || !objectsToIgnore.find((o) => o.id === object.id));
      case ObjectsType.Instrumentations:
        return isSomething(object.tag)
            && object.tag.toLowerCase().startsWith(searchString.toLowerCase())
            && (!objectsToIgnore || !objectsToIgnore.find((o) => o.id === object.id));
      case ObjectsType.Systems:
      case ObjectsType.Nodes:
        return isSomething(object.name)
            && object.name.toLowerCase().startsWith(searchString.toLowerCase())
            && (!objectsToIgnore || !objectsToIgnore.find((o) => o.id === object.id));
      /* istanbul ignore next */
      default:
        return true;
    }
  };

  const labelKey = () => {
    switch (objectsType) {
      case ObjectsType.Assets:
        return 'serialNumber';
      case ObjectsType.Nodes:
        return 'displayName';
      case ObjectsType.Instrumentations:
      case ObjectsType.FilteredInstrumentations:
        return 'tag';
      case ObjectsType.Systems:
        return 'name';
      /* istanbul ignore next */
      default:
        return 'name';
    }
  };

  return (
    <div id="objects-typeahead-wrapper" role="button">
      <InputGroup {...props}>
        <Typeahead
          autoFocus={autoFocus}
          canCreate={canCreate}
          fetching={fetching}
          valueKey="id"
          onChange={handleOnChange}
          placeholder={placeholder || label}
          loadOptions={setFilter}
          options={options}
          selectedOption={name.split('.').reduce((p, c) => p?.[c], values)}
          filterOption={filterOption}
          renderOption={renderOption}
          disabled={disabled || isSubmitting}
          labelKey={labelKey()}
        />
      </InputGroup>
    </div>
  );
}

ObjectsTypeahead.propTypes = {
  api: apiShape.isRequired,
  name: PropTypes.string.isRequired,
  notifier: notifierShape.isRequired,
  values: PropTypes.shape({}).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  objectsType: PropTypes.oneOf([ObjectsType.Assets,
    ObjectsType.Instrumentations,
    ObjectsType.FilteredInstrumentations,
    ObjectsType.Nodes,
    ObjectsType.Systems]).isRequired,
  canCreate: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  objectsToIgnore: PropTypes.arrayOf(PropTypes.shape({})),
  filteredObjects: PropTypes.arrayOf(PropTypes.shape({})),
};

ObjectsTypeahead.defaultProps = {
  objectsToIgnore: [],
};

export default withApi(withNotifier(ObjectsTypeahead));
