import { sessionReducer as commonsSessionReducer } from 'lcm-iot-commons';
import ActionType from '../constants/ActionType';

export default function sessionReducer(state, action) {
  const { type, errors } = action;
  switch (type) {
    case ActionType.CREATE_SSO_SESSION_FETCHING:
      return { ...state, fetching: true };
    case ActionType.CREATE_SSO_SESSION_FAILED:
      return {
        ...state,
        authenticated: false,
        token: null,
        tokenExpiresAt: null,
        errors,
        fetching: false,
        captchaRequired: errors.map((error) => error.type).includes('too_many_login_attempts'),
        activeSubscription: false,
        subscriptionType: null,
        subscriptionAssetQuota: null,
        subscriptionStorageQuota: null,
      };
    default:
      return commonsSessionReducer(state, action);
  }
}
