import PropTypes from 'prop-types';
import React, { Component } from 'react';
import InputGroup from './InputGroup';
import ContextualHelp from '../Contextuals/ContextualHelp';

class TextInput extends Component {
  static propTypes = {
    input: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    label: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    autoFocus: PropTypes.bool,
    autoComplete: PropTypes.string,
    type: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
    prefix: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
    placeholder: PropTypes.string,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    name: PropTypes.string,
    isSubmitting: PropTypes.bool,
    /* eslint-disable react/forbid-prop-types */
    values: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    customClass: PropTypes.string,
    onPaste: PropTypes.func,
    help: PropTypes.bool,
    helpTitle: PropTypes.string,
    helpText: PropTypes.string,
  };

  componentDidMount() {
    if (this.props.autoFocus) {
      this.focus();
    }
  }

  focus() {
    setTimeout(() => {
      /* istanbul ignore next */
      if (this.inputField) {
        this.inputField.focus();
      }
    }, 50);
  }

  render() {
    const {
      input, label, disabled, type, min, max, prefix, placeholder, help, helpTitle, helpText, customClass = '',
    } = this.props;
    let inputRender;
    // REDUX FORM
    if (input) {
      inputRender = (
        <input
          {...input}
          onChange={input.onChange}
          className={`form-control ${customClass}`}
          placeholder={placeholder || label}
          type={(type || 'text')}
          style={{ display: this.props.hidden ? 'none' : null }}
          value={input.value}
          disabled={disabled}
          ref={(i) => { this.inputField = i; }}
        />
      );
    //  FORMIK
    } else {
      const {
        handleChange, handleBlur, name, values, isSubmitting, onPaste, autoComplete = '',
      } = this.props;
      inputRender = (
        <input
          {...input}
          className={`form-control ${customClass}`}
          disabled={disabled || isSubmitting}
          name={name}
          data-testid={name}
          autoComplete={autoComplete || name}
          onChange={handleChange}
          onBlur={handleBlur}
          onPaste={onPaste}
          step="any"
          placeholder={placeholder || label}
          ref={/* istanbul ignore next */(i) => { this.inputField = i; }}
          type={(type || 'text')}
          style={{ display: this.props.hidden ? 'none' : null }}
          min={min}
          max={max}
          value={name.split('.').reduce((p, c) => p?.[c], values) || ''}
        />
      );
    }

    const textInputRender = prefix ? (
      <div className="input-group">
        <span className={disabled ? 'input-group-addon disabled' : 'input-group-addon'}>{prefix}</span>
        {inputRender}
      </div>
    ) : (inputRender);

    const contextualHelp = help ? (
      <ContextualHelp title={helpTitle} interactive>
        <p>
          {' '}
          { helpText }
          {' '}
        </p>
      </ContextualHelp>
    ) : null;

    return (
      <InputGroup {...this.props} help={contextualHelp}>
        {textInputRender}
      </InputGroup>
    );
  }
}

export default TextInput;
