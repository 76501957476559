import React from 'react';
import { injectIntl } from 'react-intl';
import {
  intlShape,
} from 'lcm-iot-commons';

export function PasswordResetError({
  intl,
}) {
  return (
    <div className="container">
      <div id="reset-password-failed" className="row">
        <div className="col-sm-6">
          <h1>{intl.formatMessage({ id: 'password_reset.failed.header' })}</h1>
          <p>{intl.formatMessage({ id: 'password_reset.failed.description' })}</p>
          <a
            href="/app/id/request_password_reset"
            className="btn btn-primary space-before"
            role="button"
            id="request-password-reset"
          >
            {intl.formatMessage({ id: 'password_reset.failed.new_request' })}
          </a>
        </div>
      </div>
    </div>
  );
}

PasswordResetError.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(PasswordResetError);
