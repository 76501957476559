import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  withIntl,
  intlShape,
  Column,
  Row,
  Form,
  Checkbox,
  TextInput,
  ButtonGroup,
  SubmitButton,
  CancelButton,
  CountriesSelectFormik,
  removeNullAttributes,
} from 'lcm-iot-commons';

export function ResellerCustomerForm({
  intl, initialValues, onSubmit, ssoUser,
}) {
  const validateForm = (values) => {
    const { withAddress } = values;
    const errors = {};
    if (!values.number) {
      errors.number = intl.formatMessage({ id: 'validation.customer_number.mandatory' });
    }
    if (values.account?.length > 150) {
      errors.account = intl.formatMessage({ id: 'validation.account.too_long' }, { max: '150' });
    }
    if (withAddress) {
      errors.streetName = !values.streetName ? intl.formatMessage({ id: 'validation.street_name.mandatory' }) : null;
      errors.zipCode = !values.zipCode ? intl.formatMessage({ id: 'validation.zip_code.mandatory' }) : null;
      errors.city = !values.city ? intl.formatMessage({ id: 'validation.city.mandatory' }) : null;
      errors.country = !values.country?.code ? intl.formatMessage({ id: 'validation.country.mandatory' }) : null;
      if (values.firstName?.length > 150) {
        errors.firstName = intl.formatMessage({ id: 'validation.first_name.too_long' }, { max: '150' });
      }
      if (values.lastName?.length > 150) {
        errors.lastName = intl.formatMessage({ id: 'validation.last_name.too_long' }, { max: '150' });
      }
      if (values.streetName?.length > 150) {
        errors.streetName = intl.formatMessage({ id: 'validation.street_name.too_long' }, { max: '150' });
      }
      if (values.zipCode?.length > 20) {
        errors.zipCode = intl.formatMessage({ id: 'validation.zip_code.too_long' }, { max: '20' });
      }
      if (values.city?.length > 50) {
        errors.city = intl.formatMessage({ id: 'validation.city.too_long' }, { max: '50' });
      }
      if (values.streetNumber?.length > 150) {
        errors.streetNumber = intl.formatMessage({ id: 'validation.street_number.too_long' }, { max: '150' });
      }
      if (values.regionCode?.length > 50) {
        errors.regionCode = intl.formatMessage({ id: 'validation.region_code.too_long' }, { max: '50' });
      }
      if (values.phone?.length > 15) {
        errors.phone = intl.formatMessage({ id: 'validation.phone.too_long' });
      }
    }
    removeNullAttributes(errors);
    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting, values } = formProps;
    return (
      <Form {...formProps}>
        <Row id="customer-account">
          <Column md={8}>
            <TextInput
              {...formProps}
              id="account"
              name="account"
              label={intl.formatMessage({ id: 'label.account' })}
            />
          </Column>
        </Row>
        <Row id="customer-number">
          <Column md={8}>
            <TextInput
              {...formProps}
              id="number"
              name="number"
              label={intl.formatMessage({ id: 'label.number' })}
              required
            />
          </Column>
        </Row>
        <Checkbox
          {...formProps}
          id="with-address"
          name="withAddress"
          label={intl.formatMessage({ id: 'reseller_customer.with_address' })}
        />
        { values.withAddress && (
          <div>
            <Row id="customer-name">
              <Column md={4}>
                <TextInput
                  {...formProps}
                  id="first-name"
                  name="firstName"
                  label={intl.formatMessage({ id: 'label.first_name' })}
                  disabled={ssoUser}
                />
              </Column>
              <Column md={4}>
                <TextInput
                  {...formProps}
                  id="last-name"
                  name="lastName"
                  label={intl.formatMessage({ id: 'label.last_name' })}
                  disabled={ssoUser}
                />
              </Column>
            </Row>
            <Row id="customer-street">
              <Column md={6}>
                <TextInput
                  {...formProps}
                  id="street-name"
                  name="streetName"
                  label={intl.formatMessage({ id: 'label.street_name' })}
                  required
                />
              </Column>
              <Column md={2}>
                <TextInput
                  {...formProps}
                  id="street-number"
                  name="streetNumber"
                  label={intl.formatMessage({ id: 'label.street_number' })}
                />
              </Column>
            </Row>
            <Row id="customer-city">
              <Column md={2}>
                <TextInput
                  {...formProps}
                  id="zip-code"
                  name="zipCode"
                  label={intl.formatMessage({ id: 'label.zip_code' })}
                  required
                />
              </Column>
              <Column md={6}>
                <TextInput
                  {...formProps}
                  id="city"
                  name="city"
                  label={intl.formatMessage({ id: 'label.city' })}
                  required
                />
              </Column>
            </Row>
            <Row id="customer-country">
              <Column md={2}>
                <TextInput
                  {...formProps}
                  id="region-code"
                  name="regionCode"
                  label={intl.formatMessage({ id: 'label.region_code' })}
                />
              </Column>
              <Column md={6}>
                <CountriesSelectFormik
                  {...formProps}
                  id="country"
                  name="country"
                  label={intl.formatMessage({ id: 'label.country' })}
                  required
                  initializeEmpty
                  all
                />
              </Column>
            </Row>
            <Row id="customer-phone">
              <Column md={8}>
                <TextInput
                  {...formProps}
                  id="phone"
                  name="phone"
                  label={intl.formatMessage({ id: 'label.phone' })}
                />
              </Column>
            </Row>
          </div>
        )}
        <ButtonGroup className="btn-group">
          <SubmitButton id="customer-submit" fetching={isSubmitting} intl={intl} disabled={!formProps.dirty} />
          <CancelButton id="customer-cancel" disabled={isSubmitting} intl={intl} target="/reseller/customers" />
        </ButtonGroup>
      </Form>
    );
  };

  return (
    <div className="form-group">
      <Formik
        id="customer-form"
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validateForm}
        render={renderForm}
      />
    </div>
  );
}

ResellerCustomerForm.propTypes = {
  intl: intlShape.isRequired,
  initialValues: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    ssoProvider: PropTypes.string,
    address: PropTypes.shape({
      account: PropTypes.string,
      streetName: PropTypes.string,
      streetNumber: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
      regionCode: PropTypes.string,
      country: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
      }),
      phone: PropTypes.string,
    }),
  }),
  onSubmit: PropTypes.func,
  ssoUser: PropTypes.bool,
};

ResellerCustomerForm.defaultProps = {
  initialValues: {
    address: {
      country: {
        code: undefined,
        name: undefined,
      },
    },
  },
  onSubmit: undefined,
  ssoUser: false,
};

export default withIntl(ResellerCustomerForm);
