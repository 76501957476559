import React from 'react';
import PropTypes from 'prop-types';

function NotificationAlerts({ children }) {
  return (
    <div id="notifications" className="notification-alerts">
      {children}
    </div>
  );
}

NotificationAlerts.propTypes = {
  children: PropTypes.node,
};

NotificationAlerts.defaultProps = {
  children: undefined,
};

export default NotificationAlerts;
