import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import React, { Component } from 'react';
import { Formik } from 'formik';
import {
  redirectTo,
  navigateTo,
  isEmpty,
  TextInput,
  SubmitButton,
  CancelButton,
  ActionBar,
  ActionBarButtons,
  EditActionButton,
  errorsShape,
  planCartShape,
  handleUnknownErrors,
  showSuccess,
  ApiErrorsHandler,
  Loader,
  loadSubscriptionPlanCart,
  updatePlanCartParameters,
  apiErrorsContain,
  upgradePlanCart,
  showError,
  ConfirmationModal,
  chooseRelevantCountryCode,
  addonIsOnBlacklist,
  CONFIGURATION,
  intlShape,
  htmlFormat,
  htmlLink,
  backendShape, withBackend,
} from 'lcm-iot-commons';

import SubscriptionPaymentEditModal from 'lcm-iot-commons/client/lib/components/Subscriptions/SubscriptionPaymentEditModal';
import SubscriptionInvoiceEstimate from 'lcm-iot-commons/client/lib/components/Subscriptions/SubscriptionInvoiceEstimate';
import SubscriptionPaymentDetails from 'lcm-iot-commons/client/lib/components/Subscriptions/SubscriptionPaymentDetails';
import SubscriptionBillingDetails from 'lcm-iot-commons/client/lib/components/Subscriptions/SubscriptionBillingDetails';
import { loadConnectSubscription, loadCurrentSubscription } from '../../api';

export class ConnectSubscriptionCheckout extends Component {
  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnDeleteDiscountCode = this.handleOnDeleteDiscountCode.bind(this);
    this.onSubmitCoupon = this.onSubmitCoupon.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.onConfirmModal = this.onConfirmModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onPaymentEditAccept = this.onPaymentEditAccept.bind(this);
    this.onPaymentEditDecline = this.onPaymentEditDecline.bind(this);
    this.onChangeToPaymentPerInvoiceAccept = this.onChangeToPaymentPerInvoiceAccept.bind(this);
    this.onChangeToPaymentPerInvoiceDecline = this.onChangeToPaymentPerInvoiceDecline.bind(this);
    this.onPaymentUpdateToInvoice = this.onPaymentUpdateToInvoice.bind(this);
    this.state = {
      loading: true,
      isDeleting: false,
      checkingOut: false,
      addonsNotAllowed: [],
      showPaymentEditModal: false,
      showUpdatePaymentModal: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  async handleOnDeleteDiscountCode() {
    const { intl } = this.props;
    this.setState({ isDeleting: true });
    try {
      const planCartWithoutVoucher = await updatePlanCartParameters({ coupon_code: null });
      this.setState({ planCart: planCartWithoutVoucher, isDeleting: false });
    } catch (errors) {
      handleUnknownErrors(errors, intl.formatMessage({ id: 'api.error.unknown' }));
      this.setState({ isDeleting: false });
    }
  }

  async handleOnSubmit() {
    const { intl } = this.props;
    this.setState({ checkingOut: true });
    try {
      await upgradePlanCart();
      showSuccess(intl.formatMessage({ id: 'subscription_checkout.success_message' }));
      navigateTo('/subscriptions/connect');
    } catch (error) {
      if (apiErrorsContain(error, 'payment_failed')) {
        showError(intl.formatMessage({ id: 'api.error.payment_failed' }));
      } else {
        handleUnknownErrors(error, intl.formatMessage({ id: 'api.error.unknown' }));
      }
    }
  }

  onConfirmModal() {
    const { match } = this.props;
    this.setState({ showModal: false });
    navigateTo(`/subscriptions/connect/${match.params.id}/upgrade`);
  }

  onChangeToPaymentPerInvoiceAccept() {
    const { intl } = this.props;
    this.onChangeToPaymentPerInvoice()
      .catch((apiErrors) => {
        this.setState((prevState) => ({ planCart: prevState.oldPlanCart, fetching: false }));
        handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
      });
  }

  async onChangeToPaymentPerInvoice() {
    const { intl, match, backend } = this.props;
    // eslint-disable-next-line react/destructuring-assignment,react/no-access-state-in-setstate
    const oldPlanCart = this.state.planCart;
    this.setState({
      showUpdatePaymentModal: false, planCart: undefined, oldPlanCart, fetching: true,
    });
    await backend.patch(`/api_subscriptions/${match.params.id}/payment/to_invoice`);
    const planCart = await loadSubscriptionPlanCart();
    const subscription = await loadConnectSubscription(match.params.id);
    const subscriptionFromBackend = await loadCurrentSubscription(match.params.id, true);
    this.setState({
      planCart, subscriptionFromBackend, subscription, fetching: false,
    });
    showSuccess(intl.formatMessage({ id: 'payment_details_edit.change_to_invoice.success_message' }));
  }

  onPaymentEditDecline() {
    this.setState({ showPaymentEditModal: false });
  }

  onPaymentEditAccept() {
    const { match } = this.props;
    this.setState({ showPaymentEditModal: false });
    redirectTo(`/payment/update/checkout/${match.params.id}`);
  }

  onPaymentUpdateToInvoice() {
    this.setState({ showPaymentEditModal: false, showUpdatePaymentModal: true });
  }

  onCloseModal() {
    this.setState({ showModal: false });
  }

  onChangeToPaymentPerInvoiceDecline() {
    this.setState({ showUpdatePaymentModal: false });
  }

  async onSubmitCoupon(data, /* istanbul ignore next */ actions = {}) {
    const { intl } = this.props;
    try {
      const planCartWithVoucher = await updatePlanCartParameters({ coupon_code: data.couponCode });
      this.setState({ planCart: planCartWithVoucher });
    } catch (errors) {
      const formErrors = {};
      this.setState({ checkingOut: false });
      if (apiErrorsContain(errors, 'invalid_input', 'coupon_code')) {
        formErrors.couponCode = intl.formatMessage({ id: 'api.error.coupon_code.invalid_input' });
      } else {
        handleUnknownErrors(errors, intl.formatMessage({ id: 'api.error.unknown' }));
      }
      actions.setErrors(formErrors);
    }
    actions.setSubmitting(false);
  }

  async loadData() {
    const { match } = this.props;
    const planCart = await loadSubscriptionPlanCart();
    const subscription = await loadConnectSubscription(match.params.id);
    const subscriptionFromBackend = await loadCurrentSubscription(match.params.id, true, true);
    const addonsNotAllowed = addonIsOnBlacklist(planCart.addons, chooseRelevantCountryCode(subscription));
    this.setState({
      planCart, subscription, subscriptionFromBackend, loading: false, addonsNotAllowed, showModal: addonsNotAllowed.length > 0,
    });
  }

  validateForm(values) {
    const { intl } = this.props;
    const errors = {};
    /* istanbul ignore next */
    if (isEmpty(values.couponCode) || isEmpty(values.couponCode.trim())) {
      errors.couponCode = intl.formatMessage({ id: 'api.error.coupon_code.invalid_input' });
    }
    return errors;
  }

  renderForm(props) {
    const { intl } = this.props;
    const { planCart, isDeleting } = this.state;
    const { handleSubmit, isSubmitting, setStatus } = props;
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.form = props;

    /* istanbul ignore next */
    const beforeHandleSubmit = (event) => {
      /* istanbul ignore next */
      setStatus('submitted');
      /* istanbul ignore next */
      handleSubmit(event);
    };

    const deleteCouponButton = planCart && planCart.couponCode ? (
      <SubmitButton
        id="delete_coupon_code_button"
        fetching={isDeleting}
        hero={false}
        text={intl.formatMessage({ id: 'subscription_checkout.delete_button' })}
        onClick={this.handleOnDeleteDiscountCode}
      />
    ) : /* istanbul ignore next */ null;

    const enterCouponCode = planCart && !planCart.couponCode ? /* istanbul ignore next */ (
      <form onSubmit={beforeHandleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12">
            <TextInput
              {...props}
              id="coupon_code"
              name="couponCode"
              label={intl.formatMessage({ id: 'subscription_checkout.coupon_code' })}
            />
          </div>
        </div>
        <div className="btn-group">
          <SubmitButton
            id="apply_coupon_code_button"
            text={intl.formatMessage({ id: 'subscription_checkout.apply_button' })}
            hero={false}
            disabled={!props.dirty}
            fetching={isSubmitting}
          />
        </div>
      </form>
    ) : null;

    return (
      <div>
        {enterCouponCode}
        {deleteCouponButton}
      </div>
    );
  }

  render() {
    const {
      planCart, subscription, subscriptionFromBackend, loading, checkingOut, showModal, addonsNotAllowed, showPaymentEditModal, showUpdatePaymentModal, fetching,
    } = this.state;
    const { intl, match } = this.props;
    const errors = [];
    let paymentDetailsValid = null;
    let canUpgrade = null;

    if (subscriptionFromBackend) {
      paymentDetailsValid = (
        (subscriptionFromBackend.cardPaymentDetails && subscriptionFromBackend.cardPaymentDetails.valid) || (
          subscriptionFromBackend.invoicePaymentDetails
          && (subscriptionFromBackend.invoicePaymentDetails
            .filter((i) => i.status && (i.status === 'posted' || i.status === 'payment_due')).length === 0))

      );
      canUpgrade = (
        (subscriptionFromBackend.cardPaymentDetails && subscriptionFromBackend.cardPaymentDetails.valid) || (
          subscriptionFromBackend.invoicePaymentDetails)

      );
    }

    const canChangeToInvoice = subscription && (
      subscription.status === 'confirmed'
      || subscription.status === 'scheduled_update'
      || subscription.status === 'open');

    const paymentEditModal = subscriptionFromBackend && showPaymentEditModal ? (
      <SubscriptionPaymentEditModal
        onAccept={this.onPaymentEditAccept}
        onChange={this.onPaymentUpdateToInvoice}
        onDecline={this.onPaymentEditDecline}
        canChangeToInvoice={canChangeToInvoice}
        isCredit={subscriptionFromBackend.invoicePaymentDetails === null}
      />
    ) : null;

    const modalChangeToInvoice = subscriptionFromBackend ? (
      <ConfirmationModal
        id="modal-dialog-invoice"
        show={showUpdatePaymentModal}
        titleText={intl.formatMessage({ id: 'payment_details_edit.change_credit_to_invoice' })}
        messageText={intl.formatMessage({ id: 'payment_details_edit.change_to_invoice_description' })}
        onConfirm={this.onChangeToPaymentPerInvoiceAccept}
        onClose={this.onChangeToPaymentPerInvoiceDecline}
        intl={intl}
      />
    ) : null;

    const invoiceEstimateDetails = planCart && !loading ? (
      <div id="subscription-checkout-invoice-estimate" className="row">
        <div className="col-md-12">
          <ActionBar>
            <h2><FormattedMessage id="subscription_checkout.invoice_estimate_header" /></h2>
          </ActionBar>
          <SubscriptionInvoiceEstimate invoiceEstimate={planCart.invoiceEstimate} />
        </div>
      </div>
    ) : null;

    const couponCodeDetailsFormik = planCart && planCart.couponIsAllowed && !loading ? (
      <div className="row">
        <div className="col-md-4">
          <Formik
            onSubmit={this.onSubmitCoupon}
            render={this.renderForm}
            validate={this.validateForm}
          />
        </div>
      </div>
    ) : null;

    const subscriptionBillingDetails = planCart && !loading ? (
      <div id="subscription-billing-details" className="row">
        <div className="col-md-12">
          <ActionBar>
            <h2><FormattedMessage id="subscription_checkout.billing_details_header" /></h2>
            <ActionBarButtons>
              <EditActionButton disabled={!paymentDetailsValid} target={`/subscriptions/connect/${match.params.id}/edit/checkout`} />
            </ActionBarButtons>
          </ActionBar>
          <SubscriptionBillingDetails
            billingAddress={subscription.billingAddress}
            shippingAddress={subscription.shippingAddress}
            customerPurchaseOrder={subscription.customerPurchaseOrder}
          />
        </div>
      </div>
    ) : null;

    const paymentDetails = planCart && !loading ? (
      <div id="subscription-checkout-payment-details" className="row">
        <div className="col-md-12">
          <ActionBar>
            <h2><FormattedMessage id="subscription_checkout.payment_details_header" /></h2>
            <ActionBarButtons>
              <EditActionButton id="payment-method-edit" onClick={() => this.setState({ showPaymentEditModal: true })} disabled={checkingOut || !paymentDetailsValid} />
            </ActionBarButtons>
          </ActionBar>
          {paymentEditModal}
          <SubscriptionPaymentDetails
            intl={intl}
            cardPaymentDetails={subscriptionFromBackend.cardPaymentDetails}
            invoicePaymentDetails={subscriptionFromBackend.invoicePaymentDetails}
            onChangeToPaymentPerInvoice={this.onChangeToPaymentPerInvoiceAccept}
            canChangeToInvoice={canChangeToInvoice}
            source="checkout"
          />
        </div>
      </div>
    ) : null;

    const submitButton = planCart && !loading ? (
      <div>
        <div className="row">
          <div className="col-md-12 space-before">
            <div className="btn-group">
              <SubmitButton
                id="upgrade_button"
                fetching={checkingOut}
                intl={intl}
                disabled={checkingOut || !canUpgrade || addonsNotAllowed.length > 0}
                text={intl.formatMessage({ id: 'subscription_checkout.submit_button' })}
                onClick={this.handleOnSubmit}
              />
              <CancelButton intl={intl} id="cancel_button" disabled={checkingOut || !paymentDetailsValid} target={`/subscriptions/connect/${match.params.id}/upgrade`} />
            </div>
          </div>
        </div>
      </div>
    ) : null;

    const mtext = (
      <div>
        <FormattedMessage
          id="subscription_create.addon_country_modal_dialog.message"
          values={{
            ...htmlFormat,
            notAllowedAddons: addonsNotAllowed.join(', '),
            a: htmlLink({ href: CONFIGURATION.HELP_URL_AVAILABILITY, target: '_blank' }),
          }}
        />
      </div>
    );

    const addonModal = showModal && !loading ? (
      <ConfirmationModal
        id="addon-country-not-allowed-modal-dialog"
        show={showModal}
        intl={intl}
        titleText={intl.formatMessage({ id: 'subscription_create.addon_country_modal_dialog.title' })}
        messageText={mtext}
        buttonConfirmText={intl.formatMessage({ id: 'subscription_create.addon_country_modal_dialog.button_close' })}
        buttonCancelText={intl.formatMessage({ id: 'subscription_create.addon_country_modal_dialog.button_confirm' })}
        onConfirm={this.onConfirmModal}
        onClose={this.onCloseModal}
      />
    ) : null;

    return (
      <ApiErrorsHandler errors={errors} submitCallback={this.submitCallback}>
        <div id="subscription-checkout" className="container">
          <ActionBar>
            <h1><FormattedMessage id="subscription_checkout.header" /></h1>
          </ActionBar>
          <Loader loading={!planCart || fetching} />
          {addonModal}
          {invoiceEstimateDetails}
          {modalChangeToInvoice}
          {couponCodeDetailsFormik}
          {subscriptionBillingDetails}
          {paymentDetails}
          {submitButton}
        </div>
      </ApiErrorsHandler>
    );
  }
}

ConnectSubscriptionCheckout.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  backend: backendShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      edgeDeviceId: PropTypes.string,
    }),
  }),
  dispatch: PropTypes.func,
  planCart: planCartShape,
  updating: PropTypes.bool,
  checkedout: PropTypes.bool,
  handleSubmit: PropTypes.func,
  intl: intlShape,
  errors: errorsShape,
  couponCode: PropTypes.string,
};

ConnectSubscriptionCheckout.defaultProps = {
  match: undefined,
  dispatch: undefined,
  planCart: undefined,
  updating: undefined,
  checkedout: undefined,
  handleSubmit: undefined,
  intl: undefined,
  errors: undefined,
  couponCode: undefined,
};

export default withBackend(injectIntl(ConnectSubscriptionCheckout));
