import Rules from './Rules';

/* node types which can't be created or edited in the normal way */
const SPECIAL_NODE_TYPE_CODES = ['inventory', 'silo', 'tank', 'container'];

class NodeTypeRules extends Rules {
  static rules = {};

  static find = (node) => {
    const nodeTypeCode = node?.typeParentCode || node?.typeCode;
    if (NodeTypeRules.rules[nodeTypeCode]) {
      return new NodeTypeRules.rules[nodeTypeCode](node);
    }
    return new NodeTypeRules(node);
  };

  constructor(node) {
    super();
    this.node = node;
  }

  isSpecialNode() {
    return !this.node.tenantPublic && (NodeTypeRules.specialNodeTypeCodes.includes(this.node.typeParentCode) || NodeTypeRules.specialNodeTypeCodes.includes(this.node.typeCode));
  }

  icon() {
    if (this.isSpecialNode()) {
      return 'lcm-iot-icon-inventory-rounded';
    }
    return 'icon-eh-folder';
  }

  iconPrimary() {
    return (!this.isSpecialNode());
  }

  editPath() {
    return `/nodes/${this.node.id}/edit`;
  }

  detailsPath() {
    return `/nodes/${this.node.id}`;
  }

  deleteModalTitleKey() {
    return 'node.actions.delete.modal_title';
  }

  unassignModalTitleKey() {
    return 'node.actions.unassign.confirmation.title';
  }

  deleteModalMessageKey() {
    return 'node.actions.delete.modal_message';
  }

  unassignModalMessageKey() {
    return 'node.actions.unassign.confirmation.message';
  }

  deleteNotificationKey() {
    return 'node.actions.delete.notification';
  }

  assignNotificationKey() {
    return 'node.actions.assign.notification';
  }

  unassignNotificationKey() {
    return 'node.actions.unassign.notification';
  }

  moveNotificationKey() {
    return 'node.actions.move.success_notification';
  }

  moveInsufficientPermissionsNotificationKey() {
    return 'node.actions.move.insufficient_permissions';
  }

  deleteAssignedRestrictionNotificationKey() {
    return 'api.error.node.assigned_restriction';
  }

  status() {
    return 'node';
  }

  editable() {
    return !this.isSpecialNode();
  }
}

NodeTypeRules.register = (nodeTypeCode, clazz) => {
  NodeTypeRules.rules[nodeTypeCode] = clazz;
};

NodeTypeRules.specialNodeTypeCodes = SPECIAL_NODE_TYPE_CODES;

export default NodeTypeRules;
