import PropTypes from 'prop-types';
import { attachmentShape } from './attachmentsShape';
import { documentCategoryShape } from './documentCategoriesShape';

export const documentShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  number: PropTypes.string,
  statusId: PropTypes.number,
  classificationId: PropTypes.number,
  attachments: PropTypes.arrayOf(attachmentShape),
  categories: PropTypes.arrayOf(documentCategoryShape),
  document_version: PropTypes.string,
  source: PropTypes.string,
  status: PropTypes.shape({
    id: PropTypes.number,
  }),
  classification: PropTypes.shape({
    id: PropTypes.number,
  }),
  tenant: PropTypes.shape({
    id: PropTypes.number,
    public: PropTypes.bool,
    name: PropTypes.string,
  }),
  defaultAttachment: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    file_name: PropTypes.string,
    content_date: PropTypes.string,
    created_at: PropTypes.string,
    download_href: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    match: PropTypes.object,
  }),
});

export const documentsShape = PropTypes.arrayOf(documentShape);
