import React, { Component } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import {
  isEmpty,
  TextInput,
  TextArea,
  scrollToTop,
  intlShape,
  withIntl,
} from 'lcm-iot-commons';

export class ClientApplicationForm extends Component {
  constructor() {
    super();
    /* istanbul ignore next */
    this.validateForm = this.validateForm.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.setErrors = this.setErrors.bind(this);
    this.setSubmitting = this.setSubmitting.bind(this);
    this.submit = this.submit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    // currently not needed, but formik wants it
    this.submit();
  }

  /* istanbul ignore next */
  setErrors(errors) {
    // called in parent components
    this.form.setErrors(errors);
  }

  /* istanbul ignore next */
  setSubmitting(value) {
    // called in parent components
    this.form.setSubmitting(value);
  }

  validateForm(values) {
    const { intl, takenNames } = this.props;
    const errors = {};
    scrollToTop();
    if (isEmpty(values.name) || isEmpty(values.name.trim())) {
      errors.name = intl.t`validation.name.mandatory`;
    } else if (values.name.length > 60) {
      errors.name = intl.formatMessage({ id: 'validation.name.too_long' }, { characters: 60 });
    } else if (takenNames.includes(values.name)) {
      errors.name = intl.t`api.error.client_application.taken`;
    }
    return errors;
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  submit() {
    if (this.form.isSubmitting) {
      return true;
    }
    this.form.setStatus('submitted');
    this.form.handleSubmit({});
    return Object.keys(this.validateForm(this.form.values)).length === 0;
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  values() {
    // called in parent components
    return this.form.values;
  }

  renderForm(props) {
    const { intl, showDebugButtons } = this.props;
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.form = props;

    return (
      <form onSubmit={this.onSubmit} id="client-application-form" noValidate>
        <TextInput
          {...props}
          id="client-application-name"
          name="name"
          label={intl.t`label.name`}
          required
          autoFocus
        />
        <TextArea
          {...props}
          id="client-application-description"
          name="description"
          label={intl.t`label.description`}
        />
        <TextArea
          {...props}
          id="redirect-uri-description"
          name="redirectURIs"
          label={intl.t`label.redirect_uris`}
        />
        {showDebugButtons && (
          <button type="button">
            {intl.t`button.submit`}
          </button>
        )}
        {showDebugButtons && (
          <textarea
            data-testid="debug"
            defaultValue={JSON.stringify(this.values(), null, 2)}
          />
        )}
      </form>
    );
  }

  render() {
    const { initialValues } = this.props;
    return (
      <Formik
        initialValues={initialValues}
        validate={this.validateForm}
        onSubmit={this.onSubmit}
        render={this.renderForm}
      />
    );
  }
}

ClientApplicationForm.propTypes = {
  intl: intlShape,
  /* eslint-disable react/forbid-prop-types */
  initialValues: PropTypes.object,
  showDebugButtons: PropTypes.bool, // only used in tests
  takenNames: PropTypes.arrayOf(PropTypes.string),
};

ClientApplicationForm.defaultProps = {
  intl: undefined,
  /* eslint-disable react/forbid-prop-types */
  initialValues: undefined,
  takenNames: [],
  showDebugButtons: false,
};

export default withIntl(ClientApplicationForm);
