import { checkNested } from './generalUtils';
import { isNothing } from './langUtils';

export const MAX_AMOUNT = 999;

export function addonIsOnBlacklist(addons, countryCode) {
  const addonsNotAllowed = [];
  if (addons) {
    addons.forEach((addon) => {
      if (addon.amount > 0 && addon.planVariantAddon.countryBlacklist.includes(countryCode)) {
        addonsNotAllowed.push(addon.planVariantAddon.name);
      }
    });
  }
  return addonsNotAllowed;
}

export function chooseRelevantCountryCode(object, shippingDifferent = true) {
  let relevantCountryCode = null;
  if (checkNested(object.billingAddress, 'countryCode')) {
    relevantCountryCode = object.billingAddress.countryCode;
  } else if (checkNested(object.billingAddress, 'country', 'code')) {
    relevantCountryCode = object.billingAddress.country.code;
  }
  if (shippingDifferent) {
    if (checkNested(object.shippingAddress, 'countryCode')) {
      relevantCountryCode = object.shippingAddress.countryCode;
    } else if (checkNested(object.shippingAddress, 'country', 'code')) {
      relevantCountryCode = object.shippingAddress.country.code;
    }
  }
  return relevantCountryCode;
}

export function checkAddonBlacklist(planCart, shippingDifferent = true) {
  let addonsNotAllowed = [];
  const relevantCountryCode = chooseRelevantCountryCode(planCart, shippingDifferent);
  if (relevantCountryCode && planCart.addons && planCart.addons.length > 0) {
    addonsNotAllowed = addonIsOnBlacklist(planCart.addons, relevantCountryCode);
  }
  return addonsNotAllowed;
}

export function addonVisibleForUserrole(userRoles, addonRoles) {
  if (isNothing(addonRoles) || addonRoles.length === 0) return true;
  if (isNothing(userRoles) || userRoles.length === 0) return false;

  let hasRole = false;
  userRoles.forEach((role) => {
    if (addonRoles.includes(role.name)) {
      hasRole = true;
    }
  });
  return hasRole;
}

export function addonMaxAmount(addon, planVariant) {
  if (planVariant?.assets > 0) {
    if (addon?.id?.endsWith('-connected-asset')) {
      return planVariant.assets;
    }
    if (addon?.id?.endsWith('heartbeat-verification')) {
      return planVariant.assets;
    }
  }

  if (addon?.id?.includes('-opc-ua-server')) {
    return 1;
  }
  if (addon?.max_amount && planVariant?.name?.toLowerCase() in addon.max_amount) {
    return addon.max_amount[planVariant.name.toLowerCase()];
  }
  return MAX_AMOUNT;
}
