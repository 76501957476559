import { isNotEmpty, isNumber } from '../utils/langUtils';

export function isRetina() {
  return window.devicePixelRatio > 1;
}

export function extractBestPicture(pictures) {
  if (isNotEmpty(pictures)) {
    pictures.sort((a, b) => b.width - a.width);
    const bestSize = isRetina() ? 1000 : 500;
    let bestPicture;

    pictures.forEach((picture) => {
      if (!bestPicture || picture.width >= bestSize) {
        bestPicture = picture;
      }
    });
    return bestPicture;
  }
  return null;
}

export function extractBestPictureUrl(pictures) {
  const bestPicture = extractBestPicture(pictures);
  return bestPicture ? bestPicture.download_href : null;
}

export function extractPicture(picture) {
  const p = {
    id: picture.id,
    url: picture.download_href,
    width: picture.width,
    height: picture.height,
  };
  if (isNotEmpty(picture.type)) {
    p.type = picture.type;
  }
  if (isNumber(picture.file_size)) {
    p.size = picture.file_size;
  }
  return p;
}

export function extractPictures(pictures) {
  return pictures?.pictures?.map((p) => extractPicture(p));
}

export function extractThumbnailPicture(pictures) {
  if (isNotEmpty(pictures)) {
    pictures.sort((a, b) => b.width - a.width);
    const bestSize = isRetina() ? 100 : 50;
    let bestPicture;
    pictures.forEach((picture) => {
      if (!bestPicture || picture.width >= bestSize) {
        bestPicture = picture;
      }
    });

    return bestPicture;
  }
  return null;
}

export function extractThumbnailPictureUrl(pictures) {
  let pictureUrl = null;
  const bestPicture = extractThumbnailPicture(pictures);
  if (bestPicture) {
    pictureUrl = bestPicture.download_href ? bestPicture.download_href : bestPicture.url;
  }
  return pictureUrl;
}
