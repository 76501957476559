/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  intlShape,
  ActionBar,
  ActionBarButtons,
  isSomething,
  CreateActionButton,
  Loader,
  navigateTo,
  handleUnknownErrors,
  isNotFoundError,
  url,
} from 'lcm-iot-commons';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';

import UserGroupItem from './UserGroupItem';
import { loadUserGroups, loadNextUserGroups } from '../../api/userGroupApi';

class UserGroups extends Component {
  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.loadUserGroups = this.loadUserGroups.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.doHandleLoadMoreUserGroups = this.doHandleLoadMoreUserGroups.bind(this);
    this.handleOnUserGroupRemoved = this.handleOnUserGroupRemoved.bind(this);
    this.state = {
      fetching: true,
      usergroups: [],
      initialized: false,
      totalCount: 0,
      nextPageUrl: undefined,
    };
  }

  componentDidMount() {
    this.loadUserGroups();
  }

  async loadUserGroups() {
    const { intl } = this.props;
    this.doHandleLoadMoreUserGroups().catch((apiErrors) => {
      if (isNotFoundError(apiErrors)) {
        navigateTo('/404');
      } else {
        handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
      }
    });
  }

  handleLoadMore() {
    const { intl } = this.props;
    this.doHandleLoadMoreUserGroups().catch((apiErrors) => {
      handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
    });
  }

  handleOnUserGroupRemoved(usergroup) {
    const { usergroups } = this.state;
    this.setState({ usergroups: usergroups.filter((i) => i !== usergroup) });
    this.handleLoadMore();
  }

  async doHandleLoadMoreUserGroups() {
    const { usergroups, nextPageUrl } = this.state;
    this.setState({ fetching: true });

    let userGroupsResult;

    if (nextPageUrl) {
      userGroupsResult = await loadNextUserGroups(nextPageUrl);
      userGroupsResult.usergroups = usergroups.concat(userGroupsResult.usergroups);
    } else {
      const filter = { order_by: 'name' };
      userGroupsResult = await (loadUserGroups(filter));
    }

    this.setState({
      usergroups: userGroupsResult.usergroups,
      nextPageUrl: userGroupsResult.nextPageUrl,
      totalCount: userGroupsResult.totalCount,
      fetching: false,
      initialized: true,
    });
  }

  renderUserGroups(usergroups) {
    return usergroups.map((item) => (<UserGroupItem key={item.id} usergroup={item} onUserGroupRemoved={this.handleOnUserGroupRemoved} />));
  }

  render() {
    const {
      usergroups, initialized, totalCount, nextPageUrl, fetching,
    } = this.state;
    const userGroupsCount = totalCount > 0 ? totalCount : null;
    const badge = fetching ? (
      <span className="loading">
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </span>
    ) : userGroupsCount;

    const actionBarButtons = (
      <ActionBarButtons>
        <div>
          <CreateActionButton id="create-usergroup-button" disabled={!initialized} target="/usergroups/create" />
        </div>
      </ActionBarButtons>
    );

    const noUserGroupsFound = initialized && totalCount === 0 && !fetching ? (
      <div id="no-usergroups-found" className="clue">
        <div className="clue-header"><FormattedMessage id="usergroups.no_usergroups_found" /></div>
        <div className="clue-details"><FormattedMessage id="usergroups.no_usergroups_found.ask_create" /></div>
        <Link className="btn btn-primary" to={url('/usergroups/create')}><FormattedMessage id="button.create_usergroup" /></Link>
      </div>
    ) : null;

    return (
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <ActionBar>
              <h1 id="usergroups-header">
                <FormattedMessage id="usergroups.header" />
                <span id="usergroups-count" className="badge">{badge}</span>
              </h1>
              {actionBarButtons}
            </ActionBar>
            <ul id="usergroups-list" className="list">
              <InfiniteScroll
                initialLoad={false}
                loadMore={this.handleLoadMore}
                hasMore={!fetching && isSomething(nextPageUrl)}
              >
                {this.renderUserGroups(usergroups)}
              </InfiniteScroll>
            </ul>
            {noUserGroupsFound}
            <Loader loading={fetching} />
          </div>
        </div>
      </div>
    );
  }
}

UserGroups.propTypes = {
  intl: intlShape,
};

export default injectIntl(UserGroups);
