import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import reduxCatch from 'redux-catch';
import { handleUnknownErrors } from 'lcm-iot-commons';
import rootReducer from '../reducers';

const logger = createLogger();
const createStoreWithMiddleware = applyMiddleware(reduxCatch(handleUnknownErrors), thunk, promise, logger)(createStore);

export default function configureStore(initialState) {
  const store = createStoreWithMiddleware(rootReducer, initialState);
  return store;
}
