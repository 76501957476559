import { InstrumentationLabels } from '../constants/Instrumentation';
import SpecificationKeys from '../constants/SpecificationKeys';

export function packInstrumentationSpecifications(instrValues) {
  const instrumentationSpecification = {
    [InstrumentationLabels.ENVIRONMENTAL_CONDITION]: {
      value: instrValues.environmentalCondition ? 'heavy' : 'normal',
    },
    [InstrumentationLabels.SPECIAL_DEMANDS]: {
      value: instrValues.specialDemands ? 'true' : 'false',
    },
    [InstrumentationLabels.CRITICALITY_QUALITY]: {
      value: instrValues.criticalityQuality ? 'true' : 'false',
    },
    [InstrumentationLabels.CRITICALITY_ENVIRONMENT]: {
      value: instrValues.criticalityEnvironment ? 'true' : 'false',
    },
    [InstrumentationLabels.CRITICALITY_SAFETY]: {
      value: instrValues.criticalitySafety ? 'true' : 'false',
    },
  };
  if (instrValues.unit) {
    instrumentationSpecification[[SpecificationKeys.FullEmptyConfiguration.UNIT]] = {
      value: instrValues.unit.id,
    };
  }
  if (instrValues.empty) {
    instrumentationSpecification[[SpecificationKeys.FullEmptyConfiguration.EMPTY]] = {
      value: instrValues.empty,
    };
  }
  if (instrValues.full) {
    instrumentationSpecification[[SpecificationKeys.FullEmptyConfiguration.FULL]] = {
      value: instrValues.full,
    };
  }
  if (instrValues.blockDistance) {
    instrumentationSpecification[[SpecificationKeys.FullEmptyConfiguration.BLOCK_DISTANCE]] = {
      value: instrValues.blockDistance,
    };
  }
  if (instrValues.sensitivity) {
    instrumentationSpecification[[SpecificationKeys.FullEmptyConfiguration.SENSITIVITY]] = {
      value: ((sensitivity) => {
        switch (sensitivity) {
          case 'low':
            return 946;
          case 'medium':
            return 616;
          case 'high':
            return 947;
          default:
            return 616;
        }
      })(instrValues.sensitivity),
    };
  }
  if (instrValues.mediumType) {
    instrumentationSpecification[[SpecificationKeys.GeneralSettings.MEDIUM_TYPE]] = {
      value: instrValues.mediumType,
    };
  }
  return instrumentationSpecification;
}
