/**
 * TODO: refactor to work with providing searchParams and setSearchParams each time
 * Custom hook for managing URL search parameters state.
 *
 * @param {string} key - The key of the URL search parameter to manage.
 * @param {URLSearchParams} searchParams - The current URL search parameters.
 * @param {Function} setSearchParams - Function to update the URL search parameters.
 * @returns {[string, Function]} - The current state and a function to update the state.
 *
 * @example
 * const [state, setState] = useUrlState('filter', searchParams, setSearchParams);
 *
 * // Get the current state
 * console.log(state); // Output: decoded value of 'filter' search param or ''
 *
 * // Set a new state
 * setState('newFilterValue');
 *
 * // Remove the state
 * setState(null); / setState(undefined);
 */
export const useUrlState = (key, searchParams, setSearchParams) => {
  const state = decodeURIComponent(searchParams.get(key) || '');

  const setState = (value) => {
    setSearchParams((prevSearchParams) => {
      if (value === undefined || value === null) prevSearchParams.delete(key);
      else prevSearchParams.set(key, encodeURIComponent(value));
      return prevSearchParams;
    }, { replace: true });
  };

  return [state, setState];
};
