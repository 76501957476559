import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { MenuItem } from 'react-bootstrap';
import {
  ItemMenu,
  ListItem,
  ConfirmationModal,
  withApi,
  withBrowser,
  withNotifier,
  apiShape,
  browserShape,
  productShape,
  intlShape,
  notifierShape,
  statusForProductStatus,
} from 'lcm-iot-commons';

export function ProductItem({
  product, api, browser, intl, notifier, removeProduct, showTenant,
}) {
  const [deleting, setDeleting] = useState(false);

  const handleOnClick = () => {
    browser.navigateTo(`/products/${product.id}`);
  };

  const handleOnEditClick = () => {
    browser.navigateTo(`/products/${product.id}/edit`);
  };

  const handleOnDeleteClick = () => {
    setDeleting(true);
  };

  const handleClose = () => {
    setDeleting(false);
  };

  const handleConfirm = async () => {
    try {
      await api.delete(`/products/${product.id}`);
      setDeleting(false);
      if (removeProduct) await removeProduct(product.id);
      notifier.showSuccess(intl.formatMessage({ id: 'product.actions.delete.notification' }));
    } catch (error) {
      setDeleting(false);
      notifier.showError(api.translateError(error));
    }
  };

  const modalDeleting = deleting ? (
    <ConfirmationModal
      id="product-item-confirm"
      show
      titleText={intl.formatMessage({ id: 'product.actions.delete.modal_title' })}
      messageText={intl.formatMessage({ id: 'product.actions.delete.modal_message' })}
      onConfirm={() => handleConfirm()}
      onClose={() => handleClose()}
    />
  ) : null;

  const actions = (
    <div id="product-action-items">
      <ItemMenu>
        <MenuItem id="item-edit" onSelect={() => handleOnEditClick()} disabled={false}>
          {intl.formatMessage({ id: 'button.edit' })}
        </MenuItem>
        <MenuItem id="item-delete" onSelect={() => handleOnDeleteClick()} disabled={false}>
          {intl.formatMessage({ id: 'button.delete' })}
        </MenuItem>
      </ItemMenu>
      {modalDeleting}
    </div>
  );

  const name = product.name ?? intl.formatMessage({ id: 'product.no_name' });
  const tenant = (
    <span className="additional-info">
      (
      {product.tenant?.name}
      )
    </span>
  );

  const manufacturer = (
    <>
      {product.manufacturer?.name}
      {showTenant ? ' ' : ''}
      {showTenant ? tenant : ''}
    </>
  );

  return (
    <ListItem
      id={`product-item-${product.id}`}
      icon="lcm-iot-icon-product"
      actions={actions}
      title={name}
      onClick={() => handleOnClick()}
      description={product.product_code}
      value={manufacturer}
      status={statusForProductStatus(product?.status?.code ?? 'undefined')}
    />
  );
}

ProductItem.propTypes = {
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  showTenant: PropTypes.bool,
  removeProduct: PropTypes.func,
  product: productShape.isRequired,
};

ProductItem.defaultProps = {
  showTenant: false,
  removeProduct: null,
};

export default injectIntl(withBrowser(withApi(withNotifier(ProductItem))));
