import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash-es';
import {
  Row,
  Column,
  Container,
  Form,
  intlShape,
  BadRequestError,
  CancelButton,
  SubmitButton,
  TextInput,
  TextArea,
  ActionBar,
  withAccessRights,
  withApi,
  apiShape,
  withNotifier,
  notifierShape,
  withBrowser,
  browserShape,
} from 'lcm-iot-commons';
import Loader from 'lcm-iot-commons/client/lib/components/Loader';

export function TenantEdit({
  api, browser, notifier, intl, match,
}) {
  const [tenant, setTenant] = React.useState();
  const { id } = match.params;

  React.useEffect(() => {
    const loadData = async () => {
      const tenantResponse = await api.get(`/tenants/${id}`);
      setTenant(tenantResponse);
    };
    loadData().catch((error) => { notifier.showError(api.translateError(error)); });
  }, []);

  const onSubmit = async (values, actions) => {
    try {
      await api.patch(`/tenants/${id}`, { name: values.name, description: values.description });
      notifier.showSuccess(intl.formatMessage({ id: 'tenant_edit.success_notification' }));
      browser.navigateTo(`/tenants/${id}`);
    } catch (error) {
      const formErrors = {};
      if (error instanceof BadRequestError && error.contains('taken')) {
        formErrors.name = intl.formatMessage({ id: 'api.error.tenant.taken' });
      }
      if (isEmpty(formErrors)) {
        notifier.showError(api.translateError(error));
      }
      actions.setErrors(formErrors);
      actions.setSubmitting(false);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (isEmpty(values.name?.trim())) {
      errors.name = intl.formatMessage({ id: 'validation.name.mandatory' });
    }
    if (values.name?.length > 60) {
      errors.name = intl.formatMessage({ id: 'validation.name.too_long' }, { characters: 60 });
    }
    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;
    return (
      <Form {...formProps}>
        <Row>
          <Column>
            <TextInput
              {...formProps}
              id="tenant-name"
              name="name"
              label={intl.formatMessage({ id: 'label.name' })}
              required
            />
            <TextArea
              {...formProps}
              id="tenant-description"
              name="description"
              label={intl.formatMessage({ id: 'label.description' })}
            />
          </Column>
        </Row>
        <div className="btn-group">
          <SubmitButton id="edit-tenant-submit" fetching={isSubmitting} disabled={!formProps.dirty} />
          <CancelButton id="edit-tenant-cancel" disabled={isSubmitting} />
        </div>
      </Form>
    );
  };

  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <h1 id="edit-tenant-header">{intl.formatMessage({ id: 'tenant_edit.header' }) }</h1>
          </ActionBar>
        </Column>
      </Row>
      <Row>
        <Column>
          <h2>
            {intl.formatMessage({ id: 'tenant_edit.details' })}
          </h2>
          { tenant && (
          <Formik
            validate={validate}
            onSubmit={onSubmit}
            render={renderForm}
            initialValues={{ name: tenant.name, description: tenant.description }}
          />
          )}
        </Column>
      </Row>
      <Row>
        <Column>
          <Loader loading={!tenant} />
        </Column>
      </Row>
    </Container>
  );
}

TenantEdit.propTypes = {
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  notifier: notifierShape.isRequired,
  intl: intlShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }).isRequired,
};

export default withBrowser(withNotifier(withApi((withAccessRights(injectIntl(TenantEdit), 'Tenant')))));
