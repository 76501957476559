import React from 'react';
import { MenuItem, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { withIntl } from '../../wrappers';
import {
  intlShape,
  documentShape,
} from '../../shapes';
import {
  getNativeLanguageName,
  url,
  isNothing,
  isNotEmpty,
  iconForFileCategory,
  selectAttachment,
  formatDate, isEmptyObject,
} from '../../utils';
import ItemMenu from '../ItemMenu';
import Highlight from '../Search/Highlight';
import CustomOverlayTrigger from '../CustomOverlayTrigger';

export function ProductDocumentItem({
  intl, document, withCreate, canCreate, productId,
}) {
  if (isEmptyObject(document)) return null;
  const isEndress = document?.source === 'endress';
  const category = document?.categories?.[0]?.name ? document?.categories?.[0]?.name : intl.formatMessage({ id: 'category.without_category' });
  const shownAttachment = isNothing(document.attachments) ? null : selectAttachment(document.attachments, intl);
  const contentDate = shownAttachment && shownAttachment.content_date ? formatDate(shownAttachment.content_date, intl) : null;
  const contentVersion = shownAttachment && shownAttachment.content_version ? shownAttachment.content_version : null;
  const model = 'products';

  const getLanguageName = (language) => {
    const name = getNativeLanguageName(language);
    return name || language;
  };
  const downloadUrl = (attachment) => {
    if (isNothing(attachment)) return null;
    return attachment.type === 'link' ? attachment.download_href : url(`/download/attachments/${attachment.id}`);
  };

  const attachmentMenuItems = () => {
    if (isNothing(document.attachments)) return [];
    const items = [];
    document.attachments.forEach((attachment) => {
      if (attachment.languages?.length > 0 && Array.isArray(attachment.languages)) {
        attachment.languages.forEach((language) => {
          items.push({ name: getLanguageName(language), attachment });
        });
      } else {
        items.push({ name: attachment.file_name, attachment });
      }
    });
    return items.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  };

  const menuItems = attachmentMenuItems();

  const renderTooltip = (
    <Tooltip id="tooltip">
      {intl.formatMessage({ id: 'document_list.products.official_document' })}
    </Tooltip>
  );

  const renderEhDocument = isEndress ? (
    <CustomOverlayTrigger placement="right" overlay={renderTooltip}>
      <span className="icon icon-eh-check2 text-info" />
    </CustomOverlayTrigger>
  ) : null;

  const renderFileIcon = () => {
    const code = isNotEmpty(document?.categories) ? document?.categories[0]?.parent?.code : null;
    const icon = code ? iconForFileCategory(code) : 'lcm-iot-icon-other';
    const ehDocument = isEndress ? ' eh-document' : '';
    return (<span className={`icon-primary ${icon}${ehDocument}`} />);
  };

  const addAttachmentMenuItem = withCreate && document ? (
    <MenuItem id="add-attachment-to-file" href={url(`/${model}/${productId}/documents/${document.id}/attachments/add`)} disabled={!canCreate}>
      <FormattedMessage id="button.add_attachment" />
    </MenuItem>
  ) : null;

  const renderItemMenu = (
    <ItemMenu>
      {addAttachmentMenuItem}
      <MenuItem disabled>
        <FormattedMessage id="button.download" />
        :
      </MenuItem>
      {
        menuItems.map((item) => (
          <MenuItem href={downloadUrl(item.attachment)} target="_blank" rel="noopener noreferrer" key={menuItems.indexOf(item)}>
            {item.name}
          </MenuItem>
        ))
      }
    </ItemMenu>
  );

  /* eslint-disable camelcase */
  return (
    <li className="document-list-item list-item">
      <div className="list-item-image">
        {
          document?.attachments?.length > 0 ? (
            <a href={downloadUrl(shownAttachment)} target="_blank" rel="noopener noreferrer">
              {renderFileIcon()}
            </a>
          ) : (
            renderFileIcon()
          )
        }
      </div>
      <div className="list-item-content">
        <a href={downloadUrl(shownAttachment)} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
          <div className="list-item-header">
            <Highlight string={document.name} match={document.match} path="name" />
            {isEndress ? ' ' : null}
            {renderEhDocument}
          </div>
          <div className="list-item-subheader">
            <span className="list-item-category">
              <Highlight string={category} match={document.match} path="categories.name" />
            </span>
            {contentDate ? ' - ' : null}
            <span className="list-item-content-date">
              <Highlight string={contentDate || ''} match={document.match} path="defaultAttachment.content_date" />
            </span>
            {contentVersion ? ' (' : null}
            <span className="list-item-content-version">
              <Highlight string={contentVersion || ''} match={document.match} path="defaultAttachment.content_version" />
            </span>
            {contentVersion ? ')' : null}
          </div>
          <div className="list-item-file-name">
            <Highlight string={shownAttachment?.file_name || downloadUrl(shownAttachment)} match={shownAttachment?.match} path="attachments.file_name" />
          </div>
        </a>
      </div>
      <div id="item-menu" className="list-item-actions">
        {renderItemMenu}
      </div>
    </li>
  );
}

ProductDocumentItem.propTypes = {
  document: documentShape.isRequired,
  intl: intlShape.isRequired,
  withCreate: PropTypes.bool,
  canCreate: PropTypes.bool,
  productId: PropTypes.number,
};

ProductDocumentItem.defaultProps = {
  withCreate: false,
  canCreate: false,
  productId: undefined,
};

export default withIntl(ProductDocumentItem);
