import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import {
  withBrowser,
  browserShape,
  intlShape,
  formatDate,
  ActionBar,
} from 'lcm-iot-commons';
import { getUpdateScheduleStatusIcon } from '../../utils/edgeDeviceUpdateSchedulesUtils';

export function UpdateSchedules({
  intl,
  browser,
  edgeDevice,
  updateSchedules,
}) {
  return (
    <div id="edge-device-update-schedules">
      <ActionBar>
        <h2 id="update-schedules-header">{intl.formatMessage({ id: 'update_schedules.header' })}</h2>
      </ActionBar>
      <table className="update-schedule-table">
        <thead className="update-schedule-table-header">
          <tr>
            <th>{intl.formatMessage({ id: 'update_schedules.software_version' })}</th>
            <th>{intl.formatMessage({ id: 'update_schedules.status' })}</th>
            <th>{intl.formatMessage({ id: 'update_schedules.installed_at' })}</th>
          </tr>
        </thead>
        <tbody className="update-schedule-table-body">
          {updateSchedules.map((updateSchedule, index) => (
            <tr
              id={`update-schedules-list-item-${index}`}
              key={`update-schedules-list-item-${updateSchedule?.updated_at}`}
              className={`${
                updateSchedule?.status?.code === 'successful'
                  && edgeDevice?.software_version?.version_name === updateSchedule?.software_version?.version_name
                  ? 'update-schedule-current'
                  : ''
              }`}
              onClick={() => browser.navigateTo(`/edge_devices/${edgeDevice.id}/update_schedules/${updateSchedule.id}`)}
            >
              <td id={`update-schedules-list-item-${index}-version_name`}>{updateSchedule?.software_version?.version_name}</td>
              <td id={`update-schedules-list-item-${index}-status`}>
                <span className={`update-schedules-icon ${getUpdateScheduleStatusIcon(updateSchedule?.status?.code)}`}>{updateSchedule?.status?.name}</span>
              </td>
              <td id={`update-schedules-list-item-${index}-updated_at`}>{updateSchedule?.updated_at ? formatDate(updateSchedule?.updated_at, intl) : '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

UpdateSchedules.propTypes = {
  updateSchedules: PropTypes.arrayOf(
    PropTypes.shape(
      {
        id: PropTypes.number,
        edge_device: PropTypes.shape({
          id: PropTypes.number,
          href: PropTypes.string,
        }),
        software_version: PropTypes.shape({
          id: PropTypes.number,
          href: PropTypes.string,
          version_name: PropTypes.string,
          ethernetip_supported: PropTypes.bool,
          status: PropTypes.shape({
            id: PropTypes.number,
            href: PropTypes.string,
          }),
          edge_device_type: PropTypes.shape({
            id: PropTypes.number,
            href: PropTypes.string,
          }),
          links: PropTypes.shape({
            edge_device_applications: PropTypes.shape({
              href: PropTypes.string,
            }),
            software_images: PropTypes.shape({
              href: PropTypes.string,
            }),
            edge_device_features: PropTypes.shape({
              href: PropTypes.string,
            }),
            edge_device_types: PropTypes.shape({
              href: PropTypes.string,
            }),
          }),
        }),
        status: PropTypes.shape({
          id: PropTypes.number,
          href: PropTypes.string,
          code: PropTypes.string,
          name: PropTypes.string,
        }),
      },
    ),
  ).isRequired,
  browser: browserShape.isRequired,
  intl: intlShape.isRequired,
  edgeDevice: PropTypes.shape({
    id: PropTypes.number,
    lastSeenAt: PropTypes.number,
    software_version: PropTypes.shape({
      version_name: PropTypes.string,
    }),
  }).isRequired,
};

export default
injectIntl(withBrowser(UpdateSchedules, 'EDM::EdgeDevice'));
