import { extractAddress } from './addressExtractor';
import { extractPlanVariant } from './planVariantsExtractor';
import { extractPlanVariantAddon } from './planVariantAddonsExtractor';
import { extractInvoiceEstimate } from './invoiceEstimateExtractor';
import { extractCardPaymentDetails } from './cardPaymentDetailsExtractor';

function extractPlanCartAddon(rawPlanCartAddon) {
  return {
    planVariantAddon: extractPlanVariantAddon(rawPlanCartAddon.plan_variant_addon),
    amount: rawPlanCartAddon.amount ? rawPlanCartAddon.amount : 0,
  };
}

export function extractPlanCart(rawPlanCart) {
  return {
    planVariant: rawPlanCart.plan_variant ? extractPlanVariant(rawPlanCart.plan_variant) : null,
    addons: rawPlanCart.addons ? rawPlanCart.addons.map((rawAddon) => extractPlanCartAddon(rawAddon)) : null,
    customerPurchaseOrder: rawPlanCart.customer_purchase_order,
    checkoutUrl: rawPlanCart.checkout_url,
    checkoutType: rawPlanCart.checkout_type,
    trialAllowed: rawPlanCart.trial_allowed,
    couponCode: rawPlanCart.coupon_code ? rawPlanCart.coupon_code : null,
    couponIsAllowed: rawPlanCart.coupon_is_allowed,
    autoCollection: rawPlanCart.auto_collection ? rawPlanCart.auto_collection : 'on',
    billingAddress: rawPlanCart.billing_address ? extractAddress(rawPlanCart.billing_address) : null,
    shippingAddress: rawPlanCart.shipping_address ? extractAddress(rawPlanCart.shipping_address) : null,
    invoiceEstimate: rawPlanCart.invoice_estimate ? extractInvoiceEstimate(rawPlanCart.invoice_estimate) : null,
    cardPaymentDetails: rawPlanCart.card_payment_details ? extractCardPaymentDetails(rawPlanCart.card_payment_details) : null,
  };
}
