/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */

export function bindRef(component, attribute) {
  return (child) => (component[attribute] = child);
}

export const writeLocalStorage = (storageKey, value) => global.localStorage.setItem(storageKey, value);

export const readLocalStorage = (storageKey) => global.localStorage.getItem(storageKey);
