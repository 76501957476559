import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  backendShape,
  List,
  isNotEmpty,
  Loader,
  intlShape,
  Row,
  Column,
  notifierShape,
  withBackend,
  withAccessRights,
  accessRightsShape,
  withNotifier,
} from 'lcm-iot-commons';
import OpcUaServerCertificateItem from './OpcUaServerCertificateItem';
import { opcUaServerShape } from '../../shapes/opcUaServersShape';

export function OpcUaServerCertificatesList({
  intl,
  opcUaServer,
  notifier,
  accessRights,
  backend,
  type,
}) {
  const [certificates, setCertificates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadCertificates = async () => {
    try {
      const certificatesResponse = await backend.getAll(`/api_subscriptions/${opcUaServer.subscription_id}/opc_ua_servers/${opcUaServer.id}/${type}_certificates`);
      // eslint-disable-next-line camelcase
      const certificatesData = certificatesResponse[`${type}_certificates`]?.length > 0 ? certificatesResponse[`${type}_certificates`] : [];
      setCertificates(certificatesData);
    } catch (error) {
      notifier.showError(backend.translateError(error));
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    loadCertificates();
  }, [opcUaServer]);

  const handleOnCertificateRemoved = (certificate) => {
    setCertificates(certificates.filter((i) => i.id !== certificate.id));
  };

  const handleOnCertificateStatusChanged = () => {
    setIsLoading(false);
    loadCertificates();
  };

  const renderItems = (items) => (
    <ul id={`${type}-certificate-item-list`} className="list">
      {items.map((item) => (
        <OpcUaServerCertificateItem
          key={item.id}
          onCertificateRemoved={handleOnCertificateRemoved}
          onCertificateStatusChanged={handleOnCertificateStatusChanged}
          type={type}
          accessRights={accessRights}
          opcUaServer={opcUaServer}
          certificate={item}
        />
      ))}
    </ul>
  );

  const showEmptyMessage = () => (!isLoading ? (
    <p id="empty-message" className="space-after">{intl.formatMessage({ id: `opc_ua_server_details.no_${type}_certificates` })}</p>
  ) : <p />);

  return (
    <Loader loading={isLoading}>
      <Row>
        <Column>
          <List id={`assigned-${type}-certificates-list`}>
            {isNotEmpty(certificates) ? renderItems(certificates) : showEmptyMessage()}
          </List>
        </Column>
      </Row>
    </Loader>
  );
}

OpcUaServerCertificatesList.defaultProps = {
  type: 'client',
};

OpcUaServerCertificatesList.propTypes = {
  intl: intlShape.isRequired,
  type: PropTypes.oneOf(['ca', 'client']),
  opcUaServer: opcUaServerShape.isRequired,
  accessRights: accessRightsShape.isRequired,
  notifier: notifierShape.isRequired,
  backend: backendShape.isRequired,
};

export default injectIntl(withNotifier(withBackend(withAccessRights(OpcUaServerCertificatesList))));
