import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  backendShape,
  browserShape,
  notifierShape,
  subscriptionShape,
  withBackend,
  withBrowser,
  withConfiguration,
  withNotifier,
  withSession,
} from '../../context';
import {
  intlShape,
  sessionShape,
} from '../../shapes';
import withFlipper from '../../wrappers/withFlipper';
import Heading from '../Heading';
import ActionBar from '../ActionBar/ActionBar';
import Loader from '../Loader';
import { Container, Row, Column } from '../Grid';
import Select from '../Form/Select';
import PlanVariant from './PlanVariant';
import PlanVariantEnterprise from './PlanVariantEnterprise';
import PlansMaintenance from './PlansMaintenance';

export function PlanVariantSelection({
  backend, browser, configuration, intl, notifier, session, match, flipper,
}) {
  const [selectionState, setSelectionState] = useState({
    planVariants: undefined,
    planCart: undefined,
    currencies: undefined,
    currency: undefined,
    loading: true,
  });
  const [creatPlanCart, setCreatPlanCart] = useState({
    creating: false,
    selectedPlanVariantId: undefined,
  });
  const [hidePlans, setHidePlans] = useState(false);
  const { defaultCurrency } = configuration;
  const loadCurrencies = async () => {
    const response = await backend.get('/plan_variants/currencies');
    return response.currencies.map((planCurrency) => ({
      id: planCurrency.code,
      name: planCurrency.code,
      selected: planCurrency.selected,
    }));
  };

  const loadData = async (actualCurrency) => {
    const planflipper = flipper;
    if (!session.reseller && configuration.setHidePlans) {
      planflipper.hidePlansPages = true;
    }
    const hidePlansValue = !window.location.search.includes('iamadev') && planflipper.hidePlansPages;
    if (!hidePlansValue) {
      let planCart;
      try {
        planCart = await backend.get('/plan_cart', {
          language: configuration.language,
          new_planVariant: true,
        });
      } catch (error) {
        // no error handling necessary
      }
      try {
        const nextCurrencies = selectionState.currencies || await loadCurrencies();
        const nextCurrency = (actualCurrency || nextCurrencies.find((c) => c.selected) || nextCurrencies.find((c) => c.id === defaultCurrency));
        const filter = {
          currency: nextCurrency.id,
          language: configuration.language,
        };

        const respondPlanVariants = await backend.get('/plan_variants', filter);

        setSelectionState({
          planVariants: respondPlanVariants.plan_variants,
          planCart,
          loading: false,
          currencies: nextCurrencies,
          currency: nextCurrency,
        });
      } catch (error) {
        notifier.showError(backend.translateError(error));
      }
    } else {
      setHidePlans(true);
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  const isFreeServiceSubscription = (planVariant, planVariantAddons) => {
    const free = (planVariant.type === 'free');
    const service = (planVariant.id.split('-')[0] !== 'connect');
    const noAddons = planVariantAddons ? Object.values(planVariantAddons).every((value) => value === 0) : true;
    return free && service && noAddons;
  };

  const handleOnSelect = async (planVariant, planVariantAddons) => {
    setCreatPlanCart({ creating: true, selectedPlanVariantId: planVariant.id });
    try {
      if (isFreeServiceSubscription(planVariant, planVariantAddons)) {
        if (session.reseller != null) {
          notifier.showError(intl.formatMessage({ id: 'reseller_basic_subscription_create.error' }));
          setCreatPlanCart({ creating: false });
          return;
        }
        if (selectionState.planCart && Object.keys(selectionState.planCart).length > 0) {
          const packedPlanCart = {
            plan_variant_id: planVariant.id,
            subscription_id: match && match.params.id,
            coupon_code: null,
            addons: planVariantAddons ? Object.keys(planVariantAddons).map((addonId) => ({
              plan_variant_addon_id: addonId,
              amount: planVariantAddons[addonId],
            })) : null,
          };
          await backend.patch('/plan_cart', packedPlanCart);
        }
        await backend.post('/subscription', { planVariant_Id: planVariant.id });
        browser.redirectTo('/');
      } else {
        const packedPlanCart = {
          plan_variant_id: planVariant.id,
          subscription_id: match.params && match.params.id,
          addons: planVariantAddons ? Object.keys(planVariantAddons).map((addonId) => ({
            plan_variant_addon_id: addonId,
            amount: planVariantAddons[addonId],
          })) : null,
        };
        await backend.post('/plan_cart', { language: configuration.language }, packedPlanCart);
        browser.navigateTo('/subscription/create');
      }
    } catch (error) {
      setCreatPlanCart({ creating: false });
      notifier.showError(backend.translateError(error));
    }
  };

  const renderPlanSelection = () => {
    let renderPlanVariants;
    if (selectionState.planVariants) {
      const planVariantsLength = selectionState.planVariants.length;
      const className = selectionState.planVariants.length > 1 ? 12 / planVariantsLength : '6 col-md-offset-3';
      renderPlanVariants = selectionState.planVariants.map((planVariant) => {
        if (planVariant.id.includes('enterprise')) {
          return (
            <div id={planVariant.id} className={`col-md-${className}`} key={planVariant.id}>
              <PlanVariantEnterprise planVariant={planVariant} />
            </div>
          );
        }
        return (
          <div id={planVariant.id} className={`col-md-${className}`} key={planVariant.id}>
            <PlanVariant
              planVariant={planVariant}
              planCart={selectionState.planCart}
              onSelect={handleOnSelect}
              disabled={creatPlanCart.creating}
              creating={creatPlanCart.creating && selectionState.selectedPlanVariantId === planVariant.id}
            />
          </div>
        );
      });
    }

    return !hidePlans ? (
      <Loader loading={selectionState.loading}>
        <Container id="plan-variants" className="plan-variants">
          <Row>
            <Column className="col-xs-12">
              <ActionBar>
                <Heading title={intl.formatMessage({ id: 'plan_variants.title' })} />
                <div className="action-bar">
                  <div id="currency-select" className="form-group">
                    <label>{intl.formatMessage({ id: 'planvariant.currency' })}</label>
                    <Select options={selectionState.currencies} selectedOption={selectionState.currency} onChange={loadData} />
                  </div>
                </div>
              </ActionBar>
            </Column>
          </Row>
          <Row>
            {renderPlanVariants}
          </Row>
        </Container>
      </Loader>
    ) : (
      <PlansMaintenance />
    );
  };
  return renderPlanSelection();
}

PlanVariantSelection.propTypes = {
  backend: backendShape.isRequired,
  browser: browserShape.isRequired,
  configuration: PropTypes.shape({ defaultCurrency: PropTypes.string, language: PropTypes.string, setHidePlans: PropTypes.string }).isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  session: sessionShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  flipper: PropTypes.shape({
    hidePlansPages: PropTypes.bool,
  }).isRequired,
  subscription: subscriptionShape,
};

export default injectIntl(
  withFlipper(
    withSession(
      withNotifier(
        withConfiguration(
          withBrowser(
            withBackend(
              PlanVariantSelection,
            ),
          ),
        ),
      ),
    ),
  ),
);
