import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import {
  withBackend,
  withBrowser,
  withConfiguration,
  withSession,
  withNotifier,
  withTracking,
  withFlipper,
  locationShape,
  notifierShape,
  browserShape,
  backendShape,
  intlShape,
  trackingShape,
  Container,
  Heading,
  Column,
  Row,
  url,
  BadRequestError,
  UnauthorizedError,
} from 'lcm-iot-commons';

import SignInForm from './SignInForm';
import SignInOtpForm from './SignInOtpForm';
import MultiFactorAuthenticationForm from '../UserProfile/MultiFactorAuthenticationForm';
import RecoveryCodesModal from '../UserProfile/RecoveryCodesModal';
import WamDiscontinuationInfoModal from './WamDiscontinuationInfoModal';

const queryParameter = (location, parameter) => (location && location.search ? new URLSearchParams(location.search).get(parameter) : null);

export function SignIn({
  intl, notifier, browser, location, backend, configuration, tracking, flipper,
}) {
  const [captchaRequired, setCaptchaRequired] = useState(localStorage.getItem('captchaRequired') === 'true');
  const [otpState, setOtpState] = useState();
  const [otpEnforcedState, setOtpEnforcedState] = useState();
  const [otpEnforcedFinishedState, setOtpEnforcedFinishedState] = useState();
  const captchaRefresh = React.useRef();
  const { googleCaptchaSiteKey } = configuration;
  const myEndressForm = React.useRef();
  const showWamDiscontinuationModal = queryParameter(location, 'show_wam_info') === 'true';

  React.useEffect(() => {
    if (queryParameter(location, 'sso') === 'myendress') {
      myEndressForm.current.submit();
    }

    localStorage.setItem('captchaRequired', googleCaptchaSiteKey && captchaRequired);
  }, [captchaRequired]);

  const handleOnSubmit = async (values, actions) => {
    try {
      const response = await backend.post('/sso_session', {
        email: values.email,
        password: values.password,
        captcha: values.captcha,
        otp_code_token: values.otpCodeToken,
        redirect_uri: queryParameter(location, 'redirect_uri'),
        client_id: queryParameter(location, 'client_id'),
        state: queryParameter(location, 'state'),
        code_challenge_method: queryParameter(location, 'code_challenge_method'),
        code_challenge: queryParameter(location, 'code_challenge'),
      }, null, false);
      localStorage.setItem('captchaRequired', false);

      if (otpEnforcedState) {
        setOtpEnforcedFinishedState({ recoveryCodes: response.recovery_codes, redirectUri: response.redirect_uri });
      } else {
        tracking.trackEvent('SignIn-Successful');
        browser.redirectToExtern(response.redirect_uri);
      }
    } catch (error) {
      if (error instanceof BadRequestError && error.contains('missing_login_token')) {
        setOtpState(values);
      } else if (error instanceof BadRequestError && error.contains('invalid_login_token')) {
        notifier.showError(intl.formatMessage({ id: 'signin.otp.invalid' }));
        actions.resetForm({ email: values.email, password: values.password });
        if (otpState) {
          setOtpState(values);
        } else {
          setOtpEnforcedState({ ...otpEnforcedState, credentials: { email: values.email, password: values.password } });
        }
      } else if (error instanceof BadRequestError && error.contains('required_login_token')) {
        const provisioningUri = error.errors[0].data.provisioning_uri;
        setOtpEnforcedState({ credentials: values, provisioningUri });
      } else {
        if (error instanceof UnauthorizedError) {
          if (error.contains('too_many_login_attempts')) {
            setCaptchaRequired(googleCaptchaSiteKey && true);
            if (otpState) {
              setOtpState(undefined);
            }
          }
          notifier.showError(intl.formatMessage({ id: 'signin.invalid' }));
        } else {
          notifier.showError(intl.formatMessage({ id: 'api.error.service_unavailable' }));
        }

        actions.setErrors(error);
        actions.resetForm({});
        // eslint-disable-next-line no-unused-expressions
        captchaRefresh.current?.reset();
      }
    }
  };

  const handleOnOtpCancel = () => {
    setOtpState(undefined);
  };

  const handleOnCloseRecoveryCodesModal = () => {
    const { redirectUri } = otpEnforcedFinishedState;
    setOtpEnforcedFinishedState(undefined);
    browser.redirectToExtern(redirectUri);
  };

  return (
    <Container>
      <WamDiscontinuationInfoModal show={showWamDiscontinuationModal} />
      {otpState ? (
        <>
          <Row>
            <Column>
              <Heading title={intl.formatMessage({ id: 'signin.otp_header' })} id="signin.header" />
            </Column>
          </Row>
          <Row>
            <Column md="6">
              <SignInOtpForm onSubmit={handleOnSubmit} onCancel={handleOnOtpCancel} initialValues={otpState} />
            </Column>
          </Row>
        </>
      ) : null }
      {otpEnforcedState ? (
        <>
          <Row>
            <Column md="8">
              <Heading title={intl.formatMessage({ id: 'signin.otp_forced_header' })} id="signin-header" />
              <p className="space-after"><FormattedMessage id="signin.otp_forced_description" /></p>
            </Column>
          </Row>
          <Row>
            <Column md="12">
              <MultiFactorAuthenticationForm onSubmit={handleOnSubmit} initialValues={otpEnforcedState.credentials} provisioningUri={otpEnforcedState.provisioningUri} />
            </Column>
          </Row>
        </>
      ) : null }
      {!otpState && !otpEnforcedState ? (
        <>
          {queryParameter(location, 'sso') === 'myendress' ? (
            <Modal backdrop show id="redirect-modal">
              <Modal.Header>
                <Modal.Title>{intl.formatMessage({ id: 'signin.sso_modal.title' })}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{intl.formatMessage({ id: 'signin.sso_modal.body' })}</Modal.Body>
            </Modal>
          ) : null }

          <Row>
            <Column>
              <Heading title={intl.formatMessage({ id: 'signin.header' })} id="signin-header" />
            </Column>
          </Row>
          <Row>
            <Column md="5">
              <SignInForm onSubmit={handleOnSubmit} captchaRequired={captchaRequired} captchaRefresh={captchaRefresh} />
              <br />
            </Column>
          </Row>
          {flipper.loginWithMyEndressHauser ? (
            <Row>
              <Column md="5">
                <p>
                  {intl.formatMessage({ id: 'signin.or_sso_provider' })}
                </p>
                <form method="post" action="/app/id/api/auth/myendress" id="myendress-signin-form" ref={myEndressForm}>
                  <input type="hidden" name="redirect_uri" value={queryParameter(location, 'redirect_uri') || ' '} />
                  <input type="hidden" name="state" value={queryParameter(location, 'state') || ' '} />
                  <input type="hidden" name="client_id" value={queryParameter(location, 'client_id') || ' '} />
                  <div className="button-group">
                    <button className="btn" type="submit">{intl.formatMessage({ id: 'signin.button.signin.myendress' })}</button>
                  </div>
                </form>
              </Column>
            </Row>
          ) : null}
          <Row>
            <Column>
              <br />
              <p>
                <FormattedMessage id="signin.no_account" />
                <Link to={url('/sign_up')}>
                  <FormattedMessage id="signin.signup_new_account" />
                </Link>
              </p>
              <p>
                <FormattedMessage id="signin.forgot_password" />
                <Link to={url('/request_password_reset')} id="request_password_reset">
                  <FormattedMessage id="signin.request_password_reset" />
                </Link>
              </p>
            </Column>
          </Row>
        </>
      ) : null}
      <RecoveryCodesModal
        id="recovery-codes-edit-modal"
        show={otpEnforcedFinishedState?.recoveryCodes !== undefined}
        recoveryCodes={otpEnforcedFinishedState?.recoveryCodes}
        onClose={handleOnCloseRecoveryCodesModal}
      />
    </Container>
  );
}

SignIn.propTypes = {
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  browser: browserShape.isRequired,
  location: locationShape.isRequired,
  backend: backendShape.isRequired,
  configuration: PropTypes.shape({ googleCaptchaSiteKey: PropTypes.string }).isRequired,
  tracking: trackingShape.isRequired,
  flipper: PropTypes.shape({
    loginWithMyEndressHauser: PropTypes.bool,
  }),
};

SignIn.defaultProps = {
  flipper: {
    loginWithMyEndressHauser: false,
  },
};

export default injectIntl(withRouter(withFlipper(withConfiguration(withTracking(withSession(withNotifier(withBrowser(withBackend(SignIn)))))))));
