import PropTypes from 'prop-types';

export const invoiceEstimateLineItemShape = PropTypes.shape({
  description: PropTypes.string,
  quantity: PropTypes.number,
  amount: PropTypes.number,
});

export const invoiceEstimateShape = PropTypes.shape({
  lineItems: PropTypes.arrayOf(invoiceEstimateLineItemShape),
  currency: PropTypes.string,
  total: PropTypes.number,
  totalDiscount: PropTypes.number,
  discountDescription: PropTypes.string,
  totalTaxes: PropTypes.number,
  subTotal: PropTypes.number,
  credits: PropTypes.number,
  totalDue: PropTypes.number,
});
