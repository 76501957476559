/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  intlShape,
  withIntl,
} from 'lcm-iot-commons';

export function FieldGatewayModbusConfigItem({
  itemHeader, itemVariable, itemChannel, intl,
}) {
  const { t } = intl;
  return (
    <>
      <Row>
        <div className="col-lg-3"><b>{t`field_gateway.modbus.config.item.valuename`}</b></div>
        <div className="col-lg-2">{itemHeader}</div>
      </Row>
      <Row>
        <div className="col-lg-3"><b>{t`field_gateway.modbus.config.item.channel`}</b></div>
        <div className="col-lg-2">{itemChannel}</div>
      </Row>
      <Row>
        <div className="col-lg-3"><b>{t`field_gateway.modbus.config.item.variable`}</b></div>
        <div className="col-lg-2">{itemVariable}</div>
      </Row>
    </>
  );
}

FieldGatewayModbusConfigItem.propTypes = {
  itemHeader: PropTypes.string.isRequired,
  itemVariable: PropTypes.string.isRequired,
  itemChannel: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

export default withIntl(FieldGatewayModbusConfigItem);
