export default {
  // Application initialization
  INITIALIZE_CONFIGURATION: 'INITIALIZE_CONFIGURATION',
  INITIALIZE_SESSION: 'INITIALIZE_SESSION',

  // Session
  CREATE_SESSION_SUCCESSFUL: 'CREATE_SESSION_SUCCESSFUL',
  CREATE_SESSION_FETCHING: 'CREATE_SESSION_FETCHING',
  CREATE_SESSION_FAILED: 'CREATE_SESSION_FAILED',
  DELETE_SESSION_SUCCESSFUL: 'DELETE_SESSION_SUCCESSFUL',
  REFRESH_SESSION: 'REFRESH_SESSION',
  REFRESH_SESSION_SUCCESSFUL: 'REFRESH_SESSION_SUCCESSFUL',
  REFRESH_SESSION_FAILED: 'REFRESH_SESSION_FAILED',
  SESSION_EXPIRED: 'SESSION_EXPIRED',

  // Current user
  FETCH_CURRENT_USER_SUCCESSFUL: 'FETCH_CURRENT_USER_SUCCESSFUL',
  FETCH_CURRENT_USER_FETCHING: 'FETCH_CURRENT_USER_FETCHING',
  FETCH_CURRENT_USER_FAILED: 'FETCH_CURRENT_USER_FAILED',
  FETCH_CURRENT_USER_ADMIN_TENANTS_SUCCESSFUL: 'FETCH_CURRENT_USER_ADMIN_TENANTS_SUCCESSFUL',
  FETCH_CURRENT_USER_ADMIN_TENANTS_FAILED: 'FETCH_CURRENT_USER_ADMIN_TENANTS_FAILED',

  // Access Rights
  INIT_ACCESS_RIGHTS: 'INIT_ACCESS_RIGHTS',
  LOAD_ACCESS_RIGHTS_SUCCESSFUL: 'LOAD_ACCESS_RIGHTS_SUCCESSFUL',
  LOAD_ACCESS_RIGHTS_FAILED: 'LOAD_ACCESS_RIGHTS_FAILED',

  // Countries select
  LOAD_SELECT_COUNTRIES_FETCHING: 'LOAD_SELECT_COUNTRIES_FETCHING',
  LOAD_SELECT_COUNTRIES_SUCCESSFUL: 'LOAD_SELECT_COUNTRIES_SUCCESSFUL',
  LOAD_SELECT_COUNTRIES_FAILED: 'LOAD_SELECT_COUNTRIES_FAILED',

  // Delete Asset
  DELETE_ASSET_DELETING: 'DELETE_ASSET_DELETING',
  DELETE_ASSET_SUCCESSFUL: 'DELETE_ASSET_SUCCESSFUL',
  DELETE_ASSET_FAILED: 'DELETE_ASSET_FAILED', // Delete Asset

  // Permissions
  EDIT_PERMISSIONS_INIT: 'EDIT_PERMISSIONS_INIT',
  EDIT_PERMISSIONS_LOAD_PERMISSIONS_FETCHING: 'EDIT_PERMISSIONS_LOAD_PERMISSIONS_FETCHING',
  EDIT_PERMISSIONS_LOAD_PERMISSIONS_SUCCESSFUL: 'EDIT_PERMISSIONS_LOAD_PERMISSIONS_SUCCESSFUL',
  EDIT_PERMISSIONS_LOAD_PERMISSIONS_FAILED: 'EDIT_PERMISSIONS_LOAD_PERMISSIONS_FAILED',
  EDIT_PERMISSIONS_CREATING: 'EDIT_PERMISSIONS_CREATING',
  EDIT_PERMISSIONS_CREATE_SUCCESSFUL: 'EDIT_PERMISSIONS_CREATE_SUCCESSFUL',
  EDIT_PERMISSIONS_CREATE_FAILED: 'EDIT_PERMISSIONS_CREATE_FAILED',
  EDIT_PERMISSIONS_DELETING: 'EDIT_PERMISSIONS_DELETING',
  EDIT_PERMISSIONS_DELETE_SUCCESSFUL: 'EDIT_PERMISSIONS_DELETE_SUCCESSFUL',
  EDIT_PERMISSIONS_DELETE_FAILED: 'EDIT_PERMISSIONS_DELETE_FAILED',
  EDIT_PERMISSIONS_CREATE_USER_PERMISSIONS: 'EDIT_PERMISSIONS_CREATE_USER_PERMISSIONS',
  EDIT_PERMISSIONS_CREATE_USER_GROUP_PERMISSIONS: 'EDIT_PERMISSIONS_CREATE_USER_GROUP_PERMISSIONS',
  EDIT_PERMISSIONS_DELETE_PERMISSIONS_DELETING: 'EDIT_PERMISSIONS_DELETE_PERMISSIONS_DELETING',
  EDIT_PERMISSIONS_DELETE_PERMISSIONS_FAILED: 'EDIT_PERMISSIONS_DELETE_PERMISSIONS_FAILED',
  EDIT_PERMISSIONS_DELETE_USER_GROUP_PERMISSIONS_SUCCESSFUL: 'EDIT_PERMISSIONS_DELETE_USER_GROUP_PERMISSIONS_SUCCESSFUL',
  EDIT_PERMISSIONS_DELETE_USER_PERMISSIONS_SUCCESSFUL: 'EDIT_PERMISSIONS_DELETE_USER_PERMISSIONS_SUCCESSFUL',

  // Load Product Status
  LOAD_PRODUCT_STATUSES_SUCCESSFUL: 'LOAD_PRODUCT_STATUSES_SUCCESSFUL',
  LOAD_PRODUCT_STATUSES_FETCHING: 'LOAD_PRODUCT_STATUSES_FETCHING',
  LOAD_PRODUCT_STATUSES_FAILED: 'LOAD_PRODUCT_STATUSES_FAILED',

  // User Lookup
  LOAD_USAGE_FETCHING: 'LOAD_USAGE_FETCHING',
  LOAD_USAGE_SUCCESSFUL: 'LOAD_USAGE_SUCCESSFUL',
  LOAD_USAGE_FAILED: 'LOAD_USAGE_FAILED',

  // User Groups Typeaheads
  LOAD_TYPEAHEAD_USER_GROUPS_FETCHING: 'LOAD_TYPEAHEAD_USER_GROUPS_FETCHING',
  LOAD_TYPEAHEAD_USER_GROUPS_SUCCESSFUL: 'LOAD_TYPEAHEAD_USER_GROUPS_SUCCESSFUL',
  LOAD_TYPEAHEAD_USER_GROUPS_FAILED: 'LOAD_TYPEAHEAD_USER_GROUPS_FAILED',

  // User Lookup
  USER_LOOKUP_FETCHING: 'USER_LOOKUP_FETCHING',
  USER_LOOKUP_SUCCESSFUL: 'USER_LOOKUP_SUCCESSFUL',
  USER_LOOKUP_FAILED: 'USER_LOOKUP_FAILED',

  //  Picture
  PICTURES_INIT: 'PICTURES_INIT',
  PICTURES_FETCHING: 'PICTURES_FETCHING',
  LOAD_PICTURES_SUCCESSFUL: 'LOAD_PICTURES_SUCCESSFUL',
  LOAD_PICTURES_FAILED: 'LOAD_PICTURES_FAILED',
  DELETE_PICTURE_SUCCESSFUL: 'DELETE_PICTURE_SUCCESSFUL',
  DELETE_PICTURE_FAILED: 'DELETE_PICTURE_FAILED',
  UPLOAD_PICTURES_SUCCESSFUL: 'UPLOAD_PICTURES_SUCCESSFUL',
  UPLOAD_PICTURES_FAILED: 'UPLOAD_PICTURES_FAILED',
  UPLOAD_PICTURES_COMPLETE: 'UPLOAD_PICTURES_COMPLETE',

  // Product Categories
  LOAD_PRODUCT_CATEGORIES_FAILED: 'LOAD_PRODUCT_CATEGORIES_FAILED',
  LOAD_PRODUCT_CATEGORIES_SUCCESSFUL: 'LOAD_PRODUCT_CATEGORIES_SUCCESSFUL',
  LOAD_PRODUCT_CATEGORIES_FETCHING: 'LOAD_PRODUCT_CATEGORIES_FETCHING',

  // Search Products
  RESET_PRODUCTS_SEARCH: 'RESET_PRODUCTS_SEARCH',
  SEARCH_PRODUCTS_SUCCESSFUL: 'SEARCH_PRODUCTS_SUCCESSFUL',
  SEARCH_PRODUCTS_FETCHING: 'SEARCH_PRODUCTS_FETCHING',
  SEARCH_COMPANIES_FAILED: 'SEARCH_COMPANIES_FAILED',
  SEARCH_PRODUCTS_FETCHING_RESET: 'SEARCH_PRODUCTS_FETCHING_RESET',
  SEARCH_PRODUCTS_FAILED: 'SEARCH_PRODUCTS_FAILED',
  LOAD_NEXT_PRODUCTS_SUCCESSFUL: 'LOAD_NEXT_PRODUCTS_SUCCESSFUL',
  LOAD_NEXT_PRODUCTS_FETCHING: 'LOAD_NEXT_PRODUCTS_FETCHING',
  LOAD_NEXT_PRODUCTS_FAILED: 'LOAD_NEXT_PRODUCTS_FAILED',
  SET_DIAGNOSIS_CODE_SUCCESSFUL: 'SET_DIAGNOSIS_CODE_SUCCESSFUL',

  // Plan_Variants
  LOAD_PLANVARIANTS_FETCHING: 'LOAD_PLANVARIANTS_FETCHING',
  LOAD_PLANVARIANTS_SUCCESSFUL: 'LOAD_PLANVARIANTS_SUCCESSFUL',
  LOAD_PLANVARIANTS_FAILED: 'LOAD_PLANVARIANTS_FAILED',

  LOAD_PLAN_CURRENCIES_FETCHING: 'LOAD_PLAN_CURRENCIES_FETCHING',
  LOAD_PLAN_CURRENCIES_SUCCESSFUL: 'LOAD_PLAN_CURRENCIES_SUCCESSFUL',
  LOAD_PLAN_CURRENCIES_FAILED: 'LOAD_PLAN_CURRENCIES_FAILED',

  // Plan Cart
  PLAN_CART_INIT: 'PLAN_CART_INIT',
  LOAD_PLAN_CART_SUCCESSFUL: 'LOAD_PLAN_CART_SUCCESSFUL',
  LOAD_PLAN_CART_FAILED: 'LOAD_PLAN_CART_FAILED',

  CREATE_PLAN_CART_CREATING: 'CREATE_PLAN_CART_CREATING',
  CREATE_PLAN_CART_SUCCESSFUL: 'CREATE_PLAN_CART_SUCCESSFUL',
  CREATE_PLAN_CART_FAILED: 'CREATE_PLAN_CART_FAILED',

  CHECKOUT_PLAN_CART_CREATING: 'CHECKOUT_PLAN_CART_CREATING',
  CHECKOUT_PLAN_CART_SUCCESSFUL: 'CHECKOUT_PLAN_CART_SUCCESSFUL',
  CHECKOUT_PLAN_CART_FAILED: 'CHECKOUT_PLAN_CART_FAILED',

  UPDATE_PLAN_CART_SAVING: 'UPDATE_PLAN_CART_SAVING',
  UPDATE_PLAN_CART_SUCCESSFUL: 'UPDATE_PLAN_CART_SUCCESSFUL',
  UPDATE_PLAN_CART_FAILED: 'UPDATE_PLAN_CART_FAILED',

  // Subscriptions
  SUBSCRIPTION_INIT: 'SUBSCRIPTION_INIT',
  CREATE_FREE_SUBSCRIPTION_CREATING: 'CREATE_FREE_SUBSCRIPTION_CREATING',
  CREATE_FREE_SUBSCRIPTION_FAILED: 'CREATE_FREE_SUBSCRIPTION_FAILED',
  CREATE_FREE_SUBSCRIPTION_SUCCESSFUL: 'CREATE_FREE_SUBSCRIPTION_SUCCESSFUL',

  LOAD_SUBSCRIPTION_LOADING: 'LOAD_SUBSCRIPTION_LOADING',
  LOAD_SUBSCRIPTION_FAILED: 'LOAD_SUBSCRIPTION_FAILED',
  LOAD_SUBSCRIPTION_SUCCESSFUL: 'LOAD_SUBSCRIPTION_SUCCESSFUL',

  SAVE_SUBSCRIPTION_SAVING: 'SAVE_SUBSCRIPTION_SAVING',
  SAVE_SUBSCRIPTION_SUCCESSFUL: 'SAVE_SUBSCRIPTION_SUCCESSFUL',
  SAVE_SUBSCRIPTION_FAILED: 'SAVE_SUBSCRIPTION_FAILED',

  UPDATE_CURRENT_USER: 'UPDATE_CURRENT_USER',
};
