import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  withRules,
} from '../../context';
import { intlShape } from '../../shapes';
import DateTimeInput from '../DateTimeInput';
import { TextInput } from '../Form';
import SwitchInput from '../Form/SwitchInput';
import AssetCalibrationHelp from './AssetCalibrationHelp';

export function AssetCalibration(
  props,
) {
  const { intl } = props;

  return (
    <div>
      <div>
        <h3>
          {intl.formatMessage({ id: 'label.offset_settings' })}
          <AssetCalibrationHelp intl={intl} />
        </h3>
      </div>
      <SwitchInput {...props} id="calibration-status" name="calibration_status" />
      <DateTimeInput
        {...props}
        id="calibration-time"
        type="text"
        name="calibration_time"
        label={intl.formatMessage({ id: 'calibration.date.time' })}
        minDate={new Date(1970)}
        showSelectTime
        showSelectDate
        required
      />
      <TextInput
        {...props}
        id="calibration-density"
        name="calibration_density"
        placeholder={intl.formatMessage({ id: 'calibration.density.placeholder' })}
        label={intl.formatMessage({ id: 'label.calibration.density' })}
      />
      <TextInput
        {...props}
        id="calibration_speed_of_sound"
        name="calibration_speed_of_sound"
        placeholder={intl.formatMessage({ id: 'calibration.ultrasonic_velocity.placeholder' })}
        label={intl.formatMessage({ id: 'label.calibration.ultrasonic_velocity' })}
      />
      <TextInput
        {...props}
        id="calibration-temperature"
        name="calibration_temperature"
        placeholder={intl.formatMessage({ id: 'calibration.temperature.placeholder' })}
        label={intl.formatMessage({ id: 'label.calibration.temperature' })}
      />
      <TextInput
        {...props}
        id="calibration-viscosity"
        name="calibration_viscosity"
        placeholder={intl.formatMessage({ id: 'calibration.viscosity.placeholder' })}
        label={intl.formatMessage({ id: 'label.calibration.viscosity' })}
      />
    </div>
  );
}

AssetCalibration.propTypes = {
  intl: intlShape.isRequired,
  values: PropTypes.shape({
    calibration_density: PropTypes.string,
    calibration_viscosity: PropTypes.string,
    calibration_temperature: PropTypes.string,
    calibration_time: PropTypes.instanceOf(Date),
    calibration_status: PropTypes.bool,
    calibration_speed_of_sound: PropTypes.string,
  }),
};

export default injectIntl(withRules(AssetCalibration));
