import PropTypes from 'prop-types';
import { fieldGatewaysShape } from './fieldGatewaysShape';
import { networkInterfacesShape } from './networkInterfacesShape';

export const edgeDeviceShape = PropTypes.shape({
  id: PropTypes.number,
  serialNumber: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  logLevel: PropTypes.string,
  status: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  type: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  lastSeenAt: PropTypes.string,
  fieldGateways: fieldGatewaysShape,
  networkInterfaces: networkInterfacesShape,
  applyTimestamp: PropTypes.number,
});
export const edgeDevicesShape = PropTypes.arrayOf(edgeDeviceShape);
