import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import intlShape from '../../shapes/intlShape';
import InstrumentationDuplicateOneTag from './InstrumentationDuplicateOneTag';
import InstrumentationDuplicateMoreTags from './InstrumentationDuplicateMoreTags';
import { BadRequestError } from '../../api';
import {
  apiShape, browserShape, notifierShape, withApi, withBrowser, withNotifier,
} from '../../context';

export function InstrumentationDuplicateModal({
  intl, onCloseDuplicate, onCreateDuplicate, duplicateTags, assetId, notifier, browser, api,
}) {
  const onAssignDuplicate = async (values) => {
    try {
      await api.post(`/instrumentations/${values.tag.id}/assets`, { assets: [{ id: assetId }] });
      notifier.showSuccess(intl.formatMessage({ id: 'instrumentation.actions.assign.notification' }));
      browser.navigateTo(`/instrumentations/${values.tag.id}`);
    } catch (error) {
      if (error instanceof BadRequestError && error.contains('associations_already_added', 'assets')) {
        notifier.showError(intl.formatMessage({ id: 'instrumentation.actions.assign.error' }));
      } else {
        notifier.showError(api.translateError(error));
      }
    }
    onCloseDuplicate();
  };

  return (
    <Modal id="duplicate-instrumentation-modal" dialogClassName="duplicate-instrumentation" show restoreFocus={false} size="sm" backdrop="static">
      <Modal.Header closeButton={false}>
        <Modal.Title id="duplicate-instrumentation-title">
          {intl.formatMessage({ id: 'instrumentation_assign.duplicate_modal_title' })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-duplicate-tag">
        <div id="duplicate-instrumentation-message" className="space-after">
          {duplicateTags.length === 1
            ? (
              <InstrumentationDuplicateOneTag
                id="instrumentation-duplicate-one-tag"
                intl={intl}
                onCreateDuplicate={onCreateDuplicate}
                onCloseDuplicate={onCloseDuplicate}
                duplicateTags={duplicateTags}
                onAssignDuplicate={onAssignDuplicate}
              />
            )
            : (
              <InstrumentationDuplicateMoreTags
                id="instrumentation-duplicate-more-tags"
                intl={intl}
                onCreateDuplicate={onCreateDuplicate}
                onCloseDuplicate={onCloseDuplicate}
                duplicateTags={duplicateTags}
                onAssignDuplicate={onAssignDuplicate}
              />
            )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

InstrumentationDuplicateModal.propTypes = {
  intl: intlShape.isRequired,
  onCloseDuplicate: PropTypes.func,
  onCreateDuplicate: PropTypes.func,
  duplicateTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      tag: PropTypes.string,
    }),
  ).isRequired,
  assetId: PropTypes.string.isRequired,
  notifier: notifierShape.isRequired,
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
};

export default injectIntl(withBrowser(withNotifier(withApi(InstrumentationDuplicateModal))));
