import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Unknown from './Unknown';
import { logShape, withLog } from '../../context';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.log = props.log;
    this.isFullPage = props.isFullPage;
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    this.log?.error('ErrorBoundary', error);
  }

  render() {
    if (this.state.hasError) {
      return (<Unknown isFullPage={this.isFullPage} />);
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  log: logShape,
  isFullPage: PropTypes.bool,
};

ErrorBoundary.defaultProps = {
  isFullPage: false,
};

export default withLog(ErrorBoundary);
