import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import {
  List,
  ListItem,
  concatValues,
  withBrowser,
  browserShape,
  intlShape,
} from 'lcm-iot-commons';

import { networkInterfaceIconClassName, statusBubbleNetworkInterface } from '../../utils/statusUtils';

export function NetworkInterfaces({
  intl,
  browser,
  edgeDevice,
  networkInterfaces,
}) {
  return (
    <div>
      <h2 id="network-interfaces-header">{ intl.formatMessage({ id: 'network_interfaces.header' }) }</h2>
      <List id="network-interfaces-list">
        {networkInterfaces.map((networkInterface) => (
          <ListItem
            id={`network-interface-list-item-${networkInterface.id}`}
            key={networkInterface.id}
            title={concatValues([`${networkInterface.type} ${networkInterface.number}`, networkInterface.name], ' - ')}
            value={networkInterface.modus === 'DHCP' ? 'DHCP' : networkInterface.ip_address}
            icon={networkInterfaceIconClassName()}
            status={statusBubbleNetworkInterface(networkInterface.status, edgeDevice)}
            description={networkInterface.description}
            onClick={() => browser.navigateTo(`/edge_devices/${edgeDevice.id}/network_interfaces/${networkInterface.id}`)}
            showItemMenu
          />
        ))}
      </List>
    </div>
  );
}

NetworkInterfaces.propTypes = {
  networkInterfaces: PropTypes.arrayOf(
    PropTypes.shape(
      {
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.string,
        modus: PropTypes.string,
        number: PropTypes.number,
        ip_address: PropTypes.string,
        status: PropTypes.string,
      },
    ),
  ).isRequired,
  browser: browserShape.isRequired,
  intl: intlShape.isRequired,
  edgeDevice: PropTypes.shape({
    id: PropTypes.number,
    lastSeenAt: PropTypes.number,
  }).isRequired,
};

export default
injectIntl(
  withBrowser(NetworkInterfaces),
);
