import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import intlShape from '../../shapes/intlShape';
import { Button } from '../Form';

export function InstrumentationDuplicateOneTag({
  intl, onCloseDuplicate, onCreateDuplicate, duplicateTags, onAssignDuplicate,
}) {
  return (
    <div id="duplicate-instrumentation-message" className="space-after">
      {duplicateTags.length > 0 ? (
        <>
          {intl.formatMessage({ id: 'instrumentation_assign.single_duplicate_modal_text' }, { tag: duplicateTags[0].tag })}
          <div className="btn-group space-before">
            <Button
              id="assign-button"
              hero
              onClick={() => onAssignDuplicate({ tag: duplicateTags[0] })}
              text={intl.formatMessage({ id: 'button.assign' })}
            />
            <Button
              id="create-button"
              hero
              onClick={onCreateDuplicate}
              text={intl.formatMessage({ id: 'button.create' })}
            />
            <Button
              id="cancel-button"
              onClick={onCloseDuplicate}
              text={intl.formatMessage({ id: 'button.cancel' })}
            />
          </div>
        </>
      ) : null}
    </div>
  );
}

InstrumentationDuplicateOneTag.propTypes = {
  intl: intlShape.isRequired,
  onAssignDuplicate: PropTypes.func,
  onCloseDuplicate: PropTypes.func,
  onCreateDuplicate: PropTypes.func,
  duplicateTags: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.string,
      id: PropTypes.number,
    }),
  ),
};

export default injectIntl(InstrumentationDuplicateOneTag);
