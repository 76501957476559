import React from 'react';
import { injectIntl } from 'react-intl';

import {
  Row,
  List,
  Loader,
  ActionBar,
  ActionBarButtons,
  CreateActionButton,
  Container,
  intlShape,
  withApi,
  apiShape,
  withNotifier,
  notifierShape,
  SearchBar,
  isTouchDevice,
  Column,
} from 'lcm-iot-commons';
import ResellerCustomerItem from './ResellerCustomerItem';

export function ResellerCustomers({
  api,
  intl,
  notifier,
}) {
  const [customers, setCustomers] = React.useState();
  const [badge, setBadge] = React.useState(
    <span className="loading">
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>,
  );

  const loadData = async (searchTerm = '') => {
    try {
      const response = await api.getAll('/customers', { number: `*${searchTerm}*` });
      setBadge(response.pagination?.total_count);
      setCustomers(response.customers);
    } catch (error) {
      notifier.showError(api.translateError(error));
      setCustomers([]);
      setBadge(0);
    }
  };
  React.useEffect(() => {
    loadData();
  }, []);

  const handleOnSearch = (searchTerm) => {
    loadData(searchTerm);
  };

  const removeCustomer = (customerId) => {
    setCustomers(customers.filter((customer) => customer.id !== customerId));
    setBadge(customers.length - 1);
  };

  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <h1 id="reseller-customers-header">
              {intl.formatMessage({ id: 'reseller_customers.header' })}
              <span id="customers-count" className="badge">{badge}</span>
            </h1>
            <ActionBarButtons>
              <CreateActionButton
                id="create-customer-button"
                target="/reseller/customers/create"
              />
            </ActionBarButtons>
          </ActionBar>
          <SearchBar
            autoFocus={!isTouchDevice()}
            filterWildcard={false}
            minChars={1}
            onSearch={handleOnSearch}
            placeholder={intl.formatMessage({ id: 'reseller_customers.search_placeholder' })}
            resettable
            timeout={300}
          />
          {customers && (
          <List id="reseller-customers-list">
            {customers.map((customer) => (
              <ResellerCustomerItem key={customer.id} customer={customer} removeCustomer={removeCustomer} />
            ))}
          </List>
          )}
          {!customers && (
          <Loader />
          )}
        </Column>
      </Row>
    </Container>
  );
}

ResellerCustomers.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
};

export default
injectIntl(
  withApi(
    withNotifier(
      ResellerCustomers,
    ),
  ),
);
