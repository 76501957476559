import React from 'react';
import { injectIntl } from 'react-intl';
import { intlShape } from '../../shapes';
import ActionButton from './ActionButton';

function EditActionButton(props) {
  return <ActionButton {...props} icon="icon-eh-edit" defaultLabel={props.intl.formatMessage({ id: 'button.edit' })} />;
}

EditActionButton.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(EditActionButton);
