import React from 'react';
import PropTypes from 'prop-types';

import {
  withIntl,
  intlShape,
  ContextualHelp,
} from 'lcm-iot-commons';
import { ModbusDataTypes, ModbusCommandNumber } from './FieldGatewayConstants';
import FieldGatewayGenericModbusConfigItem from './FieldGatewayGenericModbusConfigItem';
import FieldGatewayGenericModbusSerialNumberSubAddressItem from './FieldGatewayGenericModbusSerialNumberSubAddressItem';
import FieldGatewayGenericModbusDiagnosisItem from './FieldGatewayGenericModbusDiagnosisItem';

const isValidSerialNumberAndSubaddress = (serialNumber, subAddress) => serialNumber !== '-' && subAddress !== undefined;

export const convertCmdToText = (cmd, intl) => {
  const { t } = intl;
  if (cmd === ModbusCommandNumber.READ_HOLDING_REGISTER) {
    return t`field_gateway.modbus.config.item.cmd.read_holding_register`;
  }
  if (cmd === ModbusCommandNumber.READ_INPUT_REGISTER) {
    return t`field_gateway.modbus.config.item.cmd.read_input_register`;
  }
  return t`field_gateway.modbus.config.item.cmd.unknown`;
};

export const printHealths = (val, intl) => val.map((item, index) => (
  <div key={`printHealths-${index.toString()}`} style={{ marginBottom: '20px' }}>
    { (isValidSerialNumberAndSubaddress(item.serialNumber, item.subAddress)) && (
    <FieldGatewayGenericModbusSerialNumberSubAddressItem
      itemSerialNumber={item.serialNumber}
      itemSubAddress={item.subAddress}
      intl={intl}
    />
    )}
    <FieldGatewayGenericModbusDiagnosisItem
      itemRegisterType={item.cmd}
      itemRegisterBegin={item.registerStart}
      itemRegisterEnd={item.registerEnd}
      itemBitBegin={item.bitStart}
      itemBitEnd={item.bitEnd}
      intl={intl}
    />
  </div>
));

export const printValues = (val, intl) => val.map((item, index) => (
  <div key={`printValues-${index.toString()}`} style={{ marginBottom: '20px' }}>
    { (isValidSerialNumberAndSubaddress(item.serialNumber, item.subAddress)) && (
    <FieldGatewayGenericModbusSerialNumberSubAddressItem
      itemSerialNumber={item.serialNumber}
      itemSubAddress={item.subAddress}
      intl={intl}
    />
    )}
    <FieldGatewayGenericModbusConfigItem
      itemValue={item.valueName}
      itemUnit={item.valueUnit}
      itemRegisterType={item.cmd}
      itemRegisterBegin={item.registerStart}
      itemRegisterEnd={item.registerEnd}
      itemBitBegin={item.bitStart}
      itemBitEnd={item.bitEnd}
      intl={intl}
    />
  </div>
));

export const getSizeOfDataType = (unit) => {
  if (unit === ModbusDataTypes.BOOLEAN || unit === ModbusDataTypes.SIGNED_8 || unit === ModbusDataTypes.UNSIGNED_8
    || unit === ModbusDataTypes.SIGNED_16 || unit === ModbusDataTypes.UNSIGNED_16) {
    return 1;
  }
  if (unit === ModbusDataTypes.SIGNED_32 || unit === ModbusDataTypes.UNSIGNED_32
    || unit === ModbusDataTypes.FLOAT) {
    return 2;
  }
  if (unit === ModbusDataTypes.SIGNED_64 || unit === ModbusDataTypes.UNSIGNED_64
    || unit === ModbusDataTypes.DOUBLE || unit === ModbusDataTypes.DATE_TIME) {
    return 4;
  }
  return 0;
};

const isValidModbusConfigKey = (key) => key.split('_').length === 3 || key.split('_').length === 4;
const isValidModbusConfigValue = (value, endpoint) => value.split(';').length === 5 && value.split(';')[3] === endpoint;

const getSerialNumber = (value, specSerialNumber) => ((value.length >= 4 && value.length <= 255) ? value : specSerialNumber);

const getSubAddress = (key, specSubAddress) => (key.split('_').length === 4 ? parseInt(key.split('_')[3], 10) : specSubAddress);

export const getStartRegisterEntry = (input) => `${parseInt(input, 10)}`;

export const getEndRegisterEntry = (input, dataType) => `${parseInt(input, 10) + getSizeOfDataType(dataType) - 1}`;

export const getBitEntries = (input) => {
  const bitConfig = input.split('.');
  if (bitConfig.length !== 2) {
    return ['', ''];
  }
  const bitStartEnd = bitConfig[1].split('-');
  return [bitStartEnd[0], bitStartEnd.length === 2 ? bitStartEnd[1] : ''];
};

export const getEndpointItems = (specifications, endpoint) => {
  if (!specifications.params?.length) {
    return [];
  }
  return specifications.params.filter((item) => isValidModbusConfigKey(item.key)
   && isValidModbusConfigValue(item.value, endpoint)).map((item, index) => ({
    id: index.toString(),
    serialNumber: getSerialNumber(item.value.split(';')[0], specifications.serialNumber),
    subAddress: getSubAddress(item.key, specifications.subAddress),
    cmd: convertCmdToText(item.key.split('_')[1], specifications.intl),
    valueName: item.value.split(';')[1],
    valueUnit: item.value.split(';')[2],
    registerStart: getStartRegisterEntry(item.key.split('_')[2]),
    registerEnd: getEndRegisterEntry(item.key.split('_')[2], parseInt(item.value.split(';')[4], 10)),
    bitStart: getBitEntries(item.key.split('_')[2]).shift(),
    bitEnd: getBitEntries(item.key.split('_')[2]).pop(),
  }));
};

export const PrintValueConfigs = (params) => {
  const result = getEndpointItems(params, '1');
  return (result.length !== 0) ? printValues(result, params.intl) : params.intl.formatMessage({ id: 'field_gateway.modbus.config.item.no_modbus_config_value' });
};

export const PrintDiagConfig = (params) => {
  const result = getEndpointItems(params, '2');
  return (result.length !== 0) ? printHealths(result, params.intl) : params.intl.formatMessage({ id: 'field_gateway.modbus.config.item.no_modbus_config_health' });
};

export function FieldGatewayGenericDiagDetails(params) {
  const {
    specifications, serialNumber, subAddress, intl,
  } = params;
  const { t } = intl;
  return (
    <>
      <h4>
        <b>{t`field_gateway.modbus.config.item.health`}</b>
        <ContextualHelp title={t`field_gateway.modbus.config.item.health.title`}><p>{t`field_gateway.modbus.config.item.health.description`}</p></ContextualHelp>
      </h4>
      <PrintDiagConfig params={specifications} serialNumber={serialNumber} subAddress={subAddress} intl={intl} />
    </>
  );
}

export function FieldGatewayGenericValueDetails(params) {
  const {
    specifications, serialNumber, subAddress, intl,
  } = params;
  const { t } = intl;
  return (
    <>
      <h4>
        <b>{t`field_gateway.modbus.config.item.value`}</b>
        <ContextualHelp title={t`field_gateway.modbus.config.item.value.title`}><p>{t`field_gateway.modbus.config.item.value.description`}</p></ContextualHelp>
      </h4>
      <PrintValueConfigs params={specifications} serialNumber={serialNumber} subAddress={subAddress} intl={intl} />
    </>
  );
}

export function FieldGatewayGenericModbusDetails({
  specifications, serialNumber, subAddress, intl,
}) {
  return (
    <>
      { (isValidSerialNumberAndSubaddress(serialNumber, subAddress))
       && (<FieldGatewayGenericDiagDetails specifications={specifications} serialNumber={serialNumber} subAddress={subAddress} intl={intl} />)}
      <FieldGatewayGenericValueDetails specifications={specifications} serialNumber={serialNumber} subAddress={subAddress} intl={intl} />
    </>
  );
}

FieldGatewayGenericModbusDetails.propTypes = {
  specifications: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string.isRequired, value: PropTypes.string.isRequired })).isRequired,
  serialNumber: PropTypes.string.isRequired,
  subAddress: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
};

export default withIntl(FieldGatewayGenericModbusDetails);
