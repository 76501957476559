import { lcmApiClient } from 'lcm-iot-commons';
import { extractApiKeys, extractApiKey } from '../extractors/apiKeysExtractor';

/* istanbul ignore next */
export async function loadClientApplicationApiKeys(id) {
  const response = await lcmApiClient(false).getAll(`/client_applications/${id}/api_keys`);
  return extractApiKeys(response);
}

/* istanbul ignore next */
export async function createApiKey(clientApplicationId, apiKey) {
  const params = {
    client_application: { id: clientApplicationId },
    authentication_type: apiKey.authenticationType.id,
  };

  if (apiKey.description) {
    params.description = apiKey.description;
  }

  const response = await lcmApiClient().post('/api_keys', params);
  return extractApiKey(response);
}
