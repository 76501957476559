import React from 'react';
import { injectIntl } from 'react-intl';
import {
  Column,
  Container,
  Row,
  ActionBar,
  Heading,
  apiShape,
  browserShape,
  intlShape,
  notifierShape,
  userShape,
  withApi,
  withBrowser,
  withNotifier,
  withUser,
  isNothing,
  parseIntlNumber,
} from 'lcm-iot-commons';
import { BadRequestError } from 'lcm-iot-commons/client/lib/api/errors';
import moment from 'moment';
import TaxForm from './TaxForm';

export function TaxCreate(
  {
    api,
    browser,
    intl,
    notifier,
    user,
  },
) {
  const controllingDirector = user?.userRoles?.find((ur) => ur.name === 'controlling_director');
  if (user && isNothing(controllingDirector)) {
    browser.navigateTo('/403');
  }

  const handleOnSubmit = async (values, actions) => {
    try {
      const newTax = {
        start_date: moment(values.startDate).format('YYYY-MM-DD'),
        end_date: values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : null,
        country_code: values.country.code,
        rate: parseIntlNumber(values.rate),
        type: values.type.id,
      };

      await api.post('/taxes', newTax);
      notifier.showSuccess(intl.formatMessage({ id: 'taxes.create_success_notification' }));
      browser.navigateTo('/taxes/');
    } catch (error) {
      if (error instanceof BadRequestError && error.contains('overlap', 'start_date')) {
        actions.setErrors({
          endDate: intl.formatMessage({ id: 'api.error.taxes_overlap' }),
          startDate: intl.formatMessage({ id: 'api.error.taxes_overlap' }),
        });
      } else {
        notifier.showError(api.translateError(error));
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  return user ? (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <Heading title={intl.formatMessage({ id: 'taxes.create_tax' })} />
          </ActionBar>
        </Column>
      </Row>
      <Row>
        <Column>
          <TaxForm onSubmit={handleOnSubmit} />
        </Column>
      </Row>
    </Container>
  ) : null;
}

TaxCreate.propTypes = {
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  user: userShape,
};

TaxCreate.defaultProps = {
  user: undefined,
};

export default withUser(withApi(withBrowser(withNotifier(injectIntl(TaxCreate)))));
