import ActionType from '../constants/ActionType';
import lcmApiClient from '../api/lcmApiClient';

export function loadUsageFetching() {
  return {
    type: ActionType.LOAD_USAGE_FETCHING,
  };
}

export function loadUsageFailed(errors) {
  return {
    type: ActionType.LOAD_USAGE_FAILED,
    errors,
  };
}

export function loadUsageSuccessful(usage) {
  return {
    type: ActionType.LOAD_USAGE_SUCCESSFUL,
    assetsCount: usage.asset_count,
    fileStorage: usage.file_storage,
  };
}

export function loadUsage(userId) {
  return (dispatch, getState) => {
    dispatch(loadUsageFetching());
    return lcmApiClient(dispatch, getState).get(`/users/${userId}/usage`)
      .then((usage) => dispatch(loadUsageSuccessful(usage)))
      .catch((errors) => dispatch(loadUsageFailed(errors)));
  };
}
