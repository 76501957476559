import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import InputGroup from './InputGroup';
import { handleFormikValueChange, locale } from '../../utils';
import { intlShape } from '../../shapes';

function DateInput(props) {
  const {
    defaultValue, minDate, values, name, disabled, handleBlur, placeholder,
  } = props;

  moment.locale(locale());
  momentLocalizer(moment);

  const dateTimePicker = (
    <DateTimePicker
      {...props}
      className="date-time-input"
      name={name}
      disable={disabled}
      time={false}
      defaultValue={defaultValue}
      value={values && values[name]}
      onChange={(value) => handleFormikValueChange(props, value)}
      onBlur={(e) => handleBlur(e)}
      min={minDate}
      placeholder={placeholder}
    />
  );

  return (
    <InputGroup {...props}>
      {dateTimePicker}
    </InputGroup>
  );
}

DateInput.propTypes = {
  disabled: PropTypes.bool,
  useLocaleSetting: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  defaultValue: PropTypes.instanceOf(Date),
  input: PropTypes.instanceOf(Object),
  values: PropTypes.instanceOf(Object),
  intl: intlShape,
  handleBlur: PropTypes.func,
};

DateInput.defaultProps = {
  disabled: false,
  useLocaleSetting: false,
  defaultValue: undefined,
};

export default injectIntl(DateInput);
