/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
import { FormattedMessage, injectIntl } from 'react-intl';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ActionBar,
  AddressForm,
  apiErrorsContain,
  CancelButton,
  handleUnknownErrors,
  isNotFoundError,
  loadAllowedCountries,
  Loader,
  bindRef,
  navigateTo,
  isNotEmpty,
  showSuccess,
  SubmitButton,
  addCountryObject,
  intlShape,
  showError,
} from 'lcm-iot-commons';

import { loadConnectSubscription, patchConnectSubscriptionAddress } from '../../api';

export class ConnectSubscriptionBillingDetailsEdit extends Component {
  constructor() {
    super();
    /* istanbul ignore next */
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.bindClientApplicationForm = bindRef(this, 'clientApplicationForm');
    this.bindBillingAddessForm = bindRef(this, 'billingAddressForm');
    this.bindShippingAddessForm = bindRef(this, 'shippingAddressForm');
    this.handleOnShippingDifferentChange = this.handleOnShippingDifferentChange.bind(this);

    this.state = {
      loading: true,
      submitting: false,
      subscription: undefined,
      countries: undefined,
      shippingDifferent: false,
    };
  }

  componentDidMount() {
    const { intl } = this.props;
    this.loadData()
      .catch((apiErrors) => {
        this.setState({ loading: false });
        if (isNotFoundError(apiErrors)) {
          navigateTo('/404');
        } else {
          handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
        }
      });
  }

  async loadData() {
    const { match } = this.props;

    const countries = await loadAllowedCountries();
    const subscription = await loadConnectSubscription(match.params.id);
    const shippingDifferent = !!(subscription.shippingAddress); // !! will return false if property exists but is null
    subscription.billingAddress = addCountryObject(subscription.billingAddress, countries);
    subscription.shippingAddress = addCountryObject(subscription.shippingAddress, countries);
    this.setState({
      countries, subscription, shippingDifferent, loading: false,
    });
  }

  async handleOnSubmit(event) {
    const { subscription, shippingDifferent } = this.state;
    const { match, intl } = this.props;
    if (event) {
      event.preventDefault();
    }

    const shippingWasRemoved = subscription.shippingAddress && !this.shippingAddressForm;
    const paid = (subscription.externalPlanVariantReference !== 'connect-free' || isNotEmpty(subscription.addons));
    try {
      if (!paid || (this.billingAddressForm.submit() && (!shippingDifferent || this.shippingAddressForm.submit()))) {
        if ((this.billingAddressForm && this.billingAddressForm.form.dirty) || (this.shippingAddressForm && this.shippingAddressForm.form.dirty) || shippingWasRemoved) {
          await patchConnectSubscriptionAddress(
            subscription.id,
            this.billingAddressForm.values().customerPurchaseOrder,
            this.billingAddressForm.values(),
            this.shippingAddressForm ? this.shippingAddressForm.values() : null,
          );
        }
        showSuccess(intl.formatMessage({ id: 'subscription_edit.success_message' }));
        const target = match.params.checkout ? `/subscription/connect/${subscription.id}/${match.params.checkout}` : `/subscriptions/connect/${subscription.id}`;
        navigateTo(target);
      } else {
        paid && this.billingAddressForm.setSubmitting(false);
        paid && this.shippingAddressForm && this.shippingAddressForm.setSubmitting(false);
      }
    } catch (apiErrors) {
      this.setState({ submitting: false });

      if (apiErrorsContain(apiErrors, 'invalid_input', 'tax_address')) {
        showError(intl.formatMessage({ id: 'api.error.tax_address.invalid_input' }));
      } else if (apiErrorsContain(apiErrors, 'invalid_input', 'billing_address_zip_code')) {
        this.billingAddressForm.setErrors({ zipCode: intl.formatMessage({ id: 'api.error.billing_address_zip_code.invalid_input' }) });
      } else if (apiErrorsContain(apiErrors, 'invalid_input', 'shipping_address_zip_code')) {
        this.shippingAddressForm.setErrors({ zipCode: intl.formatMessage({ id: 'api.error.shipping_address_zip_code.invalid_input' }) });
      } else if (apiErrorsContain(apiErrors, 'vat_number', 'billing_address.base')) {
        this.billingAddressForm.setErrors({ vatNumber: intl.formatMessage({ id: 'validation.vat_number.mandatory' }) });
      } else if (apiErrorsContain(apiErrors, 'invalid_input', 'billing_address_vat_number')) {
        this.billingAddressForm.setErrors({ vatNumber: intl.formatMessage({ id: 'validation.vat_number.mandatory' }) });
      } else {
        handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
      }

      paid && this.billingAddressForm.setSubmitting(false);
      paid && this.shippingAddressForm && this.shippingAddressForm.setSubmitting(false);
    }
  }

  handleOnShippingDifferentChange() {
    const { shippingDifferent, subscription } = this.state;
    this.setState({
      shippingDifferent: !shippingDifferent,
      subscription: {
        ...subscription,
        shippingAddress: {
          country: subscription.billingAddress.country,
        },
      },
    });
  }

  render() {
    const { intl } = this.props;
    const {
      subscription, loading, submitting, countries, shippingDifferent,
    } = this.state;
    return (
      <div id="connect-subscription-billing-details-edit" className="container">
        <div className="row">
          <div className="col-md-7">
            { subscription ? (
              <div>
                { subscription.externalPlanVariantReference !== 'connect-free' || isNotEmpty(subscription.addons) ? (
                  <div>
                    <ActionBar>
                      <h1 id="subscription-billing-address-header"><FormattedMessage id="subscription.billing_address.header" /></h1>
                    </ActionBar>
                    <AddressForm
                      id="billing-address"
                      initialValues={{ ...subscription.billingAddress, customerPurchaseOrder: subscription.customerPurchaseOrder }}
                      onSubmit={this.handleOnSubmit}
                      countries={countries.countries}
                      prefix="billingAddress"
                      ref={this.bindBillingAddessForm}
                      showEmail
                      showVat
                      showCustomerPurchaseOrder
                      firstLastNameRequired
                    />
                    <div className="space-after">
                      <input
                        id="addressdifferent-checkbox"
                        type="checkbox"
                        className="unchecked"
                        disabled={submitting}
                        value="false"
                        onChange={this.handleOnShippingDifferentChange}
                        checked={shippingDifferent}
                      />
                      <label htmlFor="addressdifferent-checkbox"><FormattedMessage id="subscription.addresses_different" /></label>
                    </div>
                    { shippingDifferent ? (
                      <div className="space-after">
                        <ActionBar>
                          <h1 id="subscription-shipping-address-header"><FormattedMessage id="subscription.shipping_address.header" /></h1>
                        </ActionBar>
                        <AddressForm
                          id="shipping-address"
                          initialValues={subscription.shippingAddress}
                          onSubmit={this.handleOnSubmit}
                          countries={countries.countries}
                          prefix="shippingAddress"
                          ref={this.bindShippingAddessForm}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <div className="btn-group">
                  <SubmitButton id="edit-subscription-billing-details-submit" text={intl.formatMessage({ id: 'button.submit' })} onClick={this.handleOnSubmit} fetching={submitting} />
                  <CancelButton id="edit-subscription-billing-details-cancel" disabled={submitting} />
                </div>
              </div>
            ) : null}
            <Loader loading={loading} />
          </div>
        </div>
      </div>
    );
  }
}

ConnectSubscriptionBillingDetailsEdit.propTypes = {
  intl: intlShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      checkout: PropTypes.string,
    }),
  }).isRequired,
};

export default injectIntl(ConnectSubscriptionBillingDetailsEdit);
