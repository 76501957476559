import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Panel } from 'react-bootstrap';

class More extends Component {
  static propTypes = {
    children: PropTypes.node,
    label: PropTypes.shape({
      openId: PropTypes.string,
      closeId: PropTypes.string,
    }),
  };

  /* istanbul ignore next */
  constructor() {
    super();
    this.state = { open: false };
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick() {
    const { open } = this.state;
    this.setState({ open: !open });
  }

  render() {
    const { children, label } = this.props;
    return (
      <div className="more">
        <button
          id="more"
          type="button"
          className="btn-link btn-action"
          onClick={this.handleOnClick}
        >
          <FormattedMessage id={this.state.open ? (label?.closeId || 'global.less') : (label?.openId || 'global.more')} />
        </button>
        <Panel expanded={this.state.open} onToggle>
          <Panel.Collapse>
            <Panel.Body>
              {children}
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </div>
    );
  }
}

export default More;
