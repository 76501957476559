import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { navigateTo } from '../utils/browserUtils';
import { isNotFoundError, handleApiErrors } from '../api';
import { promiseShape } from '../shapes';

export class ApiErrorsHandler extends Component {
  static propTypes = {
    children: PropTypes.node,
    handleNotFound: PropTypes.bool,
    submitCallback: promiseShape,
    onError: PropTypes.func,
  };

  componentDidUpdate(prevProps) {
    const { onError } = prevProps;
    if (!onError || !onError(prevProps, this.props)) {
      this.handleErrors(this.props);
    }
  }

  handleErrors(nextProps) {
    const { handleNotFound, submitCallback } = this.props;
    if (handleNotFound && isNotFoundError(nextProps.errors)) {
      navigateTo('/404');
    } else {
      handleApiErrors(submitCallback, this.props, nextProps);
    }
  }

  render() {
    return (
      <span role="alert">
        {this.props.children}
      </span>
    );
  }
}

export default ApiErrorsHandler;
