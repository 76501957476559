import { scaleImageFile } from './imageUtils';
import { extractPicture } from '../extractors';

const MAX_PICTURE_SIZE = 1024 * 1024;

export const handleNextPictures = async (api, model, modelId, currentPictures, files, rejectedFiles, resolve, reject, currentFileStorage, fileStorageLimit) => {
  if (files.length > 0) {
    /* eslint-disable no-use-before-define */
    await uploadPictures(api, model, modelId, currentPictures, files, rejectedFiles, currentFileStorage, fileStorageLimit)
      .then(() => resolve())
      .catch((errors) => reject(errors));
  } else {
    resolve();
  }
};

export const uploadPictures = async (api, model, modelId, currentPictures, files, rejectedFiles, currentFileStorage, fileStorageLimit) => new Promise((resolve, reject) => {
  if (files.length === 0) {
    resolve();
  } else {
    const nextFile = files.shift();
    let newCurrentFileStorage = currentFileStorage;
    scaleImageFile(nextFile, 1200, 0.8).then(async (compressedImageFile) => {
      if (compressedImageFile.size > MAX_PICTURE_SIZE) {
        nextFile.uploadError = 'size_limit_exceeded';
        rejectedFiles.push(nextFile);
        await handleNextPictures(api, model, modelId, currentPictures, files, rejectedFiles, resolve, reject, currentFileStorage, fileStorageLimit);
      } else if (compressedImageFile.size + currentFileStorage > fileStorageLimit) {
        nextFile.uploadError = 'storage_limit_exceeded';
        rejectedFiles.push(nextFile);
        await handleNextPictures(api, model, modelId, currentPictures, files, rejectedFiles, resolve, reject, currentFileStorage, fileStorageLimit);
      } else {
        try {
          const response = await api.upload(`/${model}/${modelId}/pictures`, {
            image: compressedImageFile,
          });
          newCurrentFileStorage = currentFileStorage + compressedImageFile.size;
          currentPictures.push(extractPicture(response));
          await handleNextPictures(api, model, modelId, currentPictures, files, rejectedFiles, resolve, reject, newCurrentFileStorage, fileStorageLimit);
        } catch (errors) {
          if (errors.length > 0 && errors[0].type === 'quota_exceeded') {
            nextFile.uploadError = 'storage_limit_exceeded';
            rejectedFiles.push(nextFile);
            await handleNextPictures(api, model, modelId, currentPictures, files, rejectedFiles, resolve, reject, currentFileStorage, fileStorageLimit);
          } else {
            reject(errors);
          }
        }
      }
    });
  }
});
