import AssetRules from './AssetRules';
import { isSomething } from '../utils';

class InstrumentationRules {
  constructor(instrumentation, assets) {
    this.instrumentation = instrumentation;
    this.assetRules = assets ? assets.map((asset) => new AssetRules(asset)) : [];

    // special hack to show special number format for FWR30 on the all objects List
    if (this.assetRules.length === 0 && (isSomething(instrumentation?.full) || isSomething(instrumentation?.empty))) {
      this.assetRules = [new AssetRules({ manufacturerName: 'Endress+Hauser', productCode: 'FWR30', productTenantPublic: true })];
    }
  }

  get(rule, a, b, c) {
    const matchingRule = this.assetRules.find((assetRule) => assetRule.get(rule, a, b, c));
    return matchingRule ? matchingRule.get(rule, a, b, c) : undefined;
  }
}

export default InstrumentationRules;
