import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  ActionBar,
  Details,
  DetailsHeaderItem,
  DetailsItem,
  DetailsPicture,
  Picture,
} from 'lcm-iot-commons';
import { assetShape } from '../../shapes/assetsShape';

export function AssetDetailsItem({ asset }) {
  return asset ? (
    <div className="asset-detail-item">
      <ActionBar>
        <h2><FormattedMessage id="label.asset" /></h2>
      </ActionBar>
      <Details>
        <DetailsPicture>
          <Picture src={asset.productPictureUrl} alt={asset.serialNumber} placeholder="icon-eh-device" border />
        </DetailsPicture>
        <DetailsHeaderItem id="asset-serialnumber" translationKey="label.serial_number" value={asset.serialNumber} breakValue />
        <DetailsItem id="asset-manufacturer" translationKey="label.manufacturer" value={asset.manufacturerName} />
      </Details>
    </div>
  ) : null;
}

AssetDetailsItem.propTypes = {
  asset: assetShape,
};

AssetDetailsItem.defaultProps = {
  asset: undefined,
};

export default injectIntl(AssetDetailsItem);
