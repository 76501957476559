class Rules {
  get(rule, a, b, c) {
    if (this[rule]) {
      return this[rule](a, b, c);
    }
    return undefined;
  }
}

export default Rules;
