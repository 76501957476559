import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { extractThumbnailPictureUrl } from '../../extractors/picturesExtractor';
import { intlShape, sessionShape } from '../../shapes';

import {
  withSession,
  withConfiguration,
} from '../../context';

import TypeaheadBox from '../Form/TypeaheadBox';
import { TextInput } from '../Form';
import { handleFormikValueChange } from '../../utils/formikUtils';
import { useApi } from '../../hooks';

export function ProductTypeahead(props) {
  const {
    intl, manufacturerId, errors, values, name, id, isEndress, assetId, session, configuration,
  } = props;
  const { lcmApiUrl } = configuration;
  const { token } = session;

  const [searchText, setSearchText] = useState('');
  const [filterAttribute, setFilterAttribute] = useState('product_code');
  const api = useApi();

  const filter = { include: 'tenant,pictures', manufacturer_id: manufacturerId, include_total_count: false };
  filter[filterAttribute] = `${searchText}*`;
  const endpoint = assetId ? `/assets/${assetId}/product-options` : '/products';
  const { data, isFetching } = api.get.useQuery(endpoint, filter, { enabled: !!manufacturerId });
  const products = data?.products?.map((product) => ({
    id: product.id,
    name: product.name,
    code: product.product_code,
    manufacturerId,
    pictureUrl: extractThumbnailPictureUrl(product.pictures),
    tenantId: product?.tenant?.id,
    tenantPublic: product?.tenant?.public,
    tenantName: product?.tenant?.name,
  }));

  const handleLoadOptions = (text, attribute) => {
    setSearchText(text);
    setFilterAttribute(attribute);
  };

  React.useEffect(() => {
    handleLoadOptions(values[name]?.code || '', 'product_code');
  }, [manufacturerId, assetId, values.product]);

  const renderOption = (option) => {
    let image = null;

    let src = option.pictureUrl;

    if (src?.startsWith(lcmApiUrl)) {
      src = `${src}?access_token=${token}`;
    }

    image = (src) ? (
      <img src={src} alt={option.productCode} />
    ) : (
      <span className="icon icon-eh-device" />
    );

    return (
      <div className="select-item">
        <div className="select-item-image">
          {image}
        </div>
        <div className="select-item-content">
          <div className="select-item-header">{option.code}</div>
          <div className="select-item-details">{option.name}</div>
        </div>
      </div>
    );
  };

  const selectedProduct = values.product;

  const productNameErrors = {};
  productNameErrors[name] = errors ? errors[`${name}Name`] : undefined;

  const handleOnChange = (type, value) => {
    if (values.product?.new && !value && type === 'code' && values.product.name?.length > 0) {
      handleFormikValueChange(props, { ...values.product, code: '' });
      return;
    }

    if (values.product?.new && !value && type === 'name' && values.product.code?.length > 0) {
      handleFormikValueChange(props, { ...values.product, name: '' });
      return;
    }

    if (values.product?.new && value?.new) {
      handleFormikValueChange(props, { ...values.product, ...value });
    } else {
      handleFormikValueChange(props, value);
    }
  };

  return (
    <>
      <div className="clearfix">
        <div className={isEndress ? 'col-xs-6 col-sm-6 no-padding-left' : ''}>
          <TypeaheadBox
            {...props}
            data-testid="product-code-type-ahead"
            id={`${id}-code`}
            onChange={(value) => handleOnChange('code', value)}
            fetching={isFetching}
            label={intl.formatMessage({ id: 'label.product_code' })}
            labelKey="code"
            loadOptions={(text) => handleLoadOptions(text, 'product_code')}
            options={products}
            renderOption={renderOption}
            info={selectedProduct?.new ? intl.formatMessage({ id: 'product_typeahead.new_info' }) : null}
            loadOptionsOnMount={false}
            required
          />
        </div>
        {isEndress ? (
          <div className="col-xs-6 col-sm-6 input-separator no-padding-right">
            <TextInput
              {...props}
              id="order-code"
              name="orderCode"
            />
          </div>
        ) : null}
      </div>
      <TypeaheadBox
        {...props}
        data-testid="product-name-type-ahead"
        id={`${id}-name`}
        onChange={(value) => handleOnChange('name', value)}
        fetching={isFetching}
        errors={productNameErrors}
        label={intl.formatMessage({ id: 'label.product_name' })}
        loadOptions={(text) => handleLoadOptions(text, 'name')}
        options={products}
        renderOption={renderOption}
        loadOptionsOnMount={false}
      />
    </>
  );
}

ProductTypeahead.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  manufacturerId: PropTypes.number,
  isEndress: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  session: sessionShape.isRequired,
  configuration: PropTypes.shape({ lcmApiUrl: PropTypes.string }).isRequired,
  assetId: PropTypes.number,
  errors: PropTypes.shape({
    productName: PropTypes.shape({}),
  }),
  values: PropTypes.shape({
    product: PropTypes.shape({
      manufacturer: PropTypes.shape({ id: PropTypes.number }),
      new: PropTypes.bool,
      code: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
};

ProductTypeahead.defaultProps = {
  id: 'product',
  name: 'product',
  manufacturerId: undefined,
  errors: undefined,
};

export default injectIntl(withSession(withConfiguration(ProductTypeahead)));
