import { BadRequestError, isNothing } from 'lcm-iot-commons';

class ProductFormLogic {
  #api;

  #notifier;

  #intl;

  #browser;

  #edit;

  #initialValues;

  constructor(api, notifier, intl, browser, edit, initialValues) {
    this.#api = api;
    this.#notifier = notifier;
    this.#intl = intl;
    this.#browser = browser;
    this.#edit = edit;
    this.#initialValues = initialValues;
  }

  #createTenant = async (tenant, actions) => {
    try {
      return { id: (await this.#api.post('/tenants', { name: tenant.name })).id };
    } catch (error) {
      if (error instanceof BadRequestError
        && error.contains('taken')) {
        actions.setErrors({
          tenant: this.#intl.formatMessage({ id: 'api.error.tenant.taken' }),
        });
        return null;
      }
      throw error;
    }
  };

  #createManufacturer = async (manufacturer, tenant, actions) => {
    try {
      return { id: (await this.#api.post('/companies', { name: manufacturer.name, tenant: { id: tenant.id } })).id };
    } catch (error) {
      if (error instanceof BadRequestError
        && error.contains('taken')) {
        actions.setErrors({
          manufacturer: this.#intl.formatMessage({ id: 'api.error.manufacturer.taken' }),
        });
        return null;
      }
      throw error;
    }
  };

  #createCategory = async (category, tenant) => ({ id: (await this.#api.post('/product/categories', { name: category.name, tenant: { id: tenant.id } })).id });

  #handleProduct = async (payload, actions) => {
    try {
      if (this.#edit) {
        await this.#api.patch(`/products/${this.#initialValues.id}`, payload);
        return { id: this.#initialValues.id };
      }
      return { id: (await this.#api.post('/products', payload)).id };
    } catch (error) {
      if (error instanceof BadRequestError
        && error.contains('taken')) {
        actions.setErrors({
          code: this.#intl.formatMessage({ id: 'api.error.product_code.taken' }),
        });
        return null;
      }
      throw error;
    }
  };

  #assignProductCategory = async (category, product) => {
    if (this.#edit) {
      if (category && category.id !== this.#initialValues.category?.id) {
        await this.#api.patch(`/products/${this.#initialValues.id}/categories`, { categories: [{ id: category.id }] });
      }
    } else {
      await this.#api.post(`/product/categories/${category.id}/products`, { products: [{ id: product.id }] });
    }
  };

  #unassignProductCategory = async () => {
    await this.#api.delete(`/products/${this.#initialValues.id}/categories`, { categories: [{ id: this.#initialValues.category?.id }] });
  };

  handleOnSubmit = async (values, actions) => {
    try {
      const {
        code, name, description, status, parent, riskOfMaintainability,
      } = values;
      let { tenant, manufacturer, category } = values;

      if (tenant.new) {
        tenant = await this.#createTenant(tenant, actions);
        if (!tenant) {
          return;
        }
      }
      if (manufacturer.new) {
        manufacturer = await this.#createManufacturer(manufacturer, tenant, actions);
        if (!manufacturer) {
          return;
        }
      }
      if (category?.new) {
        category = await this.#createCategory(category, tenant);
      }
      const productPayload = {
        product_code: code,
        manufacturer: { id: manufacturer.id },
        status: { id: status.id },
        tenant: { id: tenant.id },
        risk_of_maintainability: isNothing(riskOfMaintainability) ? 'undefined' : riskOfMaintainability.id,
      };
      productPayload.parent = parent ? { id: parent.id } : null;
      productPayload.name = name;
      productPayload.description = description || '';
      const product = await this.#handleProduct(productPayload, actions);
      if (!product) {
        return;
      }
      if (category) {
        await this.#assignProductCategory(category, product);
      } else if (this.#initialValues?.category?.id) {
        await this.#unassignProductCategory(category);
      }
      if (this.#edit) {
        this.#notifier.showSuccess(this.#intl.formatMessage({ id: 'product_update.success_notification' }));
      } else {
        this.#notifier.showSuccess(this.#intl.formatMessage({ id: 'product_create.success_notification' }));
      }
      this.#browser.navigateTo(`/products/${product.id}`);
    } catch (error) {
      this.#notifier.showError(this.#api.translateError(error));
      this.#browser.navigateTo('/products');
    } finally {
      actions.setSubmitting(false);
    }
  };
}

export default ProductFormLogic;
