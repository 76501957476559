import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { intlShape } from '../../shapes';
import NamurIcon from '../NamurIcon';
import ContextualHelp from '../Contextuals/ContextualHelp';
import CustomOverlayTrigger from '../CustomOverlayTrigger';

export function DetailsItem({
  id,
  translationKey,
  label,
  value,
  children,
  intl,
  icon,
  toolTip,
  breakValue,
  isNamurIcon,
  className,
  headerSuffix,
  contextHelperTitle,
  contextHelperChildren,
}) {
  const displayLabel = label || intl.formatMessage({ id: translationKey });
  let displayIcon = null;
  if (icon) {
    displayIcon = (isNamurIcon ? <NamurIcon type={icon} size={24} /> : (<span className={icon} />));
  }

  const displayTooltip = toolTip ? (
    <Tooltip id="tooltip">{toolTip}</Tooltip>
  ) : null;

  const displayValue = toolTip ? (
    <div className={breakValue ? 'details-item-value break-all' : 'details-item-value'}>
      <CustomOverlayTrigger placement="right" overlay={displayTooltip}>
        <span>
          {displayIcon}
          {' '}
          {value || '-'}
        </span>
      </CustomOverlayTrigger>
    </div>
  ) : (
    <div className={breakValue ? 'details-item-value break-all' : 'details-item-value'}>
      <div className={isNamurIcon ? 'details-item-namur-value' : ''}>
        {displayIcon}
        {' '}
        <span>{value || children || '-'}</span>
      </div>
    </div>
  );

  const contextHelper = contextHelperTitle ? (
    <ContextualHelp title={contextHelperTitle} interactive>
      {contextHelperChildren}
    </ContextualHelp>
  ) : null;

  const style = className ? `details-item ${className}` : 'details-item';
  return (
    <div id={id} className={style}>
      <div className="details-item-name">
        {displayLabel}
        {contextHelper}
        {headerSuffix}
      </div>
      {displayValue}
    </div>
  );
}

DetailsItem.propTypes = {
  id: PropTypes.string,
  translationKey: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  toolTip: PropTypes.string,
  intl: intlShape.isRequired,
  breakValue: PropTypes.bool,
  children: PropTypes.node,
  headerSuffix: PropTypes.node,
  isNamurIcon: PropTypes.bool,
  className: PropTypes.string,
  contextHelperTitle: PropTypes.string,
  contextHelperChildren: PropTypes.node,
};

DetailsItem.defaultProps = {
  isNamurIcon: false,
  contextHelperTitle: undefined,
  contextHelperChildren: undefined,
};

export default injectIntl(DetailsItem);
