import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typeahead from '../Form/Typeahead';
import { handleFormikValueChange, convertSpecificationKeyToLabel } from '../../utils';
import {
  withApi, apiShape, withNotifier, notifierShape,
} from '../../context';
import { withIntl } from '../../wrappers';

export function SpecificationKeyTypeahead(props) {
  const [specificationKeys, setSpecificationKeys] = useState([]);
  const [fetching, setFetching] = useState();
  const selectedOption = useRef();
  const {
    api,
    valueArrayName,
    notifier,
    specificationKeysEndpoint,
    objIdx,
    values,
  } = props;

  const loadKeys = async (searchText = '') => {
    try {
      if (searchText.length < 101) {
        setFetching(true);

        const response = await api.get(specificationKeysEndpoint, {
          ui_visible: true,
          key: `${searchText}*`,
        });

        setSpecificationKeys(response.specification_keys.map((key) => ({
          id: key,
          name: convertSpecificationKeyToLabel(key),
        })));
      }
    } catch (error) {
      notifier.showError(api.translateError(error));
    } finally {
      setFetching(false);
    }
  };

  const options = specificationKeys.filter((option) => !values.specifications.find((specification) => specification.key?.name === option.name));

  useEffect(() => {
    const selectedOptionLabel = values && values[valueArrayName] && values[valueArrayName][objIdx].key?.name;
    selectedOption.current = (specificationKeys.find((option) => option.name === selectedOptionLabel)
     || (values && values[valueArrayName] && values[valueArrayName][objIdx].key !== '' && values[valueArrayName][objIdx].key)) || undefined;
  }, [values, specificationKeys]);

  return (
    <Typeahead
      {...props}
      onChange={(value) => handleFormikValueChange(props, value)}
      selectedOption={selectedOption.current}
      options={options}
      fetching={fetching}
      loadOptions={loadKeys}
      loadOptionsOnMount
      canCreate
    />
  );
}

SpecificationKeyTypeahead.propTypes = {
  api: apiShape.isRequired,
  valueArrayName: PropTypes.string.isRequired,
  notifier: notifierShape.isRequired,
  specificationKeysEndpoint: PropTypes.string.isRequired,
  objIdx: PropTypes.number.isRequired,
  values: PropTypes.shape({ specifications: PropTypes.arrayOf(PropTypes.shape({})) }).isRequired,
};

export default withIntl(withNotifier(withApi(SpecificationKeyTypeahead)));
