import PropTypes from 'prop-types';
import React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';

function ActionDropdownButton({
  children, title, icon, disabled,
}) {
  const className = `btn-link btn-action ${icon}`;

  return (
    <div className="actionbar-dropdown">
      <DropdownButton
        disabled={disabled}
        id="dropdown_button"
        bsStyle="default"
        className={className}
        noCaret
        title={<span className="action-button-label hidden-xs">{title}</span>}
      >
        {children}
        <MenuItem className="close-item">
          <span className="icon icon-eh-show-less" />
        </MenuItem>
      </DropdownButton>
    </div>
  );
}

ActionDropdownButton.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

ActionDropdownButton.defaultProps = {
  disabled: false,
  children: undefined,
};

export default ActionDropdownButton;
