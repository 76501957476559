import React from 'react';
import PropTypes from 'prop-types';
import {
  DropdownButton, MenuItem,
} from 'react-bootstrap';
import Chip from './Chip';

export default function Chips({ items, onRemove, withTooltip = true }) {
  const chipsRef = React.useRef(null);
  const [displayedChips, setDisplayedChips] = React.useState();

  React.useEffect(() => {
    /* istanbul ignore next */
    const calculateDisplayedChips = () => {
      if (chipsRef.current) {
        const chipsContainer = chipsRef.current;
        const chipsElements = Array.from(chipsContainer.children);
        // - 40px for the +(number of chips in dropdown) action
        const availableWidth = Math.floor(chipsContainer.clientWidth) - 40;
        let remainingWidth = availableWidth;
        const visibleChips = [];

        items.forEach((chip) => {
          const chipElement = chipsElements.find((element) => element.textContent === chip);
          if (chipElement) {
            // + 4px because gap between the chips
            const chipWidth = Math.floor(chipElement.clientWidth) + 4;
            if (remainingWidth - chipWidth <= 0) return;

            remainingWidth -= chipWidth;
            visibleChips.push(chip);
          }
        });

        setDisplayedChips(visibleChips);
      }
    };

    calculateDisplayedChips();
  }, [items]);

  const chipsInDropdown = items?.filter((chip) => !displayedChips?.includes(chip));
  const dropdown = displayedChips?.length < items.length && (
  <div className="chips-item-actions">
    <div className="list-item-menu">
      <DropdownButton
        onClick={(e) => e.stopPropagation()}
        bsStyle="default"
        title={(<span className="chip more-chips">{`+${items.length - displayedChips.length}`}</span>)}
        noCaret
        id="dropdown-menu"
        data-testid="dropdown-menu-button"
        pullRight
      >
        {chipsInDropdown.map((chip) => (
          /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
          <li onClick={/* istanbul ignore next */ (e) => e.stopPropagation()} key={`menu-item-${chip}`}>
            <Chip key={chip} item={chip} onRemove={onRemove} withTooltip={withTooltip} />
          </li>
        ))}
        <MenuItem onClick={(e) => e.stopPropagation()} className="close-item">
          <span data-testid="dropdown-close-button" className="icon icon-eh-show-less" />
        </MenuItem>
      </DropdownButton>
    </div>
  </div>
  );

  return (
    <div className="chips-container">
      <div ref={chipsRef} aria-hidden="true" className="chips">
        {items.map((item) => (
          <Chip key={`hidden-${item}`} item={item} onRemove={onRemove} />
        ))}
      </div>
      <div data-testid="chips" className="chips">
        {displayedChips?.map(/* istanbul ignore next */ (chip) => (
          <Chip key={chip} item={chip} onRemove={onRemove} withTooltip={withTooltip} />
        ))}
        {dropdown}
      </div>
    </div>
  );
}

Chips.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRemove: PropTypes.func,
  withTooltip: PropTypes.bool,
};
