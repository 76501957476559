import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import SearchFilterItem from './SearchFilterItem';
import { useRules } from '../../context';
import { withFlipper } from '../../wrappers';

export function SearchFilters({ flipper, filters, onChange }) {
  const rules = useRules();
  const intl = useIntl();

  const handleFilterChange = (filterName, checked) => {
    if (checked) {
      onChange([...filters, filterName]);
    }
    if (!checked) {
      onChange(filters.filter((item) => item !== filterName));
    }
  };

  return (

    <ul className="list-inline">
      <li className="list-inline-item hidden-sm hidden-xs">
        <span className="additional-info filter-label">
          <FormattedMessage id="search.filters" />
        </span>
      </li>
      <SearchFilterItem
        label={intl.formatMessage({ id: 'search.filters.assets' })}
        filter="assets"
        onChange={handleFilterChange}
        checked={filters.indexOf('assets') >= 0}
      />
      <SearchFilterItem
        label={intl.formatMessage({ id: 'search.filters.nodes' })}
        filter="nodes"
        onChange={handleFilterChange}
        checked={filters.indexOf('nodes') >= 0}
      />
      <SearchFilterItem
        label={intl.formatMessage({ id: 'search.filters.instrumentations' })}
        filter="instrumentations"
        onChange={handleFilterChange}
        checked={filters.indexOf('instrumentations') >= 0}
      />
      {rules.application().get('supportsSystems') && !flipper.fermentationDeactivated ? (
        <SearchFilterItem
          label={intl.formatMessage({ id: 'search.filters.systems' })}
          filter="systems"
          onChange={handleFilterChange}
          checked={filters.indexOf('systems') >= 0}
        />
      ) : null }
      {rules.application().get('supportsBatches') && !flipper.fermentationDeactivated ? (
        <SearchFilterItem
          label={intl.formatMessage({ id: 'search.filters.batches' })}
          filter="batches"
          onChange={handleFilterChange}
          checked={filters.indexOf('batches') >= 0}
        />
      ) : null }
      {rules.application().get('supportsRecipes') && !flipper.fermentationDeactivated ? (
        <SearchFilterItem
          label={intl.formatMessage({ id: 'search.filters.recipes' })}
          filter="recipes"
          onChange={handleFilterChange}
          checked={filters.indexOf('recipes') >= 0}
        />
      ) : null }
    </ul>

  );
}

SearchFilters.propTypes = {
  flipper: PropTypes.shape({
    fermentationDeactivated: PropTypes.bool,
  }).isRequired,
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withFlipper(SearchFilters);
