/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import { Colors } from 'lcm-iot-commons';

// component can be moved to commons someday
function ListItemWithCheckBox({
  icon,
  checked,
  listItem,
  onAddCheck,
  onRemoveCheck,
}) {
  const onCheckBoxClick = async () => {
    if (checked === true) { await onRemoveCheck(listItem.id); } else { await onAddCheck(listItem.id); }
  };

  return (
    <div className="list-item">
      <div className="list-item-icon primary">
        <i className={`icon ${icon}`} style={{ color: Colors.EH_WINTER_GRAY_2 }} />
      </div>
      <div className="list-item-content">
        <div className="list-item-content-wrapper">
          <div className="list-item-content-title">{listItem.name}</div>
          <div className="list-item-content-description">{listItem.description}</div>
        </div>
        { listItem.value ? (<div className="list-item-content-value">{listItem.value}</div>) : null }
      </div>

      <div className="list-item-checkbox">
        <input
          className="checkbox-input"
          id={`checkbox-input-item-${listItem.id}`}
          type="checkbox"
          checked={checked}
          onChange={onCheckBoxClick}
        />
        <label htmlFor={`checkbox-input-item-${listItem.id}`} />
      </div>
    </div>
  );
}

ListItemWithCheckBox.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onAddCheck: PropTypes.func.isRequired,
  onRemoveCheck: PropTypes.func.isRequired,
  listItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    value: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

ListItemWithCheckBox.defaultProps = {
  id: 'list-item-with-checkbox',
  icon: 'icon lcm-iot-icon-application',
};

export default ListItemWithCheckBox;
