import ApiClientError from './ApiClientError';

class InternalServerError extends ApiClientError {
  /* istanbul ignore next */
  constructor(request, response, cause) {
    super('InternalServerError', 500, 'An unknwon error occured on the server.', request, response, cause);
  }
}

export default InternalServerError;
