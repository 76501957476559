import PropTypes from 'prop-types';
import React from 'react';

function MenuItemAddon({ id, className, children }) {
  return <div id={id} className={className}>{children}</div>;
}

MenuItemAddon.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default MenuItemAddon;
