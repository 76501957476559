import {
  extractPlanCart as commonsExtractPlanCart,
} from 'lcm-iot-commons';

import { extractClientApplication } from './clientApplicationExtractor';

export function extractPlanCart(rawPlanCart) {
  return {
    ...commonsExtractPlanCart(rawPlanCart),
    subscriptionId: rawPlanCart.subscription_id,
    clientApplication: rawPlanCart.client_application ? extractClientApplication(rawPlanCart.client_application) : undefined,
  };
}
