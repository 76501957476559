import PropTypes from 'prop-types';
import React from 'react';
import { intlShape, withIntl } from 'lcm-iot-commons';

import { Breadcrumb, BreadcrumbItem } from 'lcm-iot-commons/client/lib/components/Breadcrumb';
import { SignUpBreadcrumbLabels, SignInTermsBreadcrumbLabels } from '../../context/SignUpContext';

const buildBreadCrumb = (intl, current, action) => {
  const items = action === 'signup' ? SignUpBreadcrumbLabels : SignInTermsBreadcrumbLabels;

  return items.map((item) => (
    <BreadcrumbItem
      className="breadcrumb-item-eh-style breadcrumb-not-focused"
      focus={item.key === current}
      key={item.key}
      title={intl.formatMessage({ id: `${action}.breadcrumb.${item.key}` })}
    />
  ));
};

export function SignUpBreadcrumb({
  intl, current, action,
}) {
  const breadcrumb = buildBreadCrumb(intl, current, action);

  return (
    <Breadcrumb id="sign-up-header">
      {breadcrumb}
    </Breadcrumb>
  );
}

SignUpBreadcrumb.propTypes = {
  current: PropTypes.string,
  intl: intlShape.isRequired,
  action: PropTypes.string.isRequired,
};

SignUpBreadcrumb.defaultProps = {
  current: undefined,
};

export default withIntl(SignUpBreadcrumb);
