import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Formik } from 'formik';

import {
  CancelButton, SelectBox, SubmitButton, TextArea, TextInput, ToggleButton,
} from '../Form';

import {
  isEmpty,
  parseIntlNumber,
  isValidDecimalSeparator,
  isValidFloat,
  numberFormatter,
  isNothing,
} from '../../utils';

import {
  validateSpecifications,
} from '../../validators';

import InstrumentationFullEmptyCalibrationHelp from './InstrumentationFullEmptyCalibrationHelp';

import {
  instrumentationShape,
  instrumentationStatusesShape,
  instrumentationStatusShape,
  instrumentationTypesShape,
  instrumentationTypeShape,
  intlShape,
} from '../../shapes';
import More from '../More';
import SpecificationsInput from '../Specifications/SpecificationsInput';

export function InstrumentationForm({
  instrumentation,
  intl,
  instrumentationStatuses,
  instrumentationTypes,
  assetFirmwareVersion,
  assetSensorBuildNumber,
  defaultValues,
  onSubmit,
  cancelTarget,
  instrumentationRules,
  collapseProperties,
}) {
  const criticality = [
    { id: 'low', name: intl.formatMessage({ id: 'enum.criticality.low' }) },
    { id: 'medium', name: intl.formatMessage({ id: 'enum.criticality.medium' }) },
    { id: 'high', name: intl.formatMessage({ id: 'enum.criticality.high' }) },
    { id: 'undefined', name: intl.formatMessage({ id: 'enum.criticality.undefined' }) },
  ];
  const accessibility = [
    { id: 'easy', name: intl.formatMessage({ id: 'enum.accessibility.easy' }) },
    { id: 'moderate', name: intl.formatMessage({ id: 'enum.accessibility.moderate' }) },
    { id: 'difficult', name: intl.formatMessage({ id: 'enum.accessibility.difficult' }) },
    { id: 'undefined', name: intl.formatMessage({ id: 'enum.accessibility.undefined' }) },
  ];
  const sensitivity = [
    { id: 'low', name: intl.formatMessage({ id: 'enum.sensitivity.low' }) },
    { id: 'medium', name: intl.formatMessage({ id: 'enum.sensitivity.medium' }) },
    { id: 'high', name: intl.formatMessage({ id: 'enum.sensitivity.high' }) },
  ];

  const mediumTypes = [
    { id: 'liquid', name: intl.formatMessage({ id: 'enum.medium_type.liquid' }) },
    { id: 'solid', name: intl.formatMessage({ id: 'enum.medium_type.solid' }) },
  ];

  const initialValues = instrumentation
    ? {
      tag: instrumentation.tag,
      description: instrumentation.description,
      status: instrumentationStatuses.find((s) => s.name === instrumentation.statusName),
      type: instrumentationTypes.find((t) => t.name === instrumentation.typeName),
      accessibility: accessibility.find((a) => a.id === instrumentation.accessibility),
      criticality: criticality.find((c) => c.id === instrumentation.criticality),
      criticalityQuality: instrumentation.criticalityQuality,
      criticalityEnvironment: instrumentation.criticalityEnvironment,
      criticalitySafety: instrumentation.criticalitySafety,
      specialDemands: instrumentation.specialDemands,
      environmentalCondition: instrumentation.environmentalCondition,
      unit: instrumentation.unit ? instrumentation.unit : instrumentationRules?.get('fullEmptyCalibrationUnitOptions')?.find((o) => o.id === 'mm'),
      empty: instrumentation.empty ? numberFormatter({ useGrouping: false, maximumFractionDigits: 10 }).format(instrumentation.empty) : '',
      full: instrumentation.full ? numberFormatter({ useGrouping: false, maximumFractionDigits: 10 }).format(instrumentation.full) : '',
      blockDistance: instrumentation.blockDistance ? numberFormatter({ useGrouping: false, maximumFractionDigits: 10 }).format(instrumentation.blockDistance) : '-1',
      sensitivity: sensitivity.find((s) => s.id === instrumentation.sensitivity) || sensitivity.find((s) => s.id === 'medium'),
      mediumType: mediumTypes.find((s) => s.id === instrumentation.mediumType) || mediumTypes.find((s) => s.id === 'liquid'),
      specifications: instrumentation.specifications,
    }
    : {
      tag: defaultValues.tag,
      status: defaultValues.status ? defaultValues.status : instrumentationStatuses.find((s) => s.code === 'undefined'),
      type: defaultValues.type ? defaultValues.type : instrumentationTypes.find((t) => t.code === 'undefined'),
      accessibility: accessibility.find((a) => a.id === defaultValues.accessibility) || accessibility.find((a) => a.id === 'undefined'),
      criticality: criticality.find((a) => a.id === defaultValues.criticality) || criticality.find((a) => a.id === 'undefined'),
      unit: instrumentationRules?.get('fullEmptyCalibrationUnitOptions')?.find((o) => o.id === 'mm'),
      blockDistance: '-1',
      sensitivity: sensitivity.find((s) => s.id === defaultValues.sensitivity) || sensitivity.find((s) => s.id === 'medium'),
      mediumType: mediumTypes.find((s) => s.id === defaultValues.mediumType) || mediumTypes.find((s) => s.id === 'liquid'),
    };

  const [customBlockDistance, disableCustomBlockDistance] = useState(initialValues.blockDistance !== '-1');

  const validateForm = (values) => {
    const errors = {};

    if (isEmpty(values.tag) || isEmpty(values.tag.trim())) {
      errors.tag = intl.formatMessage({ id: 'validation.tag.mandatory' });
    } else if (values.tag.length > 255) {
      errors.tag = intl.formatMessage({ id: 'validation.tag.too_long' });
    }

    if (instrumentationRules && instrumentationRules?.get('needsFullEmptySettings')) {
      if (!values.unit) {
        errors.unit = intl.formatMessage({ id: 'validation.unit.mandatory' });
      }

      if (!values.empty) {
        errors.empty = intl.formatMessage({ id: 'validation.empty.mandatory' });
      } else if (!isValidDecimalSeparator(values.empty) || !isValidFloat(values.empty)) {
        errors.empty = intl.formatMessage({ id: 'validation.number.invalid' });
      } else {
        const emptyValue = parseIntlNumber(values.empty);
        const range = instrumentationRules?.get('fullEmptyRange', values.unit.id);
        if (emptyValue < range.min) {
          errors.empty = intl.formatMessage({ id: 'validation.empty.smallerthanrange' }, { min: `${range.min}${values.unit.id}` });
        } else if (emptyValue > range.max) {
          errors.empty = intl.formatMessage({ id: 'validation.empty.biggerthanrange' }, { max: `${range.max}${values.unit.id}` });
        }
      }

      if (!values.full) {
        errors.full = intl.formatMessage({ id: 'validation.full.mandatory' });
      } else if (!isValidDecimalSeparator(values.full) || !isValidFloat(values.full)) {
        errors.full = intl.formatMessage({ id: 'validation.number.invalid' });
      } else {
        const fullValue = parseIntlNumber(values.full);
        const range = instrumentationRules?.get('fullEmptyRange', values.unit.id);
        if (fullValue < range.min) {
          errors.full = intl.formatMessage({ id: 'validation.full.smallerthanrange' }, { min: `${range.min}${values.unit.id}` });
        } else if (fullValue > range.max) {
          errors.full = intl.formatMessage({ id: 'validation.full.biggerthanrange' }, { max: `${range.max}${values.unit.id}` });
        }
      }

      if (!values.blockDistance) {
        errors.blockDistance = intl.formatMessage({ id: 'validation.block_distance.mandatory' });
      } else if (!isValidDecimalSeparator(values.blockDistance) || !isValidFloat(values.blockDistance)) {
        errors.blockDistance = intl.formatMessage({ id: 'validation.number.invalid' });
      } else {
        const blockDistanceValue = parseIntlNumber(values.blockDistance);
        const range = instrumentationRules?.get('blockDistanceRange', values.unit.id);
        if (blockDistanceValue !== -1) {
          if (blockDistanceValue < range.min) {
            errors.blockDistance = intl.formatMessage({ id: 'validation.block_distance.smallerthanrange' }, { min: `${range.min}${values.unit.id}` });
          } else if (blockDistanceValue > range.max) {
            errors.blockDistance = intl.formatMessage({ id: 'validation.block_distance.biggerthanrange' }, { max: `${range.max}${values.unit.id}` });
          }
        }
      }

      if (values.empty && values.full && values.blockDistance) {
        const emptyValue = parseIntlNumber(values.empty);
        const fullValue = parseIntlNumber(values.full);
        const blockDistanceValue = parseIntlNumber(values.blockDistance);
        if (fullValue > emptyValue) {
          errors.full = intl.formatMessage({ id: 'validation.full.biggerthanempty' });
        }
        if (blockDistanceValue > emptyValue) {
          errors.blockDistance = intl.formatMessage({ id: 'validation.block_distance.biggerthanempty' });
        }
      }
    }

    const specificationErrors = validateSpecifications(intl, values);

    if (specificationErrors) {
      errors.specifications = specificationErrors;
    }

    return errors;
  };

  const mediumTypeSetting = (formProps) => (instrumentationRules
    && instrumentationRules?.get('needsMediumType')
    && instrumentationRules?.get('isCapableOfMeasuringSolids', assetFirmwareVersion, assetSensorBuildNumber) ? (
      <SelectBox
        {...formProps}
        id="medium-type"
        name="mediumType"
        labelKey="name"
        label={intl.formatMessage({ id: 'label.medium_type' })}
        options={mediumTypes}
        disableOptionsSort
        required
      />
    ) : null);

  const fullEmptySettings = (formProps) => {
    const onBDAutomaticChange = () => {
      const newCustomValue = !customBlockDistance;
      if (!newCustomValue) {
        formProps.setFieldValue('blockDistance', '-1');
        /* eslint-disable no-param-reassign */
        formProps.errors.blockDistance = null;
      } else {
        formProps.setFieldValue('blockDistance', '');
        formProps.errors.blockDistance = null;
      }
      disableCustomBlockDistance(newCustomValue);
    };

    return instrumentationRules && instrumentationRules?.get('needsFullEmptySettings') ? (
      <>
        <h3>
          <FormattedMessage id="label.full_empty_calibration" />
          <InstrumentationFullEmptyCalibrationHelp />
        </h3>
        <SelectBox
          {...formProps}
          id="instrumentation-unit"
          name="unit"
          label={intl.formatMessage({ id: 'label.unit' })}
          options={instrumentationRules?.get('fullEmptyCalibrationUnitOptions')}
          disableOptionsSort
          required
        />
        <TextInput
          {...formProps}
          id="instrumentation-empty"
          name="empty"
          min="0"
          label={intl.formatMessage({ id: 'label.empty' })}
          required
        />
        <TextInput
          {...formProps}
          id="instrumentation-full"
          name="full"
          min="0"
          label={intl.formatMessage({ id: 'label.full' })}
          required
        />
        <label htmlFor="block-distance-section">{`${intl.formatMessage({ id: 'label.block_distance' })}*`}</label>
        <div id="block-distance-section">
          <input
            id="block-distance-automatic-checkbox"
            name="blockDistanceAutomatic"
            type="checkbox"
            className="unchecked"
            checked={!customBlockDistance}
            onChange={onBDAutomaticChange}
          />
          <label htmlFor="block-distance-automatic-checkbox" className="inline-display">{intl.formatMessage({ id: 'label.automatic' })}</label>
          <TextInput
            {...formProps}
            id="instrumentation-block-distance"
            name="blockDistance"
            min="-1"
            hidden={!customBlockDistance}
            hideLabel={!customBlockDistance}
            placeholder={intl.formatMessage({ id: 'label.block_distance' })}
            customClass="inline-text-input"
          />
        </div>
        <SelectBox
          {...formProps}
          id="instrumentation-sensitivity"
          name="sensitivity"
          label={intl.formatMessage({ id: 'label.sensitivity' })}
          options={sensitivity}
          disableOptionsSort
          required
        />
      </>
    ) : null;
  };

  const renderForm = (formProps) => {
    const { handleSubmit, setStatus, isSubmitting } = formProps;

    const beforeHandleSubmit = (event) => {
      setStatus('submitted');
      handleSubmit(event);
    };

    const properties = (
      <>
        <SelectBox
          {...formProps}
          id="accessibility"
          name="accessibility"
          label={intl.formatMessage({ id: 'label.accessibility' })}
          options={accessibility}
          disableOptionsSort
          required
        />
        <SelectBox
          {...formProps}
          id="tag-criticality"
          name="criticality"
          label={intl.formatMessage({ id: 'label.criticality' })}
          options={criticality}
          disableOptionsSort
          required
        />
        <div id="tag-labels" className="form-group">
          <label htmlFor="edit-tag-labels">{intl.formatMessage({ id: 'label.tag_labels' })}</label>
          <ToggleButton
            {...formProps}
            id="criticality-environment"
            name="criticalityEnvironment"
            text={intl.formatMessage({ id: 'label.criticality_env' })}
            initial={false}
          />
          <ToggleButton
            {...formProps}
            id="criticality-quality"
            name="criticalityQuality"
            text={intl.formatMessage({ id: 'label.criticality_quality' })}
            initial={false}
          />
          <ToggleButton
            {...formProps}
            id="criticality-safety"
            name="criticalitySafety"
            text={intl.formatMessage({ id: 'label.criticality_safety' })}
            initial={false}
          />
          <ToggleButton {...formProps} id="special-demands" name="specialDemands" text={intl.formatMessage({ id: 'label.special_demands' })} initial={false} />
          <ToggleButton
            {...formProps}
            id="environmental-condition"
            name="environmentalCondition"
            text={intl.formatMessage({ id: 'label.environmental_condition' })}
            initial={false}
          />
        </div>
      </>
    );
    return (
      <form onSubmit={beforeHandleSubmit} noValidate>
        <TextInput {...formProps} id="tag-name" name="tag" label={intl.formatMessage({ id: 'label.tag' })} required />
        <SelectBox {...formProps} id="tag-status" name="status" label={intl.formatMessage({ id: 'label.operation_mode' })} options={instrumentationStatuses} required />
        <SelectBox {...formProps} id="tag-type" name="type" label={intl.formatMessage({ id: 'label.type' })} options={instrumentationTypes} required />
        <TextArea {...formProps} id="tag-description" name="description" label={intl.formatMessage({ id: 'label.description' })} />
        <SpecificationsInput {...formProps} specificationKeysEndpoint="/instrumentation/specification_keys" />
        {mediumTypeSetting(formProps)}
        {fullEmptySettings(formProps)}
        {collapseProperties
          ? (
            <More id="more-properties">
              {properties}
            </More>
          )
          : properties}
        <div className="btn-group">
          <SubmitButton id="instrumentation-submit" onClick={beforeHandleSubmit} fetching={isSubmitting} disabled={!formProps.dirty && isNothing(defaultValues?.tag)} />
          <CancelButton id="instrumentation-cancel" disabled={false} target={cancelTarget} />
        </div>
      </form>
    );
  };

  return (
    <Formik
      id="formik-instrumentation"
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validateForm}
      render={renderForm}
    />
  );
}

InstrumentationForm.propTypes = {
  instrumentation: instrumentationShape,
  instrumentationStatuses: instrumentationStatusesShape,
  instrumentationTypes: instrumentationTypesShape,
  defaultValues: PropTypes.shape({
    tag: PropTypes.string,
    status: instrumentationStatusShape,
    type: instrumentationTypeShape,
    accessibility: PropTypes.string,
    criticality: PropTypes.string,
    unit: PropTypes.string,
    sensitivity: PropTypes.string,
    mediumType: PropTypes.string,
  }),
  assetFirmwareVersion: PropTypes.string,
  assetSensorBuildNumber: PropTypes.string,
  onSubmit: PropTypes.func,
  cancelTarget: PropTypes.string,
  intl: intlShape.isRequired,
  instrumentationRules: PropTypes.shape({
    get: PropTypes.func,
  }),
  collapseProperties: PropTypes.bool,
};

export default injectIntl(InstrumentationForm);
