import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import { ActionBar, ActionBarButtons, CreateActionButton } from '../ActionBar';
import Hint from '../Hint';
import { Column, Row } from '../Grid';
import { Loader } from '../index';
import { useApi } from '../../hooks';
import DataExportItem from './DataExportItem';
import { extractDataExport, extractDataExports } from '../../extractors/dataExportsExtractor';
import { DataExportsDescription } from './DataExportsDescription';
import { useNotifier } from '../../context';

export default function DataExports({
  exportType,
}) {
  const notifier = useNotifier();
  const intl = useIntl();
  const api = useApi();

  const handleSuccess = () => {
    notifier.showSuccess(intl.formatMessage({ id: 'data_export.success_notification' }));
  };

  const filter = { order_by: '-created_at' };
  if (!exportType.includes('complete')) {
    filter.type = exportType;
  }
  const { data: dataExports, isLoading } = api.get.useQuery('/users/current/data_exports', filter);
  const { mutate } = api.post.useMutation('/users/current/data_exports');

  const onCreate = () => {
    mutate({ type: 'complete' }, { onSuccess: handleSuccess });
  };

  return (
    <Row>
      <Column>
        <ActionBar>
          <h2 id="export-data-header"><FormattedMessage id="export_data.header" /></h2>
          <ActionBarButtons>
            { exportType === 'complete' ? (
              <CreateActionButton
                icon="icon-eh-export"
                id="export-file-button"
                onClick={onCreate}
                disabled={dataExports?.data_exports.some((exp) => exp.type === 'complete')}
              />
            ) : null}
          </ActionBarButtons>
        </ActionBar>
        <Row>
          <Column>
            <table className="table" cellSpacing="0">
              <thead>
                <tr>
                  <th><FormattedMessage id="label.status" /></th>
                  <th><FormattedMessage id="label.type" /></th>
                  <th className="hidden-xs"><FormattedMessage id="label.object_export" /></th>
                  <th className="hidden-xs"><FormattedMessage id="label.started" /></th>
                  <th className="hidden-xs"><FormattedMessage id="label.finished" /></th>
                  <th><FormattedMessage id="label.available_until" /></th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {dataExports?.data_exports.map(
                  (dataExport) => (
                    <DataExportItem
                      key={`data-export-${dataExport.id}`}
                      dataExport={extractDataExport(dataExport)}
                      exportType={dataExport.type}
                      data-testid={`data-export-${dataExport.id}`}
                    />
                  ),
                )}
              </tbody>
            </table>
            {dataExports?.data_exports.length === 0
              && <Hint id="empty_hint" title={intl.formatMessage({ id: 'hint.no_export_data' })} details={intl.formatMessage({ id: 'hint.no_export_data.text' })} />}
            {dataExports && <DataExportsDescription exportType={exportType} dataExports={extractDataExports(dataExports)} /> }
          </Column>
        </Row>
        <Loader loading={isLoading} />
      </Column>
    </Row>
  );
}

DataExports.propTypes = {
  exportType: PropTypes.string.isRequired,
};
