import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import AllOrMultipleSelect from '../../AllOrMultipleSelect';

function DocumentExportAllOrMultipleSelect({
  setParentState, parentState, allItems, topItems, itemKey, title, moreItemsLabel, topItemsLabel,
}) {
  const AllOrMultipleSelectState = parentState;
  const intl = useIntl();

  function handleSelectionSwitch(event) {
    AllOrMultipleSelectState.selectedSwitch = event.target.value;
    if (event.target.value === 'all') {
      AllOrMultipleSelectState.selectedItems = [];
    }
    setParentState({ ...AllOrMultipleSelectState });
  }

  function handleSpecificSelectionChange(event) {
    const target = event.target.value;
    if (!AllOrMultipleSelectState?.selectedItems?.includes(target)) {
      AllOrMultipleSelectState.selectedItems.push(target);
    } else {
      AllOrMultipleSelectState.selectedItems = AllOrMultipleSelectState.selectedItems.filter((id) => id !== target);
    }
    setParentState({ ...AllOrMultipleSelectState });
  }

  return (
    <AllOrMultipleSelect
      allItems={allItems}
      topItems={topItems}
      itemKey={itemKey}
      title={title}
      topItemsLabel={topItemsLabel}
      moreItemsLabel={moreItemsLabel}
      /* eslint-disable-next-line react/jsx-no-bind */
      handleSpecificSelectionChange={handleSpecificSelectionChange}
      /* eslint-disable-next-line react/jsx-no-bind */
      handleSelectionSwitch={handleSelectionSwitch}
      values={AllOrMultipleSelectState}
      noItemLabel={intl.formatMessage({ id: `export_files_dialog.no_${itemKey}` })}
    />
  );
}

DocumentExportAllOrMultipleSelect.propTypes = {
  itemKey: PropTypes.oneOf(['categories', 'languages']).isRequired,
  title: PropTypes.string.isRequired,
  topItemsLabel: PropTypes.string,
  moreItemsLabel: PropTypes.string,
  parentState: PropTypes.shape({
    selectedSwitch: PropTypes.string,
    selectedItems: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allItems: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  topItems: PropTypes.arrayOf(PropTypes.any),
  setParentState: PropTypes.func.isRequired,
};

export default DocumentExportAllOrMultipleSelect;
