import React from 'react';
import { injectIntl } from 'react-intl';
import { Formik } from 'formik';
import { intlShape } from '../../shapes';
import {
  withApi,
  apiShape,
  withBackend,
  backendShape,
  withBrowser,
  browserShape,
  notifierShape,
  withNotifier,
} from '../../context';
import { scaleImageFile } from '../../utils/imageUtils';

import {
  Form, TextArea, TextInput, SubmitButton, CancelButton,
} from '../Form';

import {
  Container,
  Row,
  Column,
} from '../Grid';

import Heading from '../Heading';
import FilesUpload from '../Files/FilesUpload';
import FilesUploadList from '../Files/FilesUploadList';
import BackButton from '../BackButton';

export function TicketCreate({
  api, backend, browser, notifier, intl,
}) {
  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;
    return (
      <Form id="ticket-form" {...formProps}>
        <TextInput
          {...formProps}
          id="ticket-subject"
          label={intl.formatMessage({ id: 'label.subject' })}
          disabled={isSubmitting}
          name="subject"
          required
          autoFocus
        />
        <TextArea
          {...formProps}
          id="ticket-description"
          label={intl.formatMessage({ id: 'label.description' })}
          disabled={isSubmitting}
          name="description"
          required
          cols={50}
          rows={5}
        />
        <FilesUploadList {...formProps} filesUploadNameReference="filesUpload" uploading={isSubmitting} />
        <FilesUpload
          {...formProps}
          id="attachment-upload"
          name="filesUpload"
          multiple
          buildInWhiteList="image/*, video/*, .pdf, .zip"
          fileSizeLimit={1024 * 1024 * 10}
          dropzoneInfoPrefix="add_attachment"
        />
        <div className="btn-group">
          <SubmitButton id="ticket-create-submit" fetching={isSubmitting} disabled={!formProps.dirty} intl={intl} />
          <CancelButton id="ticket-create-cancel" fetching={isSubmitting} intl={intl} />
        </div>
      </Form>
    );
  };

  const validateForm = (values) => {
    const errors = {};
    if (values.subject?.length > 255) { errors.subject = intl.formatMessage({ id: 'validation.subject.too_long' }, { characters: 255 }); }
    if (!values.subject || !values.subject?.trim()) { errors.subject = intl.formatMessage({ id: 'validation.subject.mandatory' }); }
    if (!values.description || !values.description?.trim()) { errors.description = intl.formatMessage({ id: 'validation.description.mandatory' }); }
    return errors;
  };

  async function uploadFiles(ticketId, files, index = 0) {
    try {
      const compressedFile = await scaleImageFile(files[index], 1200, 0.8);
      await backend.upload(`/support/tickets/${ticketId}/attachments`, { name: files[index].name, file: compressedFile });
      if (files.length > index + 1) {
        await uploadFiles(ticketId, files, index + 1);
      }
    } catch (error) {
      notifier.showError(intl.formatMessage({ id: 'support.ticket.attachments.error_notification' }));
    }
  }

  const onSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    const { subject, description, filesUpload } = values;
    try {
      const response = await backend.post('/support/tickets', { subject, description });
      if (filesUpload?.acceptedFiles.length > 0) {
        await uploadFiles(response.id, filesUpload.acceptedFiles);
      }
      notifier.showSuccess(intl.formatMessage({ id: 'support.ticket.create.success_notification' }));
      browser.navigateTo('/support/tickets');
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
    actions.setSubmitting(false);
  };

  return (
    <Container>
      <Row>
        <Column>
          <BackButton />
        </Column>
      </Row>
      <Row>
        <Column>
          <Heading title="Ticket Create" />
          <Formik
            render={renderForm}
            validate={validateForm}
            onSubmit={onSubmit}
            validateOnBlur={false}
          />
        </Column>
      </Row>
    </Container>
  );
}

TicketCreate.propTypes = {
  api: apiShape,
  intl: intlShape,
  browser: browserShape,
  backend: backendShape,
  notifier: notifierShape,
};

export default
injectIntl(
  withBackend(
    withBrowser(
      withNotifier(
        withApi(
          TicketCreate,
        ),
      ),
    ),
  ),
);
