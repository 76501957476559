/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  intlShape,
  convertBytesToGB,
  formatDate,
  navigateTo,
  formatNumber,
} from 'lcm-iot-commons';

import { connectSubscriptionShape } from '../../shapes/connectSubscriptionsShape';

export class ConnectSubscriptionItem extends Component {
  constructor() {
    super();
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick() {
    const { subscription } = this.props;
    if (subscription.status === 'open') {
      navigateTo(`/plan_variants/${subscription.id}`);
    } else {
      navigateTo(`/subscriptions/connect/${subscription.id}`);
    }
  }

  render() {
    const { subscription, intl } = this.props;
    const {
      clientApplicationName, status, subscriptionName, billedUntil, pricingModel,
    } = subscription;
    const apiCallQuota = subscription.apiCallQuota ? formatNumber(subscription.apiCallQuota) : 0;
    const storageQuota = subscription.storageQuota ? formatNumber(convertBytesToGB(subscription.storageQuota)) : 0;
    const uploadDownloadQuota = subscription.uploadDownloadQuota ? formatNumber(convertBytesToGB(subscription.uploadDownloadQuota)) : 0;

    const subscriptionStatus = (
      <span>
        <FormattedMessage id="label.status" />
        :
        {' '}
        <FormattedMessage id={`enum.subscription_status.${status}`} />
        {' '}
      </span>
    );

    const statusDetails = (status !== 'payment_required' && status !== 'open') ? (
      <FormattedMessage id={status === 'confirmed' ? 'label.renewing_on' : 'label.ending_on'} values={{ date: formatDate(billedUntil, intl) }} />
    ) : null;

    let currentTermDetails;
    if (!(pricingModel === 'free')) { // paid subscription
      currentTermDetails = (
        <div>
          {subscriptionStatus}
          { statusDetails }
        </div>
      );
    } else { // free subscription
      currentTermDetails = (
        <div>
          {subscriptionStatus}
          <FormattedMessage id="label.free_subscription" />
        </div>
      );
    }

    return (
      <li className="list-item clickable" onClick={this.handleOnClick}>
        <div className="list-item-image">
          <span className="icon lcm-iot-icon-connect" id="application-icon" />
        </div>
        <div className="list-item-content">
          <div className="list-item-header">
            {`${clientApplicationName} - ${subscriptionName}`}
          </div>
          <div>
            <FormattedMessage id="label.subscription_connect_quota" values={{ uploadDownloadQuota, storageQuota, apiCallQuota }} />
          </div>
          {currentTermDetails}
        </div>
      </li>
    );
  }
}

ConnectSubscriptionItem.propTypes = {
  intl: intlShape.isRequired,
  subscription: connectSubscriptionShape.isRequired,
};

export default injectIntl(ConnectSubscriptionItem);
