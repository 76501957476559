import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '../List/ListItem';
import ListItemImage from '../List/ListItemImage';

export function SubscriptionAssignedAssetsEditItem({
  id, pictureUrl, title, description, checked, onChange, disabled, opacity, currentInSubscription,
}) {
  const handleOnClick = () => {
    if (!disabled) {
      onChange(id, currentInSubscription);
    }
  };

  return (
    <ListItem
      id={`asset-item-${id}`}
      image={pictureUrl ? <ListItemImage pictureUrl={pictureUrl} /> : undefined}
      icon={pictureUrl ? undefined : 'icon-eh-device'}
      title={title}
      description={description}
      onClick={handleOnClick}
      actions={(
        <div style={{ marginTop: '-20px', opacity }}>
          <input
            id={`asset-item-${id}-checkbox`}
            type="checkbox"
            className={checked ? 'checked' : 'unchecked'}
            checked={checked}
            onChange={handleOnClick}
            disabled={disabled}
          />
          <label className="checkbox-label" htmlFor={`asset-item-${id}-checkbox`} />
        </div>
)}
    />
  );
}

SubscriptionAssignedAssetsEditItem.propTypes = {
  id: PropTypes.number.isRequired,
  pictureUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  currentInSubscription: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  opacity: PropTypes.number,
};

SubscriptionAssignedAssetsEditItem.defaultProps = {
  pictureUrl: undefined,
  opacity: 1,
  description: '',
};

export default SubscriptionAssignedAssetsEditItem;
