import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import 'cookieconsent';
import Template from '../layouts/Template';

import { intlShape } from '../shapes';
import Notification from './Notification';

export class Layout extends Component {
  static propTypes = {
    intl: intlShape, // not required but recommended
    children: PropTypes.node,
    authenticated: PropTypes.bool,
  };

  constructor() {
    super();
    /* istanbul ignore next */
    this.state = { cookieConsentVisible: false };
    this.notificationComponent = this.notificationComponent.bind(this);
  }

  componentDidMount() {
    const { intl } = this.props;
    window.cookieconsent.initialise({
      position: 'bottom',
      content: {
        href: '/legal/privacy-policy',
        message: intl.formatMessage({ id: 'cookie_consent.message' }),
        link: intl.formatMessage({ id: 'cookie_consent.link' }),
        dismiss: intl.formatMessage({ id: 'cookie_consent.dismiss' }),
      },
      onPopupOpen: () => {
        this.setState({ cookieConsentVisible: true });
      },
      onPopupClose: /* istanbul ignore next */ () => this.setState({ cookieConsentVisible: false }),
    });
  }

  notificationComponent() {
    return this.props.authenticated
      ? (
        <div className="container">
          <Notification />
        </div>
      ) : null;
  }

  render() {
    const { children } = this.props;
    return (
      <Template
        cookieConsentVisible={this.state.cookieConsentVisible}
        notification={this.notificationComponent()}
      >
        {children}
      </Template>
    );
  }
}

export default injectIntl(Layout);
