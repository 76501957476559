import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import TypeaheadBox from '../Form/TypeaheadBox';
import intlShape from '../../shapes/intlShape';
import {
  withApi, apiShape, withNotifier, notifierShape,
} from '../../context';

export function CompanyTypeahead(props) {
  const [companies, setCompanies] = useState();
  const [fetching, setFetching] = useState();
  const {
    api, notifier, intl, values, name, assetId,
  } = props;

  const loadCompanies = async (searchText = '') => {
    try {
      setFetching(true);

      const endpoint = assetId ? `/assets/${assetId}/company-options` : '/companies';

      const response = await api.getAll(endpoint, {
        include: 'tenant',
        name: `${searchText}*`,
      });

      setCompanies(response.companies.map((company) => ({
        id: company.id,
        name: company.name,
        tenantId: company?.tenant?.id,
        tenantPublic: company?.tenant?.public,
        tenantName: company?.tenant?.name,
      })));
    } catch (error) {
      notifier.showError(api.translateError(error));
    } finally {
      setFetching(false);
    }
  };

  const selectedCompany = values[name];

  return (
    <TypeaheadBox
      {...props}
      info={selectedCompany?.new ? intl.formatMessage({ id: 'company_typeahead.new_info' }) : null}
      options={companies}
      fetching={fetching}
      loadOptions={loadCompanies}
      loadOptionsOnMount={!values[name]}
    />
  );
}

CompanyTypeahead.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  name: PropTypes.string.isRequired,
  notifier: notifierShape.isRequired,
  assetId: PropTypes.number,
  values: PropTypes.shape({
    product: PropTypes.shape({}),
  }).isRequired,
};

export default injectIntl(withNotifier(withApi(CompanyTypeahead)));
