import { extractPlanVariantAddons } from './planVariantAddonsExtractor';

export function extractPlanVariant(rawPlanVariant) {
  return {
    id: rawPlanVariant.id,
    currency: rawPlanVariant.currency,
    name: rawPlanVariant.name,
    price: rawPlanVariant.price ? parseFloat(rawPlanVariant.price) : undefined,
    type: rawPlanVariant.type,
    sort: rawPlanVariant.sort,
    popular: rawPlanVariant.popular,
    button: rawPlanVariant.button,
    features: rawPlanVariant.features,
    assets: rawPlanVariant.assets,
    storage: rawPlanVariant.storage,
    addons: rawPlanVariant.addons ? extractPlanVariantAddons(rawPlanVariant.addons) : null,
  };
}

export function extractPlanVariants(rawPlanVariants) {
  return rawPlanVariants.plan_variants.map((planVariant) => extractPlanVariant(planVariant));
}
