import React, { Component } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { intlShape } from '../../shapes';
import { ActionBar } from '../ActionBar';

import {
  loadAssetInstrumentations,
  isNotFoundError,
  loadAsset,
  loadExtendedOrderCodeLookup,
  assignInstrumentationToAsset,
  apiErrorsContain,
  unassignNodeFromAsset,
} from '../../api';

import {
  SubmitButton,
  ObjectsTypeahead,
} from '../Form';

import InstrumentationCreate from './InstrumentationCreate';

import {
  handleUnknownErrors,
  sortBy, navigateTo, showSuccess, showError,
} from '../../utils';
import { ObjectsType } from '../../constants';
import { Column, Container, Row } from '../Grid';
import BackButton from '../BackButton';

class InstrumentationAssign extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    dispatch: PropTypes.func,
    match: PropTypes.shape({ params: PropTypes.shape({ assetId: PropTypes.string, nodeId: PropTypes.string }) }),
  };

  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.state = {}; // necessary for static methods in render()
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    return Promise.all([this.getAssetInstrumentations(params.assetId), this.getAsset(params.assetId)]);
  }

  assignInstrumentation = async ({ instrumentation }) => {
    const { intl, match: { params } } = this.props;
    const { asset, instrumentations } = this.state;

    try {
      await assignInstrumentationToAsset(asset.id, instrumentation.id);
      this.setState({ instrumentations: sortBy(instrumentations.concat([{ ...instrumentation }]), 'tag') });
      showSuccess(intl.formatMessage({ id: 'instrumentation.actions.assign.notification' }));
      if (params.nodeId) {
        await unassignNodeFromAsset(asset.id, params.nodeId);
      }
      navigateTo('/nodes');
    } catch (apiErrors) {
      this.form.resetForm({});
      if (apiErrorsContain(apiErrors, 'associations_already_added')) {
        showError(intl.formatMessage({ id: 'asset.actions.assign.error' }));
      } else if (apiErrorsContain(apiErrors, 'associations_not_found') || apiErrorsContain(apiErrors, 'not_found_no_permission')) {
        showError(intl.formatMessage({ id: 'asset.actions.assign_instrumentation.no_permission' }));
      } else {
        handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
      }
    }
  };

  getAsset = async (assetId) => {
    const { intl } = this.props;
    try {
      const include = 'product,product.pictures,product.status,product.tenant,product.manufacturer,product.manufacturer.tenant,status,pictures,specifications[eh.pcps.tmp.ordercode]';
      const asset = await loadAsset(assetId, { include });

      if (asset && asset.manufacturerName === 'Endress+Hauser'
        && asset.specifications
        && asset.specifications['eh.pcps.tmp.ordercode']
        && /^[a-zA-Z0-9]+-+[a-zA-Z0-9]+\/[a-zA-Z0-9]+/g.exec(asset.specifications['eh.pcps.tmp.ordercode'].value)) {
        try {
          asset.extendedOrderCode = await loadExtendedOrderCodeLookup(asset);
        } catch (apiErrors) {
          // ignore
        }
      }
      this.setState({ asset });
    } catch (apiErrors) {
      if (isNotFoundError(apiErrors)) {
        navigateTo('/404');
      } else {
        handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
      }
    }
  };

  getAssetInstrumentations = async (assetId) => {
    const { intl } = this.props;
    try {
      const instrumentations = sortBy(await loadAssetInstrumentations(assetId, { include: 'type' }), 'tag');
      this.setState({ instrumentations });
    } catch (apiErrors) {
      if (isNotFoundError(apiErrors)) {
        // ignore
      } else {
        handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
      }
    }
  };

  renderInstrumentationAssignForm = (props) => {
    const { handleSubmit, isSubmitting, values } = props;
    const { intl } = this.props;
    const { asset, instrumentations } = this.state;

    this.form = props;

    return asset ? (
      <form onSubmit={handleSubmit} noValidate>
        <ObjectsTypeahead
          {...props}
          id="instrumentation-typeahead"
          name="instrumentation"
          label={intl.formatMessage({ id: 'instrumentations.header' })}
          placeholder={intl.formatMessage({ id: 'label.tag' })}
          objectsToIgnore={instrumentations}
          objectsType={ObjectsType.Instrumentations}
        />
        <div className="btn-group">
          <SubmitButton
            intl={intl}
            id="assign-instrumentation-button"
            disabled={!values.instrumentation}
            fetching={isSubmitting}
            text={intl.formatMessage({ id: 'button.assign' })}
          />
        </div>
      </form>
    ) : null;
  };

  render() {
    const { match } = this.props;

    const { asset, instrumentations } = this.state;
    return (
      <Container>
        <Row>
          <Column>
            <BackButton />
          </Column>
        </Row>
        <Row>
          <Column>
            <ActionBar>
              <h1><FormattedMessage id="instrumentation_assign.header" /></h1>
            </ActionBar>
          </Column>
        </Row>
        <Row>
          <Column sm="6">
            {asset && instrumentations && (
              <div id="instrumentation-list">
                <h2><FormattedMessage id="instrumentation.actions.assign.existing" /></h2>
                <Formik onSubmit={this.assignInstrumentation} render={this.renderInstrumentationAssignForm} />
              </div>
            )}
          </Column>
        </Row>
        <InstrumentationCreate
          match={match}
          cancelTarget={`/assets/${match.params.assetId}`}
          isSubComponent
        />
      </Container>
    );
  }
}

export default injectIntl(InstrumentationAssign);
