import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { MenuItem } from 'react-bootstrap';
import {
  ItemMenu,
  ListItem,
  ConfirmationModal,
  withApi,
  withBrowser,
  withNotifier,
  formatDate,
  formatNumber,
  apiShape,
  browserShape,
  intlShape,
  notifierShape,
} from 'lcm-iot-commons';

export function TaxItem({
  tax, api, browser, intl, notifier, loadTaxes,
}) {
  const [state, setState] = useState({
    deleting: false,
  });
  const taxType = [
    { id: 'withholding', name: intl.formatMessage({ id: 'enum.taxes.withholding' }) },
    { id: 'undefined', name: intl.formatMessage({ id: 'enum.taxes.undefined' }) },
  ];

  const handleOnEditClick = () => {
    browser.navigateTo(`/taxes/${tax.id}/edit`);
  };

  const handleOnDeleteClick = () => {
    setState({ deleting: true });
  };

  const handleClose = () => {
    setState({ deleting: false });
  };

  const handleConfirm = async () => {
    try {
      await api.delete(`/taxes/${tax.id}`);
      setState({ deleting: false });
      if (loadTaxes) await loadTaxes();
      notifier.showSuccess(intl.formatMessage({ id: 'taxes.actions.delete.notification' }));
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  const modalDeleting = state.deleting ? (
    <ConfirmationModal
      id="tax-item-confirm"
      show
      titleText={intl.formatMessage({ id: 'taxes.actions.delete.modal_title' })}
      messageText={intl.formatMessage({ id: 'taxes.actions.delete.modal_message' })}
      onConfirm={() => handleConfirm()}
      onClose={() => handleClose()}
    />
  ) : null;

  const actions = (
    <div id="tax-action-items">
      <ItemMenu>
        <MenuItem id="item-edit" onSelect={() => handleOnEditClick()} disabled={false}>
          {intl.formatMessage({ id: 'button.edit' })}
        </MenuItem>
        <MenuItem id="item-delete" onSelect={() => handleOnDeleteClick()} disabled={false}>
          {intl.formatMessage({ id: 'button.delete' })}
        </MenuItem>
      </ItemMenu>
      {modalDeleting}
    </div>
  );

  const startDate = tax.start_date ? formatDate(tax.start_date, intl) : null;
  const endDate = tax.end_date ? formatDate(tax.end_date, intl) : intl.formatMessage({ id: 'taxes.validity_period_end' });
  const taxValidityPeriod = `${intl.formatMessage({ id: 'taxes.validity_period' }, { startDate, endDate })}`;
  const taxRate = `${intl.formatMessage({ id: 'taxes.item_rate' })}: ${formatNumber(tax.rate)}%`;
  const findTaxType = taxType.find((t) => t.id === tax.type);
  const typeName = findTaxType ? findTaxType.name : taxType.find((t) => t.id === 'undefined').name;

  return (
    <ListItem
      id={`tax-item-${tax.id}`}
      icon="lcm-iot-icon-tax-rounded"
      actions={actions}
      title={`${typeName} - ${tax.country_code.toUpperCase()}`}
      description={taxValidityPeriod}
      value={taxRate}
    />
  );
}

TaxItem.propTypes = {
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  loadTaxes: PropTypes.func,
  tax: PropTypes.shape({
    id: PropTypes.number,
    country_code: PropTypes.string,
    type: PropTypes.string,
    rate: PropTypes.number,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }).isRequired,
};

TaxItem.defaultProps = {
  loadTaxes: undefined,
};

export default injectIntl(withBrowser(withApi(withNotifier(TaxItem))));
