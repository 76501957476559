import { random } from './langUtils';
import { BadRequestError } from '../api';

export async function createEnforcedTenant(api, name, suffix = '') {
  try {
    return await api.post('/tenants', { name: `${name} ${suffix}`.trim() });
  } catch (error) {
    if (error instanceof BadRequestError
      && error.contains('taken')) {
      return createEnforcedTenant(api, name, random(10000, 99999).toString());
    }
    throw error;
  }
}
