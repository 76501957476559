import lcmApiClient from './lcmApiClient';
import { extractUserGroup } from '../extractors/userGroupsExtractor';
import { extractUser } from '../extractors/usersExtractor';

/* istanbul ignore next */
export async function createUserGroup(userGroup) {
  const newUserGroup = {
    name: userGroup.name,
    description: userGroup.description,
  };
  const response = await lcmApiClient().post('/usergroups', newUserGroup);
  return response.id;
}

/* istanbul ignore next */
export async function updateUserGroup(id, userGroup) {
  const updatedUserGroup = {
    name: userGroup.name,
    description: userGroup.description,
  };
  await lcmApiClient().patch(`/usergroups/${id}`, updatedUserGroup);
}

/* istanbul ignore next */
export async function loadUserGroup(id, parameters) {
  const response = await lcmApiClient(false).get(`/usergroups/${id}`, parameters);
  return extractUserGroup(response);
}

/* istanbul ignore next */
export async function unassignUserFromUserGroup(id, body) {
  await lcmApiClient(false).delete(`/usergroups/${id}/users`, body);
}

/* istanbul ignore next */
export async function assignUserToUserGroup(id, body) {
  await lcmApiClient(false).post(`/usergroups/${id}/users`, body);
}

/* istanbul ignore next */
export async function lookupUser(email) {
  return extractUser(await lcmApiClient(false).get('/users/lookup', { email }));
}

/* istanbul ignore next */
export async function deleteUserGroup(id) {
  return lcmApiClient().delete(`/usergroups/${id}`);
}
