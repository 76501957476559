/* eslint-disable default-param-last */
import ActionType from '../constants/ActionType';

const initialState = {
  errors: null,
  user: null,
  defaultAdminTenant: null,
  adminTenants: null,
  saving: false,
  saved: false,
};

function findDefaultAdminTenant(state, adminTenants) {
  const defaultAdminTenantRegex = new RegExp(`${state.user.firstName} ${state.user.lastName} ([\\d])+`);
  const defaultAdminTenant = adminTenants.find((tenant) => tenant.name === `${state.user.firstName} ${state.user.lastName}`);
  const defaultAdminTenants = adminTenants.filter((tenant) => defaultAdminTenantRegex.test(tenant.name));
  if (defaultAdminTenant) {
    return defaultAdminTenant;
  } if (defaultAdminTenants.length > 0) {
    return defaultAdminTenants[0];
  } if (adminTenants.length > 0) {
    return adminTenants[0];
  }
  return null;
}

export default function currentUserReducer(state = initialState, action) {
  const {
    type, user, adminTenants, errors,
  } = action;
  switch (type) {
    case ActionType.FETCH_CURRENT_USER_SUCCESSFUL:
      return { ...state, user, errors: null };
    case ActionType.FETCH_CURRENT_USER_FAILED:
      return { ...state, user: null, errors };
    case ActionType.FETCH_CURRENT_USER_ADMIN_TENANTS_SUCCESSFUL:
      return {
        ...state,
        adminTenants,
        defaultAdminTenant: findDefaultAdminTenant(state, adminTenants),
        errors: null,
      };
    case ActionType.FETCH_CURRENT_USER_ADMIN_TENANTS_FAILED:
      return {
        ...state, adminTenants: null, defaultAdminTenant: null, errors,
      };
    case ActionType.UPDATE_CURRENT_USER:
      return { ...state, user };
    default:
      return state;
  }
}
