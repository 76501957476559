const errors = {
  ConflictError: 'api.error.conflict',
  ForbiddenError: 'api.error.forbidden',
  NetworkError: 'api.error.network_error',
  NotFoundError: 'api.error.not_found',
  PaymentRequiredError: 'api.error.payment_required',
  ServiceUnavailableError: 'api.error.service_unavailable',
  TooManyRequestsError: 'api.error.too_many_requests',
  UnauthorizedError: 'api.error.unauthorized',
};

export const getErrorTranslation = (error) => errors[error?.constructor.name] || 'api.error.unknown';

export const isSentry = (error) => !errors[error?.constructor.name];
