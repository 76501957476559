import { isNil } from 'lodash-es';
import ApiClientError from './ApiClientError';

class NotFoundError extends ApiClientError {
  /* istanbul ignore next */
  constructor(request, response, cause) {
    super('NotFoundError', 404, 'The resource or the specified URL could not be found or the requesting user has no permission.', request, response, cause);

    if (this.response && this.response.data) {
      this.errors = this.response.data.errors;
    }
  }

  contains = (type, attribute = null) => {
    if (attribute) {
      return !isNil(this.errors) && !isNil(this.errors.find((error) => error.type === type && error.attribute === attribute));
    }
    return !isNil(this.errors) && !isNil(this.errors.find((error) => error.type === type));
  };
}

NotFoundError.create = (errors) => new NotFoundError(null, { data: { errors } });

export default NotFoundError;
