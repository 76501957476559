export function extractRecipe(rawRecipe) {
  return {
    id: rawRecipe.id,
    name: rawRecipe.name,
    description: rawRecipe.description,
    statusName: rawRecipe.status?.name,
    statusCode: rawRecipe.status?.code,
    typeName: rawRecipe.type?.name,
    typeCode: rawRecipe.type?.code,
    itemType: 'recipe',
  };
}

export function extractRecipes(raw) {
  return raw.recipes.map((rawRecipe) => extractRecipe(rawRecipe));
}
