import PropTypes from 'prop-types';

// TechnicalUser
export const technicalUserShape = PropTypes.shape({
  id: PropTypes.number,
  email: PropTypes.string,
  disabled: PropTypes.bool,
});

export const technicalUsersShape = PropTypes.arrayOf(technicalUserShape);
