import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { withConfiguration } from './ConfigurationContext';
import { isSomething } from '../utils/langUtils';
import { withLog, logShape } from './LogContext';

const TrackingContext = React.createContext();

const trackingShape = PropTypes.shape({
  trackEvent: PropTypes.func,
  reset: PropTypes.func,
});

function InitializedTrackingContextProvider({
  children, configuration, log, testTracking,
}) {
  const [tracking, setTracking] = useState(testTracking || {
    tracking: {
      trackEvent: () => true,
      reset: () => true,
    },
  });

  useMemo(() => {
    if (testTracking) {
      return;
    }
    if (isSomething(configuration.trackingToken)) {
      mixpanel.init(configuration.trackingToken, {
        api_host: 'https://api-eu.mixpanel.com',
        disable_persistence: true,
        secure_cookie: true,
      });
      const trackEvent = (eventName, eventProps) => {
        try {
          const props = { serviceApp: configuration.appName, ...eventProps };
          mixpanel.track(eventName, props);
        } catch (error) {
          log.error('InitializedTrackingContextProvider', error);
        }
      };

      const reset = () => {
        try {
          mixpanel.reset();
        } catch (error) {
          log.error('InitializedTrackingContextProvider', error);
        }
      };

      setTracking({ tracking: { trackEvent, reset } });
    }
  }, []);

  return (
    <TrackingContext.Provider value={tracking}>
      {children}
    </TrackingContext.Provider>
  );
}

InitializedTrackingContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  configuration: PropTypes.shape({ appName: PropTypes.string, trackingToken: PropTypes.string }).isRequired,
  log: logShape.isRequired,
  testTracking: trackingShape,
};

const TrackingContextProvider = withConfiguration(withLog(InitializedTrackingContextProvider));

const withTracking = (ChildComponent) => {
  function ConnectedComponent(props) {
    return (
      <TrackingContext.Consumer>
        {(context) => <ChildComponent {...props} tracking={context.tracking} />}
      </TrackingContext.Consumer>
    );
  }
  ConnectedComponent.displayName = ChildComponent.displayName || ChildComponent.name;
  return ConnectedComponent;
};

export {
  TrackingContextProvider,
  withTracking,
  trackingShape,
};
