import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import {
  locationShape,
  Intl,
  Layout,
  configureApplication,
  ApplicationContext,
  url,
  Loader,
} from 'lcm-iot-commons';
import Navigation from '../components/Navigation';

import { SignUpContextProvider } from '../context/SignUpContext';
import 'react-widgets/lib/scss/react-widgets.scss';
import '../assets/styles/main.scss';
import { mergeAppMessages } from '../translations';

configureApplication({
  BASE_URL: '/app/id',
});

const UNSECURED_ENDPOINTS = [
  url('/sign_in'),
  url('/sign_up'),
  url('/sign_up_success'),
  url('/sign_up_complete'),
  url('/session_expired'),
  url('/sign_in_confirmation'),
  url('/request_password_reset'),
  url('/password_reset'),
  url('/confirmation'),
  url('/404'),
  url('/403'),
  url('/500'),
];

export function SsoRoot({ location, children }) {
  return (
    <SignUpContextProvider>
      <Suspense fallback={<Loader />}>
        <Intl mergeAppMessages={mergeAppMessages}>
          <ApplicationContext unsecuredEndpoints={UNSECURED_ENDPOINTS}>
            <span>
              <Navigation location={location} />
              <div className="navbar-separator" />
              <Layout>
                {children}
              </Layout>
            </span>
          </ApplicationContext>
        </Intl>
      </Suspense>
    </SignUpContextProvider>
  );
}

SsoRoot.propTypes = {
  children: PropTypes.node,
  location: locationShape,
};

SsoRoot.defaultProps = {
  children: undefined,
  location: undefined,
};

const mapStateToProps = (state) => ({
  configuration: state.configuration,
});

export default connect(mapStateToProps)(SsoRoot);
