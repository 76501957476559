import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import uuid from 'uuid';
import {
  intlShape,
  userShape,
  planCartShape,
  addonsShape,
} from '../../shapes';
import {
  withConfiguration,
  withUser,
} from '../../context';
import {
  addonMaxAmount,
  addonVisibleForUserrole,
} from '../../utils';
import PlanVariantAddon from '../PlanVariants/PlanVariantAddon';
import { withIntl } from '../../wrappers';

function PaidPlan({
  planCart, planVariant, currentAddons, onSelect, disabled,
  intl, creating, configuration, user, mode, currentPlanVariant,
}) {
  const [addons, setAddons] = React.useState();

  const addonQuantity = (addon) => {
    if (mode === 'upgrade') {
      const fullAddon = planVariant.addons.find((pvAddon) => addon.id === pvAddon.id);
      if (currentAddons && currentPlanVariant.addons && fullAddon.assets) {
        const relevantCurrentAddons = currentAddons.filter((currentAddon) => currentPlanVariant.addons.find((planAddon) => currentAddon.id === planAddon.id));
        const currentAssetsCount = relevantCurrentAddons.map((currentAddon) => {
          const currentAddonAssets = currentPlanVariant.addons.find((planAddon) => currentAddon.id === planAddon.id).assets;
          return currentAddonAssets ? currentAddonAssets * currentAddon.quantity : 0;
        }).reduce((sum, assets) => sum + assets, 0) + currentPlanVariant.assets;
        const currentStorageCount = relevantCurrentAddons.map((currentAddon) => {
          const currentAddonStorage = currentPlanVariant.addons.find((planAddon) => currentAddon.id === planAddon.id).storage;
          return currentAddonStorage ? currentAddonStorage * currentAddon.quantity : 0;
        }).reduce((sum, storage) => sum + storage, 0) + currentPlanVariant.storage;
        const assetQuantity = Math.ceil((currentAssetsCount - planVariant.assets) / fullAddon.assets);
        const storageQuantity = Math.ceil((currentStorageCount - planVariant.storage) / fullAddon.storage);
        return (Math.max(assetQuantity, storageQuantity) < 0 ? 0 : Math.max(assetQuantity, storageQuantity));
      }
    }

    if (currentAddons) {
      const foundMatchingAddon = currentAddons.find((currentAddon) => currentAddon.id === addon.id || currentAddon.id === addon.id.replace('-premium', ''));
      if (foundMatchingAddon) {
        return foundMatchingAddon.quantity;
      }
    }
    return null;
  };
  const setAddonsState = async () => {
    // Calculate the initial addons quantites for this plan, considering the plancart
    // its written to the state because the addon boxes also operate on the state, when quantities are changed by the user
    const addonsResult = {};
    if (planCart && planCart.plan_variant.id === planVariant.id) {
      planCart.addons.forEach((addon) => {
        addonsResult[addon.plan_variant_addon.id] = addon.amount;
      });
    } else if (currentAddons && planVariant.addons) {
      planVariant.addons.forEach((addon) => {
        addonsResult[addon.id] = addonQuantity(addon);
      });
    }
    setAddons(addonsResult);
  };

  if (!addons) {
    setAddonsState();
  }

  const handleMouseClick = (planVariantUsed) => {
    onSelect(planVariantUsed, addons);
  };

  const onAddonChange = (value, addon) => {
    const addonsResult = { ...addons };
    addonsResult[addon.id] = value;
    setAddons(addonsResult);
  };

  const calculateTotal = (plan = planVariant, addonsList = addons) => {
    // add up the total of the current plan configuration (using the addon quantites from the compononentes state)
    let total = parseFloat(plan.price);
    if (addonsList) {
      Object.keys(addonsList).forEach((addonId) => {
        const planVariantAddon = plan.addons.find((addon) => addon.id === addonId);
        total += parseFloat(planVariantAddon.price) * addonsList[addonId];
      });
    }

    return total;
  };

  const isDirty = () => {
    let isDirtyValue = false;
    const selectedAddons = addons;
    if (currentAddons && currentAddons.length > 0 && selectedAddons) {
      Object.keys(selectedAddons).forEach((key) => {
        const correspondingCurrentAddon = currentAddons.find((c) => c.id === key);
        isDirtyValue = isDirtyValue || (!correspondingCurrentAddon && selectedAddons[key]) || (correspondingCurrentAddon && selectedAddons[key] !== correspondingCurrentAddon.quantity);
      });
    } else if ((!currentAddons || currentAddons.length === 0) && (selectedAddons && Object.keys(selectedAddons).length > 0)) {
      isDirtyValue = Object.keys(selectedAddons).reduce((prev, key) => prev || selectedAddons[key] > 0, false);
    }

    return isDirtyValue ? true : false; // eslint-disable-line  no-unneeded-ternary
  };

  const addonsHeader = mode === 'current' ? (
    <h3>
      <FormattedMessage id="upgradeplan.currentplan.upgrade_addons" />
    </h3>
  ) : null;

  const addonsSectionStyle = mode === 'current' ? 'panel-current-addon-upgrade' : null;

  const buttonDisabled = () => {
    if (mode === 'current') {
      return (!isDirty() || disabled);
    }
    return disabled;
  };

  const buttonLabel = () => {
    if (mode === 'current') {
      return isDirty()
        ? intl.formatMessage({ id: 'upgradeplan.currentplan.upgrade_now' })
        : intl.formatMessage({ id: 'upgradeplan.currentplan.currentplan_button' });
    }
    return planVariant.button;
  };

  const totalLabel = () => {
    if (mode === 'current') {
      return isDirty()
        ? intl.formatMessage({ id: 'upgradeplan.currentplan.estimated_total' })
        : intl.formatMessage({ id: 'upgradeplan.currentplan.current_total' });
    }
    return <FormattedMessage id="upgradeplan.currentplan.current_total" />;
  };

  const buttonStyle = () => {
    if (mode === 'upgrade') {
      const highlightedPlan = currentPlanVariant.sort + 1 === planVariant.sort;
      return highlightedPlan ? 'btn btn-primary select-button' : 'btn select-button';
    }
    return 'btn btn-primary select-button';
  };

  const panelStyle = () => {
    if (mode === 'upgrade') {
      const highlightedPlan = currentPlanVariant.sort + 1 === planVariant.sort;
      return highlightedPlan ? 'panel panel-primary' : 'panel';
    }
    return 'panel panel-current';
  };

  const panelClass = panelStyle();

  const features = planVariant.features.map((item) => (<li className="list-item-checked" key={uuid()}>{item}</li>));
  const planVariantPrice = parseFloat(planVariant.price);

  const displayPrice = configuration.planPriceDisplay === 'yearly' ? `${intl.formatNumber(planVariantPrice.toFixed(2), {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })} / ${intl.formatMessage({ id: 'planvariant.per_user' })} / ${intl.formatMessage({ id: 'global.year' })}` : `${intl.formatNumber((planVariantPrice / 12).toFixed(2), {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })} / ${intl.formatMessage({ id: 'planvariant.per_user' })} / ${intl.formatMessage({ id: 'global.month' })}`;
  let price;
  if (planVariant.name.toLowerCase().includes('fermentation')) {
    const yearlyPrice = parseFloat(planVariant.addons.find((addon) => addon.type === 'connectivity')?.price);
    price = `${planVariant.currency} ${intl.formatNumber((yearlyPrice / 12).toFixed(2), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} / ${intl.formatMessage({ id: 'planvariant.per_qwx43' })} / ${intl.formatMessage({ id: 'global.month' })}`;
  } else {
    price = planVariant.type === 'free' || planVariantPrice === 0
      ? intl.formatMessage({ id: 'planvariant.free' })
      : `${planVariant.currency} ${displayPrice}`;
  }

  const outcomeSelections = planVariant.addons
    ? planVariant.addons.filter((addon) => addon.type === 'outcome' && addonVisibleForUserrole(user.userRoles, addon.limited_for_roles)).map((addon) => {
      const planCartAddon = planCart
            && planCart.plan_variant
            && planCart.plan_variant.id === planVariant.id
            && planCart.addons
            && planCart.addons.find((a) => a.plan_variant_addon.id === addon.id);

      const minAmount = addonQuantity(addon) || 0;
      const maxAmount = addonMaxAmount(addon, planVariant);
      const amount = planCartAddon ? planCartAddon.amount : minAmount;
      return (<PlanVariantAddon key={addon.id} planVariantAddon={addon} onChange={onAddonChange} amount={amount} minAmount={minAmount} maxAmount={maxAmount} />);
    }) : null;

  const connectivitySelections = planVariant.addons
    ? planVariant.addons.filter((addon) => addon.type === 'connectivity' && addonVisibleForUserrole(user.userRoles, addon.limited_for_roles)).map((addon) => {
      const planCartAddon = planCart
            && planCart.plan_variant
            && planCart.plan_variant.id === planVariant.id
            && planCart.addons
            && planCart.addons.find((a) => a.plan_variant_addon.id === addon.id);

      const minAmount = addonQuantity(addon) || 0;
      const amount = planCartAddon ? planCartAddon.amount : minAmount;
      const maxAmount = addonMaxAmount(addon, planVariant);
      return (<PlanVariantAddon key={addon.id} planVariantAddon={addon} onChange={onAddonChange} amount={amount} minAmount={minAmount} maxAmount={maxAmount} />);
    }) : null;

  const addonSelections = planVariant.addons
    ? planVariant.addons.filter((addon) => (addon.type === 'addon' || addon.type === 'extension') && addonVisibleForUserrole(user.userRoles, addon.limited_for_roles)).map((addon) => {
      const planCartAddon = planCart
            && planCart.plan_variant
            && planCart.plan_variant.id === planVariant.id
            && planCart.addons
            && planCart.addons.find((a) => a.plan_variant_addon.id === addon.id);

      const minAmount = addonQuantity(addon) || 0;
      const maxAmount = addonMaxAmount(addon, planVariant);
      const amount = planCartAddon ? planCartAddon.amount : minAmount;
      return (<PlanVariantAddon key={addon.id} planVariantAddon={addon} onChange={onAddonChange} amount={amount} minAmount={minAmount} maxAmount={maxAmount} />);
    }) : null;

  const total = calculateTotal();
  const totalPrice = configuration.planPriceDisplay === 'yearly' ? `${intl.formatNumber(total.toFixed(2), {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })} / ${intl.formatMessage({ id: 'global.year' })}` : `${intl.formatNumber((total / 12).toFixed(2), {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })} / ${intl.formatMessage({ id: 'global.month' })}`;
  const totalRendered = (
    <div className="plan-variant-total">
      <div className="plan-variant-total-header">{totalLabel()}</div>
      <div className="plan-variant-total-value">
        {`${planVariant.currency} ${totalPrice}`}
      </div>
      <div className="plan-variant-total-value-note"><FormattedMessage id="planvariant.billed_annually" /></div>
    </div>
  );

  const addonsSection = planVariant.addons ? (
    <div className={addonsSectionStyle}>
      {addonsHeader}
      {outcomeSelections && outcomeSelections.length > 0
        ? (
          <>
            <h4 id="planvariant-outcome"><FormattedMessage id="planvariant.outcome" /></h4>
            <div id="planvariant-outcome-addons">
              {outcomeSelections}
            </div>
          </>
        ) : null}
      {connectivitySelections && connectivitySelections.length > 0
        ? (
          <>
            <h4 id="planvariant-connectivity"><FormattedMessage id="planvariant.connectivity" /></h4>
            <div id="planvariant-connectivity-addons">
              {connectivitySelections}
            </div>
          </>
        ) : null}
      {addonSelections && addonSelections.length > 0
        ? (
          <>
            <h4 id="planvariant-addons"><FormattedMessage id="planvariant.addons" /></h4>
            <div id="planvariant-standard-addons">
              {addonSelections}
            </div>
          </>
        ) : null}
      <div>
        {totalRendered}
      </div>
    </div>
  ) : null;

  const shortDescription = planVariant.short_description ? (
    <div id="short-description" className="space-after short-description">
      {planVariant.short_description}
    </div>
  ) : null;

  const spinner = creating ? (<i className="btn-spinner" />) : null;

  return (
    <div id={`plan-variant-${planVariant.id}`} className={panelClass}>
      <div className="panel-heading">
        <div className="title">
          {planVariant.name}
          <div id="price" className="panel-subtitle">
            {price}
          </div>
          { planVariant.type === 'free' || planVariantPrice === 0 ? null : (<p className="panel-subtitle">{intl.formatMessage({ id: 'planvariant.billed_annually' })}</p>)}

        </div>
      </div>
      <div className="panel-body">
        <div className="panel-current-selection">
          {shortDescription}
          <ul id="plan-variant-features" className="list-checked">
            {features}
          </ul>
        </div>
        {addonsSection}
      </div>
      <div className="panel-footer">
        <button type="button" disabled={buttonDisabled()} onMouseDown={() => handleMouseClick(planVariant)} className={buttonStyle()}>
          {buttonLabel()}
          {spinner}
        </button>
      </div>
    </div>
  );
}

PaidPlan.propTypes = {
  planVariant: PropTypes.shape({
    id: PropTypes.string,
    currency: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.string,
    type: PropTypes.string,
    sort: PropTypes.number,
    popular: PropTypes.bool,
    button: PropTypes.string,
    assets: PropTypes.number,
    storage: PropTypes.number,
    short_description: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string),
    // eslint-disable-next-line react/forbid-prop-types
    addons: PropTypes.arrayOf(PropTypes.any),
  }),
  currentPlanVariant: PropTypes.shape({
    id: PropTypes.string,
    currency: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.string,
    type: PropTypes.string,
    sort: PropTypes.number,
    popular: PropTypes.bool,
    button: PropTypes.string,
    assets: PropTypes.number,
    storage: PropTypes.number,
    short_description: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string),
    // eslint-disable-next-line react/forbid-prop-types
    addons: PropTypes.arrayOf(PropTypes.any),
  }),
  planCart: planCartShape,
  creating: PropTypes.bool,
  disabled: PropTypes.bool,
  currentAddons: addonsShape,
  intl: intlShape.isRequired,
  mode: PropTypes.oneOf(['upgrade', 'current']).isRequired,
  onSelect: PropTypes.func.isRequired,
  configuration: PropTypes.shape({
    planPriceDisplay: PropTypes.string,
  }),
  user: userShape.isRequired,
};

export default withConfiguration(withUser(withIntl(PaidPlan)));
