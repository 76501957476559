import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-bootstrap';
import CustomOverlayTrigger from '../CustomOverlayTrigger';

export default function Chip({ item, onRemove, withTooltip }) {
  const handleRemoveClicked = (e) => {
    e.stopPropagation();
    onRemove(item);
  };

  const chip = (
    <div id={`menu-item-${item.replace(' ', '-')}`} data-testid={`menu-item-${item.replace(' ', '-')}`} className="chip">
      <span>{item}</span>
      {onRemove && <button type="button" onClick={handleRemoveClicked} className="icon-eh-close" />}
    </div>
  );

  if (!withTooltip) return chip;

  return (
    <CustomOverlayTrigger key={`visible-${item}`} placement="bottom" overlay={<Tooltip id="tooltip">{item}</Tooltip>}>
      {chip}
    </CustomOverlayTrigger>
  );
}

Chip.propTypes = {
  item: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  withTooltip: PropTypes.bool,
};
