/* eslint-disable camelcase */
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Row,
  Column,
  Container,
  Loader,
  Heading,
  Details,
  DetailsHeaderItem,
  withApi,
  withUser,
  withBrowser,
  withAccessRights,
  apiShape,
  ActionBar,
  intlShape,
  browserShape,
  withNotifier,
  notifierShape,
  NotFoundError,
  formatDateTime,
} from 'lcm-iot-commons';
import { getUpdateScheduleStatusIcon, renameDownloadingStatus } from '../../utils/edgeDeviceUpdateSchedulesUtils';

export function UpdateSchedulesDetails(props) {
  const {
    api, browser, match, intl, notifier,
  } = props;
  const { id, updateSchedulesId } = match.params;
  const [detailsObject, setDetailsObject] = useState(null);

  const loadData = async () => {
    try {
      let response = await api.get(`/edm/update_schedules/${updateSchedulesId}`, { include: 'software_version, status' }, false);
      if (response?.status?.code?.includes('downloading')) response = renameDownloadingStatus(response);
      setDetailsObject({ ...response });
    } catch (error) {
      if (error instanceof NotFoundError) {
        browser.navigateTo('/404');
      } else {
        notifier.showError(api.translateError(error));
        setDetailsObject(null);
      }
    }
  };
  React.useEffect(() => {
    loadData();
  }, [id]);

  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <Heading id="update-schedules-details-header" title={intl.formatMessage({ id: 'update_schedules.details.header' })} />
          </ActionBar>
          {detailsObject && (
            <Details>
              <DetailsHeaderItem id="update-schedules-details-software-version" translationKey="update_schedules.details.software_version" value={detailsObject?.software_version?.version_name} />
              <DetailsHeaderItem
                id="update-schedules-details-status"
                icon={`update-schedules-icon ${getUpdateScheduleStatusIcon(detailsObject?.status?.code)}`}
                translationKey="update_schedules.details.status"
                value={detailsObject?.status?.name}
              />
              <DetailsHeaderItem
                id="update-schedules-details-updated-at"
                translationKey="update_schedules.details.updated_at"
                value={detailsObject?.updated_at ? formatDateTime(detailsObject?.updated_at, intl) : '-'}
              />
            </Details>
          )}
          <Loader id="loader" loading={!detailsObject} />
        </Column>
      </Row>
    </Container>
  );
}

UpdateSchedulesDetails.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  browser: browserShape.isRequired,
  notifier: notifierShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      updateSchedulesId: PropTypes.string,
    }),
  }).isRequired,
};

export default withBrowser(withNotifier(withApi(withUser(injectIntl(withAccessRights(UpdateSchedulesDetails, 'EDM::EdgeDevice'))))));
