import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  SelectBox,
  apiShape,
  notifierShape,
  withApi,
  withNotifier,
} from 'lcm-iot-commons';

export function ProductStatusSelect(props) {
  const [productStatuses, setProductStatuses] = useState([]);
  const [fetching, setFetching] = useState(true);
  const {
    api, notifier, initialValues,
  } = props;

  React.useEffect(() => {
    const loadProductStatuses = async () => {
      try {
        const response = await api.get('/product/statuses', { include: 'tenant' });
        const statuses = response.product_statuses.map((productStatus) => ({
          id: productStatus.id,
          name: productStatus.name,
          code: productStatus.code,
          description: productStatus?.description,
          tenantPublic: productStatus?.tenant?.public,
          tenantName: productStatus?.tenant?.name,
        }));
        if (!initialValues.status) {
          initialValues.status = statuses.find((status) => status.code === 'active');
        }
        setProductStatuses(statuses);
      } catch (error) {
        notifier.showError(api.translateError(error));
      } finally {
        setFetching(false);
      }
    };

    loadProductStatuses();
  }, [initialValues]);

  return fetching ? null : (
    <SelectBox
      {...props}
      options={productStatuses}
      labelKey="name"
    />
  );
}

ProductStatusSelect.propTypes = {
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
  initialValues: PropTypes.shape({
    status: PropTypes.shape({}),
  }).isRequired,
};

export default withNotifier(withApi(ProductStatusSelect));
