import ApiClientError from './ApiClientError';

class BadRequestError extends ApiClientError {
  constructor(request, response, cause) {
    super('BadRequestError', 400, 'The data given in the request failed validation.', request, response, cause);
  }
}

BadRequestError.create = (errors) => new BadRequestError(null, { data: { errors } });

export default BadRequestError;
