import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { Tooltip, MenuItem } from 'react-bootstrap';
import AllObjectsSelectModal from '../AllObjects/AllObjectsSelectModal';
import ConfirmationModal from '../ConfirmationModal';
import ItemMenu from '../ItemMenu';
import ListItem from '../List/ListItem';

import {
  apiErrorsContain,
  fetchAccessRights,
  assignInstrumentationToAsset,
  assignNodeToAsset,
  deleteAsset,
  moveAssetFromNodeToInstrumentation,
  moveAssetNodeAssignment,
  unassignAssetFromInstrumentation,
  unassignAssetFromNode,
  unassignAssetFromSystem,
  loadAsset,
  moveAssetFromNodeToSystem,
  assignSystemToAsset,
  assignAssetToSubscription,
} from '../../api';

import {
  assetShape,
  accessRightsShape,
  nodeShape,
  instrumentationShape,
  intlShape,
  systemShape,
} from '../../shapes';

import {
  formatAssetDetails,
  navigateTo,
  showError,
  showSuccess,
  handleUnknownErrors,
  url,
} from '../../utils';

import {
  withRules,
  rulesShape,
  withSubscription,
  subscriptionShape,
} from '../../context';
import Picture from '../Pictures/Picture';
import { FormattedNumber } from '../Format';
import { withIntl } from '../../wrappers';
import DocumentExportModal from '../Documents/DocumentExportModal';
import CustomOverlayTrigger from '../CustomOverlayTrigger';

export class AssetItem extends Component {
  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.checkIfUserCanDeleteAsset = this.checkIfUserCanDeleteAsset.bind(this);
    this.handleOnItemMenuOpen = this.handleOnItemMenuOpen.bind(this);
    this.handleOnEditClick = this.handleOnEditClick.bind(this);
    this.handleOnConfirm = this.handleOnConfirm.bind(this);
    this.handleOnAssignClick = this.handleOnAssignClick.bind(this);
    this.handleOnUnassignClick = this.handleOnUnassignClick.bind(this);
    this.handleOnMoveAssignmentClick = this.handleOnMoveAssignmentClick.bind(this);
    this.handleOnModalClose = this.handleOnModalClose.bind(this);
    this.handleOnNodeSelect = this.handleOnNodeSelect.bind(this);
    this.handleOnInstrumentationSelect = this.handleOnInstrumentationSelect.bind(this);
    this.handleOnDeleteClick = this.handleOnDeleteClick.bind(this);
    this.handleOnSystemSelect = this.handleOnSystemSelect.bind(this);
    this.handleOnAssignToSubscriptionClick = this.handleOnAssignToSubscriptionClick.bind(this);

    this.state = {
      accessRights: { canUpdate: false, canDelete: false },
      assigning: false,
      unassigning: false,
      moving: false,
      deleting: false,
      manualAssignment: false,
    };
  }

  handleOnConfirm() {
    const {
      intl, onAssetRemoved, asset, parentNode, parentInstrumentation, parentSystem, subscription,
    } = this.props;
    const { deleting, unassigning } = this.state;
    this.resetActionsState();
    if (deleting) {
      return this.checkIfUserCanDeleteAsset().then((userCanDelete) => {
        if (userCanDelete) {
          return deleteAsset(asset.id).then(async () => {
            showSuccess(intl.formatMessage({ id: 'asset.actions.delete.notification' }));
            onAssetRemoved(asset);
            await subscription.refresh();
          });
        }
        showError(intl.formatMessage({ id: 'api.error.device_not_deactivated_conflict' }));
        return null;
      }).catch((apiErrors) => {
        if (apiErrorsContain(apiErrors, 'assigned_restriction', 'children')) {
          showError(intl.formatMessage({ id: 'api.error.asset.assigned_restriction' }));
        } else {
          handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
        }
      });
    } if (unassigning) {
      if (parentNode) {
        return unassignAssetFromNode(asset.id, parentNode.id).then(() => {
          showSuccess(intl.formatMessage({ id: 'asset.actions.unassign.notification' }));
          onAssetRemoved(asset);
        }).catch((apiErrors) => {
          handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
        });
      } if (parentInstrumentation) {
        return unassignAssetFromInstrumentation(asset.id, parentInstrumentation.id).then(() => {
          showSuccess(intl.formatMessage({ id: 'asset.actions.unassign.notification' }));
          onAssetRemoved(asset);
        }).catch((apiErrors) => {
          handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
        });
      } if (parentSystem) {
        return unassignAssetFromSystem(asset.id, parentSystem.id).then(() => {
          showSuccess(intl.formatMessage({ id: 'asset.actions.unassign.notification' }));
          onAssetRemoved(asset);
        }).catch((apiErrors) => {
          handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
        });
      }
    }
    return null;
  }

  handleOnItemMenuOpen() {
    const { asset, intl } = this.props;
    fetchAccessRights('Asset', asset.id)
      .then((accessRights) => this.setState({ accessRights }))
      .catch((errors) => handleUnknownErrors(errors, intl.formatMessage({ id: 'api.error.unknown' })));
  }

  handleOnEditClick() {
    const { asset } = this.props;
    navigateTo(`/assets/${asset.id}/edit`);
  }

  handleOnAssignClick() {
    this.setState({ assigning: true });
  }

  handleOnUnassignClick() {
    this.setState({ unassigning: true });
  }

  handleOnMoveAssignmentClick() {
    this.setState({ moving: true });
  }

  handleOnDeleteClick() {
    this.setState({ deleting: true });
  }

  handleOnModalClose() {
    this.resetActionsState();
  }

  handleOnAssignToSubscriptionClick() {
    const {
      intl, asset, subscription,
    } = this.props;
    if (subscription?.asset_quota > 0 && subscription?.number_assigned_assets >= subscription?.asset_quota) {
      showError(intl.formatMessage({ id: 'subscription.limit.asset_details' }, { asset_quota: subscription?.asset_quota }));
    } else {
      assignAssetToSubscription(asset.id, subscription?.id).then(() => {
        this.setState({ manualAssignment: true });
        showSuccess(intl.formatMessage({ id: 'asset.actions.assign.notification' }));
      }).catch((apiErrors) => {
        handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
      });
    }
  }

  handleApiErrors(apiErrors) {
    const { intl } = this.props;
    if (apiErrorsContain(apiErrors, 'associations_already_added')) {
      showError(intl.formatMessage({ id: 'asset.actions.assign.error' }));
    } else {
      handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
    }
  }

  handleOnNodeSelect(node) {
    const {
      asset, onAssetRemoved, intl, parentNode, parentInstrumentation, parentSystem,
    } = this.props;
    const { assigning, moving } = this.state;

    return new Promise((resolve, reject) => {
      if (assigning && node) {
        assignNodeToAsset(asset.id, node.id).then(() => {
          if (!parentNode && !parentInstrumentation && !parentSystem) {
            showSuccess(intl.formatMessage({ id: 'asset.actions.move_assignment.notification' }));
            onAssetRemoved(asset);
          } else {
            showSuccess(intl.formatMessage({ id: 'asset.actions.assign.notification' }));
          }
          this.resetActionsState();
          resolve();
        }).catch((apiErrors) => {
          this.handleApiErrors(apiErrors);
          reject();
        });
      } else if (moving && asset && parentNode && node) {
        moveAssetNodeAssignment(asset.id, parentNode.id, node.id).then(() => {
          showSuccess(intl.formatMessage({ id: 'asset.actions.move_assignment.notification' }));
          onAssetRemoved(asset);
          this.resetActionsState();
          resolve();
        }).catch((apiErrors) => {
          this.handleApiErrors(apiErrors);
          reject();
        });
      } else {
        reject();
      }
    });
  }

  handleOnInstrumentationSelect(instrumentation) {
    const {
      asset, onAssetRemoved, intl, parentNode, parentInstrumentation,
    } = this.props;
    const { assigning, moving } = this.state;

    return new Promise((resolve, reject) => {
      if (assigning && instrumentation) {
        assignInstrumentationToAsset(asset.id, instrumentation.id).then(() => {
          if (!parentNode && !parentInstrumentation) {
            onAssetRemoved(asset);
            showSuccess(intl.formatMessage({ id: 'asset.actions.move_assignment.notification' }));
          } else {
            showSuccess(intl.formatMessage({ id: 'asset.actions.assign.notification' }));
          }
          this.resetActionsState();
          resolve();
        }).catch((apiErrors) => {
          this.handleApiErrors(apiErrors);
          reject();
        });
      } else if (moving && asset && parentNode && instrumentation) {
        moveAssetFromNodeToInstrumentation(asset.id, parentNode.id, instrumentation.id).then(() => {
          showSuccess(intl.formatMessage({ id: 'asset.actions.move_assignment.notification' }));
          onAssetRemoved(asset);
          this.resetActionsState();
          resolve();
        }).catch((apiErrors) => {
          this.handleApiErrors(apiErrors);
          reject();
        });
      }
    });
  }

  handleOnSystemSelect(system) {
    const {
      asset, onAssetRemoved, intl, parentNode, parentSystem,
    } = this.props;
    const { assigning, moving } = this.state;
    return new Promise((resolve, reject) => {
      if (assigning && system) {
        assignSystemToAsset(asset.id, system.id).then(() => {
          if (!parentNode && !parentSystem) {
            onAssetRemoved(asset);
            showSuccess(intl.formatMessage({ id: 'asset.actions.move_assignment.notification' }));
          } else {
            showSuccess(intl.formatMessage({ id: 'asset.actions.assign.notification' }));
          }
          this.resetActionsState();
          resolve();
        }).catch((apiErrors) => {
          this.handleApiErrors(apiErrors);
          reject();
        });
      } else if (moving && asset && parentNode && system) {
        moveAssetFromNodeToSystem(asset.id, parentNode.id, system.id).then(() => {
          showSuccess(intl.formatMessage({ id: 'asset.actions.move_assignment.notification' }));
          onAssetRemoved(asset);
          this.resetActionsState();
          resolve();
        }).catch((apiErrors) => {
          this.handleApiErrors(apiErrors);
          reject();
        });
      }
    });
  }

  async checkIfUserCanDeleteAsset() {
    const { asset, rules } = this.props;
    if (rules.asset(asset).get('needsToBeDeactivatedForDeletion')) {
      const include = 'specifications[eh.user_config.device_should_be_active,eh.pcps.device_active]';
      const connectedAsset = await loadAsset(asset.id, { include });
      if (connectedAsset.specifications?.['eh.pcps.device_active'] && connectedAsset.specifications?.['eh.user_config.device_should_be_active']) {
        return connectedAsset.specifications['eh.pcps.device_active'].value === 'false' && connectedAsset.specifications['eh.user_config.device_should_be_active'].value === 'false';
      }
    }
    return true;
  }

  resetActionsState() {
    this.setState({
      assigning: false, unassigning: false, moving: false, deleting: false,
    });
  }

  render() {
    const {
      asset, showItemMenu, intl, parentNode, parentInstrumentation, parentAccessRights, rules, parentSystem,
    } = this.props;
    const { canUpdate, canDelete, canPermit } = this.state.accessRights;
    const {
      assigning, moving, unassigning, deleting, manualAssignment,
    } = this.state;
    const assetRules = rules.asset(asset);
    const applicationRules = rules.application();
    const assetAssigned = manualAssignment || asset.inSubscription;

    const listItemValue = assetAssigned && asset.value ? (
      <>
        <FormattedNumber
          minimumFractionDigits={assetRules.get('minDecimalDigits', asset.value.key)}
          maximumFractionDigits={assetRules.get('maxDecimalDigits', asset.value.key)}
          value={asset.value.value}
        />
        <span>
          {` ${asset.value.unit}`}
        </span>
      </>
    ) : null;

    const listItemActions = !assetAssigned ? (
      <ItemMenu onOpen={this.handleOnItemMenuOpen}>
        <MenuItem onSelect={this.handleOnAssignToSubscriptionClick}><FormattedMessage id="button.assign_to_subscription" /></MenuItem>
      </ItemMenu>
    ) : (
      <>
        { showItemMenu ? (
          <ItemMenu onOpen={this.handleOnItemMenuOpen}>
            <MenuItem onSelect={this.handleOnEditClick} disabled={!canUpdate}><FormattedMessage id="button.edit" /></MenuItem>
            { parentNode && (
              <>
                <MenuItem onSelect={this.handleOnAssignClick} disabled={!canPermit}><FormattedMessage id="button.assign" /></MenuItem>
                <MenuItem onSelect={this.handleOnUnassignClick} disabled={!parentAccessRights.canUpdate || !canPermit}><FormattedMessage id="button.unassign" /></MenuItem>
                <MenuItem onSelect={this.handleOnMoveAssignmentClick} disabled={!parentAccessRights.canUpdate || !canPermit}><FormattedMessage id="button.move" /></MenuItem>
              </>
            )}
            { !parentNode && !parentInstrumentation && !parentSystem && (
              <MenuItem onSelect={this.handleOnAssignClick} disabled={!canPermit}><FormattedMessage id="button.move" /></MenuItem>
            )}
            { (parentInstrumentation || parentSystem) && (
              <MenuItem onSelect={this.handleOnUnassignClick} disabled={!parentAccessRights.canUpdate || !canPermit}><FormattedMessage id="button.unassign" /></MenuItem>
            )}
            { applicationRules.get('showFilesExportInContextMenu') && (
              <DocumentExportModal object={{ asset }}>
                {({ showModal }) => (
                  <MenuItem onSelect={showModal}><FormattedMessage id="button.export_files" /></MenuItem>
                )}
              </DocumentExportModal>
            )}
            <MenuItem onSelect={this.handleOnDeleteClick} disabled={!canDelete}><FormattedMessage id="button.delete" /></MenuItem>
          </ItemMenu>
        ) : null }
        {assigning || moving ? (
          <AllObjectsSelectModal
            onClose={this.handleOnModalClose}
            onSelectNode={this.handleOnNodeSelect}
            onSelectInstrumentation={this.handleOnInstrumentationSelect}
            onSelectSystem={this.handleOnSystemSelect}
            canSelectInstrumentations
            canSelectSystems
            type={((parentNode || parentInstrumentation || parentSystem) && assigning && 'assign') || 'move'}
          />
        ) : null}
        {deleting || unassigning ? (
          <ConfirmationModal
            intl={intl}
            id="confirmation-modal"
            show
            titleText={intl.formatMessage({ id: deleting ? 'asset.actions.delete.modal_title' : 'asset.actions.unassign.confirmation.title' })}
            messageText={intl.formatMessage({ id: deleting ? 'asset.actions.delete.modal_message' : 'asset.actions.unassign.confirmation.message' })}
            onConfirm={this.handleOnConfirm}
            onClose={this.handleOnModalClose}
          />
        ) : null}
      </>
    );

    const listItem = (
      <ListItem
        id={`asset-item-${asset.id}`}
        image={asset.thumbnailUrl ? <Picture width={50} height={50} src={asset.thumbnailUrl} placeholder="icon icon-eh-device" /> : undefined}
        icon={asset.thumbnailUrl ? undefined : 'icon-eh-device'}
        title={asset.serialNumber}
        description={formatAssetDetails(asset)}
        namur={assetAssigned ? asset.assetStatusCode : null}
        target={url(`/assets/${asset.id}`)}
        disabled={!assetAssigned}
        value={listItemValue}
        actions={listItemActions}
      />
    );

    if (!assetAssigned) {
      const displayTooltip = (
        <Tooltip id="tooltip">{ intl.formatMessage({ id: 'subscription.asset_inactive_because_not_assigned_message' }) }</Tooltip>
      );
      return (
        <CustomOverlayTrigger placement="bottom" overlay={displayTooltip}>
          <span>
            {listItem}
          </span>
        </CustomOverlayTrigger>
      );
    }
    return listItem;
  }
}

AssetItem.propTypes = {
  asset: assetShape,
  intl: intlShape,
  onAssetRemoved: PropTypes.func,
  showItemMenu: PropTypes.bool,
  parentNode: nodeShape,
  parentSystem: systemShape,
  parentInstrumentation: instrumentationShape,
  parentAccessRights: accessRightsShape,
  rules: rulesShape,
  subscription: subscriptionShape,
};

export default withSubscription(withRules(withIntl(AssetItem)));
