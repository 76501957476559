/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  intlShape,
  backendShape,
  browserShape,
  notifierShape,
  withBackend,
  withBrowser,
  withNotifier,
  NotFoundError,
  Select,
  ActionBar,
  isEmpty,
  Container,
  Column,
  Heading,
  Loader,
  Row,
  withConfiguration,
} from 'lcm-iot-commons';
import PaidPlan from 'lcm-iot-commons/client/lib/components/UpgradePlan/PaidPlan';
import UnavailablePlan from 'lcm-iot-commons/client/lib/components/UpgradePlan/UnavailablePlan';

export function PlanVariantSelectionUpgrade({
  backend, browser, intl, notifier, match, configuration,
}) {
  const [upgradeState, setUpgradeState] = useState({
    planVariants: undefined,
    planCart: undefined,
    subscription: undefined,
    currencies: undefined,
    currency: undefined,
    loading: true,
  });
  const [creatPlanCart, setCreatPlanCart] = useState({
    creating: false,
    selectedPlanVariantId: undefined,
  });
  const { defaultCurrency } = configuration;

  const loadCurrencies = async () => {
    const response = await backend.get('/plan_variants/currencies');
    return response.currencies.map((planCurrency) => ({
      id: planCurrency.code,
      name: planCurrency.code,
      selected: planCurrency.selected,
    }));
  };

  const loadData = async (actualCurrency) => {
    try {
      let nextCurrency;
      let planCart;
      const nextCurrencies = upgradeState.currencies || await loadCurrencies();
      const subscription = await backend.get(`/subscriptions/${match.params.id}`, { include: 'payment_details' });

      const filter = { language: configuration.language };
      if (subscription.plan_variant.endsWith('-usd')) {
        nextCurrency = actualCurrency || nextCurrencies.find((c) => c.id === 'USD');
        filter.currency = nextCurrency.id;
      } else if (subscription.type === 'free' && subscription.plan_variant.endsWith('-free')) {
        nextCurrency = actualCurrency || nextCurrencies.find((c) => c.id === defaultCurrency);
        filter.currency = nextCurrency.id;
      } else {
        nextCurrency = actualCurrency || nextCurrencies.find((c) => c.selected);
        filter.current_id = subscription.plan_variant;
      }

      const responsePlanVariants = await backend.get('/plan_variants', filter);
      try {
        planCart = await backend.get('/plan_cart', { language: configuration.language });
      } catch (error) {
        if (!(error instanceof NotFoundError)) {
          notifier.showError(backend.translateError(error));
        }
      }
      setUpgradeState({
        planVariants: responsePlanVariants.plan_variants,
        planCart,
        subscription,
        currencies: nextCurrencies,
        currency: nextCurrency,
        loading: false,
      });
    } catch (error) {
      if ((error instanceof NotFoundError)) {
        browser.navigateTo('/404');
      } else {
        notifier.showError(backend.translateError(error));
      }
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  const handleOnSelect = async (planVariant, planVariantAddons) => {
    setCreatPlanCart({ creating: true, selectedPlanVariantId: planVariant.id });
    try {
      const packedPlanCart = {
        plan_variant_id: planVariant.id,
        subscription_id: match.params.id,
        addons: planVariantAddons ? Object.keys(planVariantAddons).map((addonId) => ({
          plan_variant_addon_id: addonId,
          amount: planVariantAddons[addonId],
        })) : null,
      };
      await backend.post('/plan_cart', { language: configuration.language }, packedPlanCart);
      if (upgradeState.subscription.type === 'free' && isEmpty(upgradeState.subscription.addons)) {
        browser.navigateTo('/subscription/create');
      } else {
        browser.navigateTo(`/subscription/connect/${match.params.id}/checkout`);
      }
    } catch (error) {
      notifier.showError(backend.translateError(error));
    }
  };

  const renderPlanVariant = (planVariant) => {
    const { planVariants, subscription, planCart } = upgradeState;
    const { creating, selectedPlanVariantId } = creatPlanCart;
    const isFree = subscription.type === 'free' && isEmpty(subscription.addons);
    const fullCurrentVariant = isFree ? planVariants.find((plan) => plan.type === 'free') : planVariants.find((plan) => plan.id === subscription.plan_variant);
    let displayPlanVariant = null;

    if (subscription.plan_variant === planVariant.id || (isFree && subscription.type === planVariant.type)) {
      displayPlanVariant = (
        <PaidPlan
          mode="current"
          planCart={planCart}
          planVariant={planVariant}
          currentAddons={subscription.addons}
          currentPlanVariant={fullCurrentVariant}
          onSelect={handleOnSelect}
          disabled={creating}
          creating={selectedPlanVariantId === planVariant.id}
        />
      );
    } else if (fullCurrentVariant.sort > planVariant.sort) {
      displayPlanVariant = (
        <UnavailablePlan
          planVariant={planVariant}
        />
      );
    } else {
      displayPlanVariant = (
        <PaidPlan
          mode="upgrade"
          planCart={planCart}
          planVariant={planVariant}
          currentAddons={subscription.addons}
          currentPlanVariant={fullCurrentVariant}
          onSelect={handleOnSelect}
          disabled={creating}
          creating={selectedPlanVariantId === planVariant.id}
        />
      );
    }

    return (
      <div id={planVariant.id} className={`col-md-${12 / upgradeState.planVariants.length}`} key={planVariant.id}>
        {displayPlanVariant}
      </div>
    );
  };

  const renderPlanVariants = () => {
    const chargeBeePlanVariants = upgradeState.planVariants.map((pv) => renderPlanVariant(pv));
    return chargeBeePlanVariants;
  };

  // eslint-disable-next-line camelcase
  const planVariants = upgradeState?.planVariants && upgradeState?.subscription?.plan_variant ? renderPlanVariants() : null;

  const renderCurrencySelection = upgradeState?.subscription?.type === 'free' && isEmpty(upgradeState?.subscription?.addons) ? (
    <div className="action-bar">
      <div id="currency-select" className="form-group">
        <label>{intl.formatMessage({ id: 'planvariant.currency' })}</label>
        <Select options={upgradeState.currencies} selectedOption={upgradeState.currency} onChange={loadData} />
      </div>
    </div>
  ) : null;

  return (
    <Loader loading={upgradeState.loading}>
      <Container id="connect-plan-variants" className="container plan-variants">
        <Row>
          <Column className="col-xs-12">
            <ActionBar>
              <Heading title={intl.formatMessage({ id: 'plan_variants.title' })} />
              {renderCurrencySelection}
            </ActionBar>
          </Column>
        </Row>
        <Row>
          <div id="plan-variants-items">
            {planVariants}
          </div>
        </Row>
      </Container>
    </Loader>
  );
}

PlanVariantSelectionUpgrade.propTypes = {
  backend: backendShape.isRequired,
  browser: browserShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }).isRequired,
  configuration: PropTypes.shape({ defaultCurrency: PropTypes.string, language: PropTypes.string }).isRequired,
};

export default injectIntl(withBackend(withBrowser(withNotifier(withConfiguration(PlanVariantSelectionUpgrade)))));
