import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import { isEmpty, uniqBy } from 'lodash-es';

import {
  ActionBar,
  apiShape,
  Column,
  Container,
  Heading,
  intlShape,
  isTouchDevice,
  List,
  Loader,
  notifierShape,
  PaymentRequiredError,
  Row,
  SearchBar,
  withAccessRights,
  withApi,
  withIntl,
  withNotifier,
} from 'lcm-iot-commons';

import SubscriptionAssignedAssetsEditItem from 'lcm-iot-commons/client/lib/components/Subscriptions/SubscriptionAssignedAssetsEditItem';

export function ConnectSubscriptionAssignedAssetsEdit({
  api,
  intl,
  match,
  notifier,
}) {
  const [data, setData] = useState();
  const [searchResult, setSearchResult] = useState();
  const [fetching, setFetching] = useState(true);
  const [updating, setUpdating] = useState(false);

  const search = async (assignedAssetIds, query) => {
    try {
      setFetching(true);
      const response = await api.get('/search', { q: isEmpty(query) ? '*' : query, scope: 'assets' });
      setSearchResult({
        // search result is not unique, so we need to filter out duplicates
        assets: uniqBy(response.results, 'id'),
        nextPageUrl: response.pagination.next,
      });
      setFetching(false);
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  const loadData = async () => {
    try {
      const response = await api.getAll(`/api_subscriptions/${match.params.id}/assets`, { include: 'product,product.manufacturer,product.tenant' });
      const assignedAssetIds = response.assets.map((asset) => asset.id);

      setData({
        assignedAssetIds,
        assignedAssetCount: assignedAssetIds.length,
      });

      await search(assignedAssetIds);
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  const handleLoadMore = async () => {
    // only trigger this function if there is a next page
    if (!searchResult?.nextPageUrl) return;

    try {
      setFetching(true);
      const response = await api.get(searchResult.nextPageUrl);
      setSearchResult({
        assets: [...searchResult.assets, ...response.results.map((result) => ({
          ...result,
          assigned: data.assignedAssetIds.indexOf(result.id) >= 0,
        }))],
        nextPageUrl: response.pagination.next,
      });
      setFetching(false);
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  const handleOnSearch = async (query) => {
    /* istanbul ignore if */
    if (!data) return;
    await search(data.assignedAssetIds, query);
  };

  const handleOnChange = async (assetId, currentInSubscription) => {
    setUpdating(true);
    try {
      if (currentInSubscription) {
        await api.delete(`/api_subscriptions/${match.params.id}/assets`, { assets: [{ id: assetId }] });
        const assignedAssetIds = data.assignedAssetIds.filter((id) => id !== assetId);
        setData({
          assignedAssetIds,
          assignedAssetCount: assignedAssetIds.length,
        });
        notifier.showSuccess(intl.formatMessage({ id: 'subscription.asset_unassigned_success_message' }));
      } else {
        await api.post(`/api_subscriptions/${match.params.id}/assets`, { assets: [{ id: assetId }] });
        setData({
          assignedAssetIds: [...data.assignedAssetIds, assetId],
          assignedAssetCount: data.assignedAssetCount + 1,
        });
        notifier.showSuccess(intl.formatMessage({ id: 'subscription.asset_assigned_success_message' }));
      }
    } catch (error) {
      if (error instanceof PaymentRequiredError) {
        notifier.showError(intl.formatMessage({ id: 'subscription.connected_asset.quota_exceed_message' }));
      } else {
        notifier.showError(api.translateError(error));
      }
    }
    setUpdating(false);
  };

  React.useEffect(() => {
    loadData();
  }, []);

  const title = data ? `${intl.formatMessage({ id: 'subscription.assigned_asset_edit' })} (${data.assignedAssetCount})`
    : intl.formatMessage({ id: 'subscription.assigned_asset_edit' });

  return (
    <Loader loading={fetching}>
      <Container>
        <Row>
          <Column>
            <ActionBar>
              <Heading title={title} />
            </ActionBar>
          </Column>
        </Row>
        <Row>
          <Column>
            <SearchBar
              autoFocus={!isTouchDevice()}
              disabled={!searchResult}
              filterWildcard={false}
              minChars={1}
              onSearch={handleOnSearch}
              placeholder={intl.formatMessage({ id: 'search.searchbox_placeholder' })}
              resettable
              timeout={800}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <List id="assets-list">
              {searchResult && (
              <InfiniteScroll
                data-testid="infinite-scroll"
                initialLoad={false}
                loadMore={handleLoadMore}
                hasMore={!fetching && !!searchResult?.nextPageUrl}
              >
                {searchResult.assets.map((asset) => (
                  <SubscriptionAssignedAssetsEditItem
                    key={asset.id}
                    id={asset.id}
                    title={asset.title}
                    currentInSubscription={data.assignedAssetIds.indexOf(asset.id) >= 0}
                    description={asset.description}
                    pictureUrl={asset.picture_url}
                    disabled={fetching || updating}
                    checked={data.assignedAssetIds.includes(asset.id)}
                    onChange={handleOnChange}
                  />
                )) }
              </InfiniteScroll>
              )}
            </List>
          </Column>
        </Row>
      </Container>
    </Loader>
  );
}

ConnectSubscriptionAssignedAssetsEdit.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default withIntl(withApi(withNotifier(withAccessRights(ConnectSubscriptionAssignedAssetsEdit, 'APISubscription'))));
