export function extractCountry(country) {
  return {
    code: country.code,
    name: country.name,
  };
}

export function extractCountries(rawCountries) {
  return rawCountries.countries.map((country) => extractCountry(country));
}

export function extractCountriesWithUserCountry(rawCountries) {
  return { countries: extractCountries(rawCountries), userCountryCode: rawCountries.user_country_code };
}
