/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import validator from 'validator';

import {
  Form,
  Loader,
  Heading,
  Container,
  Row,
  Column,
  DetailsItem,
  ActionBar,
  TextInput,
  SelectBox,
  ButtonGroup,
  SubmitButton,
  CancelButton,
  apiShape,
  intlShape,
  browserShape,
  notifierShape,
  withApi,
  withIntl,
  withBrowser,
  withNotifier,
  withAccessRights,
  handleFormikValueChange,
  capitalizeWords,
  setEmptyOrUndefinedToNull,
  removeNullAttributes,
} from 'lcm-iot-commons';

const ENUM_DHCP = 'DHCP';
const ENUM_MANUAL = 'manual';

export function extractInitialValues(response, intl) {
  const initialValues = { ...response };
  initialValues.type = response.number !== undefined ? `${response.type} ${response.number}` : response.type;
  initialValues.modus = {
    key: response.modus,
    name: intl.formatMessage({ id: `enum.network_interface_modus.${response.modus.toLowerCase()}` }),
  };
  return initialValues;
}

export function createPayload(values) {
  const {
    name, modus, ip_address, subnet_mask, default_gateway, dns1, dns2, http_proxy_host, http_proxy_port,
  } = values;
  let payload = {
    modus: modus.key,
    name: name?.trim(),
    http_proxy_host: http_proxy_host?.trim(),
    http_proxy_port,
  };
  if (modus.key === ENUM_MANUAL) {
    payload = {
      ...payload,
      default_gateway,
      dns1,
      dns2,
      ip_address,
      subnet_mask,
    };
  }
  return setEmptyOrUndefinedToNull(payload);
}

export const validateAccessRights = (accessRights) => accessRights.canUpdate;

export function NetworkInterfaceEdit(props) {
  const {
    api, intl, browser, match, notifier,
  } = props;
  const { id, networkInterfaceId } = match.params;
  const [initialValues, setInitialValues] = useState();

  const loadData = async () => {
    try {
      const response = await api.get(`/edm/edge_devices/${id}/network_interfaces/${networkInterfaceId}`);
      if (response.type === 'WAN') { browser.navigateTo('/404'); }
      setInitialValues(extractInitialValues(response, intl));
    } catch (error) {
      notifier.showError(api.translateError(error));
      setInitialValues({});
    }
  };
  React.useEffect(() => {
    loadData();
  }, [id]);

  const onSubmit = async (values, actions) => {
    const payload = createPayload(values);
    try {
      await api.patch(`/edm/edge_devices/${id}/network_interfaces/${networkInterfaceId}`, payload);
      notifier.showSuccess(intl.formatMessage({ id: 'network_interface_edit.success_notification' }));
      browser.goBack();
    } catch (error) {
      notifier.showError(api.translateError(error));
    } finally {
      actions.setSubmitting(false);
    }
  };

  const validateForm = (values) => {
    const errors = {};
    const {
      modus, ip_address, subnet_mask, default_gateway, dns1, dns2, http_proxy_port,
    } = values;
    const validIpHint = intl.formatMessage({ id: 'validation.ip_address.invalid' });
    const validPortHint = intl.formatMessage({ id: 'validation.port.invalid' });

    if (modus.key === ENUM_MANUAL) {
      errors.ip_address = validator.isIP(ip_address || '') ? null : validIpHint;
      errors.subnet_mask = validator.isIP(subnet_mask || '') ? null : validIpHint;
      if (default_gateway) { errors.default_gateway = validator.isIP(default_gateway) ? null : validIpHint; }
      if (dns1) { errors.dns1 = validator.isIP(dns1) ? null : validIpHint; }
      if (dns2) { errors.dns2 = validator.isIP(dns2) ? null : validIpHint; }
    }
    if (http_proxy_port) { errors.http_proxy_port = validator.isPort(http_proxy_port.toString()) ? null : validPortHint; }
    return removeNullAttributes(errors);
  };

  const renderForm = (formProps) => {
    const { isSubmitting, values } = formProps;
    const { type } = values;
    const isDhcp = values.modus.key === ENUM_DHCP;
    const isManualMode = values.modus.key === ENUM_MANUAL;
    return (
      <Form {...formProps}>
        <DetailsItem id="edge-device-type" translationKey="label.type" value={type} />
        <TextInput
          {...formProps}
          id="name"
          name="name"
          label={intl.formatMessage({ id: 'label.name' })}
        />
        <h2 id="section-ip-configuration">{intl.formatMessage({ id: 'network_interface_details.ip_configuration' })}</h2>
        <SelectBox
          {...formProps}
          id="modus"
          name="modus"
          valueKey="key"
          label={intl.formatMessage({ id: 'label.modus' })}
          options={[
            { id: 0, key: ENUM_MANUAL, name: capitalizeWords(ENUM_MANUAL) },
            { id: 1, key: ENUM_DHCP, name: ENUM_DHCP },
          ]}
          handleChange={(event) => handleFormikValueChange({ ...formProps, key: 'modus', name: 'modus' }, event.target.value)}
          required
        />
        {isManualMode && (
          <>
            <TextInput
              {...formProps}
              id="ip-address"
              name="ip_address"
              label={intl.formatMessage({ id: 'label.ip_address' })}
              placeholder={intl.formatMessage({ id: 'general.default_ip' })}
              required={isManualMode}
              disabled={isDhcp}
            />
            <TextInput
              {...formProps}
              id="subnet-mask"
              name="subnet_mask"
              label={intl.formatMessage({ id: 'label.subnet_mask' })}
              placeholder={intl.formatMessage({ id: 'general.default_ip' })}
              required={isManualMode}
              disabled={isDhcp}
            />
            <TextInput
              {...formProps}
              id="default-gateway"
              name="default_gateway"
              label={intl.formatMessage({ id: 'label.default_gateway' })}
              placeholder={intl.formatMessage({ id: 'general.default_ip' })}
              disabled={isDhcp}
            />
            <TextInput
              {...formProps}
              id="dns1"
              name="dns1"
              label={intl.formatMessage({ id: 'label.dns1' })}
              placeholder={intl.formatMessage({ id: 'general.default_ip' })}
              disabled={isDhcp}
            />
            <TextInput
              {...formProps}
              id="dns2"
              name="dns2"
              label={intl.formatMessage({ id: 'label.dns2' })}
              placeholder={intl.formatMessage({ id: 'general.default_ip' })}
              disabled={isDhcp}
            />
          </>
        )}
        <h2 id="section-proxy">{intl.formatMessage({ id: 'network_interface_details.proxy' })}</h2>
        <TextInput
          {...formProps}
          id="http-proxy-host"
          name="http_proxy_host"
          label={intl.formatMessage({ id: 'label.http_proxy_host' })}
        />
        <TextInput
          {...formProps}
          id="http-proxy-port"
          name="http_proxy_port"
          label={intl.formatMessage({ id: 'label.http_proxy_port' })}
        />
        <ButtonGroup>
          <SubmitButton
            id="edit-submit-button"
            fetching={!initialValues || isSubmitting}
            disabled={!formProps.dirty || isSubmitting}
          />
          <CancelButton
            id="edit-cancel-button"
            fetching={!initialValues || isSubmitting}
            disabled={isSubmitting}
          />
        </ButtonGroup>
      </Form>
    );
  };

  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <Heading title={intl.formatMessage({ id: 'network_interface_edit.header' })} />
          </ActionBar>
        </Column>
      </Row>
      { initialValues && (
      <Row>
        <Column>
          <Formik
            id="network-interface-form"
            onSubmit={onSubmit}
            validate={validateForm}
            initialValues={initialValues}
            render={renderForm}
          />
        </Column>
      </Row>
      )}
      <Loader loading={!initialValues} />
    </Container>
  );
}

NetworkInterfaceEdit.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  browser: browserShape.isRequired,
  notifier: notifierShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      networkInterfaceId: PropTypes.string,
    }),
  }).isRequired,
};

NetworkInterfaceEdit.defaultProps = {
};

export default withBrowser(withApi(withIntl(withNotifier(withAccessRights(NetworkInterfaceEdit, 'EDM::EdgeDevice', validateAccessRights)))));
