import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { accessRightsShape, intlShape } from '../../shapes';
import { withIntl } from '../../wrappers';
import { ObjectsTypeahead, Form, SubmitButton } from '../Form';
import { ObjectsType } from '../../constants';

export function AssignSystemForm({
  intl,
  disabled,
  onSubmit,
  accessRights,
  systemsToIgnore,
}) {
  const renderTypeahead = (formProps) => {
    const {
      isSubmitting,
    } = formProps;

    return (
      <ObjectsTypeahead
        {...formProps}
        id="systems-typeahead"
        name="system"
        disabled={disabled}
        label={intl.formatMessage({ id: 'system.actions.assign' })}
        placeholder={intl.formatMessage({ id: 'label.name' })}
        isSubmitting={isSubmitting}
        objectsType={ObjectsType.Systems}
        objectsToIgnore={systemsToIgnore}
      />
    );
  };

  const renderForm = (formProps) => {
    const {
      isSubmitting,
    } = formProps;

    return (
      <Form {...formProps} noValidate>
        {renderTypeahead(formProps)}
        <div className="btn-group">
          <SubmitButton
            id="assign-systems-form-submit-button"
            fetching={isSubmitting}
            disabled={!formProps?.values?.system || isSubmitting}
            text={intl.formatMessage({ id: 'button.assign' })}
          />
        </div>
      </Form>
    );
  };

  return (
    accessRights.canPermit ? (
      <Formik
        id="assign-systems-formik"
        initialValues={{}}
        onSubmit={onSubmit}
        render={renderForm}
      />
    ) : null
  );
}

AssignSystemForm.propTypes = {
  disabled: PropTypes.bool,
  intl: intlShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  accessRights: accessRightsShape.isRequired,
  systemsToIgnore: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

AssignSystemForm.defaultProps = {
  disabled: false,
};

export default withIntl(AssignSystemForm);
