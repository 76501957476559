import PropTypes from 'prop-types';

export const accessRightsShape = PropTypes.shape({
  type: PropTypes.string,
  id: PropTypes.number,
  canRead: PropTypes.bool,
  canUpdate: PropTypes.bool,
  canDelete: PropTypes.bool,
  canPermit: PropTypes.bool,
});
