import React from 'react';
import PropTypes from 'prop-types';

const createClass = (xs, sm, md, lg, className) => {
  let classNames = [];

  if (xs) {
    classNames.push(`col-xs-${xs}`);
  }

  if (sm) {
    classNames.push(`col-sm-${sm}`);
  }

  if (md) {
    classNames.push(`col-md-${md}`);
  }

  if (lg) {
    classNames.push(`col-lg-${lg}`);
  }

  if (className) {
    classNames.push(className);
  }

  if (classNames.length === 0) {
    classNames = ['col-sm-12'];
  }

  return classNames.join(' ');
};

function Column({
  id, className, style, xs, sm, md, lg, children,
}) {
  return (
    <div id={id} className={createClass(xs, sm, md, lg, className)} style={style}>
      {children}
    </div>
  );
}

Column.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
};

Column.defaultProps = {
  id: undefined,
  className: undefined,
  style: undefined,
  xs: undefined,
  sm: undefined,
  md: undefined,
  lg: undefined,
  children: undefined,
};

export default Column;
