import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { withFlipper } from '../../wrappers';

import {
  htmlLink, url,
} from '../../utils';

export function AllObjectsNoAssetsFoundClue({ nodeId, flipper }) {
  return (
    <div id="no-assets-found" className="clue">
      <div className="clue-header">
        <FormattedMessage id="assets.noassets.ask_create.header" />
      </div>
      <div className="clue-details">
        {flipper.demoDataActive
          ? (
            <FormattedMessage
              id="assets.noassets.ask_create.details_demo_data"
              values={{ a: htmlLink({ href: '/app/id/profile' }) }}
            />
          )
          : <FormattedMessage id="assets.noassets.ask_create.details" />}
      </div>
      <Link className="btn btn-primary" to={url(nodeId ? `/nodes/${nodeId}/assets/create` : '/assets/create')}>
        <FormattedMessage id="button.create_asset" />
      </Link>
    </div>
  );
}

AllObjectsNoAssetsFoundClue.propTypes = {
  nodeId: PropTypes.number,
  flipper: PropTypes.shape({ demoDataActive: PropTypes.bool }),
};

AllObjectsNoAssetsFoundClue.defaultProps = {
  nodeId: undefined,
};

export default withFlipper(AllObjectsNoAssetsFoundClue);
