import PropTypes from 'prop-types';

export const userPermissionsShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  email: PropTypes.string,
  read: PropTypes.number,
  update: PropTypes.number,
  delete: PropTypes.number,
  permit: PropTypes.number,
});

export const usersPermissionsShape = PropTypes.arrayOf(userPermissionsShape);

export const userGroupPermissionsShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  read: PropTypes.number,
  update: PropTypes.number,
  delete: PropTypes.number,
  permit: PropTypes.number,
});

export const userGroupsPermissionsShape = PropTypes.arrayOf(userGroupPermissionsShape);

export const permissionShape = PropTypes.shape({
  permissionType: PropTypes.string,
  permitableType: PropTypes.string,
  permitableId: PropTypes.number,
  assignableType: PropTypes.string,
  assignableId: PropTypes.number,
  assignableUserName: PropTypes.string,
  assignableUserGroupName: PropTypes.string,
});

export const permissionsShape = PropTypes.shape({ permissions: PropTypes.arrayOf(permissionShape) });
