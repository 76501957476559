import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import ActionBar from '../ActionBar/ActionBar';
import EditPictures from '../Pictures/EditPictures';
import PermissionsEdit from '../Permissions/PermissionsEdit';
import Loader from '../Loader';
import Heading from '../Heading';
import { NotFoundError } from '../../api/errors';
import {
  withRules,
  rulesShape,
  withApi,
  apiShape,
  withNotifier,
  notifierShape,
  withBrowser,
  browserShape,
  withBackend,
  backendShape,
} from '../../context';
import { accessRightsShape } from '../../shapes/accessRightsShape';
import withAccessRights, { enforceAccessRightCanUpdate } from '../../wrappers/withAccessRights';
import intlShape from '../../shapes/intlShape';
import {
  extractInstrumentationStatuses, extractInstrumentationTypes, extractInstrumentation, extractAssets,
} from '../../extractors';
import { packInstrumentationSpecifications } from '../../packmans';
import { parseIntlNumber, convertSpecificationKeyToLabel, convertLabelToSpecificationKey } from '../../utils';
import InstrumentationForm from './InstrumentationForm';
import BackButton from '../BackButton';
import { Column, Container, Row } from '../Grid';

export function InstrumentationEdit({
  intl, api, backend, browser, notifier, rules, match, accessRights,
}) {
  const instrumentationId = match.params.id;
  const [instrumentationDetails, setInstrumentationDetails] = useState();
  const instrumentationRules = instrumentationDetails ? rules.instrumentation(instrumentationDetails.instrumentation, instrumentationDetails.assets) : undefined;
  const collapseProperties = rules.application().get('shouldCollapseProperties');

  const onSubmit = async (values, actions) => {
    let packSpecificationValues = instrumentationRules.get('needsFullEmptySettings') ? {
      ...values,
      full: parseIntlNumber(values.full).toString(),
      empty: parseIntlNumber(values.empty).toString(),
      blockDistance: parseIntlNumber(values.blockDistance).toString(),
      sensitivity: values.sensitivity.id,
    } : values;

    if (values.mediumType) {
      packSpecificationValues = {
        ...packSpecificationValues,
        mediumType: values.mediumType.id,
      };
    }

    try {
      const patchInstrumentation = {
        tag: values.tag,
        description: values.description,
        status: { id: values.status.id },
        type: { id: values.type.id },
        criticality: values.criticality.id.toLowerCase(),
        accessibility: values.accessibility.id.toLowerCase(),
      };
      if (!values.type.tenantPublic && values.tenantId !== values.type.tenantId) {
        // FIXME: this assigns the tenant of the type when creating a new instrumentation. We need real tenant handling instead here!
        patchInstrumentation.tenant = { id: values.type.tenantId };
      }

      await api.patch(`/instrumentations/${instrumentationId}`, patchInstrumentation);

      const specifications = packInstrumentationSpecifications(packSpecificationValues);

      values.specifications?.forEach((specification) => {
        if (specification.key !== '' && specification.key?.name) {
          specifications[convertLabelToSpecificationKey(specification.key.name)] = {
            value: specification.value,
            ui_visible: true,
          };
        }
      });

      await api.patch(`/instrumentations/${instrumentationId}/specifications`, specifications);

      if (instrumentationDetails.initialSpecificationKeys?.length > 0) {
        const specificationKeysToDelete = instrumentationDetails.initialSpecificationKeys
          .filter((key) => !values.specifications.find((specification) => specification.key !== '' && specification.key?.id === key));
        if (specificationKeysToDelete.length > 0) {
          await api.delete(`/instrumentations/${instrumentationId}/specifications`, specificationKeysToDelete);
        }
      }

      if (instrumentationRules.get('needsFullEmptySettings')) {
        await backend.patch(`/connected_instrumentations/${instrumentationId}`);
      }

      actions.setSubmitting(false);
      actions.resetForm(values);
      notifier.showSuccess(intl.formatMessage({ id: 'instrumentation_edit.success_notification' }));
      browser.navigateTo(`/instrumentations/${instrumentationId}`);
    } catch (error) {
      actions.setSubmitting(false);
      notifier.showError(api.translateError(error));
    }
  };

  const loadData = async () => {
    try {
      const tag = await api.get(`/instrumentations/${instrumentationId}`, { include: 'type,status,specifications' }, false);
      const assets = await api.getAll(`/instrumentations/${instrumentationId}/assets`, { include: 'product,product.tenant,product.manufacturer.tenant,specifications' });
      const tagStatuses = await api.getAll(`/instrumentations/${instrumentationId}/status-options`, { include: 'tenant' });
      const tagTypes = await api.getAll(`/instrumentations/${instrumentationId}/type-options`, { include: 'tenant' });
      const extractedAssets = extractAssets(assets);
      let firmwareVersion;
      let sensorBuildNumber;
      if (extractedAssets.length > 0) {
        const firstAsset = extractedAssets[0];
        const firmwareLoaded = await api.get(`/assets/${firstAsset.id}/softwares`, { software_type_id: 1 });
        if (firmwareLoaded && firmwareLoaded.softwares && firmwareLoaded.softwares.length > 0) {
          firmwareVersion = firmwareLoaded.softwares[0].version_number;
        }
        sensorBuildNumber = firstAsset.sensorModuleBuildNumber;
      }
      const tagRules = rules.instrumentation(tag, extractedAssets);
      const extractedTag = extractInstrumentation(tag);

      extractedTag.unit = tagRules?.get('fullEmptyCalibrationUnitOptions')?.filter((unit) => unit.id === extractedTag.unit)[0];

      const initialSpecificationKeys = tag.specifications ? Object.keys(tag.specifications).filter((key) => tag.specifications[key].ui_visible).sort() : [];
      extractedTag.specifications = initialSpecificationKeys.map((key) => ({ key: { id: key, name: convertSpecificationKeyToLabel(key) }, value: tag.specifications[key].value }));

      setInstrumentationDetails({
        accessRights,
        assets: extractedAssets,
        firmwareVersion,
        initialSpecificationKeys,
        instrumentation: extractedTag,
        sensorBuildNumber,
        statuses: extractInstrumentationStatuses(tagStatuses),
        types: extractInstrumentationTypes(tagTypes, true),
      });
    } catch (error) {
      if (error instanceof NotFoundError) {
        browser.navigateTo('/404');
      } else {
        notifier.showError(api.translateError(error));
      }
    }
  };

  useEffect(() => {
    loadData();
  }, [match.params]);

  const editImages = instrumentationDetails ? (
    <EditPictures
      id="edit-pictures"
      model="instrumentations"
      modelId={instrumentationDetails.instrumentation.id}
      placeholder="lcm-iot-icon-instrumentation"
    />
  ) : null;
  const editPermissions = instrumentationDetails ? (
    <PermissionsEdit
      id="edit-permissions"
      permitableType="Instrumentation"
      permitableId={instrumentationDetails.instrumentation.id}
      accessRights={accessRights}
      targetOnDeleteOwnReadPermission="/nodes"
    />
  ) : null;
  const editInstrumentation = instrumentationDetails ? (
    <InstrumentationForm
      id="instrumentation-form"
      intl={intl}
      instrumentationStatuses={instrumentationDetails.statuses}
      instrumentationTypes={instrumentationDetails.types}
      assetFirmwareVersion={instrumentationDetails.firmwareVersion}
      assetSensorBuildNumber={instrumentationDetails.sensorBuildNumber}
      onSubmit={onSubmit}
      instrumentation={instrumentationDetails.instrumentation}
      instrumentationRules={instrumentationRules}
      collapseProperties={collapseProperties}
    />
  ) : null;

  return (
    <Container>
      <Row>
        <Column>
          <BackButton />
        </Column>
      </Row>
      <Row>
        <Column>
          <ActionBar>
            <Heading id="instrumentation-edit-header" level={1} title={intl.formatMessage({ id: 'instrumentation_edit.header' })} />
          </ActionBar>
        </Column>
      </Row>
      <Row>
        <Column lg="7">
          <Heading id="instrumentation-edit-details" level={2} title={intl.formatMessage({ id: 'instrumentation_edit.details' })} />
          {editInstrumentation}
        </Column>
        <Column lg="5">
          {editImages}
        </Column>
      </Row>
      <Row>
        <div>{editPermissions}</div>
      </Row>
      <Row>
        <div>
          <Loader loading={!instrumentationDetails} />
        </div>
      </Row>
    </Container>
  );
}

InstrumentationEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
  accessRights: accessRightsShape.isRequired,
  intl: intlShape.isRequired,
  api: apiShape.isRequired,
  backend: backendShape.isRequired,
  notifier: notifierShape.isRequired,
  browser: browserShape.isRequired,
  rules: rulesShape,
};

export default withRules(withBrowser(withNotifier(withApi(withBackend(injectIntl(withAccessRights(InstrumentationEdit, 'Instrumentation', enforceAccessRightCanUpdate)))))));
