import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  intlShape,
  formatDateTime,
} from 'lcm-iot-commons';

export function SignInItem({ signIn, intl }) {
  const iconClassname = `small-icon lcm-iot-icon-${signIn.device_type}`;
  const signInTime = Date.parse(signIn.time);
  const headerText = `${signIn.application} - ${formatDateTime(signInTime, intl)}`;
  return (
    <li className="list-item">
      <div className="list-item-image">
        <span className={iconClassname} />
      </div>
      <div className="list-item-content">
        <div className="list-item-header">{headerText}</div>
        <div className="list-item-details">
          {signIn.ip}
          {' - '}
          {signIn.browser_name}
          {' - '}
          {signIn.platform_name}
          {' ('}
          {signIn.platform_version}
          )
        </div>
      </div>
    </li>
  );
}

SignInItem.propTypes = {
  signIn: PropTypes.shape({
    time: PropTypes.string,
    application: PropTypes.string,
    ip: PropTypes.string,
    browser_name: PropTypes.string,
    browser_version: PropTypes.string,
    device_type: PropTypes.string,
    platform_name: PropTypes.string,
    platform_version: PropTypes.string,
  }),
  intl: intlShape,
};

SignInItem.defaultProps = {
  signIn: undefined,
  intl: undefined,
};

export default injectIntl(SignInItem);
