import React, { useState } from 'react';
import { Formik } from 'formik';
import { injectIntl } from 'react-intl';
import {
  ActionBar,
  ButtonGroup,
  Column,
  Container,
  Heading,
  Form,
  Loader,
  Row,
  CancelButton,
  InputAlert,
  SelectBox,
  SubmitButton,
  TextInput,
  apiShape,
  backendShape,
  browserShape,
  intlShape,
  notifierShape,
  userShape,
  withApi,
  withBackend,
  withBrowser,
  withFlipper,
  withNotifier,
  withUser,
  isEmpty,
  apiErrorsContain,
  NotFoundError,
  ConflictError,
  AlertType,
  CONFIGURATION,
} from 'lcm-iot-commons';
import PropTypes from 'prop-types';
import { edgeDeviceTypeMapping } from '../../utils/edgeDeviceTypeUtils';

export function EdgeDeviceAdd({
  api, backend, browser, intl, notifier, user, flipper,
}) {
  const [errors, setErrors] = useState();
  const [formData, setFormData] = useState();

  const loadEdgeDeviceTypes = async () => {
    try {
      if (!flipper.edgeDeviceLateBinding) {
        browser.navigateTo('/404');
        return;
      }
      const response = await api.get('/edm/edge_device/types');
      const edgeDeviceTypes = response.edge_device_types.map((t) => edgeDeviceTypeMapping(t));
      setFormData({
        types: edgeDeviceTypes,
        initialType: edgeDeviceTypes.find((t) => t.code === 'sgc200'),
      });
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  React.useEffect(() => {
    loadEdgeDeviceTypes();
  }, []);

  const handleOnSubmit = async (values, actions) => {
    try {
      let addonValidationSuccessfull = true;
      if (values.type.hasAddon) {
        const availableEdgeDevices = await api.get('/edm/edge_devices', { type_id: values.type.id });
        const availableAddons = await backend.get(`/subscriptions/addon_count/${values.type.hasAddon}`);
        addonValidationSuccessfull = availableEdgeDevices.pagination.total_count < availableAddons.count;
      }
      if (addonValidationSuccessfull) {
        await api.patch('/edm/edge_devices/link', { serial_number: values.serialNumber });
        setErrors([]);
        notifier.showSuccess(intl.formatMessage({ id: 'edge_device_add.success_message' }));
        browser.navigateTo('/edge_devices');
      } else {
        setErrors([{ type: 'addon_error' }]);
      }
    } catch (error) {
      if (error instanceof NotFoundError) {
        setErrors([{ type: 'not_found' }]);
      } else if (error instanceof ConflictError) {
        setErrors([{ type: 'taken' }]);
      } else {
        setErrors([{ type: 'api_error' }]);
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  const validateForm = (values) => {
    const formErrors = {};

    if (isEmpty(values.serialNumber) || isEmpty(values.serialNumber.trim())) {
      formErrors.serialNumber = intl.formatMessage({ id: 'validation.serial_number.mandatory' });
      setErrors();
    }
    return formErrors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;

    return (
      <Form {...formProps}>
        <SelectBox
          {...formProps}
          id="edge-device-type"
          name="type"
          label={intl.formatMessage({ id: 'label.type' })}
          options={formData.types}
          disabled
        />
        <TextInput
          {...formProps}
          id="edge-device-serial-number"
          name="serialNumber"
          label={intl.formatMessage({ id: 'label.serial_number' })}
          required
        />
        <ButtonGroup>
          <SubmitButton
            id="edge-device-add-submit"
            fetching={isSubmitting}
            text={intl.formatMessage({ id: 'edge_device_add.submit_button' })}
            intl={intl}
          />
          <CancelButton id="edge-device-add-cancel" disabled={isSubmitting} intl={intl} />
        </ButtonGroup>
      </Form>
    );
  };

  const renderErrorMessage = () => {
    const errorReasons = [];

    if (apiErrorsContain(errors, 'not_found')) {
      errorReasons.push((
        <li id="not-found" key="not_found">
          {intl.formatMessage({ id: 'edge_device_add.errors.not_found' })}
        </li>
      ));
    } else if (apiErrorsContain(errors, 'taken')) {
      errorReasons.push((
        <li id="taken" key="taken">
          {intl.formatMessage({ id: 'edge_device_add.errors.conflict' })}
          <a id="support-link" href={`${CONFIGURATION}/support/tickets`}>{intl.formatMessage({ id: 'edge_device_add.errors.support' })}</a>
        </li>
      ));
    } else if (apiErrorsContain(errors, 'api_error')) {
      errorReasons.push((
        <li id="api-error" key="api_error">
          {intl.formatMessage({ id: 'edge_device_add.errors.unknown' })}
          <a id="support-link" href={`${CONFIGURATION}/support/tickets`}>{intl.formatMessage({ id: 'edge_device_add.errors.support' })}</a>
        </li>
      ));
    } else if (apiErrorsContain(errors, 'addon_error')) {
      errorReasons.push((
        <li id="addon-error" key="addon_error">
          {intl.formatMessage({ id: 'edge_device_add.errors.addon_error' })}
        </li>
      ));
    }

    return (errorReasons.length > 0) ? (
      <div id="edge-device-add-error" className="space-before">
        <InputAlert id="edge-device-add-error-message" message={intl.formatMessage({ id: 'edge_device_add.errors.general' })} type={AlertType.ERROR}>
          <ul id="edge-device-add-error-reasons">
            {errorReasons}
          </ul>
        </InputAlert>
      </div>
    ) : null;
  };

  return user ? (
    <Loader loading={!formData}>
      <Container>
        <Row id="edge-device-add-header">
          <Column>
            <ActionBar>
              <Heading title={intl.formatMessage({ id: 'edge_device_add.header' })} />
            </ActionBar>
          </Column>
        </Row>
        <Row id="edge-device-add-form">
          <Column sm="6">
            {formData ? (
              <Formik
                onSubmit={handleOnSubmit}
                validate={validateForm}
                initialValues={{ type: formData.initialType }}
                render={renderForm}
              />
            ) : null}
          </Column>
        </Row>
        <Row id="edge-device-add-errors">
          <Column sm="6">
            {renderErrorMessage()}
          </Column>
        </Row>
      </Container>
    </Loader>
  ) : null;
}

EdgeDeviceAdd.propTypes = {
  api: apiShape.isRequired,
  backend: backendShape.isRequired,
  browser: browserShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  user: userShape,
  flipper: PropTypes.shape({
    edgeDeviceLateBinding: PropTypes.bool,
  }).isRequired,
};

EdgeDeviceAdd.defaultProps = {
  user: undefined,
};

export default withFlipper(withApi(withBackend(withUser(withBrowser(withNotifier(injectIntl(EdgeDeviceAdd)))))));
