import React from 'react';
import PropTypes from 'prop-types';
import
{
  handleFormikValueChange,
  Select,
} from 'lcm-iot-commons';

export function FieldGatewayModbusVariableSelect(props) {
  const {
    valueArrayName,
    objIdx,
    values,
  } = props;

  return (
    <Select
      {...props}
      options={values.allAvailableVariables}
      selectedOption={(values[valueArrayName][objIdx].regName || null)}
      onChange={(value) => {
        handleFormikValueChange(props, value);
      }}
      required
      input
    />
  );
}

FieldGatewayModbusVariableSelect.propTypes = {
  valueArrayName: PropTypes.string.isRequired,
  objIdx: PropTypes.number.isRequired,
  values: PropTypes.shape(
    {
      availableVariables: PropTypes.arrayOf(PropTypes.shape({})),
      modbusConfig: PropTypes.arrayOf(PropTypes.shape({})),
      allAvailableVariables: PropTypes.arrayOf(PropTypes.shape({})),
    },
  ).isRequired,
};

export default FieldGatewayModbusVariableSelect;
