export function extractBatchInExecution(rawSystem) {
  if (rawSystem.batches_in_execution && rawSystem.batches_in_execution.length > 0) {
    const [batch] = rawSystem.batches_in_execution;
    return {
      id: batch.id,
      name: batch.name,
      startDate: batch.start_date,
    };
  }
  return null;
}

export function extractSystem(rawSystem) {
  return {
    id: rawSystem.id,
    name: rawSystem.name,
    description: rawSystem.description,
    statusName: rawSystem.status?.name,
    statusCode: rawSystem.status?.code,
    worstStatusName: rawSystem.worst_asset_status ? rawSystem.worst_asset_status.name : null,
    worstStatusCode: rawSystem.worst_asset_status ? rawSystem.worst_asset_status.code : null,
    typeName: rawSystem.type?.name,
    typeCode: rawSystem.type?.code,
    itemType: 'system',
    recipeName: rawSystem.recipe?.name,
    batchInExecution: extractBatchInExecution(rawSystem),
    batchAutoConditions: rawSystem.specifications ? {
      startStop: rawSystem.specifications['eh.user_config.batch.auto_start_stop.conditions']?.value,
      start: rawSystem.specifications['eh.user_config.batch.auto_start.condition.0']?.value,
      stop0: rawSystem.specifications['eh.user_config.batch.auto_stop.condition.0']?.value,
      stop1: rawSystem.specifications['eh.user_config.batch.auto_stop.condition.1']?.value,
      stopConfigValue: rawSystem.specifications['eh.user_config.batch.auto_stop.condition.1.config']?.value,
      stopConfigUnit: rawSystem.specifications['eh.user_config.batch.auto_stop.condition.1.config']?.unit,
    } : null,
    keyValueMapping: rawSystem.specifications ? {
      pv: rawSystem.specifications['eh.user_config.value_mapping.pv']?.value,
      qv: rawSystem.specifications['eh.user_config.value_mapping.qv']?.value,
      sv: rawSystem.specifications['eh.user_config.value_mapping.sv']?.value,
      tv: rawSystem.specifications['eh.user_config.value_mapping.tv']?.value,
    } : null,
  };
}

export function extractSystems(rawSystems) {
  if (!rawSystems) { return []; }
  if (rawSystems.items) {
    return rawSystems.items.map((system) => extractSystem(system));
  }
  return rawSystems.systems.map((rawSystem) => extractSystem(rawSystem));
}
