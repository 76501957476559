import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { SkeletonTheme } from 'react-loading-skeleton';
import { WindowSizeContextProvider } from './WindowSizeContext';
import { ApiContextProvider } from './ApiContext';
import { BackendContextProvider } from './BackendContext';
import { NotifierContextProvider } from './NotifierContext';
import { BrowserContextProvider } from './BrowserContext';
import { SessionContextProvider } from './SessionContext';
import { UserContextProvider } from './UserContext';
import { SubscriptionContextProvider } from './SubscriptionContext';
import { lcmApiClient, backendApiClient } from '../api';
import { RulesContextProvider } from './RulesContext';
import { TrackingContextProvider } from './TrackingContext';
import { LogContextProvider } from './LogContext';
import { CustomQueryClientProvider } from './CustomQueryClientContext';
import { Colors } from '../constants';

export function ApplicationContext({
  children, enforceSubscription, subscriptionEndpoints, unsecuredEndpoints,
}) {
  return (
    <LogContextProvider>
      <TrackingContextProvider>
        <RulesContextProvider>
          <BackendContextProvider backendApiClient={backendApiClient}>
            <SessionContextProvider>
              <UserContextProvider>
                <NotifierContextProvider>
                  <ApiContextProvider lcmApiClient={lcmApiClient}>
                    <CustomQueryClientProvider>
                      <SubscriptionContextProvider
                        enforceSubscription={enforceSubscription}
                        subscriptionEndpoints={subscriptionEndpoints}
                        unsecuredEndpoints={unsecuredEndpoints}
                      >
                        <BrowserContextProvider>
                          <WindowSizeContextProvider>
                            <SkeletonTheme baseColor={Colors.EH_WINTER_GRAY_1} highlightColor={Colors.EH_WINTER_GRAY_2}>
                              {children}
                            </SkeletonTheme>
                          </WindowSizeContextProvider>
                        </BrowserContextProvider>
                      </SubscriptionContextProvider>
                    </CustomQueryClientProvider>
                  </ApiContextProvider>
                </NotifierContextProvider>
              </UserContextProvider>
            </SessionContextProvider>
          </BackendContextProvider>
        </RulesContextProvider>
      </TrackingContextProvider>
    </LogContextProvider>
  );
}

ApplicationContext.propTypes = {
  children: PropTypes.node,
  enforceSubscription: PropTypes.bool,
  subscriptionEndpoints: PropTypes.arrayOf(PropTypes.string),
  unsecuredEndpoints: PropTypes.arrayOf(PropTypes.string),
};

ApplicationContext.defaultProps = {
  children: undefined,
  enforceSubscription: false,
  subscriptionEndpoints: undefined,
  unsecuredEndpoints: undefined,
};

export default injectIntl(ApplicationContext);
