import React, { Component } from 'react';
import { Formik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  intlShape,
  apiErrorsContain,
  handleUnknownErrors,
  isEmpty,
  navigateTo,
  showSuccess,
  createTenant,
} from 'lcm-iot-commons';

import { ActionBar } from 'lcm-iot-commons/client/lib/components/ActionBar';

import {
  CancelButton,
  SubmitButton,
  TextInput,
  TextArea,
} from 'lcm-iot-commons/client/lib/components/Form';

export class TenantCreate extends Component {
  constructor(props) {
    super(props);
    this.validateForm = this.validateForm.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values, actions) {
    const { intl } = this.props;

    createTenant(values)
      .then((id) => {
        actions.setSubmitting(false);
        showSuccess(intl.formatMessage({ id: 'tenant_create.success_notification' }));
        navigateTo(`/tenants/${id}`);
      })
      .catch((errors) => {
        const formErrors = {};

        if (apiErrorsContain(errors, 'taken', 'name')) {
          formErrors.name = intl.formatMessage({ id: 'api.error.tenant.taken' });
        }

        if (Object.keys(formErrors).length < errors.length) {
          handleUnknownErrors(errors, intl.formatMessage({ id: 'api.error.unknown' }));
        }

        actions.setErrors(formErrors);
        actions.setSubmitting(false);
      });
  }

  validateForm(values) {
    const { intl } = this.props;
    const errors = {};

    if (isEmpty(values.name) || isEmpty(values.name.trim())) {
      errors.name = intl.formatMessage({ id: 'validation.name.mandatory' });
    } else if (values.name.length > 60) {
      errors.name = intl.formatMessage({ id: 'validation.name.too_long' }, { characters: 60 });
    }
    return errors;
  }

  renderForm(props) {
    const { intl } = this.props;
    const { handleSubmit, isSubmitting, setStatus } = props;

    // eslint-disable-next-line react/no-unused-class-component-methods
    this.form = props;

    /* istanbul ignore next */
    const beforeHandleSubmit = (event) => {
      /* istanbul ignore next */
      setStatus('submitted');
      /* istanbul ignore next */
      handleSubmit(event);
    };

    return (
      <form onSubmit={beforeHandleSubmit} noValidate>
        <TextInput
          {...props}
          name="name"
          label={intl.formatMessage({ id: 'label.name' })}
          required
        />
        <TextArea
          {...props}
          name="description"
          label={intl.formatMessage({ id: 'label.description' })}
        />
        <div className="btn-group">
          <SubmitButton id="create-tenant-submit" onClick={beforeHandleSubmit} fetching={isSubmitting} />
          <CancelButton id="create-tenant-cancel" disabled={isSubmitting} />
        </div>
      </form>
    );
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <ActionBar>
              <h1 id="create-tenant-header"><FormattedMessage id="tenant_create.header" /></h1>
            </ActionBar>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <Formik
              validate={this.validateForm}
              onSubmit={this.onSubmit}
              render={this.renderForm}
            />
          </div>
        </div>
      </div>
    );
  }
}

TenantCreate.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(TenantCreate);
