import PropTypes from 'prop-types';

// Node
export const nodeShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
});

// Type
export const nodeTypeShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  tenantId: PropTypes.number,
  tenantName: PropTypes.string,
});
export const nodeTypesShape = PropTypes.arrayOf(nodeTypeShape);

export const nodesShape = PropTypes.arrayOf(nodeShape);
