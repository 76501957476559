export function packAddress(address) {
  const result = {
    company_name: address.companyName,
    first_name: address.firstName,
    last_name: address.lastName,
    email: address.email,
    street: address.streetName,
    street_number: address.streetNumber,
    city: address.city,
    zip_code: address.zipCode,
    region_code: address.regionCode,
    country_code: address.countryCode,
    country_name: address.countryName,
    phone: address.phone,
    vat_number: address.vatNumber,
  };

  if (address.country) {
    result.country_code = address.country.code;
    result.country_name = address.country.name;
  }
  if (address.region) {
    result.region_code = address.region.code;
  }
  return result;
}
