export function extractFieldGateway(rawFieldGateway) {
  const fieldGateway = {
    id: rawFieldGateway.id,
    ipAddress: rawFieldGateway.ip_address,
    name: rawFieldGateway.name,
    description: rawFieldGateway.description,
    status: rawFieldGateway.status,
    type: rawFieldGateway.type,
  };
  if (rawFieldGateway.specifications && rawFieldGateway.specifications['eh.edm.hart.address']) {
    fieldGateway.hartAddress = rawFieldGateway.specifications['eh.edm.hart.address'].value;
  }
  if (rawFieldGateway.port) {
    fieldGateway.port = rawFieldGateway.port;
  }
  return fieldGateway;
}

export function extractFieldGateways(rawFieldGatways) {
  return rawFieldGatways.field_gateways.map((fieldGateway) => extractFieldGateway(fieldGateway));
}
