import React, { useState } from 'react';
import {
  apiShape,
  notifierShape,
  intlShape,
  withApi,
  withNotifier,
  withIntl,
  Row,
  Column,
  Container,
  Loader,
  Heading,
  ActionBar, withSession, withUser, userShape, sessionShape,
} from 'lcm-iot-commons';

import PropTypes from 'prop-types';
import EditPictures from 'lcm-iot-commons/client/lib/components/Pictures/EditPictures';
import ProductForm from './ProductForm';

export function ProductEdit({
  api,
  notifier,
  intl,
  match,
  user,
  session,
}) {
  const [fetching, setFetching] = useState(true);
  const [initialValues, setInitialValues] = useState(null);

  React.useEffect(() => {
    const loadData = async () => {
      try {
        const response = await api.get(`/products/${match.params.id}`, { include: 'status,tenant,parent,manufacturer,categories.parent' });
        const iv = {
          id: response.id,
          tenant: { id: response.tenant.id, name: response.tenant.name },
          manufacturer: { id: response.manufacturer.id, name: response.manufacturer.name },
          code: response.product_code,
          name: response.name,
          description: response.description,
          status: { id: response.status.id, name: response.status.name },
          parent: (response.parent ? { id: response.parent?.id, name: response.parent?.name } : null),
          category: (response.categories.length > 0 ? response.categories[0] : null),
          riskOfMaintainability: response.risk_of_maintainability,
        };
        setInitialValues(iv);
      } catch (error) {
        notifier.showError(api.translateError(error));
      } finally {
        setFetching(false);
      }
    };
    loadData();
  }, [match.params]);

  return (
    <Loader loading={!initialValues}>
      <Container>
        <Row>
          <Column>
            <ActionBar>
              <Heading id="edit-header" title={intl.formatMessage({ id: 'product_edit.header' })} />
            </ActionBar>
          </Column>
        </Row>
        <Row>
          <Column lg="7">
            <Heading id="edit-details" title={intl.formatMessage({ id: 'product_edit.details' })} level={2} />
            {initialValues
            && (
            <ProductForm
              id="edit-product"
              fetching={fetching}
              initialValues={initialValues}
              edit
            />
            )}
          </Column>
          <Column lg="5">
            {initialValues && initialValues.id
            && (
              <EditPictures
                id="edit-pictures"
                model="products"
                modelId={initialValues.id}
                placeholder="lcm-iot-icon-product"
                user={user}
                session={session}
              />
            )}
          </Column>
        </Row>
      </Container>
    </Loader>
  );
}

ProductEdit.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
  user: userShape.isRequired,
  session: sessionShape.isRequired,
};

export default withIntl(withApi(withSession(withUser(withNotifier(ProductEdit)))));
