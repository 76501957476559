import lcmApiClient from './lcmApiClient';
import { extractTenants } from '../extractors';

/* istanbul ignore next */
export async function findTenants(filter) {
  const response = await lcmApiClient().get('/tenants', filter);
  return extractTenants(response);
}

/* istanbul ignore next */
export async function findPublicTenants() {
  return findTenants({ public: true });
}

/* istanbul ignore next */
export async function getPublicTenantIds() {
  const publicTenants = await findPublicTenants();
  return publicTenants.map((publicTenant) => publicTenant.id);
}

/* istanbul ignore next */
export async function createTenant(tenant) {
  const response = await lcmApiClient().post('/tenants', { name: tenant.name, description: tenant.description, public: false });
  return response.id;
}
