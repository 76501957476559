import PropTypes from 'prop-types';

export const assetShape = PropTypes.shape({
  id: PropTypes.number,
  serialNumber: PropTypes.string,
  assetStatusName: PropTypes.string,
  assetProductionDate: PropTypes.string,
  manufacturerName: PropTypes.string,
  productName: PropTypes.string,
  productCode: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  productPictureUrl: PropTypes.string,
  pictureUrls: PropTypes.arrayOf(PropTypes.string),
  orderCode: PropTypes.string,
  description: PropTypes.string,
});
export const assetsShape = PropTypes.arrayOf(assetShape);
