import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const IconButton = forwardRef(({
  id,
  icon,
  disabled,
  onClick,
  className,
}, ref) => (
  <button
    id={id}
    className={[className, 'icon-button'].filter((c) => c).join(' ')}
    onClick={onClick}
    disabled={disabled}
    // eslint-disable-next-line react/button-has-type
    type="button"
    ref={ref}
  >
    <Icon name={icon} />
  </button>
));

IconButton.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};

IconButton.defaultProps = {
  id: undefined,
  disabled: false,
  onClick: undefined,
  className: undefined,
};

export default IconButton;
