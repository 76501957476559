import React from 'react';
import PropTypes from 'prop-types';

function Container({
  className, style, children, id,
}) {
  return (
    <div id={id} className={['container', className].filter((c) => c).join(' ')} style={style}>
      {children}
    </div>
  );
}

Container.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.node,
};

Container.defaultProps = {
  id: undefined,
  className: undefined,
  style: undefined,
  children: undefined,
};

export default Container;
