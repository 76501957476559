import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function Step({ step, stepClassName, handleClicked }) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current.scrollIntoView && stepClassName === ' is-current') ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [stepClassName]);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li ref={ref} onKeyDown={handleClicked} onClick={handleClicked} className={`c-process-item${stepClassName}`}>
      <div className="c-process-title-wrapper">
        <span className="c-process-title">{step}</span>
      </div>
    </li>
  );
}

Step.propTypes = {
  step: PropTypes.string.isRequired,
  stepClassName: PropTypes.string,
  handleClicked: PropTypes.func,
};

export default Step;
