/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AlertType } from '../../constants';
import PictureGallery from './PictureGallery';
import SubscriptionLimitClue from '../Subscriptions/SubscriptionLimitClue';
import FilesUpload from '../Files/FilesUpload';
import { InputAlert } from '../Form';
import { DeleteActionButton } from '../ActionBar';
import {
  uploadPictures,
} from '../../utils/pictureUtils';
import {
  intlShape,
  sessionShape,
} from '../../shapes';
import {
  isEmpty,
  isNotEmpty,
  fileStorageAlertExists,
} from '../../utils';
import {
  apiShape,
  notifierShape,
  subscriptionShape,
  withApi,
  withNotifier,
  withSession,
  withSubscription,
} from '../../context';
import { extractPictures } from '../../extractors';
import { withIntl } from '../../wrappers';
import FileStorageLimitHelper from '../Files/FileStorageLimitHelper';

const ALLOWED_FILE_TYPES = 'image/jpeg, image/png, image/gif';

export function EditPictures({
  api,
  intl,
  notifier,
  model,
  modelId,
  session,
  subscription,
  placeholder,
}) {
  const [pictures, setPictures] = useState();
  const [rejectFiles, setRejectFiles] = useState();
  const [selectedPicture, setSelectedPicture] = useState();
  const [fetching, setFetching] = useState(true);
  const [currentFileStorage, setCurrentFileStorage] = useState(subscription?.usage?.file_storage || 0);
  const fileStorageLimit = (model !== 'products')
    ? session?.subscriptionStorageQuota
    : Number.POSITIVE_INFINITY;

  const onPictureChange = (picture) => {
    setSelectedPicture(picture);
  };

  const isFileStorageExceeded = fileStorageAlertExists(subscription);

  const loadPictures = async () => {
    try {
      setFetching(true);
      const response = await api.getAll(`/${model}/${modelId}/pictures`);
      setPictures(extractPictures(response));
      if (!selectedPicture && response.pictures?.length > 0) {
        onPictureChange(response.pictures[0]);
      }
    } catch (error) {
      notifier.showError(api.translateError(error));
    } finally {
      setFetching(false);
    }
  };

  React.useEffect(() => {
    loadPictures();
  }, [model, modelId]);

  const onConfirmDelete = async () => {
    try {
      await api.delete(`/${model}/${modelId}/pictures/${selectedPicture.id}`);
      await loadPictures();
      await subscription.refresh();
      setCurrentFileStorage(subscription.usage?.file_storage || 0);
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    try {
      setFetching(true);
      setRejectFiles(rejectedFiles);
      const acceptedFilesWithPreview = acceptedFiles.map((file) => Object.assign(file, {
        preview: URL.createObjectURL(file),
      }));

      if (acceptedFilesWithPreview.length > 0) {
        await uploadPictures(api, model, modelId, pictures, acceptedFilesWithPreview, rejectedFiles, Number(currentFileStorage), fileStorageLimit);
        setPictures([]);
        await loadPictures();
        await subscription.refresh();
        setCurrentFileStorage(subscription.usage?.file_storage || 0);
      }
    } catch (error) {
      notifier.showError(api.translateError(error));
    } finally {
      setFetching(false);
    }
  };

  const contextHelper = isFileStorageExceeded ? (
    <FileStorageLimitHelper />
  ) : null;

  const spinner = fetching ? (
    <div className="pictures-spinner">
      <div className="icon-spinner-dark" />
    </div>
  ) : null;

  const pictureDelete = pictures && pictures.length > 0 && selectedPicture ? (
    <div className="picture-delete">
      <DeleteActionButton
        id="delete-picture"
        modalTitle={intl.formatMessage({ id: 'edit_pictures.delete.modal_title' })}
        modalMessage={intl.formatMessage({ id: 'edit_pictures.delete.modal_message' })}
        onConfirm={onConfirmDelete}
      />
    </div>
  ) : null;

  let subscriptionLimitReached = false;
  const rejectedFilesRendered = rejectFiles ? rejectFiles.map((file) => {
    if (isEmpty(file.name) && file.file) {
      // only needed when the file is rejected directly from the dropzone (e.g. because of type)
      // the name variant is only needed for cypress headless browser
      /* istanbul ignore next */
      file.name = isNotEmpty(file.file.path) ? file.file.path : file.file.name;
    }
    switch (file.uploadError) {
      case 'size_limit_exceeded':
        file.uploadErrorMessage = intl.formatMessage({ id: 'edit_pictures.size_error' });
        break;
      case 'storage_limit_exceeded':
        subscriptionLimitReached = true;
        file.uploadErrorMessage = intl.formatMessage({ id: 'edit_pictures.size_exceeds_limit' });
        break;
      default:
        file.uploadErrorMessage = intl.formatMessage({ id: 'edit_pictures.incompatible_type_error' });
    }
    return (
      <li key={file.name}>
        <strong>{file.name}</strong>
        {`: ${file.uploadErrorMessage}`}
      </li>
    );
  }) : null;

  const rejectionError = rejectFiles && rejectFiles.length > 0 ? (
    <div className="pictures-rejection">
      <InputAlert
        message={intl.formatMessage({ id: 'edit_pictures.dropzone.rejection' })}
        type={AlertType.ERROR}
      >
        <ul>{rejectedFilesRendered}</ul>
      </InputAlert>
      <SubscriptionLimitClue
        condition={subscriptionLimitReached}
      />
    </div>
  ) : null;

  return pictures ? (
    <>
      <div className="pictures-header">
        <h2><FormattedMessage id="edit_pictures.header" /></h2>
        <div>{contextHelper}</div>
      </div>
      <div className="edit-pictures">
        <PictureGallery
          id="picture-gallery"
          fetching={fetching}
          pictures={pictures}
          placeholder={fetching ? null : placeholder}
          onPictureChange={onPictureChange}
        >
          {pictureDelete}
          {spinner}
        </PictureGallery>
        <FilesUpload
          id="pictures-upload"
          buildInWhiteList={ALLOWED_FILE_TYPES}
          classNamePrefix="picture"
          dropzoneInfoPrefix="edit_pictures"
          onDrop={onDrop}
        />
      </div>
      {rejectionError}
    </>
  ) : null;
}

EditPictures.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  model: PropTypes.string.isRequired,
  modelId: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
  session: sessionShape.isRequired,
  subscription: subscriptionShape.isRequired,
};

export default withSession(withSubscription(withIntl(withNotifier(withApi(EditPictures)))));
