import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import UserPermissionsEdit from './UserPermissionsEdit';
import UserGroupPermissionsEdit from './UserGroupPermissionsEdit';
import { intlShape } from '../../shapes';
import {
  isNothing, sortBy, displayUserName,
} from '../../utils';
import * as browserUtils from '../../utils/browserUtils';
import PermissionType from '../../constants/PermissionType';
import {
  apiShape, notifierShape, withApi, withNotifier, withRules, rulesShape,
} from '../../context';
import { NotFoundError, ConflictError, BadRequestError } from '../../api/errors';

export function PermissionsEdit({
  api, permitableType, permitableId, notifier, intl, targetOnDeleteOwnReadPermission, allowedPermissions, rules,
}) {
  const [usersPermissions, setUsersPermissions] = useState([]);
  const [userGroupsPermissions, setUserGroupsPermissions] = useState([]);
  const [accessRights, setAccessRights] = useState({});
  const [currentUser, setCurrentUser] = useState({});

  const acceptConnectUsersInput = rules.application().get('acceptConnectUsers');

  const loadAccessRights = () => {
    const param = {
      permitable_type: permitableType,
      permitable_id: permitableId,
    };
    api.get('/users/current/access_rights', param).then((result) => {
      setAccessRights(result);
      if (!result.can_read) {
        browserUtils.navigateTo(targetOnDeleteOwnReadPermission);
      }
    }).catch(() => notifier.showError(intl.formatMessage({ id: 'api.error.unknown' })));
  };

  const onUserSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    api.get('/users/lookup', { email: values.email })
      .then((user) => {
        if (usersPermissions.findIndex((u) => user.id === u.id) === -1) {
          usersPermissions.push({
            id: user.id,
            name: `${displayUserName(user, true, true)}`,
            read: null,
            update: null,
            delete: null,
            permit: null,
          });
          actions.setSubmitting(false);
          actions.resetForm({ values: {} });
        } else {
          actions.setErrors({ email: intl.formatMessage({ id: 'edit_permissions.user_permissions_already_exist' }) });
        }
        actions.setSubmitting(false);
      })
      .catch((error) => {
        actions.setSubmitting(false);
        if (error instanceof NotFoundError) {
          actions.setErrors({ email: intl.formatMessage({ id: 'api.error.email.not_found_no_permission' }) });
        } else {
          notifier.showError(intl.formatMessage({ id: 'api.error.unknown' }));
        }
      });
  };

  const onUserGroupSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    userGroupsPermissions.push({
      id: values.usergroup.id,
      name: values.usergroup.name,
      read: null,
      update: null,
      delete: null,
      permit: null,
    });
    actions.setSubmitting(false);
    actions.resetForm({ values: {} });
  };

  function setUserOrUserGroupPermission(permissions, permission) {
    /*  eslint-disable no-param-reassign */
    // eslint-disable-next-line default-case
    switch (permission.permission_type) {
      case PermissionType.READ:
        permissions.read = permission.id;
        break;
      case PermissionType.UPDATE:
        permissions.update = permission.id;
        break;
      case PermissionType.DELETE:
        permissions.delete = permission.id;
        break;
      case PermissionType.PERMIT:
        permissions.permit = permission.id;
    }
    /* eslint-enable no-param-reassign */
  }

  const loadPermissions = () => {
    const includeables = acceptConnectUsersInput ? 'assignable, technical_connect_users' : 'assignable';
    const permissionsFilter = {
      permitable_id: permitableId,
      permitable_type: permitableType,
      per_page: 100,
      include: includeables,
    };
    // permissions are paginated in different way and the best value for per_page parameter is 300
    api.getAll('/permissions', { ...permissionsFilter, per_page: 300, include_total_count: false }).then((response) => {
      const usPermissions = [];
      const ugsPermissions = [];
      response.permissions.forEach((permission) => {
        if (permission.assignable.type === 'User') {
          let userPermissions = usPermissions.find((u) => u.id === permission.assignable.id);
          const permissionUser = permission.assignable.user;
          if (!userPermissions) {
            userPermissions = {
              id: permission.assignable.id,
              name: displayUserName(permissionUser, true, true),
              read: null,
              update: null,
              delete: null,
              permit: null,
            };
            usPermissions.push(userPermissions);
          }
          setUserOrUserGroupPermission(userPermissions, permission);
        }
        if (permission.assignable.type === 'Usergroup') {
          let userGroupPermissions = ugsPermissions.find((u) => u.id === permission.assignable.id);
          if (!userGroupPermissions) {
            userGroupPermissions = {
              id: permission.assignable.id,
              name: permission.assignable.usergroup.name,
              read: null,
              update: null,
              delete: null,
              permit: null,
            };
            ugsPermissions.push(userGroupPermissions);
          }
          setUserOrUserGroupPermission(userGroupPermissions, permission);
        }
        setUsersPermissions(sortBy(usPermissions, 'name'));
        setUserGroupsPermissions(sortBy(ugsPermissions, 'name'));
      });
    }).catch(() => notifier.showError(intl.formatMessage({ id: 'api.error.unknown' })));
  };

  const loadCurrentUser = () => {
    api.get('/users/current', {})
      .then((user) => setCurrentUser(user))
      .catch(() => notifier.showError(intl.formatMessage({ id: 'api.error.unknown' })));
  };

  React.useEffect(() => {
    loadAccessRights();
    loadCurrentUser();
    loadPermissions();
  }, []);

  const handlePermissionDeleteError = (errors) => {
    if (errors instanceof NotFoundError) {
      // somebody deleted his permit permission in the background, we will reload the access rights for the user
      loadAccessRights();
      notifier.showError(intl.formatMessage({ id: 'api.error.permission_delete_no_permission' }));
    } else if (errors instanceof ConflictError) {
      // if the permission is removed no user have the 4 permissions for the resource
      notifier.showError(intl.formatMessage({ id: 'api.error.owner_required' }));
    } else {
      notifier.showError(intl.formatMessage({ id: 'api.error.unknown' }));
    }
  };

  const onPermissionDelete = async (permissionId, assignableType, assignableId) => {
    try {
      await api.delete(`/permissions/${permissionId}`);
      const neededPermissions = assignableType === 'User' ? usersPermissions : userGroupsPermissions;
      const existingPermissions = neededPermissions.find((u) => u.id === assignableId);
      if (existingPermissions.read === permissionId) {
        existingPermissions.read = null;
      } else if (existingPermissions.update === permissionId) {
        existingPermissions.update = null;
      } else if (existingPermissions.delete === permissionId) {
        existingPermissions.delete = null;
      } else {
        existingPermissions.permit = null;
      }
      if (assignableType === 'User') {
        setUsersPermissions([...neededPermissions]);
      } else {
        setUserGroupsPermissions([...neededPermissions]);
        loadAccessRights();
      }
      notifier.showSuccess(intl.formatMessage({ id: 'edit_permissions.notifications.deleted' }));
    } catch (e) {
      handlePermissionDeleteError(e);
    }
  };

  function remove(neededPermissions, id, isUser) {
    const newPermissions = neededPermissions.filter((u) => u.id !== id);
    if (isUser) {
      setUsersPermissions(newPermissions);
    } else {
      setUserGroupsPermissions(newPermissions);
    }
  }

  const onDelete = (id, isUser) => {
    const neededPermissions = isUser ? usersPermissions : userGroupsPermissions;
    const permissions = neededPermissions.find((u) => u.id === id);
    const existing = [permissions.read, permissions.update, permissions.delete, permissions.permit].filter((n) => n);
    if (existing.length === 0) {
      remove(neededPermissions, id, isUser);
    } else {
      // we can just delete all permissions by the first id and then remove the user from the list
      api.delete(`/permissions/${existing[0]}`, { all: true }).then(() => {
        remove(neededPermissions, id, isUser);
        notifier.showSuccess(intl.formatMessage({ id: 'edit_permissions.notifications.deleted' }));
      }).catch((e) => {
        handlePermissionDeleteError(e);
        loadPermissions();
      });
    }
  };

  const onPermissionCreate = (assignableType, assignableId, permissionType) => {
    const neededPermissions = assignableType === 'User' ? usersPermissions : userGroupsPermissions;
    const existingPermissions = neededPermissions.find((u) => u.id === assignableId);
    const addReadPermission = permissionType !== PermissionType.READ && isNothing(existingPermissions.read);

    const newPermission = {
      permission_type: addReadPermission ? [PermissionType.READ, permissionType] : [permissionType],
      assignable: {
        id: assignableId,
        type: assignableType,
      },
      permitable: {
        id: permitableId,
        type: permitableType,
      },
    };

    api.post('/permissions', newPermission).then((response) => {
      response.permissions.forEach((permission) => {
        setUserOrUserGroupPermission(existingPermissions, permission);
      });
      if (assignableType === 'User') {
        setUsersPermissions([...neededPermissions]);
      } else {
        setUserGroupsPermissions([...neededPermissions]);
      }
      notifier.showSuccess(intl.formatMessage({ id: 'edit_permissions.notifications.created' }), 3000);
    }).catch((errors) => {
      if (errors instanceof BadRequestError
        && errors.contains('taken')) {
        notifier.showError(intl.formatMessage({ id: 'edit_permissions.user_permissions_already_exist' }));
      } else {
        notifier.showError(intl.formatMessage({ id: 'api.error.unknown' }));
      }
    });
  };

  const renderedUserPermissions = (
    <UserPermissionsEdit
      currentUser={currentUser}
      permitableId={permitableId}
      permitableType={permitableType}
      usersPermissions={usersPermissions}
      onPermissionCreate={onPermissionCreate}
      onPermissionDelete={onPermissionDelete}
      onDelete={onDelete}
      onAdd={onUserSubmit}
      allowedPermissions={allowedPermissions}
      acceptConnectUsers={acceptConnectUsersInput}
    />
  );

  const renderedUserGroupPermissions = (
    <UserGroupPermissionsEdit
      userGroupsPermissions={userGroupsPermissions}
      permitableId={permitableId}
      permitableType={permitableType}
      onPermissionCreate={onPermissionCreate}
      onPermissionDelete={onPermissionDelete}
      onDelete={onDelete}
      onAdd={onUserGroupSubmit}
      allowedPermissions={allowedPermissions}
    />
  );

  return accessRights && accessRights.can_permit ? (
    <div>
      <h2 id="edit-permissions"><FormattedMessage id="edit_permissions.header" /></h2>
      {renderedUserGroupPermissions}
      {renderedUserPermissions}
    </div>
  ) : null;
}

PermissionsEdit.defaultProps = {
  targetOnDeleteOwnReadPermission: '/',
  allowedPermissions: [PermissionType.READ, PermissionType.UPDATE, PermissionType.DELETE, PermissionType.PERMIT],
};

PermissionsEdit.propTypes = {
  allowedPermissions: PropTypes.arrayOf(PropTypes.oneOf([PermissionType.READ, PermissionType.UPDATE, PermissionType.DELETE, PermissionType.PERMIT])),
  permitableId: PropTypes.number.isRequired,
  permitableType: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  targetOnDeleteOwnReadPermission: PropTypes.string,
  notifier: notifierShape,
  api: apiShape,
  rules: rulesShape,
};

export default injectIntl(withNotifier(withApi(withRules(PermissionsEdit))));
