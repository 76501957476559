import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { ActionButton } from '../../ActionBar';
import { DetailsItem } from '../../Details';
import ProductCodeDetailsTable from './ProductCodeDetailsTable';

function ProductCodeDetails({ productCode, productCodeExtended, productVariantId }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const modal = (
    <Modal
      id="product-code-details-modal"
      show={isModalOpen}
      onHide={() => setIsModalOpen(false)}
      restoreFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Product Code Details</Modal.Title>
      </Modal.Header>
      <label className="product-code">{productCode}</label>
      <Modal.Body>
        { productCodeExtended && <DetailsItem translationKey="label.product_code_extended" value={productCodeExtended} /> }
        <DetailsItem translationKey="label.details">
          <div><ProductCodeDetailsTable productVariantId={productVariantId} /></div>
        </DetailsItem>
      </Modal.Body>
    </Modal>
  );

  return (
    <>
      <ActionButton
        defaultLabel=""
        icon="icon3-eh-check-device-details"
        onClick={() => setIsModalOpen(true)}
      />
      {modal}
    </>
  );
}

ProductCodeDetails.propTypes = {
  productCode: PropTypes.string.isRequired,
  productCodeExtended: PropTypes.string,
  productVariantId: PropTypes.number.isRequired,
};

export default ProductCodeDetails;
