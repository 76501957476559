import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DetailsItem from '../Details/DetailsItem';
import {
  apiShape, notifierShape, withApi, withNotifier,
} from '../../context';

// FIXME do it the right way, when the hub offers the needed endpoints/filters
const softwareTypeFirmwareId = 1;

export function AssetFirmwareVersion({ asset, api, notifier }) {
  const [firmwareVersion, setFirmwareVersion] = useState();

  React.useEffect(() => {
    const loadData = async () => {
      try {
        const response = await api.getAll(`/assets/${asset.id}/softwares`);
        const firmware = response.softwares?.find((software) => software.software_type.id === softwareTypeFirmwareId);
        if (firmware) {
          setFirmwareVersion(firmware.version_number);
        }
      } catch (e) {
        notifier.showError(api.translateError(e));
      }
    };

    loadData();
  }, [asset.id]);

  return firmwareVersion ? (
    <DetailsItem
      translationKey="label.firmware_version"
      value={firmwareVersion}
    />
  ) : null;
}

AssetFirmwareVersion.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
};

export default withApi(withNotifier(AssetFirmwareVersion));
