import PropTypes from 'prop-types';

export const recipeShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  statusName: PropTypes.string,
  statusCode: PropTypes.string,
  typeName: PropTypes.string,
  typeCode: PropTypes.string,
  itemType: PropTypes.string,
});
export const recipesShape = PropTypes.arrayOf(recipeShape);
