export function extractCardPaymentDetails(cardPaymentDetails) {
  return {
    method: cardPaymentDetails.method,
    status: cardPaymentDetails.status,
    firstName: cardPaymentDetails.first_name,
    lastName: cardPaymentDetails.last_name,
    expiryMonth: cardPaymentDetails.expiry_month,
    expiryYear: cardPaymentDetails.expiry_year,
    autoCollection: cardPaymentDetails.auto_collection,
    type: cardPaymentDetails.type,
    identifier: cardPaymentDetails.identifier,
    valid: cardPaymentDetails.method === 'card' && (cardPaymentDetails.status === 'valid' || cardPaymentDetails.status === 'expiring'),
  };
}
