import React from 'react';
import { Formik } from 'formik';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  ButtonGroup,
  Column,
  Form,
  intlShape,
  isEmpty,
  Row,
  SubmitButton,
} from 'lcm-iot-commons';
import withZxcvbn, { zxcvbnShape } from '../../wrappers/withZxcvbn';
import PasswordInput from '../Form/PasswordInput';

export function PasswordResetForm({
  intl, onSubmit, zxcvbn,
}) {
  const validateForm = (values) => {
    const errors = {};
    if (isEmpty(values.password)) {
      errors.password = intl.formatMessage({ id: 'validation.password.mandatory' });
    } else {
      const passwordError = zxcvbn.checkPassword(values.password).error;
      if (passwordError) {
        errors.password = passwordError;
      }
    }
    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;
    return (
      <Form {...formProps}>
        <Row id="user-password">
          <Column md={8}>
            <PasswordInput
              {...formProps}
              label={intl.formatMessage({ id: 'label.password' })}
              intl={intl}
              id="password"
              name="password"
              type="password"
              disabled={isSubmitting}
              required
            />
          </Column>
        </Row>
        <ButtonGroup className="btn-group">
          <SubmitButton
            id="renew-button"
            fetching={isSubmitting}
            disabled={isSubmitting}
            text={intl.formatMessage({ id: 'password_reset.button_renew_password' })}
          />
        </ButtonGroup>
      </Form>
    );
  };

  return (
    <div className="row">
      <div className="col-sm-6">
        <h1>{intl.formatMessage({ id: 'password_reset.header' })}</h1>
        <p>{intl.formatMessage({ id: 'password_reset.enter_new_password' })}</p>
        <div className="form-group">
          <Formik
            id="edit-password-form"
            onSubmit={onSubmit}
            validate={validateForm}
            render={renderForm}
          />
        </div>
      </div>
    </div>
  );
}

PasswordResetForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: PropTypes.func,
  zxcvbn: zxcvbnShape.isRequired,
};

PasswordResetForm.defaultProps = {
  onSubmit: undefined,
};

export default injectIntl(withZxcvbn(PasswordResetForm));
