import { convertSpecificationKeyToLabel, convertLabelToSpecificationKey } from '../utils/specificationUtils';

export default function validateSpecifications(intl, values) {
  if (values.specifications && values.specifications.length > 0) {
    const errors = [];
    values.specifications.forEach((specification, index) => {
      const specificationErrors = {};

      if ((specification.key !== '' || specification.value !== '') && specification.value?.length > 0 && (specification.key === '' || !specification.key)) {
        specificationErrors.key = intl.formatMessage({ id: 'validation.specification_key.mandatory' });
      } else if (specification.key?.name && specification.key.name.toLowerCase() !== convertSpecificationKeyToLabel(convertLabelToSpecificationKey(specification.key.name)).toLowerCase()) {
        specificationErrors.key = intl.formatMessage({ id: 'validation.specification_key.format' });
      } else if (specification.key?.name
        && values.specifications.filter((s) => s.key?.name && convertLabelToSpecificationKey(s.key.name) === convertLabelToSpecificationKey(specification.key.name)).length > 1) {
        specificationErrors.key = intl.formatMessage({ id: 'validation.specification_key.duplicate' });
      } else if (specification.key?.name?.length > 100) {
        specificationErrors.key = intl.formatMessage({ id: 'validation.specification_key.too_long' });
      } else if (specification.value?.length > 5000) {
        specificationErrors.key = intl.formatMessage({ id: 'validation.specification_value.too_long' });
      }

      if (Object.keys(specificationErrors).length > 0) {
        errors[index] = specificationErrors;
      }
    });
    if (errors.length > 0) {
      return errors;
    }
  }

  return undefined;
}
