import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { accessRightsShape, intlShape } from '../../shapes';
import List from '../List/List';
import AssetItem from './AssetItem';
import Hint from '../Hint';

export function AssetComponentsList(props) {
  const {
    intl, accessRights, assets,
  } = props;

  return (
    <div id="asset-list">
      <h2>
        <FormattedMessage id="child_asset_list.header" />
      </h2>
      { assets.length > 0
        ? (
          <List id="assets_assign_list">
            {assets.map((el) => (
              <AssetItem
                key={el.id}
                asset={el}
                showItemMenu={false}
                parentAccessRights={accessRights}
              />
            ))}
          </List>
        ) : (
          <Hint
            id="hint-no-assets-assigned"
            details={intl.formatMessage({ id: 'asset_list.no_assets_assign' })}
          />
        )}
    </div>
  );
}

AssetComponentsList.propTypes = {
  accessRights: accessRightsShape,
  intl: intlShape.isRequired,
  assets: PropTypes.arrayOf(PropTypes.shape({})),
};
export default injectIntl(AssetComponentsList);
