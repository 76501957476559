import { extractThumbnailPictureUrl, extractBestPictureUrl, extractPictures } from './picturesExtractor';
import { extractInstrumentations } from './instrumentationsExtractor';
import { parseISO8601Date } from '../utils/dateTimeUtils';
import { extractPrimaryValue, extractValue } from './valuesExtractor';
import SpecificationKeys from '../constants/SpecificationKeys';
import { extractSystems } from './systemsExtractor';

function extractThumbnailAssetPictures(rawAsset) {
  let thumbnailPictures = [];
  if (rawAsset.product && rawAsset.product.pictures && rawAsset.product.pictures.length > 0) {
    thumbnailPictures = rawAsset.product.pictures;
  } /* in case the asset picture fallback logic is needed, uncomment this:
  else if (rawAsset.pictures && rawAsset.pictures.length > 0) {
    thumbnailPictures = extractPictures(rawAsset);
    thumbnailPictures = [thumbnailPictures[thumbnailPictures.length - 1]];
  } */
  return thumbnailPictures;
}

export function hasHeartbeatVerificationSpecifications(specifications) {
  if (!specifications) return false;
  let counter = 0;
  const HBVKeys = [
    SpecificationKeys.HeartbeatSpecs.DEVICE_FIRMWARE_REVISION,
    SpecificationKeys.HeartbeatSpecs.LOCKING_STATE,
    SpecificationKeys.HeartbeatSpecs.FIELDBUS_TYPE,
    SpecificationKeys.HeartbeatSpecs.OPTIONABLE_OVERVIEW,
  ];
  Object.keys(specifications).forEach((key) => {
    if (HBVKeys.indexOf(key) > -1) {
      counter += 1;
    }
  });
  return counter === 4;
}

export function extractAsset(rawAsset) {
  const asset = {
    id: rawAsset.id,
    serialNumber: rawAsset.serial_number,
    assetStatusName: rawAsset.status ? rawAsset.status.name : null,
    assetStatusCode: rawAsset.status ? rawAsset.status.code : null,
    assetProductionDate: rawAsset.production_date ? rawAsset.production_date : null,
    lastSeenAt: rawAsset.last_seen_at ? parseISO8601Date(rawAsset.last_seen_at) : null,
    manufacturerName: rawAsset.product.manufacturer ? rawAsset.product.manufacturer.name : null,
    product: rawAsset.product,
    productId: rawAsset.product.id,
    productName: rawAsset.product.name,
    productCode: rawAsset.product.product_code,
    thumbnailUrl: extractThumbnailPictureUrl(extractThumbnailAssetPictures(rawAsset)),
    productPictureUrl: extractBestPictureUrl(rawAsset.product.pictures),
    pictures: rawAsset.pictures ? extractPictures(rawAsset) : null,
    productStatusCode: rawAsset.product.status ? rawAsset.product.status.code : null,
    productTenantPublic: (rawAsset.tenant == null) || (rawAsset.product && rawAsset.product.tenant ? rawAsset.product.tenant.public : false),
    productStatusName: rawAsset.product.status ? rawAsset.product.status.name : null,
    productSpecifications: rawAsset.product.specifications,
    specifications: rawAsset.specifications ? rawAsset.specifications : null,
    isHeartbeat: hasHeartbeatVerificationSpecifications(rawAsset.specifications),
    orderCode: rawAsset.specifications && rawAsset.specifications['eh.pcps.tmp.ordercode'] ? rawAsset.specifications['eh.pcps.tmp.ordercode'].value : null,
    sensorModuleBuildNumber: rawAsset.specifications
    && rawAsset.specifications[SpecificationKeys.GeneralSettings.SENSOR_BUILD_NUMBER] ? rawAsset.specifications[SpecificationKeys.GeneralSettings.SENSOR_BUILD_NUMBER].value : null,
    description: rawAsset.description ? rawAsset.description : null,
    instrumentations: rawAsset.instrumentations ? extractInstrumentations(rawAsset.instrumentations) : null,
    systems: rawAsset.systems ? extractSystems(rawAsset.systems) : null,
    itemType: 'asset',
    inSubscription: rawAsset.in_subscription === undefined || rawAsset.in_subscription,
    value: extractPrimaryValue(rawAsset.values),
    isMebakActivated: rawAsset?.systems?.items[0]?.specifications && rawAsset.systems.items[0].specifications[SpecificationKeys.MebakActivatedSpecs.Mebak_Activated]
      ? rawAsset.systems.items[0].specifications[SpecificationKeys.MebakActivatedSpecs.Mebak_Activated].value === 'true' : false,
    isConnectedAsset: !!(rawAsset.product.specifications && rawAsset.product.specifications[SpecificationKeys.ProductSpecs.CONNECTED_ASSET]),
    lastStatusChangedAt: rawAsset.last_status_changed_at ? rawAsset.last_status_changed_at : null,
  };
  if (rawAsset.values) {
    asset.values = rawAsset.values.map((value) => extractValue(value));
  }
  return asset;
}

export function extractAssets(rawAssets) {
  return rawAssets?.assets.map((asset) => extractAsset(asset));
}

export const extractLatLong = (asset) => [Number(asset?.specifications?.latitude?.value), Number(asset?.specifications?.longitude?.value)];
