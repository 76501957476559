import React from 'react';
import PropTypes from 'prop-types';
import { isSomething } from '../../utils';

function ActionBarButtons({ children }) {
  const buttons = children && children.map ? children.filter((c) => isSomething(c)).map((button) => (<li key={button.props.id}>{button}</li>)) : (<li>{children}</li>);

  return (
    <div className="action-bar">
      <ul>
        {buttons}
      </ul>
    </div>
  );
}
ActionBarButtons.propTypes = {
  children: PropTypes.node,
};

export default ActionBarButtons;
