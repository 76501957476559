export function extractAccessRights(type, id, rawAccessRights) {
  return {
    type,
    id,
    canRead: rawAccessRights.can_read,
    canUpdate: rawAccessRights.can_update,
    canDelete: rawAccessRights.can_delete,
    canPermit: rawAccessRights.can_permit,
  };
}
