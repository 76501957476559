import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Formik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';

import { ActionBar } from '../ActionBar';
import PermissionsEdit from '../Permissions/PermissionsEdit';
import Loader from '../Loader';
import withAccessRights from '../../wrappers/withAccessRights';

import {
  intlShape,
} from '../../shapes';

import {
  apiErrorsContain,
  createNodeType,
  createTenant,
  findDefaultAdminTenant,
  findAllAvailableNodeTypes,
  isNotFoundError,
  loadCurrentUser,
  loadCurrentUserAdminTenants,
  loadNode,
  updateNode,
} from '../../api';

import {
  CancelButton,
  SelectBox,
  SubmitButton,
  TextArea,
  TextInput,
  TypeaheadBox,
  Form,
} from '../Form';

import {
  isEmpty,
  navigateTo,
  showSuccess,
  handleUnknownErrors,
} from '../../utils';

import { NodeTypeRules } from '../../rules';
import { apiShape, withApi } from '../../context';
import { Column, Container, Row } from '../Grid';
import BackButton from '../BackButton';

export class NodeEdit extends Component {
  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.validateForm = this.validateForm.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.loadNode = loadNode.bind(this);
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.api = props.api;

    this.state = {
      nodeLoading: true,
      node: undefined,
      nodeTypes: undefined,
      tenants: undefined,
      defaultAdminTenant: undefined,
      user: undefined,
    };
  }

  componentDidMount() {
    const { intl } = this.props;
    this.loadData(this.props.match.params.id).catch((apiErrors) => {
      if (isNotFoundError(apiErrors)) {
        navigateTo('/404');
      } else {
        handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
      }
    });
  }

  onSubmit(values, actions) {
    const { intl, match } = this.props;
    this.submitData(match.params.id, values, actions)
      .then(() => {
        actions.resetForm(values);
        showSuccess(intl.formatMessage({ id: 'node_edit.success_notification' }));
        navigateTo(`/nodes/${match.params.id}`);
      })
      .catch((apiErrors) => {
        const formErrors = {};
        if (apiErrorsContain(apiErrors, 'taken', 'name')) {
          formErrors.name = intl.formatMessage({ id: 'api.error.node.taken' });
        }

        if (Object.keys(formErrors).length < apiErrors.length) {
          handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
        }

        actions.setSubmitting(false);
        actions.setErrors(formErrors);
      });
  }

  async submitData(nodeId, values, actions) {
    let tenantId = null;
    const { user } = this.state;
    if (values.type.new) {
      if (this.state.tenants.length === 0) {
        tenantId = await createTenant({ name: `${user.firstName} ${user.lastName}` });
      } else if (values.tenant) {
        tenantId = values.tenant.id;
      } else {
        tenantId = this.state.defaultAdminTenant.id;
      }
      /* eslint-disable no-param-reassign */
      values.type.id = await createNodeType(values.type, tenantId);
    } else if (!values.type.tenantPublic) {
      // eslint-disable-next-line prefer-destructuring
      tenantId = values.type.tenantId;
    }

    const data = {
      name: values.name,
      description: values.description,
      type: { id: values.type.id },
    };

    if (tenantId) {
      data.tenant = { id: tenantId };
    }

    await updateNode(nodeId, data);
    actions.setSubmitting(false);
  }

  async loadData() {
    const { match } = this.props;

    const node = await this.loadNode(match.params.id, { include: 'type' });
    const user = await loadCurrentUser();

    const nodeTypes = (await findAllAvailableNodeTypes(match.params.id, { include: 'tenant,parent' }))
      .filter((nodeType) => !nodeType.tenantPublic || (NodeTypeRules.specialNodeTypeCodes.indexOf(nodeType.code) < 0 && NodeTypeRules.specialNodeTypeCodes.indexOf(nodeType.parentCode) < 0));

    const tenants = await loadCurrentUserAdminTenants(user);
    const defaultAdminTenant = findDefaultAdminTenant(user, tenants);

    this.setState({
      node, user, nodeTypes, tenants, defaultAdminTenant,
    });
    this.setState({ nodeLoading: false });
  }

  validateForm(values) {
    const { intl } = this.props;
    const errors = {};

    if (isEmpty(values.name) || isEmpty(values.name.trim())) {
      errors.name = intl.formatMessage({ id: 'validation.name.mandatory' });
    } else if (values.name.length > 255) {
      errors.name = intl.formatMessage({ id: 'validation.name.too_long' }, { characters: 255 });
    } else if (/[,;/\\$?<>]/.test(values.name)) {
      errors.name = intl.formatMessage({ id: 'validation.node_name.special_chars' });
    }

    if (!values.type || isEmpty(values.type.name) || isEmpty(values.type.name.trim())) {
      errors.type = intl.formatMessage({ id: 'validation.type.mandatory' });
    } else if (values.type.name.length > 255) {
      errors.type = intl.formatMessage({ id: 'validation.type.too_long' });
    }
    return errors;
  }

  renderForm(props) {
    const { intl } = this.props;
    const { nodeTypes, tenants } = this.state;
    const { isSubmitting, values } = props;
    const typeInfo = (values.type && values.type.new) ? intl.formatMessage({ id: 'node.info_type.new' }) : null;

    // eslint-disable-next-line react/no-unused-class-component-methods
    this.form = props;

    const tenant = (tenants && tenants.length > 1 && values.type && values.type.new) ? (
      <SelectBox
        {...props}
        id="node-tenant"
        name="tenant"
        label={intl.formatMessage({ id: 'label.tenant' })}
        options={tenants}
      />
    ) : null;

    return (
      <Form {...props}>
        <Row>
          <Column xs="12">
            <TextInput
              {...props}
              id="node-name"
              name="name"
              label={intl.formatMessage({ id: 'label.name' })}
              required
            />
            <TypeaheadBox
              {...props}
              id="node-type"
              name="type"
              info={typeInfo}
              label={intl.formatMessage({ id: 'label.type' })}
              options={nodeTypes}
              canCreate
              required
            />
            {tenant}
            <TextArea
              {...props}
              name="description"
              label={intl.formatMessage({ id: 'label.description' })}
            />
          </Column>
        </Row>
        <div className="btn-group">
          <SubmitButton id="edit-node-submit" fetching={isSubmitting} disabled={!props.dirty} />
          <CancelButton id="edit-node-cancel" disabled={isSubmitting} />
        </div>
      </Form>
    );
  }

  render() {
    const {
      nodeLoading, node, nodeTypes, tenants, defaultAdminTenant,
    } = this.state;

    let tenant;
    if (tenants && tenants.length > 1) {
      if (node.tenantId) {
        tenant = tenants.find((t) => t.id === parseInt(node.tenantId, 10));
      } else {
        tenant = defaultAdminTenant;
      }
    }

    const initValues = node && tenants && nodeTypes && !nodeLoading ? ({
      name: node.name,
      description: node.description,
      type: nodeTypes.find((t) => t.name === node.typeName) || nodeTypes.find((t) => t.name === 'Undefined'),
      tenant,
    }) : null;

    const editPermissions = node && !nodeLoading ? (
      <PermissionsEdit permitableType="Node" permitableId={node.id} targetOnDeleteOwnReadPermission="/nodes" />) : null;

    const editNode = node && !nodeLoading ? (
      <Formik
        validate={this.validateForm}
        onSubmit={this.onSubmit}
        render={this.renderForm}
        initialValues={initValues}
      />
    ) : null;
    return (
      <Container>
        <Row>
          <Column>
            <BackButton />
          </Column>
        </Row>
        <Row>
          <Column>
            <ActionBar>
              <h1><FormattedMessage id="node_edit.header" /></h1>
            </ActionBar>
          </Column>
        </Row>
        <Row>
          <Column sm="6">
            {editNode}
          </Column>
        </Row>
        <Row>
          <Column>
            {editPermissions}
          </Column>
        </Row>
        <Row>
          <Column>
            <Loader loading={nodeLoading} />
          </Column>
        </Row>
      </Container>
    );
  }
}

NodeEdit.propTypes = {
  api: apiShape,
  intl: intlShape.isRequired,
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) }),
};

export default withApi(injectIntl(withAccessRights(NodeEdit, 'Node')));
