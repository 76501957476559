import ApiClientError from './ApiClientError';

class TooManyRequestsError extends ApiClientError {
  /* istanbul ignore next */
  constructor(request, response, cause) {
    super('TooManyRequestsError', 429, 'Too many request. Overload protection has been enabled.', request, response, cause);
  }
}

export default TooManyRequestsError;
