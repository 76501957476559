/*
DynamicFieldArray renders a field array inside a formik form that gets extended once all field are filled out, but just up to a certain limit (maxNumRows).
The number of inputs in a row depends on the dimensionality of the input data.
It requires the following props:

valueArrayName:       The string used to search in the form's values prop for the array of input objects for this component.
maxNumRows:           The limit of the auto-extend (if reached, no more rows will be added).
labels:               An array of strings that is put as a label over each field in a row (number of labels must match the dimensionality of the value objects).
placeholders:         An array of strings that is put as a placeholder in each field in a row (number of placeholders must match the dimensionailty of the value objects).
fieldNames:           An array of keys that is used to map the input data objects to input fields (number of fieldNames must match the dimensionality of the value objects).
values:               An object that includes an array of objects (the array is named like the value of prop "valueArrayName"),
                      each of which contain key-value pairs. The keys in those key-value pairs must match the values in the "fieldNames" prop.

Example:
valueArrayName: 'linearisation'
maxNumRows: '32'
labels: ['Level', 'Volume']
placeholders: ['Level', 'Volume']
fieldNames: ['level', 'volume']
values: {
    < other form values >,
    linearisation: [
      {
        level: 3,
        volume: 4
      },
      {
        level: 5,
        volume: 10
      },
      ...
    ]
  }
*/

import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, Field } from 'formik';
import { Column, Row } from '../Grid';
import InputGroup from './InputGroup';

export function DynamicFieldArray(props) {
  const {
    valueArrayName,
    values,
    labels,
    placeholders,
    fieldNames,
    fieldComponents,
    maxNumRows,
    disabled,
    fieldNumbering,
    colSizeArray,
    onClickDelete,
  } = props;

  const numCols = fieldNames.length;
  const roundedNumCols = Math.round(10 / numCols);
  const colClassName = `col-xs-${roundedNumCols - (fieldNumbering ? 1 : 0)} col-sm-${roundedNumCols - (fieldNumbering ? 1 : 0)}`;
  const hideLabels = labels === undefined;
  const hidePlaceholders = placeholders === undefined;
  const deleteButtonOffset = hideLabels ? '' : 'dfa-button-col';

  const extendForm = (formValues) => {
    if ((!maxNumRows || formValues[valueArrayName].length < maxNumRows)
      && formValues[valueArrayName].every((fieldValueObject) => Object.values(fieldValueObject).find((val) => val !== ''))) {
      const newFieldValueObject = {};
      fieldNames.forEach((fieldName) => {
        newFieldValueObject[fieldName] = '';
      });
      formValues[valueArrayName].push(newFieldValueObject);
    }
  };

  const addFieldNumbering = (objIdx) => {
    if (!fieldNumbering) {
      return null;
    }
    return (
      <Column className="col-xs-2 col-sm-1 dfa-numbering" key={`column-numbering-${objIdx}`}>
        {`${objIdx + 1}.`}
      </Column>
    );
  };

  const getSizeViaColSizeArray = (fieldIdx) => (`col-xs-${colSizeArray[fieldIdx] - (fieldNumbering ? 1 : 0)} col-sm-${colSizeArray[fieldIdx] - (fieldNumbering ? 1 : 0)}`);
  const getColClassName = (fieldIdx) => (colSizeArray.length === numCols ? getSizeViaColSizeArray(fieldIdx) : colClassName);

  const renderFields = (formValues, fieldObject, objIdx, remove) => (
    <Row key={`row-field-${objIdx}`}>
      { addFieldNumbering(objIdx) }
      {
        Object.keys(fieldObject).map((fieldName, fieldIdx) => (
          <Column className={`${getColClassName(fieldIdx)} dfa-input-col`} key={`column-field-${objIdx}-${fieldName}`}>
            <InputGroup
              {...props}
              hideLabel={hideLabels}
              label={!hideLabels ? labels[fieldIdx] : undefined}
              name={valueArrayName}
              objIdx={objIdx}
              fieldName={fieldName}
            >
              {fieldComponents && fieldComponents.length > fieldIdx ? React.createElement(fieldComponents[fieldIdx], {
                ...props,
                name: `${valueArrayName}.${objIdx}.${fieldName}`,
                id: `${valueArrayName}.${objIdx}.${fieldName}`,
                'data-testid': `${valueArrayName}.${objIdx}.${fieldName}`,
                placeholder: !hidePlaceholders ? placeholders[fieldIdx] : undefined,
                objIdx,
                disabled,
              }) : (
                <Field
                  name={`${valueArrayName}.${objIdx}.${fieldName}`}
                  id={`${valueArrayName}.${objIdx}.${fieldName}`}
                  data-testid={`${valueArrayName}.${objIdx}.${fieldName}`}
                  placeholder={!hidePlaceholders ? placeholders[fieldIdx] : undefined}
                  type="text"
                  className="form-control"
                  autoComplete="none"
                  disabled={disabled}
                />
              )}
            </InputGroup>
          </Column>
        ))
      }
      <Column className={`col-xs-2 col-sm-${2 + (fieldNumbering ? 1 : 0)} ${deleteButtonOffset}`} key={`column-button-${objIdx}`}>
        {/* eslint-disable jsx-a11y/control-has-associated-label */}
        {fieldNames.find((elem) => fieldObject[elem] !== '') ? (
          <button
            id={`${valueArrayName}.${objIdx}.remove`}
            type="button"
            className="btn-link btn-action icon-eh-delete dfa-button"
            onClick={() => {
              remove(objIdx);
              if (onClickDelete) {
                onClickDelete(objIdx, props);
              }
            }}
            disabled={disabled}
          />
        ) : null}
      </Column>
    </Row>
  );

  const renderFieldArray = (formValues, remove) => {
    if (!formValues[valueArrayName]) {
      /* eslint-disable no-param-reassign */
      formValues[valueArrayName] = [];
    }
    extendForm(formValues);
    return formValues[valueArrayName].map((fieldObject, index) => renderFields(formValues, fieldObject, index, remove));
  };

  return (
    <FieldArray
      name={valueArrayName}
      render={({ remove }) => renderFieldArray(values, remove)}
    />
  );
}

DynamicFieldArray.propTypes = {
  valueArrayName: PropTypes.string.isRequired,
  maxNumRows: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.string),
  placeholders: PropTypes.arrayOf(PropTypes.string),
  fieldNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  // should be function/component but thests are setup to be object
  // eslint-disable-next-line react/forbid-prop-types
  fieldComponents: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  fieldNumbering: PropTypes.bool,
  colSizeArray: PropTypes.arrayOf(PropTypes.number),
  onClickDelete: PropTypes.func,
};

DynamicFieldArray.defaultProps = {
  values: undefined,
  disabled: false,
  fieldNumbering: false,
  fieldComponents: [],
  maxNumRows: undefined,
  colSizeArray: [],
};

export default DynamicFieldArray;
