import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { withConfiguration } from '../ConfigurationContext';

export function SentryLogging({ children, configuration }) {
  const log = useMemo(() => {
    Sentry.init({
      integrations: [
        Sentry.browserTracingIntegration({
          enableInp: true,
        }),
        Sentry.replayIntegration(),
      ],
      dsn: configuration.sentryDSN,
      environment: configuration.environment,
      tracePropagationTargets: ['localhost', /netilion\.endress\.com\/v1/],
      tracesSampleRate: configuration.sentryTracesSampleRate,
      replaysSessionSampleRate: configuration.sentryReplaySessionSampleRate || 0.1,
      replaysOnErrorSampleRate: configuration.sentryReplayOnErrorSampleRate || 1,
      // see https://docs.sentry.io/platforms/javascript/#decluttering-sentry
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        'Can\'t find variable: ZiteReader',
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
      ],
      blacklistUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
      // https://github.com/getsentry/sentry-javascript/issues/3147
      /* istanbul ignore next */
      beforeSend(event, hint) {
        try {
          if (event.exception.values[0].stacktrace.frames[0].filename === '<anonymous>') {
            return null;
          }
          if (hint.originalException === 'Timeout') return null;
          // eslint-disable-next-line no-empty
        } catch (e) { }
        return event;
      },
    });

    Sentry.setTag('page_locale', configuration.locale);

    return {
      setUser: (user) => Sentry.setUser({ email: user.email, id: user.id }),
      error: (context, error) => {
        Sentry.withScope((scope) => {
          scope.setTag('context', context);
          scope.setExtra('error', error);
          Sentry.captureException(error);
        });
      },
    };
  }, [configuration]);

  return (<>{children(log)}</>);
}

SentryLogging.propTypes = {
  configuration: PropTypes.shape({
    locale: PropTypes.string,
    sentryDSN: PropTypes.string,
    sentryTracesSampleRate: PropTypes.number,
    sentryReplaySessionSampleRate: PropTypes.number,
    sentryReplayOnErrorSampleRate: PropTypes.number,
    environment: PropTypes.string,
  }).isRequired,
  children: PropTypes.func,
};

export default withConfiguration(Sentry.withProfiler(SentryLogging));
