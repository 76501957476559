import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

export function AutoRouting({ location, history }) {
  useEffect(() => {
    const brackets = location.pathname.split('/');
    history.push(brackets.splice(0, brackets.length - 1).join('/'));
  }, [location]);

  return (<div>...</div>);
}

AutoRouting.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(AutoRouting);
