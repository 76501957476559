import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  ActionBar,
  Details,
  displayUserName,
  DetailsItem,
  userShape,
} from 'lcm-iot-commons';

export function UserDetailsItem({ user }) {
  return user ? (
    <div className="user-detail-item">
      <ActionBar>
        <h2 id="permission-request-user-header"><FormattedMessage id="label.user" /></h2>
      </ActionBar>
      <Details>
        <DetailsItem id="user-name" translationKey="label.name" value={displayUserName(user)} />
        <DetailsItem id="user-email" translationKey="label.email" value={user.email} />
      </Details>
    </div>
  ) : null;
}

UserDetailsItem.propTypes = {
  user: userShape,
};

UserDetailsItem.defaultProps = {
  user: undefined,
};

export default injectIntl(UserDetailsItem);
