import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-input-switch';
import { injectIntl } from 'react-intl';
import Colors from '../../constants/Colors';
import { handleFormikCheckboxChange } from '../../utils';
import { intlShape } from '../../shapes';

function SwitchInput(props, isEventPropagate) {
  const {
    id, disabled, name, values, intl, activeLabel, inactiveLabel, hideLabel, smallButton,
  } = props;
  const value = values ? values[name] === true : false;
  const labelKey = value ? activeLabel : inactiveLabel;
  const trackBackgroundColor = disabled ? Colors.EH_WINTER_GRAY_3 : Colors.EH_RED_ORANGE_3;
  const trackCheckedBackgroundColor = disabled ? Colors.EH_WINTER_GRAY_3 : Colors.EH_MAY_GREEN_2;
  const buttonWidth = smallButton ? 40 : 50;
  const buttonHeight = smallButton ? 22.4 : 28;

  return (
    <div className="form-group" id={`group-${id}`}>
      <Switch
        id={id}
        value={value}
        on
        off={false}
        disabled={disabled}
        styles={{
          container: {
            width: buttonWidth,
            height: buttonHeight,
          },
          track: {
            borderRadius: 14,
            backgroundColor: trackBackgroundColor,
          },
          trackChecked: {
            backgroundColor: trackCheckedBackgroundColor,
          },
          button: {
            borderRadius: 18,
            right: 24,
            left: 2,
            top: 3,
            bottom: 3,
          },
          buttonChecked: {
            left: 24,
            right: 2,
            top: 3,
            bottom: 3,
          },
        }}
        onChange={() => {
          handleFormikCheckboxChange(props, isEventPropagate);
          // added this property to fix the library issue wIth IE
          // Need to avoid propagating event in IE
          // isEventPropagate : added a lint exception, since this parameter value needs not to be reload when assigning from a local variable
          // eslint-disable-next-line no-param-reassign
          isEventPropagate = !isEventPropagate;
        }}
      />
      { hideLabel ? null : (
        <div id="switch-label" className="switch">
          {intl.formatMessage({ id: labelKey })}
        </div>
      )}
    </div>
  );
}

SwitchInput.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  intl: intlShape.isRequired,
  values: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  activeLabel: PropTypes.string,
  inactiveLabel: PropTypes.string,
  hideLabel: PropTypes.bool,
  smallButton: PropTypes.bool,
};

SwitchInput.defaultProps = {
  id: undefined,
  disabled: false,
  values: undefined,
  activeLabel: 'label.active',
  inactiveLabel: 'label.inactive',
  hideLabel: false,
  smallButton: false,
};

export default injectIntl(SwitchInput);
