import { useParams } from 'react-router-dom';
import { useApi } from './useApi';

/**
 * @typedef {Object} AccessRights
 * @property {boolean} canRead
 * @property {boolean} canUpdate
 * @property {boolean} canDelete
 * @property {boolean} canPermit
 */

/**
 * Custom hook for querying the access rights of the current user for a permitable.
 *
 * @param {('APISubscription'|'Asset'|'Batch'|'Customer'|'EDM::EdgeDevice'|'Event'|'Instrumentation'|'Node'|'Nodes'|'Product'|'Recipe'|'System'|'Tenant'|'Usergroup')
 * } permitableType - The type of the permitable.
 * @param {import('@tanstack/react-query').UseQueryOptions} options - Additional options for the query.
 * @returns {import('@tanstack/react-query').UseQueryResult<AccessRights>} The result of the query.
 */
export const useAccessRightsQuery = (permitableType, options) => {
  const { id } = useParams();
  const api = useApi();
  // to optimize caching, the permitableType should stay the same
  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

  return api.get.useQuery('/users/current/access_rights', { permitable_type: capitalize(permitableType), permitable_id: id }, {
    enabled: !!id && !!permitableType,
    select: (data) => ({
      canRead: data.can_read,
      canUpdate: data.can_update,
      canDelete: data.can_delete,
      canPermit: data.can_permit,
    }),
    // roughly for one page load
    staleTime: 10000,
    refetchOnWindowFocus: false,
    ...options,
  });
};
