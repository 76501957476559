import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroller';

import {
  ActionBar,
  ActionBarButtons,
  CreateActionButton,
  Container,
  List,
  Loader,
  isSomething,
  withApi,
  withNotifier,
  withUser,
  apiShape,
  intlShape,
  notifierShape,
  userShape,
} from 'lcm-iot-commons';

import TenantItem from './TenantItem';
import NoTenants from './NoTenants';

export function Tenants({
  api, intl, notifier, user,
}) {
  const [tenants, setTenants] = useState([]);
  const [pagination, setPagination] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [badge, setBadge] = useState(
    <span className="loading">
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>,
  );

  const loadTenants = async () => {
    try {
      if (isFetching) return;
      setIsFetching(true);

      let response;

      if (pagination?.next) {
        response = await api.get(pagination.next);
      } else {
        response = await api.get('/tenants', { order_by: 'name', admin_or_user_id: user.id });
        setIsLoading(false);
      }

      setPagination(response.pagination);
      setTenants(tenants.concat(response.tenants));
      // eslint-disable-next-line camelcase
      setBadge(response.pagination?.total_count);

      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      notifier.showError(api.translateError(error));
    }
  };

  const removeTenant = (tenantId) => {
    setTenants(tenants.filter((tenant) => tenant.id !== tenantId));
    setBadge(tenants.length - 1);
  };

  React.useEffect(() => {
    if (user) loadTenants();
  }, [user]);

  const tenantsList = !isLoading && tenants.length > 0 ? (
    <List id="tenants-list">
      <InfiniteScroll
        initialLoad
        loadMore={loadTenants}
        hasMore={isSomething(pagination?.next)}
      >
        {
            tenants.map((tenant) => (
              <TenantItem
                key={tenant.id}
                tenant={tenant}
                removeTenant={removeTenant}
              />
            ))
          }
      </InfiniteScroll>
    </List>
  ) : null;

  const noTenants = !isLoading && tenants.length === 0 ? <NoTenants /> : null;

  return user ? (
    <Loader loading={isLoading}>
      <Container>
        <ActionBar>
          <h1 id="tenants-header">
            {intl.formatMessage({ id: 'tenants.header' })}
            <span id="tenants-count" className="badge">{badge}</span>
          </h1>
          <ActionBarButtons>
            <CreateActionButton
              id="create-tenant-button"
              target="/tenants/create"
            />
          </ActionBarButtons>
        </ActionBar>
        {!isLoading ? tenantsList : null}
        {!isLoading ? noTenants : null}
      </Container>
    </Loader>
  ) : null;
}

Tenants.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  user: userShape,
};

Tenants.defaultProps = {
  user: undefined,
};

export default injectIntl(withUser(withApi(withNotifier(Tenants))));
