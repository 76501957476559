import {
  ActionType as CommonActionsType,
} from 'lcm-iot-commons';

const ActionType = {
  // Password reset
  PASSWORD_RESET_CREATING: 'PASSWORD_RESET_CREATING',
  PASSWORD_RESET_SUCCESSFUL: 'PASSWORD_RESET_SUCCESSFUL',
  PASSWORD_RESET_FAILED: 'PASSWORD_RESET_FAILED',

  // Redux Form
  REDUX_FORM_CHANGE: '@@redux-form/CHANGE',

  // Search EdgeDevices
  SEARCH_EDGEDEVICES_SUCCESSFUL: 'SEARCH_EDGEDEVICES_SUCCESSFUL',
  SEARCH_EDGEDEVICES_FETCHING: 'SEARCH_EDGEDEVICES_FETCHING',
  SEARCH_EDGEDEVICES_FETCHING_RESET: 'SEARCH_EDGEDEVICES_FETCHING_RESET',
  SEARCH_EDGEDEVICES_FAILED: 'SEARCH_EDGEDEVICES_FAILED',
  LOAD_NEXT_EDGEDEVICES_SUCCESSFUL: 'LOAD_NEXT_EDGEDEVICES_SUCCESSFUL',
  LOAD_NEXT_EDGEDEVICES_FETCHING: 'LOAD_NEXT_EDGEDEVICES_FETCHING',
  LOAD_NEXT_EDGEDEVICES_FAILED: 'LOAD_NEXT_EDGEDEVICES_FAILED',

  // Details EdgeDevice
  EDGEDEVICE_DETAILS_INIT: 'EDGEDEVICE_DETAILS_INIT',
  LOAD_EDGEDEVICE_FETCHING: 'LOAD_EDGEDEVICE_FETCHING',
  LOAD_EDGEDEVICE_SUCCESSFUL: 'LOAD_EDGEDEVICE_SUCCESSFUL',
  LOAD_EDGEDEVICE_FAILED: 'LOAD_EDGEDEVICE_FAILED',
  SAVE_EDGEDEVICE_SAVING: 'SAVE_EDGEDEVICE_SAVING',
  SAVE_EDGEDEVICE_SUCCESSFUL: 'SAVE_EDGEDEVICE_SUCCESSFUL',
  SAVE_EDGEDEVICE_FAILED: 'SAVE_EDGEDEVICE_FAILED',

  // Edit EdgeDevice
  SAVE_EDGEDEVICESPECIFICATIONS_FAILED: 'SAVE_EDGEDEVICESPECIFICATIONS_FAILED',
  SAVE_EDGEDEVICESPECIFICATIONS_SUCCESSFUL: 'SAVE_EDGEDEVICESPECIFICATIONS_SUCCESSFUL',
  SAVE_EDGEDEVICESPECIFICATIONS_SAVING: 'SAVE_EDGEDEVICESPECIFICATIONS_SAVING',

  // User Profile
  CHANGE_CURRENT_PASSWORD_SAVING: 'CHANGE_CURRENT_PASSWORD_SAVING',
  CHANGE_CURRENT_PASSWORD_SUCCESSFUL: 'CHANGE_CURRENT_PASSWORD_SUCCESSFUL',
  CHANGE_CURRENT_PASSWORD_FAILED: 'CHANGE_CURRENT_PASSWORD_FAILED',
  RESET_SUBMIT_PROPS: 'RESET_SUBMIT_PROPS',

  // SSO Session
  CREATE_SSO_SESSION_SUCCESSFUL: 'CREATE_SSO_SESSION_SUCCESSFUL',
  CREATE_SSO_SESSION_FAILED: 'CREATE_SSO_SESSION_FAILED',
  CREATE_SSO_SESSION_FETCHING: 'CREATE_SSO_SESSION_FETCHING',
};

const combinedActionTypes = {
  ...CommonActionsType,
};

Object.keys(ActionType).forEach((key) => {
  combinedActionTypes[key] = ActionType[key];
});

export default combinedActionTypes;
