import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  intlShape,
  url,
} from 'lcm-iot-commons';

export function RequestPasswordResetResponse({
  intl, type,
}) {
  let messageType;
  switch (type) {
    case 'unconfirmed':
      messageType = 'password_reset.confirm_mail_send';
      break;
    case 'password_reset':
      messageType = 'password_reset.an_email_has_been_sent';
      break;
    default:
      break;
  }

  return (
    <div>
      {type === 'password_reset' || type === 'unconfirmed' ? (
        <div className="row">
          <div className="col-md-6" id="request-password-reset-success">
            <h1>{intl.formatMessage({ id: 'password_reset.header' })}</h1>
            <p>{intl.formatMessage({ id: `${messageType}` })}</p>
            <p>{intl.formatMessage({ id: 'password_reset.check_your_emails' })}</p>
            <p>
              <Link to={url('/sign_in')} id="back_to_sign_in">
                {intl.formatMessage({ id: 'password_reset.back_to_sign-in' })}
              </Link>
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
}

RequestPasswordResetResponse.propTypes = {
  intl: intlShape.isRequired,
  type: PropTypes.string,
};

RequestPasswordResetResponse.defaultProps = {
  type: undefined,
};

export default injectIntl(RequestPasswordResetResponse);
