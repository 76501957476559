import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import {
  isEmpty, isNotEmpty, isNothing, isSomething,
  url,
} from '../../utils';
import { trackingShape, withTracking } from '../../context';

export function SearchBar(props) {
  const {
    placeholder,
    disabled,
    resettable,
    timeout,
    onSearch,
    onClick,
    onInputChange,
    searchCount,
    filterWildcard,
    minChars,
    hideSubmit,
    scanSearchSource,
    tracking,
    inputName,
    id,
    defaultValue,
  } = props;

  const navigate = useNavigate();
  const inputRef = React.useRef();
  const timeoutRef = React.useRef(null);

  const [term, setTerm] = React.useState('');

  React.useEffect(() => {
    setTerm(defaultValue);
  }, [defaultValue]);

  const focus = () => {
    setTimeout(() => {
      // eslint-disable-next-line no-unused-expressions
      inputRef.current?.focus();
    }, 50);
  };

  const search = (newValue) => {
    if (filterWildcard && newValue.match(/^[*]+$/g)) {
      onSearch('');
    } else {
      onSearch(newValue);
    }
    focus();
  };

  function handleInputChange(newValue) {
    setTerm(newValue);
    clearTimeout(timeoutRef.current);
    if (isEmpty(newValue)) {
      search('');
      return;
    }
    if (minChars && newValue.length < minChars) {
      return;
    }
    if (timeout > 0) {
      timeoutRef.current = setTimeout(() => {
        search(newValue);
      }, timeout);
    } else {
      search(newValue);
    }
  }

  function resetInput(e) {
    e.stopPropagation();
    setTerm('');
    handleInputChange('');
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    search(term);
  };

  const handleOnClick = (e) => {
    e.preventDefault();
    if (isSomething(scanSearchSource)) {
      navigate(url(`${scanSearchSource}/search_scan`));
      return;
    }
    handleSubmit(e);
  };

  const resetButton = resettable && (isNotEmpty(term))
    ? (
      <button data-testid="reset-search" type="button" className="btn reset-button" onClick={resetInput}>
        <i className="icon icon-eh-close" />
      </button>
    )
    : null;

  let buttonClass = 'lcm-iot-icon-scan';
  if (isNothing(scanSearchSource)) {
    buttonClass = 'icon-eh-search';
  }

  const onSearchInputDone = () => {
    if (term?.length) tracking.trackEvent('Search-started', { searchResultCount: searchCount, searchTermLength: term.length });
  };

  return (
    <div className="search-bar">
      <form className="searchForm" onSubmit={handleSubmit} noValidate>
        {!hideSubmit && <button data-testid="search-submit" type="button" id="searchSubmit" className={`btn ${buttonClass}`} onClick={handleOnClick} />}
        <div className="search-bar-items">
          <input
            name={inputName || 'search'}
            id={id || 'searchInputField'}
            data-testid="search-input"
            onChange={(e) => {
              handleInputChange(e?.target.value);
              onInputChange?.();
            }}
            type="text"
            placeholder={placeholder}
            disabled={disabled}
            value={term}
            ref={inputRef}
            onBlur={onSearchInputDone}
            onClick={onClick}
          />
          {resetButton}
        </div>
      </form>
    </div>
  );
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  minChars: PropTypes.number,
  resettable: PropTypes.bool,
  timeout: PropTypes.number,
  filterWildcard: PropTypes.bool,
  hideSubmit: PropTypes.bool,
  scanSearchSource: PropTypes.string,
  tracking: trackingShape,
  searchCount: PropTypes.number,
  onInputChange: PropTypes.func,
  onClick: PropTypes.func,
  inputName: PropTypes.string,
  id: PropTypes.string,
  defaultValue: PropTypes.string,
};

SearchBar.defaultProps = {
  timeout: 800,
  filterWildcard: true,
  resettable: true,
  minChars: 1,
  disabled: false,
  placeholder: '',
  defaultValue: '',
};

export default withTracking(SearchBar);
