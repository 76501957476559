import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Formik } from 'formik';
import PermissionEdit from './PermissionEdit';
import PermissionType from '../../constants/PermissionType';
import UserGroupTypeahead from '../UserGroups/UserGroupTypeahead';
import { createPermissionKey } from '../../utils/permissionUtils';
import {
  DeleteActionButton,
  ActionBar, ActionBarButtons, CreateActionButton,
} from '../ActionBar';
import {
  Form, SubmitButton,
} from '../Form';

import {
  usersPermissionsShape, intlShape,
} from '../../shapes';
import * as browserUtils from '../../utils/browserUtils';

function UserGroupPermissionsEdit({
  userGroupsPermissions, permitableId, permitableType, allowedPermissions, onPermissionCreate, onDelete, onPermissionDelete, onAdd, intl,
}) {
  const renderUserGroupPermissions = (userGroupPermission) => {
    const renderedPermissionItems = allowedPermissions.map((permissionType) => {
      const key = createPermissionKey(permitableType, permitableId, 'Usergroup', userGroupPermission.id, permissionType);
      const permissionId = userGroupPermission[permissionType.substring(4)];
      return (
        <PermissionEdit
          key={key}
          onCreate={onPermissionCreate}
          onDelete={onPermissionDelete}
          permissionId={permissionId}
          assignableId={userGroupPermission.id}
          assignableType="Usergroup"
          permissionType={permissionType}
          permitableId={permitableId}
          permitableType={permitableType}
        />
      );
    });

    return (
      <div key={userGroupPermission.id} className="permission-group-item" id="permission-user-items">
        <div className="permission-assignee">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div className="clickable" onClick={() => browserUtils.navigateTo(`/usergroups/${userGroupPermission.id}`)}>
            {userGroupPermission.name}
          </div>
        </div>
        <div className="permission-items" id="permission-user-item">
          {renderedPermissionItems}
          <div className="permission-delete">
            <DeleteActionButton
              disabled={false}
              modalTitle={intl.formatMessage({ id: 'edit_permissions.delete.modal_title' })}
              modalMessage={intl.formatMessage({ id: 'edit_permissions.delete.modal_message' }, { name: userGroupPermission.name })}
              onConfirm={() => onDelete(userGroupPermission.id, false)}
            />
          </div>
        </div>
      </div>
    );
  };

  const validateForm = (values) => {
    const errors = {};
    if (values.usergroup?.id === undefined) {
      errors.usergroup = intl.formatMessage({ id: 'validation.user_group.mandatory' });
    }
    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;
    const permissionItems = userGroupsPermissions.map((permissions) => renderUserGroupPermissions(permissions));
    return (
      <div id="edit-user-group-permissions" className="permission-group-items">
        <ActionBar>
          <h3><FormattedMessage id="edit_permissions.user_groups" /></h3>
          <ActionBarButtons>
            <CreateActionButton id="create-user-group-button" target="/usergroups/create" />
          </ActionBarButtons>
        </ActionBar>
        {permissionItems}
        <Form id="edit-permissions-add-user-group" {...formProps}>
          <UserGroupTypeahead
            {...formProps}
            id="user-group"
            name="usergroup"
            label={intl.formatMessage({ id: 'label.name' })}
            excludables={userGroupsPermissions}
          />
          <SubmitButton
            id="add-user-group-permission"
            text={intl.formatMessage({ id: 'edit_permissions.add_user_group_button' })}
            fetching={isSubmitting}
          />
        </Form>
      </div>
    );
  };

  return (
    <Formik
      validate={validateForm}
      onSubmit={onAdd}
      render={renderForm}
    />
  );
}

UserGroupPermissionsEdit.propTypes = {
  allowedPermissions: PropTypes.arrayOf(PropTypes.oneOf([PermissionType.READ, PermissionType.UPDATE, PermissionType.DELETE, PermissionType.PERMIT])).isRequired,
  onPermissionCreate: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  onPermissionDelete: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  permitableId: PropTypes.number.isRequired,
  permitableType: PropTypes.string.isRequired,
  userGroupsPermissions: usersPermissionsShape.isRequired,
};

export default injectIntl(UserGroupPermissionsEdit);
