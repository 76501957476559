import { isNotEmpty } from './langUtils';

export function formatInstrumentationDetails(instrumentation) {
  let details = instrumentation.tag;
  details = instrumentation.type.displayName;
  if (isNotEmpty(instrumentation.description)) {
    details = details.concat(', ').concat(instrumentation.description);
  }
  return details;
}

export function findMatchingInstrumentationType(productCategories, instrumentationTypes) {
  let matchingType = null;
  productCategories.some((categorie) => instrumentationTypes.some((type, index) => {
    if (type.code === categorie.code || (categorie.parent && type.code === categorie.parent.code)) {
      matchingType = instrumentationTypes[index];
      return true;
    }
    return false;
  }));
  return matchingType;
}
