/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { RadioSelect } from './Form';
import { List } from './List';
import { isTouchDevice } from '../utils';
import { SearchBar } from './Search';
import { Column, Container, Row } from './Grid';

function AllOrMultipleSelect({
  values, allItems, topItems, itemKey, title, handleSpecificSelectionChange, handleSelectionSwitch, noItemLabel, moreItemsLabel, topItemsLabel,
}) {
  const intl = useIntl();
  const [searchTerm, setSearchTerm] = useState('');

  function renderListItem(id, name, checked, tenant) {
    const enabled = values.selectedSwitch !== 'specific_selection' ? '' : 'tenant-option-enabled';
    const verified = tenant?.name && tenant?.public ? (
      <span className={`tenant-option-icon ${enabled}`}><span id={id} className="icon icon-eh-check2" /></span>) : null;
    const tenantContent = tenant?.name && !tenant?.public ? (` (${tenant?.name})`) : null;
    const content = (
      <div id={id}>
        <span id={id}>{name}</span>
        {tenantContent}
        {verified}
      </div>
    );

    return (
      <div
        key={`checkbox-${id}`}
      >
        <div>
          <input
            id={`${itemKey}-${id}-checkbox`}
            type="checkbox"
            className={checked ? 'checked' : 'unchecked'}
            checked={checked}
            value={id}
            onChange={handleSpecificSelectionChange}
            disabled={values.selectedSwitch !== 'specific_selection' || allItems === undefined}
          />
          <label htmlFor={`${itemKey}-${id}-checkbox`}>
            {content}
          </label>
        </div>
      </div>
    );
  }

  const filteredItems = searchTerm ? allItems?.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase())) : allItems;
  const noItem = !searchTerm || noItemLabel.toLowerCase().includes(searchTerm.toLowerCase())
    ? renderListItem('null', noItemLabel, values.selectedItems?.includes('null'), undefined)
    : null;

  return (
    <>
      <h3>{title}</h3>
      <RadioSelect
        id={`${itemKey}-items-switch`}
        options={[
          { value: 'all', label: intl.formatMessage({ id: 'label.all' }) },
          { value: 'specific_selection', label: intl.formatMessage({ id: 'label.specific_selection' }) },
        ]}
        handleChange={handleSelectionSwitch}
        values={{ 'items-type': values.selectedSwitch }}
        name="items-type"
        handleBlur={() => true}
      />
      <SearchBar
        autoFocus={!isTouchDevice()}
        disabled={values.selectedSwitch !== 'specific_selection'}
        filterWildcard={false}
        onSearch={(searchResult) => setSearchTerm(searchResult)}
        placeholder={intl.formatMessage({ id: 'search.searchbox_placeholder' })}
        resettable
        hideSubmit
        timeout={300}
      />
      <div className="all-or-multiple-selected-container">
        {values.selectedItems?.map((selectedItem) => {
          const foundItem = allItems.find((item) => item.id.toString() === selectedItem);
          const key = foundItem?.id || 'null';
          const name = foundItem?.name || noItemLabel;
          return (
            <div data-testid="specific-selected-item" className="all-or-multiple-selected-item" key={key}>
              <span data-testid={`selected-item-${name}`}>{name}</span>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div
                data-testid="remove-specific-selected-item"
                onClick={() => handleSpecificSelectionChange({ target: { value: selectedItem } })}
                className="icon-eh-close"
              />
            </div>
          );
        })}
      </div>
      <Container className="all-or-multiple-select-container">
        <Row>
          <Column>
            <List>
              {allItems ? (
                <>
                  {!searchTerm
                    ? (
                      <>
                        <div className="all-or-multiple-select-top-more-items" disabled={values.selectedSwitch !== 'specific_selection'}>
                          {topItemsLabel}
                        </div>
                        {topItems?.map((item) => {
                          const {
                            id,
                            name,
                            tenant,
                          } = item;
                          const checked = values.selectedItems?.includes(id.toString());
                          return (
                            renderListItem(id, name, checked, tenant)
                          );
                        })}
                        <div className="all-or-multiple-select-top-more-items" disabled={values.selectedSwitch !== 'specific_selection'}>
                          {moreItemsLabel}
                        </div>
                      </>
                    )
                    : null}
                  {noItem}
                  {filteredItems?.map((item) => {
                    const {
                      id,
                      name,
                      tenant,
                    } = item;
                    const checked = values.selectedItems?.includes(id.toString());
                    return renderListItem(id, name, checked, tenant);
                  })}
                </>
              ) : <Skeleton height={400} />}
            </List>
          </Column>
        </Row>
      </Container>
    </>
  );
}

AllOrMultipleSelect.propTypes = {
  itemKey: PropTypes.oneOf(['categories', 'languages']).isRequired,
  title: PropTypes.string.isRequired,
  topItemsLabel: PropTypes.string,
  moreItemsLabel: PropTypes.string,
  noItemLabel: PropTypes.string,
  values: PropTypes.shape({
    selectedSwitch: PropTypes.string,
    selectedItems: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allItems: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  topItems: PropTypes.arrayOf(PropTypes.any),
  handleSpecificSelectionChange: PropTypes.func.isRequired,
  handleSelectionSwitch: PropTypes.func.isRequired,
};

export default AllOrMultipleSelect;
