import React, { useState } from 'react';
import {
  apiShape,
  BadRequestError,
  browserShape,
  Container,
  intlShape,
  Loader,
  notifierShape,
  UnauthorizedError,
  withApi,
  withBrowser,
  withIntl,
  withNotifier,
} from 'lcm-iot-commons';
import RecoveryCodesModal from './RecoveryCodesModal';
import MultiFactorAuthenticationForm from './MultiFactorAuthenticationForm';

export function MultiFactorAuthenticationEdit({
  api, intl, notifier, browser,
}) {
  const [provisioningUri, setProvisioningUri] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showRecoveryCodesModal, setShowRecoveryCodesModal] = useState(false);
  const [recoveryCodes, setRecoveryCodes] = useState([]);

  const loadUser = async () => {
    try {
      const user = await api.get('/users/current/');
      setProvisioningUri(user.provisioning_uri); // it has value only when mfa is disabled
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    loadUser();
  }, []);

  const handleOnSubmit = async (values, actions) => {
    try {
      const newStatus = provisioningUri ? 'enabled' : 'disabled';
      const response = await api.patch('/users/current/multi_factor_authentication', { otp_code_token: values.otpCodeToken, status: newStatus });
      notifier.showSuccess(intl.formatMessage({ id: `mfa.${newStatus}_success_message` }));
      if (newStatus === 'enabled') {
        setRecoveryCodes(response.recovery_codes);
        setShowRecoveryCodesModal(true);
      } else {
        browser.goBack();
      }
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        notifier.showError(intl.formatMessage({ id: 'mfa.invalid_token' }));
      } else if (error instanceof BadRequestError && error.contains('required_login_token')) {
        notifier.showError(intl.formatMessage({ id: 'mfa.error.required_login_token' }));
      } else {
        notifier.showError(api.translateError(error));
      }
    } finally {
      actions.setSubmitting(false);
    }
  };
  const onCloseRecoveryCodesModal = () => {
    setShowRecoveryCodesModal(false);
    browser.navigateTo('/profile');
  };

  return (
    <Loader loading={isLoading}>
      <Container>
        <h1 id="mfa-header">
          {intl.formatMessage({ id: 'mfa.header' })}
        </h1>
        <MultiFactorAuthenticationForm provisioningUri={provisioningUri} onSubmit={handleOnSubmit} />
        <RecoveryCodesModal
          id="recovery-codes-edit-modal"
          show={showRecoveryCodesModal}
          recoveryCodes={recoveryCodes}
          onClose={() => onCloseRecoveryCodesModal()}
        />
      </Container>
    </Loader>
  );
}

MultiFactorAuthenticationEdit.propTypes = {
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
};

export default withBrowser(withApi(withIntl(withNotifier(MultiFactorAuthenticationEdit))));
