import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import filesize from 'filesize';

import { Container, Row, Column } from '../Grid';
import Heading from '../Heading';
import { ActionBar } from '../ActionBar';
import {
  withApi,
  apiShape,
  withBackend,
  backendShape,
  notifierShape,
  withNotifier,
  withConfiguration,
} from '../../context';
import { Clue } from '../Clue';
import { intlShape } from '../../shapes';
import { Details, DetailsItem } from '../Details';
import { formatDateTime } from '../../utils';
import { iconForFileName } from '../../utils/fileUtils';
import Loader from '../Loader';
import Clickable from '../Clickable';
import Comments from './Comments';

import TicketAttachmentsForm from './TicketAttachmentsForm';

export function TicketDetails({
  api, backend, intl, notifier, match, configuration,
}) {
  const { id } = match.params;
  const [ticket, setTicket] = React.useState();
  const [attachments, setAttachments] = React.useState();
  const [comments, setComments] = React.useState();

  React.useEffect(() => {
    const loadData = async () => {
      try {
        const response = await backend.get(`/support/tickets/${id}`, { language: configuration.language });
        const responseComments = await backend.get(`/support/tickets/${id}/comments`);
        const responseAttachments = await backend.get(`/support/tickets/${id}/attachments`);
        setTicket(response);
        setAttachments(responseAttachments.attachments);
        setComments(responseComments.comments);
      } catch (error) {
        notifier.showError(api.translateError(error));
      }
    };

    loadData();
  }, [id]);

  const closedTicket = ticket?.status === 'closed';

  const downloadAttachment = async (attachmentId, name) => {
    const response = await backend.get(`/support/attachments/${attachmentId}/download`, {}, false, 'arraybuffer');
    const file = new Blob([response]);
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    /* istanbul ignore next */
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, name);
      return;
    }
    const url = window.URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = name;
    a.click();
  };

  const renderClickableAttachment = (attachment) => {
    const clickableName = (
      <Clickable className="left-clickable" onClick={() => { downloadAttachment(attachment.id, attachment.name); }}>
        {attachment.name}
      </Clickable>
    );
    return (
      <div key={attachment.id} className="list-item">
        <div className="list-item-icon">
          <span className={` icon ${iconForFileName(attachment.name)}`} />
        </div>
        <div className="list-item-content">
          <div id="ticket-attachment-header" className="list-item-header-only">
            {clickableName}
          </div>
          <div>
            {filesize(attachment.body_length)}
            {' - '}
            {formatDateTime(attachment.created_at, intl)}
            {' - '}
            {attachment.created_by}
          </div>
        </div>
      </div>
    );
  };

  const submitComment = async (values, actions) => {
    try {
      const response = await backend.post(`/support/tickets/${id}/comments`, values);
      notifier.showSuccess(intl.formatMessage({ id: 'support.ticket.create_comment.success_notification' }));
      setComments(comments.concat([{ ...{ ...response } }]));
      actions.resetForm({});
    } catch (error) {
      notifier.showError(api.translateError(error));
    } finally {
      actions.setSubmitting(false);
    }
  };

  const showNewAttachments = (newAttachments) => {
    setAttachments(attachments.concat(newAttachments));
  };

  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <Heading id="ticket-header" title={intl.formatMessage({ id: 'support.ticket.details.header' })} />
          </ActionBar>
          {ticket && (
            <Details>
              <DetailsItem id="ticket-number" translationKey="label.number" value={ticket.number} />
              <DetailsItem id="ticket-created-at" translationKey="label.created_at" value={formatDateTime(ticket.created_at, intl)} />
              <DetailsItem id="ticket-updated-at" translationKey="label.updated_at" value={formatDateTime(ticket.updated_at, intl)} />
              <DetailsItem id="ticket-status" translationKey="label.status" value={intl.formatMessage({ id: `support.status.${ticket.status}` })} />
              <DetailsItem id="ticket-subject" translationKey="label.subject" value={ticket.subject} />
              <DetailsItem id="ticket-description" translationKey="label.description" value={ticket.description} className="support-wrap" />
            </Details>
          )}
          {attachments && (
            <div>
              <ActionBar>
                <Heading level={2} id="ticket-attachments-header" title={intl.formatMessage({ id: 'support.ticket.attachments.header' })} />
              </ActionBar>
              {attachments.length > 0 ? (
                <div id="ticket-attachments-list" className="list space-after">
                  {attachments.map((attachment) => renderClickableAttachment(attachment))}
                </div>
              ) : (<Clue details={intl.formatMessage({ id: 'support.tickets.no_attachments' })} />)}
              {!closedTicket && (
                <TicketAttachmentsForm id="ticket-attachment-Form" ticketId={id} returnResponse={showNewAttachments} />
              )}
            </div>
          )}
          {ticket && (
          <Comments comments={comments} showCreateForm={!closedTicket} onSubmit={submitComment} />
          )}
          <Loader loading={!ticket || !attachments || !comments} />
        </Column>
      </Row>
    </Container>
  );
}

TicketDetails.propTypes = {
  api: apiShape,
  intl: intlShape,
  backend: backendShape,
  notifier: notifierShape,
  configuration: PropTypes.shape({ language: PropTypes.string.isRequired }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }),
};

export default
withApi(
  withNotifier(
    withBackend(
      withConfiguration(
        injectIntl(
          TicketDetails,
        ),
      ),
    ),
  ),
);
