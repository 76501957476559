import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Formik } from 'formik';
import { isEmpty } from 'lodash-es';
import isEmail from 'validator/lib/isEmail';
import PermissionEdit from './PermissionEdit';
import PermissionType from '../../constants/PermissionType';
import { createPermissionKey } from '../../utils/permissionUtils';
import { DeleteActionButton } from '../ActionBar';
import { Form, TextInput, SubmitButton } from '../Form';
import {
  usersPermissionsShape, userShape, intlShape,
} from '../../shapes';

function UserPermissionsEdit({
  permitableId, permitableType, onPermissionDelete, onDelete, onPermissionCreate, onAdd, intl, currentUser, usersPermissions, allowedPermissions, acceptConnectUsers,
}) {
  const renderUserPermissions = (userPermissions) => {
    // user should not edit his own permissions via UI says the PO ;)
    const disabledByUser = currentUser !== null && userPermissions.id === currentUser.id;
    const renderedPermissionItems = allowedPermissions.map((permissionType) => {
      const key = createPermissionKey(permitableType, permitableId, 'User', userPermissions.id, permissionType);
      const permissionId = userPermissions[permissionType.substring(4)];
      return (
        <PermissionEdit
          key={key}
          onCreate={onPermissionCreate}
          onDelete={onPermissionDelete}
          permissionId={permissionId}
          assignableId={userPermissions.id}
          assignableType="User"
          permissionType={permissionType}
          permitableId={permitableId}
          permitableType={permitableType}
          disabled={disabledByUser}
        />
      );
    });

    return (
      <div key={userPermissions.id} className="permission-group-item" id="permission-user-items">
        <div className="permission-assignee">
          <div>{userPermissions.name}</div>
          <span>{userPermissions.email}</span>
        </div>
        <div className="permission-items" id="permission-user-item">
          {renderedPermissionItems}
          <div className="permission-delete">
            <DeleteActionButton
              disabled={disabledByUser}
              modalTitle={intl.formatMessage({ id: 'edit_permissions.delete.modal_title' })}
              modalMessage={intl.formatMessage({ id: 'edit_permissions.delete.modal_message' }, { name: userPermissions.name })}
              onConfirm={() => onDelete(userPermissions.id, true)}
            />
          </div>
        </div>
      </div>
    );
  };

  const validConnectUser = (email) => acceptConnectUsers && email.split('@')[1] === 'connect';

  const validateForm = (values) => {
    const errors = {};

    if (isEmpty(values.email)) {
      errors.email = intl.formatMessage({ id: 'validation.email.mandatory' });
    } else if (!isEmail(values.email) && !validConnectUser(values.email)) {
      errors.email = intl.formatMessage({ id: 'validation.email.invalid' });
    }
    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;
    const userPermissionItems = usersPermissions.map((userPermission) => renderUserPermissions(userPermission));
    return (
      <div id="edit-user-permissions" className="permission-group-items">
        <h3><FormattedMessage id="edit_permissions.users" /></h3>
        {userPermissionItems}
        <Form {...formProps}>
          <TextInput
            {...formProps}
            hideLabel
            id="user-email"
            label={intl.formatMessage({ id: 'label.email' })}
            name="email"
          />
          <SubmitButton
            id="add-user-permissions"
            text={intl.formatMessage({ id: 'edit_permissions.add_user_button' })}
            fetching={isSubmitting}
          />
        </Form>
      </div>
    );
  };

  return (
    <Formik
      validate={validateForm}
      onSubmit={onAdd}
      render={renderForm}
    />
  );
}

UserPermissionsEdit.defaultProps = {
  acceptConnectUsers: false,
};

UserPermissionsEdit.propTypes = {
  allowedPermissions: PropTypes.arrayOf(PropTypes.oneOf([PermissionType.READ, PermissionType.UPDATE, PermissionType.DELETE, PermissionType.PERMIT])).isRequired,
  onPermissionCreate: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  onPermissionDelete: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  permitableId: PropTypes.number.isRequired,
  permitableType: PropTypes.string.isRequired,
  usersPermissions: usersPermissionsShape.isRequired,
  currentUser: userShape.isRequired,
  acceptConnectUsers: PropTypes.bool,
};

export default injectIntl(UserPermissionsEdit);
