export default function validateModbusConfig(intl, values) {
  if (values.modbusConfig && values.modbusConfig.length > 1) {
    const errors = [];
    values.modbusConfig.forEach((configItem, index) => {
      const mbConfigErrors = {};

      if (configItem.channel || configItem.regName || configItem.valueName) {
        if (configItem.valueName === '') {
          mbConfigErrors.valueName = intl.formatMessage({ id: 'validation.modbus.config.valuename.mandatory' });
        } else if (configItem.valueName.length > 122) {
          mbConfigErrors.valueName = intl.formatMessage({ id: 'validation.modbus.config.valuename.valuetolong' });
        } else if (!configItem.valueName.match(/^[0-9a-zA-Z ]{1,122}$/)) {
          mbConfigErrors.valueName = intl.formatMessage({ id: 'validation.modbus.config.valuename.invalidcharacter' });
        } else if (values.modbusConfig.filter((obj) => obj.valueName.toLowerCase() === configItem.valueName.toLowerCase() && obj.channel.name === configItem.channel.name).length > 1) {
          mbConfigErrors.valueName = intl.formatMessage({ id: 'validation.modbus.config.valuename.notunique' });
        }
        if (configItem.channel === '') {
          mbConfigErrors.channel = intl.formatMessage({ id: 'validation.modbus.config.channel.mandatory' });
        }
        if (configItem.regName === '') {
          mbConfigErrors.regName = intl.formatMessage({ id: 'validation.modbus.config.regname.mandatory' });
        } else if (values.modbusConfig.filter((obj) => obj.regName.name === configItem.regName.name).length > 1) {
          mbConfigErrors.regName = intl.formatMessage({ id: 'validation.modbus.config.regname.notunique' });
        }
      }

      if (Object.keys(mbConfigErrors).length) {
        errors[index] = mbConfigErrors;
      }
    });

    if (errors.length) {
      return errors;
    }
  }
  return undefined;
}
