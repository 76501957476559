import { parseDecimal } from '../utils/langUtils';

function extractInvoiceEstimateLineItem(lineItem) {
  return {
    description: lineItem.description,
    quantity: lineItem.quantity,
    amount: parseDecimal(lineItem.amount),
    type: lineItem.type,
  };
}

function extractInvoiceEstimateTaxLineItem(lineItem) {
  return {
    description: lineItem.description,
    amount: parseDecimal(lineItem.amount),
  };
}

export function extractInvoiceEstimate(invoiceEstimate) {
  return {
    lineItems: invoiceEstimate.line_items ? invoiceEstimate.line_items.map((lineItem) => extractInvoiceEstimateLineItem(lineItem)) : [],
    taxes: invoiceEstimate.taxes ? invoiceEstimate.taxes.map((taxItem) => extractInvoiceEstimateTaxLineItem(taxItem)) : [],
    currency: invoiceEstimate.currency,
    total: parseDecimal(invoiceEstimate.total),
    totalDiscount: parseDecimal(invoiceEstimate.total_discount),
    discountDescription: invoiceEstimate.discount_description,
    totalTaxes: parseDecimal(invoiceEstimate.total_taxes),
    credits: parseDecimal(invoiceEstimate.credits),
    totalDue: parseDecimal(invoiceEstimate.total_due),
    subTotal: parseDecimal(invoiceEstimate.sub_total),
  };
}
