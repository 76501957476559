import PropTypes from 'prop-types';
import moment from 'moment';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { intlShape } from '../../shapes';

import { Details, DetailsItem } from '../Details';
import { Clue } from '../Clue';
import {
  redirectTo, displayUserName, isNumber,
} from '../../utils';
import { cardPaymentDetailsShape } from '../../shapes/cardPaymentDetailsShape';
import { invoicePaymentDetailsShape } from '../../shapes/invoicePaymentDetailsShape';
import SubscriptionPaymentEditModal from './SubscriptionPaymentEditModal';
import ConfirmationModal from '../ConfirmationModal';

const CARD_NAMES = {
  visa: 'Visa',
  mastercard: 'MasterCard',
  american_express: 'American Express',
  discover: 'Discover',
  jcb: 'JCB',
  diners_club: 'Diner\'s Club',
};

class SubscriptionPaymentDetails extends Component {
  static propTypes = {
    cardPaymentDetails: cardPaymentDetailsShape,
    invoicePaymentDetails: invoicePaymentDetailsShape,
    source: PropTypes.string.isRequired,
    assetId: PropTypes.number,
    intl: intlShape.isRequired,
    onChangeToPaymentPerInvoice: PropTypes.func,
    canChangeToInvoice: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.onPaymentEditAccept = this.onPaymentEditAccept.bind(this);
    this.onPaymentEditDecline = this.onPaymentEditDecline.bind(this);
    this.onPaymentUpdateToInvoice = this.onPaymentUpdateToInvoice.bind(this);
    this.onChangeToPaymentPerInvoiceAccept = this.onChangeToPaymentPerInvoiceAccept.bind(this);
    this.onChangeToPaymentPerInvoiceDecline = this.onChangeToPaymentPerInvoiceDecline.bind(this);
    this.state = {
      showPaymentEditModal: false,
      showUpdatePaymentModal: false,
    };
  }

  onChangeToPaymentPerInvoiceAccept() {
    this.setState({ showUpdatePaymentModal: false });
    this.props.onChangeToPaymentPerInvoice();
  }

  onChangeToPaymentPerInvoiceDecline() {
    this.setState({ showUpdatePaymentModal: false });
  }

  onPaymentUpdateToInvoice() {
    this.setState({ showPaymentEditModal: false, showUpdatePaymentModal: true });
  }

  onPaymentEditDecline() {
    this.setState({ showPaymentEditModal: false });
  }

  onPaymentEditAccept() {
    const { source, assetId } = this.props;
    const assetIdSuffix = isNumber(assetId) ? `/${assetId}` : '';
    this.setState({ showPaymentEditModal: false });
    redirectTo(`/payment/update/${source}${assetIdSuffix}`);
  }

  render() {
    const {
      cardPaymentDetails, intl, invoicePaymentDetails, canChangeToInvoice,
    } = this.props;
    const { showPaymentEditModal, showUpdatePaymentModal } = this.state;
    if (invoicePaymentDetails) {
      return (
        <Details>
          <DetailsItem
            intl={intl}
            id="payment-details-payment-method-per-invoice"
            translationKey="subscription.payment_method.header"
            value={intl.formatMessage({ id: 'subscription.payment_method.invoice' })}
          />
        </Details>
      );
    }
    if (cardPaymentDetails && cardPaymentDetails.valid) {
      const card = intl.formatMessage({ id: 'payment_details.card' }, {
        name: CARD_NAMES[cardPaymentDetails.type],
        ending: cardPaymentDetails.identifier,
      });
      const validUntil = moment(`${cardPaymentDetails.expiryYear}-${cardPaymentDetails.expiryMonth}-01`, 'YYYY-MM-DD')
        .format('MM/YYYY');
      return (
        <Details>
          <DetailsItem
            intl={intl}
            id="payment-details-payment-method-per-credit-card"
            translationKey="subscription.payment_method.header"
            value={intl.formatMessage({ id: 'subscription.payment_method.credit_card' })}
          />
          <DetailsItem
            intl={intl}
            id="payment-details-card-holder"
            translationKey="label.card_holder"
            value={displayUserName(cardPaymentDetails)}
          />
          <DetailsItem intl={intl} id="payment-details-card" translationKey="label.credit_card" value={card} />
          <DetailsItem intl={intl} id="payment-details-card-expiry" translationKey="label.valid_until" value={validUntil} />
        </Details>
      );
    }

    const modalChangeToInvoice = (
      <ConfirmationModal
        id="modal-dialog-invoice"
        show={showUpdatePaymentModal}
        titleText={intl.formatMessage({ id: 'payment_details_edit.change_credit_to_invoice' })}
        messageText={intl.formatMessage({ id: 'payment_details_edit.change_to_invoice_description' })}
        onConfirm={this.onChangeToPaymentPerInvoiceAccept}
        onClose={this.onChangeToPaymentPerInvoiceDecline}
        intl={intl}
      />
    );

    const paymentEditModal = showPaymentEditModal ? (
      <SubscriptionPaymentEditModal
        onAccept={this.onPaymentEditAccept}
        onDecline={this.onPaymentEditDecline}
        onChange={this.onPaymentUpdateToInvoice}
        isCredit={cardPaymentDetails !== null}
        canChangeToInvoice={canChangeToInvoice}
      />
    )
      : null;
    return (
      <div>
        {paymentEditModal}
        {modalChangeToInvoice}
        <Clue
          id="payment-invalid-clue"
          title={intl.formatMessage({ id: 'payment_details.invalid.header' })}
          details={intl.formatMessage({ id: 'payment_details.invalid.description' })}
          action={intl.formatMessage({ id: 'payment_details.invalid.button' })}
          onClick={() => this.setState({ showPaymentEditModal: true })}
          highlighted
        />
      </div>
    );
  }
}

export default injectIntl(SubscriptionPaymentDetails);
