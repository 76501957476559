import { FormattedMessage, injectIntl } from 'react-intl';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ActionBar,
  apiErrorsContain,
  bindRef,
  CancelButton,
  handleUnknownErrors,
  intlShape,
  isNotFoundError,
  Loader,
  navigateTo, PermissionsEdit,
  showSuccess,
  SubmitButton,
  PermissionType,
} from 'lcm-iot-commons';

import ClientApplicationForm from '../ClientApplications/ClientApplicationForm';
import { loadClientApplication, patchClientApplication, loadConnectSubscription } from '../../api';

export class ConnectSubscriptionEdit extends Component {
  constructor() {
    super();
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.bindClientApplicationForm = bindRef(this, 'clientApplicationForm');
    this.state = {
      loading: true,
      submitting: false,
    };
  }

  componentDidMount() {
    const { intl } = this.props;
    this.loadData()
      .catch((apiErrors) => {
        this.setState({ loading: false });
        if (isNotFoundError(apiErrors)) {
          navigateTo('/404');
        } else {
          handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
        }
      });
  }

  async loadData() {
    const { match } = this.props;
    const subscription = await loadConnectSubscription(match.params.id);
    const clientApplication = await loadClientApplication(subscription.clientApplicationId);

    this.setState({ clientApplication, loading: false });
  }

  async handleOnSubmit(event) {
    const { match, intl } = this.props;
    const { clientApplication } = this.state;
    if (event) {
      event.preventDefault();
    }

    if (this.clientApplicationForm.submit()) {
      this.setState({ submitting: true });
      if (this.clientApplicationForm.form.dirty) {
        patchClientApplication(clientApplication.id, this.clientApplicationForm.values()).then(() => {
          showSuccess(intl.formatMessage({ id: 'subscription_edit.success_notification' }));
          navigateTo(`/subscriptions/connect/${match.params.id}`);
        }).catch((apiErrors) => {
          this.setState({ submitting: false });
          if (apiErrorsContain(apiErrors, 'taken')) {
            this.clientApplicationForm.setErrors({ name: intl.formatMessage({ id: 'api.error.client_application.taken' }) });
          } else if (apiErrorsContain(apiErrors, 'invalid_input', 'redirect_uris')) {
            this.clientApplicationForm.setErrors({ redirectURIs: intl.formatMessage({ id: 'api.error.redirect_uri.invalid_input' }) });
          } else {
            handleUnknownErrors(apiErrors, intl.formatMessage({ id: 'api.error.unknown' }));
          }
          this.clientApplicationForm.setSubmitting(false);
        });
      } else {
        showSuccess(intl.formatMessage({ id: 'subscription_edit.success_notification' }));
        navigateTo(`/subscriptions/connect/${match.params.id}`);
      }
    } else {
      this.clientApplicationForm.setSubmitting(false);
    }
  }

  render() {
    const { intl } = this.props;
    const { clientApplication, loading, submitting } = this.state;
    return (
      <div id="connect-subscription-edit" className="container">
        <div className="row">
          <div className="col-md-7">
            { clientApplication ? (
              <div>
                <ActionBar>
                  <h1 id="subscription-client-application-header"><FormattedMessage id="subscription_create.application_title" /></h1>
                </ActionBar>
                <ClientApplicationForm ref={this.bindClientApplicationForm} initialValues={clientApplication} onSubmit={this.handleOnSubmit} />
                <div className="btn-group">
                  <SubmitButton id="edit-subscription-submit" text={intl.formatMessage({ id: 'button.submit' })} onClick={this.handleOnSubmit} fetching={submitting} />
                  <CancelButton id="edit-subscription-cancel" disabled={submitting} />
                </div>
              </div>
            ) : null}
            <Loader loading={loading} />
          </div>
        </div>
        { clientApplication ? (
          <PermissionsEdit
            id="edit-permissions"
            allowedPermissions={[PermissionType.READ, PermissionType.UPDATE, PermissionType.PERMIT]}
            permitableType="ClientApplication"
            permitableId={clientApplication.id}
            targetOnDeleteOwnReadPermission="/subscriptions/connect"
          />
        ) : null }
      </div>
    );
  }
}

ConnectSubscriptionEdit.propTypes = {
  intl: intlShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string, checkout: PropTypes.string }),
  }).isRequired,
};

export default injectIntl(ConnectSubscriptionEdit);
