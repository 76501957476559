/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Formik } from 'formik';

import {
  intlShape,
  apiShape,
  withApi,
  SwitchInput,
  Details,
  notifierShape,
  withNotifier,
  withIntl,
  ActionBar,
  htmlFormat,
  htmlLink,
  withBrowser, browserShape,
  withFlipper, userShape, withUser, ContextualHelp,
} from 'lcm-iot-commons';
import { FormattedMessage } from 'react-intl';

import CONFIGURATION from 'lcm-iot-commons/client/lib/configuration';

export function DemoDataHandler({
  notifier, api, intl, flipper, user, browser,
}) {
  if (!user || !flipper.demoDataActive) return null;
  const [activated, setActivation] = useState(user.demoDataActive);
  const [switchDisabled, setSwitchDisabled] = useState(false);

  const saveDemoDataActivationState = async () => {
    setSwitchDisabled(true);
    try {
      if (activated) {
        await api.delete('/users/current/demo_data');
        notifier.showSuccess(intl.formatMessage({ id: 'user_profile.demo_data_deactivation_success_notification' }));
      } else {
        await api.post('/users/current/demo_data');
        notifier.showSuccess(intl.formatMessage({ id: 'user_profile.demo_data_activation_success_notification' }));
      }
      user.updateDemoDataStatus(!user.demoDataActive);
      setActivation(!user.demoDataActive);
      setTimeout(() => {
        browser.redirectToExtern(`${CONFIGURATION.BASE_URL}`);
      }, 2000);
    } catch (apiErrors) {
      notifier.showError(api.translateError(apiErrors));
    } finally {
      setSwitchDisabled(false);
    }
  };

  const renderForm = (formProps) => (
    <>
      <ActionBar>
        <h2 id="user-profile-demo-data">
          <FormattedMessage id="user_profile.demo_data" />
          <ContextualHelp title={intl.formatMessage({ id: 'user_profile.demo_data' })} interactive>
            <p>
              <FormattedMessage
                id="user_profile.demo_data.description"
                values={{
                  ...htmlFormat,
                  a: htmlLink({ href: CONFIGURATION.HELP_URL_DEMODATA, target: '_blank' }),
                }}
              />
            </p>
          </ContextualHelp>
        </h2>
      </ActionBar>
      <Details>
        <form id="demo-data-form" noValidate>
          <SwitchInput
            {...formProps}
            id="demo-data-switch-input"
            values={{ 'demo-data-switch-input': activated }}
            handleChange={saveDemoDataActivationState}
            name="demo-data-switch-input"
            disabled={switchDisabled}
          />
        </form>
      </Details>
    </>
  );

  return (
    <Formik
      id="demo-data-formik"
      render={renderForm}
    />
  );
}

DemoDataHandler.propTypes = {
  intl: intlShape.isRequired,
  user: userShape.isRequired,
  browser: browserShape.isRequired,
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
  flipper: PropTypes.shape({
    demoDataActive: PropTypes.bool,
  }).isRequired,
};

export default withBrowser(withNotifier(withUser(withFlipper(withApi(withIntl(DemoDataHandler))))));
