import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import 'moment/locale/de';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import { InputGroup } from './Form';
import { intlShape } from '../shapes';
import { handleFormikValueChange } from '../utils/formikUtils';

function DateTimeInput(props) {
  moment.locale(global.configuration.locale);
  momentLocalizer(moment);

  const {
    intl,
    defaultValue,
    showSelectTime,
    showSelectDate,
    timeSelectStep,
    minDate,
    values,
    name,
    disabled,
    isSubmitting,
    format = intl.formatMessage({ id: 'placeholder.datetime_format' }),
    placeholder = intl.formatMessage({ id: 'placeholder.datetime_format' }),
  } = props;

  const dateTimePicker = (
    <DateTimePicker
      className="date-time-input"
      date={showSelectDate || false}
      time={showSelectTime || false}
      editFormat={format}
      format={format}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={((value) => handleFormikValueChange(props, value))}
      value={values[name]}
      data-testid={name}
      step={timeSelectStep || 10}
      min={minDate || (showSelectDate && new Date())}
      disabled={disabled || isSubmitting}
    />
  );

  return (
    <InputGroup {...props}>
      {dateTimePicker}
    </InputGroup>
  );
}

DateTimeInput.propTypes = {
  showSelectTime: PropTypes.bool,
  showSelectDate: PropTypes.bool,
  editFormat: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  values: PropTypes.instanceOf(Object),
  intl: intlShape,
  timeSelectStep: PropTypes.number,
  isSubmitting: PropTypes.bool,
  format: PropTypes.string,
  placeholder: PropTypes.string,
};

export default injectIntl(DateTimeInput);
