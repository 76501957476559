import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import NotFoundError from '../api/errors/NotFoundError';
import { withNotifier, notifierShape } from './NotifierContext';
import { withBackend, backendShape } from './BackendContext';
import { withConfiguration } from './ConfigurationContext';
import { withSession } from './SessionContext';
import { sessionShape } from '../shapes';

const SubscriptionContext = React.createContext();

const subscriptionShape = PropTypes.shape({
  plan_variant: PropTypes.string,
  refresh: PropTypes.func,
  subscriptionLoaded: PropTypes.bool,
});

const removeBaseUrl = (urls, baseUrl) => urls.map((url) => {
  if (url.indexOf(baseUrl) === 0) {
    return url.substring(baseUrl.length);
  }
  return url;
});

function InternalSubscriptionContextProvider({
  backend,
  children,
  configuration,
  enforceSubscription,
  history,
  location,
  notifier,
  session,
  subscriptionEndpoints,
  unsecuredEndpoints,
  testSubscription,
}) {
  const [subscription, setSubscription] = useState();

  const doEnforceSubscription = () => {
    const relevantLocationUrl = location.pathname.substring(configuration.baseUrl.length);
    if (session.authenticated
         && !session.activeSubscription
         && enforceSubscription
         && !location.pathname.match(/\/subscriptions\/[0-9]+/) // special handling to access subscription via permissions
         && removeBaseUrl(subscriptionEndpoints, configuration.baseUrl).indexOf(relevantLocationUrl) < 0
         && removeBaseUrl(unsecuredEndpoints, configuration.baseUrl).indexOf(relevantLocationUrl) < 0) {
      history.push(`${configuration.baseUrl}/plan_variants`);
    }
  };

  const loadSubscription = async () => {
    try {
      const response = await backend.get('/subscription', true);
      doEnforceSubscription();
      setSubscription(response);
    } catch (error) {
      if (!(error instanceof NotFoundError)) {
        notifier.showError(backend.translateError(error));
      }
      doEnforceSubscription();
      setSubscription({});
    }
  };

  useEffect(() => {
    if (session.authenticated) {
      if (!subscription) {
        loadSubscription();
      } else {
        doEnforceSubscription();
      }
    }
  }, [location, session.authenticated]);

  const context = useMemo(() => {
    if (testSubscription) {
      return {
        subscription: {
          ...testSubscription,
          refresh: () => true,
          hasAddon: (addon) => !!testSubscription.addons?.find((a) => a.id.indexOf(addon) >= 0),
          subscriptionLoaded: true,
        },
      };
    }

    return subscription ? {
      subscription: {
        ...subscription,
        refresh: loadSubscription,
        hasAddon: (addon) => !!subscription.addons?.find((a) => a.id.indexOf(addon) >= 0),
        subscriptionLoaded: true,
      },
    } : {
      subscription: {
        hasAddon: () => false,
        refresh: loadSubscription,
        subscriptionLoaded: false,
      },
    };
  }, [subscription]);

  return (<SubscriptionContext.Provider value={context}>{children}</SubscriptionContext.Provider>);
}

InternalSubscriptionContextProvider.propTypes = {
  notifier: notifierShape.isRequired,
  backend: backendShape.isRequired,
  children: PropTypes.node,
  session: sessionShape.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  configuration: PropTypes.shape({ baseUrl: PropTypes.string }),
  enforceSubscription: PropTypes.bool,
  subscriptionEndpoints: PropTypes.arrayOf(PropTypes.string),
  unsecuredEndpoints: PropTypes.arrayOf(PropTypes.string),
  testSubscription: PropTypes.shape({
    id: PropTypes.number,
    customer_purchase_order: PropTypes.string,
    external_reference: PropTypes.string,
    external_plan_variant_reference: PropTypes.string,
    display_name: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string,
    asset_quota: PropTypes.number,
    seat_quota: PropTypes.number,
    storage_quota: PropTypes.number,
    addons: PropTypes.arrayOf(PropTypes.shape({})),
    billing_address: PropTypes.shape({}),
    shipping_address: PropTypes.shape({}),
    usage: PropTypes.shape({}),
  }),
};

InternalSubscriptionContextProvider.defaultProps = {
  subscriptionEndpoints: [
    '/plan_variants',
    '/reseller/subscriptions/create',
    '/subscription',
    '/subscription/create',
    '/subscription/enterprise-quotation-request/success',
    '/subscription/invoice/success',
    '/subscription/overview',
    '/subscriptions',
  ],
  unsecuredEndpoints: [
    '/403',
    '/404',
    '/500',
    '/session_expired',
  ],
  enforceSubscription: false,
  testSubscription: undefined,
};

const SubscriptionContextProvider = withConfiguration(withRouter(withSession(withBackend(withNotifier(InternalSubscriptionContextProvider)))));

const withSubscription = (ChildComponent) => {
  function ConnectedComponent(props) {
    return (
      <SubscriptionContext.Consumer>
        {(context) => (<ChildComponent {...props} subscription={context.subscription} />)}
      </SubscriptionContext.Consumer>
    );
  }
  ConnectedComponent.displayName = ChildComponent.displayName || ChildComponent.name;
  return ConnectedComponent;
};

export {
  SubscriptionContextProvider, withSubscription, subscriptionShape,
};
