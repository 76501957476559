import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { htmlLink } from '../../utils';
import { ContextualNotification } from '../Contextuals';

export default function AssetConnectivityIssuesNotification({ type, hoursAfterLatestUpdateToExpire }) {
  const intl = useIntl();
  return (
    <ContextualNotification title={intl.formatMessage({ id: `asset-connectivity-issues-${type}-info.title` })} interactive>
      <div className="contextual-info">
        <p>
          {
          intl.formatMessage(
            { id: `asset-connectivity-issues-${type}-info.explanation` },
            {
              hours: hoursAfterLatestUpdateToExpire,
              a: htmlLink({ href: 'https://help.netilion.endress.com/hc/en-us/articles/11726912300828-Troubleshooting-Asset-or-Edge-Device-offline', target: '_blank' }),
            },
          )
        }
        </p>
      </div>
    </ContextualNotification>
  );
}

AssetConnectivityIssuesNotification.propTypes = {
  type: PropTypes.string.isRequired, // can be either latest-value or last-seen
  hoursAfterLatestUpdateToExpire: PropTypes.number,
};
