import React, { useMemo, forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash-es';
import CONFIGURATION from '../configuration';

const ConfigurationContext = React.createContext();
require('../../app/startup/configure');

const staticConfiguration = () => {
  const config = {};
  Object.keys(CONFIGURATION).forEach((key) => {
    config[camelCase(key)] = CONFIGURATION[key];
  });
  return config;
};

function ConfigurationContextProvider({ children, configuration = {}, testConfiguration }) {
  const context = useMemo(() => ({
    configuration: { ...staticConfiguration(), ...configuration, ...testConfiguration },
  }), [configuration, testConfiguration]);
  return (
    <ConfigurationContext.Provider value={context}>
      {children}
    </ConfigurationContext.Provider>
  );
}

ConfigurationContextProvider.propTypes = {
  configuration: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  testConfiguration: PropTypes.shape({}),
};

const withConfiguration = (ChildComponent) => {
  const WrappedComponent = forwardRef((props, ref) => (
    <ConfigurationContext.Consumer>
      {(context = {}) => (
        <ChildComponent
          {...props}
          ref={ref}
          configuration={context.configuration}
        />
      )}
    </ConfigurationContext.Consumer>
  ));

  WrappedComponent.displayName = ChildComponent.displayName || ChildComponent.name;
  return WrappedComponent;
};

const useConfiguration = () => useContext(ConfigurationContext).configuration;

export {
  ConfigurationContextProvider,
  withConfiguration,
  useConfiguration,
};
