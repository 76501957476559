import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { MenuItem } from 'react-bootstrap';
import ConfirmationModal from 'lcm-iot-commons/client/lib/components/ConfirmationModal';
import {
  intlShape,
  ItemMenu,
  notifierShape,
  withNotifier,
  withBackend,
  withApi,
  backendShape,
} from 'lcm-iot-commons';
import OpcUaServerCertificateStatus from '../../constants/OpcUaServerCertificateStatus';
import { opcUaServerCertificateIconClassName, opcUaServerCertificateStatusClassName } from '../../utils/statusUtils';
import { opcUaServerCertificateShape } from '../../shapes/opcUaServerCertificatesShape';
import { accessRightsShape } from '../../shapes/accessRightsShape';
import { opcUaServerShape } from '../../shapes/opcUaServersShape';

export function OpcUaServerCertificateItem({
  intl,
  onCertificateRemoved,
  onCertificateStatusChanged,
  opcUaServer,
  certificate,
  notifier,
  accessRights,
  backend,
  type,
}) {
  const [deleting, setDeleting] = useState(false);

  const onCloseModal = () => {
    setDeleting(false);
  };

  const onDeleteConfirmationModal = async () => {
    setDeleting(false);
    try {
      await backend.delete(`/api_subscriptions/${opcUaServer.subscription_id}/opc_ua_servers/${opcUaServer.id}/${type}_certificates/${certificate.id}`);
      onCertificateRemoved(certificate);
      notifier.showSuccess(intl.formatMessage({ id: `${type}_certificate.delete.notification` }));
    } catch (error) {
      notifier.showError(backend.translateError(error));
    }
  };

  const handleOnTrustOrRejectClick = async () => {
    const newStatus = certificate.status === 'trusted' ? 'rejected' : 'trusted';
    try {
      await backend.patch(`/api_subscriptions/${opcUaServer.subscription_id}/opc_ua_servers/${opcUaServer.id}/client_certificates/${certificate.id}`, { status: newStatus });
      onCertificateStatusChanged();
      notifier.showSuccess(intl.formatMessage({ id: `client_certificate.${newStatus}.notification` }));
    } catch (error) {
      notifier.showError(backend.translateError(error));
    }
  };

  const handleOnDeleteClick = () => {
    setDeleting(true);
  };

  const deleteConfirmationModal = deleting ? (
    <ConfirmationModal
      id="delete-modal"
      intl={intl}
      show
      titleText={intl.formatMessage({ id: `${type}_certificate_delete.actions.modal_title` })}
      messageText={intl.formatMessage({ id: `${type}_certificate_delete.actions.modal_message` }, { certificate: certificate.file_name })}
      onConfirm={onDeleteConfirmationModal}
      onClose={onCloseModal}
    />
  ) : null;

  const detail = type === 'client' ? (
    <div className="list-item-details">
      <span id="list-item-details-icon-icon" className={opcUaServerCertificateStatusClassName(certificate.status)} />
      <span id="list-item-details-icon-description">{intl.formatMessage({ id: `enum.opc_ua_server.certificate.${certificate.status}` })}</span>
    </div>
  ) : null;

  return (
    <li id={`item-${certificate.id}`} className="list-item">
      <div className="list-item-image">
        <div className="details-item-value">
          <span className={opcUaServerCertificateIconClassName()} />
        </div>
      </div>
      <div className="list-item-content">
        <div className="list-item-header">{certificate.file_name}</div>
        {detail}
      </div>
      <div className="list-item-actions">
        <ItemMenu>
          {type === 'client' ? (

            <MenuItem
              id="item-trust"
              onSelect={handleOnTrustOrRejectClick}
              disabled={!accessRights.canUpdate || certificate.status === OpcUaServerCertificateStatus.TRUSTED}
            >
              <FormattedMessage id="button.trust" />
            </MenuItem>
          ) : null }
          {type === 'client' ? (
            <MenuItem
              id="item-reject"
              onSelect={handleOnTrustOrRejectClick}
              disabled={!accessRights.canUpdate || certificate.status === OpcUaServerCertificateStatus.REJECTED}
            >
              <FormattedMessage id="button.reject" />
            </MenuItem>
          ) : null }
          <MenuItem id="item-delete" onSelect={handleOnDeleteClick} disabled={!accessRights.canUpdate}><FormattedMessage id="button.delete" /></MenuItem>
        </ItemMenu>
      </div>
      {deleteConfirmationModal}
    </li>
  );
}

OpcUaServerCertificateItem.propTypes = {
  intl: intlShape.isRequired,
  onCertificateRemoved: PropTypes.func,
  onCertificateStatusChanged: PropTypes.func,
  opcUaServer: opcUaServerShape.isRequired,
  certificate: opcUaServerCertificateShape.isRequired,
  notifier: notifierShape.isRequired,
  backend: backendShape.isRequired,
  accessRights: accessRightsShape.isRequired,
  type: PropTypes.oneOf(['ca', 'client']),
};

OpcUaServerCertificateItem.defaultProps = {
  onCertificateRemoved: undefined,
  onCertificateStatusChanged: undefined,
  type: 'client',
};

export default withBackend(withApi(withNotifier(injectIntl(OpcUaServerCertificateItem))));
