import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  intlShape,
  withIntl,
} from 'lcm-iot-commons';

export function FieldGatewayGenericModbusSerialNumberSubAddressItem({
  itemSerialNumber, itemSubAddress, intl,
}) {
  const { t } = intl;
  return (
    <Row>
      <div className="col-lg-3"><b>{t`field_gateway.modbus.config.item.serial_number_subaddress`}</b></div>
      <div className="col-lg-6">{`${itemSerialNumber}, ${itemSubAddress}`}</div>
    </Row>
  );
}

FieldGatewayGenericModbusSerialNumberSubAddressItem.propTypes = {
  itemSerialNumber: PropTypes.string.isRequired,
  itemSubAddress: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
};

export default withIntl(FieldGatewayGenericModbusSerialNumberSubAddressItem);
