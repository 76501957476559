import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function Clickable({
  target, onClick, children, className,
}) {
  if (target && onClick) {
    const handleOnClick = (event) => {
      event.preventDefault();
      onClick(event);
    };
    return (<Link className={`clickable ${className}`} to={target} onClick={handleOnClick}>{children}</Link>);
  }
  if (target) {
    return (
      <Link className={`clickable ${className}`} to={target}>{children}</Link>
    );
  }
  if (onClick) {
    return (<button className={`clickable ${className}`} type="button" onClick={onClick}>{children}</button>);
  }
  return (<div className="clickable">{children}</div>);
}

Clickable.propTypes = {
  target: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

Clickable.defaultProps = {
  className: '',
  target: undefined,
  onClick: undefined,
  children: undefined,
};

export default Clickable;
