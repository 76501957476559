import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Modal } from 'react-bootstrap';
import {
  Button, ButtonGroup,
} from '../../Form';
import { useApi } from '../../../hooks';
import { assetShape, nodeShape, orderShape } from '../../../shapes';
import DocumentExportAllOrMultipleSelect from './DocumentExportAllOrMultipleSelect';
import { DocumentLanguages } from '../../../constants/DocumentLanguages';

function LanguagesSelectionStep({
  exportType, object, handleNext, handleBack, setStepsState, stepsState,
}) {
  const intl = useIntl();
  const api = useApi();
  const allLanguages = DocumentLanguages.map(({ code, translationKey }) => {
    const translation = intl.formatMessage({ id: translationKey });
    return { code, name: translation, id: code };
  }).sort((a, b) => a.name.localeCompare(b.name));

  const topLanguages = [];
  topLanguages.push(allLanguages.find((l) => l.code === intl.locale));
  if (!intl.locale.startsWith('en')) topLanguages.push(allLanguages.find((l) => l.code === 'en'));

  const { mutate: createExport, isLoading } = api.post.useMutation('/users/current/data_exports');

  const handleConfirmClick = () => {
    const selectedLanguages = stepsState.languagesSelectionStep.selectedItems;
    const languageFilter = selectedLanguages?.length ? { language: selectedLanguages.join(',') } : {};
    const selectedCategories = stepsState.categoriesSelectionStep.selectedItems;
    const categoryFilter = selectedCategories?.length ? { document_category_id: selectedCategories.map((c) => (c !== 'null' ? `${c}+` : c)).join(',') } : {};
    const getObject = (type) => {
      if (type.asset) {
        return { asset: { id: object.asset.id } };
      }
      if (type.node) {
        return { node: { id: object.node.id } };
      }
      return { purchase_order: { id: object.order.id } };
    };

    const objectLink = getObject(object);
    createExport({
      type: exportType, ...objectLink, ...languageFilter, ...categoryFilter,
    }, { onSuccess: handleNext });
  };

  const setLanguagesState = (newState) => setStepsState({ ...stepsState, languagesSelectionStep: newState });

  return (
    <>
      <Modal.Body>
        <DocumentExportAllOrMultipleSelect
          title={intl.formatMessage({ id: 'export_files_dialog.file_languages.title' })}
          topItemsLabel={intl.formatMessage({ id: 'export_files_dialog.file_languages.top_items_label' })}
          moreItemsLabel={intl.formatMessage({ id: 'export_files_dialog.file_languages.more_items_label' })}
          itemKey="languages"
          allItems={allLanguages}
          topItems={topLanguages}
          parentState={stepsState.languagesSelectionStep}
          setParentState={setLanguagesState}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button
            text={intl.formatMessage({ id: 'button.back' })}
            onClick={handleBack}
          />
          <Button
            hero
            text={intl.formatMessage({ id: 'button.export' })}
            fetching={isLoading}
            onClick={handleConfirmClick}
            disabled={stepsState.languagesSelectionStep.selectedItems.length === 0 && stepsState.languagesSelectionStep.selectedSwitch === 'specific_selection'}
          />
        </ButtonGroup>
      </Modal.Footer>
    </>
  );
}

LanguagesSelectionStep.propTypes = {
  stepsState: PropTypes.shape({
    categoriesSelectionStep: PropTypes.shape({
      selectedSwitch: PropTypes.string,
      selectedItems: PropTypes.arrayOf(PropTypes.string),
    }),
    languagesSelectionStep: PropTypes.shape({
      selectedSwitch: PropTypes.string,
      selectedItems: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  setStepsState: PropTypes.func.isRequired,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  exportType: PropTypes.string.isRequired,
  object: PropTypes.oneOfType([PropTypes.shape({ asset: assetShape }), PropTypes.shape({ node: nodeShape }), PropTypes.shape({ order: orderShape })]),
};

export default LanguagesSelectionStep;
