import React from 'react';
import { assetShape, intlShape } from '../../shapes';
import BreadcrumbItem from '../Breadcrumb/BreadcrumbItem';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { withIntl } from '../../wrappers';
import { url } from '../../utils';

function AssetBreadcrumb({ intl, asset }) {
  const breadcrumbItems = [
    { name: intl.formatMessage({ id: 'navigation.all_objects' }), id: 'nodes', url: '/nodes' },
    { name: asset?.parent?.serial_number, id: asset?.parent?.id, url: `/assets/${asset?.parent?.id}` },
    { name: asset?.serial_number, id: asset?.id, url: `/assets/${asset?.id}` },
  ].filter((item) => item.name);

  breadcrumbItems[breadcrumbItems.length - 1].url = undefined;

  return (
    <Breadcrumb>
      { breadcrumbItems.map((item) => <BreadcrumbItem key={item.id} title={item.name} target={item.url && url(item.url)} />) }
    </Breadcrumb>
  );
}

AssetBreadcrumb.propTypes = {
  intl: intlShape.isRequired,
  asset: assetShape,
};

export default withIntl(AssetBreadcrumb);
