import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import List from '../List/List';
import {
  apiShape,
  backendShape,
  notifierShape,
  subscriptionShape,
  withApi,
  withBackend,
  withNotifier,
  withSubscription,
} from '../../context';
import { accessRightsShape, intlShape } from '../../shapes';
import { extractSystems } from '../../extractors';

import { apiErrorsContain } from '../../api';
import { withIntl } from '../../wrappers';
import AssignSystemForm from './AssignSystemForm';
import SystemItem from './SystemItem';

export function AssignedSystems({
  api,
  intl,
  object,
  onlyOne,
  backend,
  notifier,
  headerName,
  onAssigned,
  subscription,
  accessRights,
}) {
  const objectId = object?.id;
  const [objectsType, setObjectsType] = useState(null);
  const [fetching, setFetching] = useState(true);
  const [systems, setSystems] = useState([]);

  React.useEffect(() => {
    setFetching(true);
    const loadAssignedSystems = async () => {
      try {
        const type = `${object.itemType}s`;
        setObjectsType(type);
        const rawSystems = await api.getAll(`/${type}/${object.id}/systems`, {
          include: 'type,status',
          order_by: 'name',
        });
        setSystems(extractSystems(rawSystems));
      } catch (error) {
        notifier.showError(api.translateError(error));
      } finally {
        setFetching(false);
      }
    };
    if (object) {
      loadAssignedSystems();
    }
  }, [objectId]);

  const handleOnSystemUnassign = async (system) => {
    const newSystems = systems.filter((s) => s.id !== system.id);
    setSystems(newSystems);
    onAssigned(false);
  };

  const renderAssignedSystems = () => {
    if (systems.length === 0) {
      return <FormattedMessage id="system_list.no_systems_assign" />;
    }
    return (
      <List id="systems-list">
        {systems.map((system) => (
          <SystemItem
            key={system.id}
            system={system}
            showItemMenu
            parentAsset={object.itemType === 'asset' ? object : null}
            parentInstrumentation={object.itemType === 'instrumentation' ? object : null}
            parentAccessRights={accessRights}
            onSystemRemoved={handleOnSystemUnassign}
          />
        ))}
      </List>
    );
  };
  const handleOnSubmit = async (values, actions) => {
    const { system } = values;
    try {
      const payload = {};
      payload[objectsType] = [{ id: object.id }];
      await api.post(`/systems/${system.id}/${objectsType}`, payload);
      if (object.itemType === 'asset') {
        await backend.patch(`/connected_systems/${system.id}`);
      }
      const newSystems = [...systems];
      newSystems.push(system);
      setSystems(newSystems);
      onAssigned(true);
      actions.resetForm();
      notifier.showSuccess(intl.formatMessage({ id: 'system.actions.assign.notification' }));
    } catch (error) {
      onAssigned(false);
      if (apiErrorsContain(error.errors, 'associations_already_added')) {
        notifier.showError(intl.formatMessage({ id: 'system.actions.assign.error' }));
      } else if (apiErrorsContain(error.errors, 'associations_not_found') || apiErrorsContain(error, 'not_found_no_permission')) {
        notifier.showError(intl.formatMessage({ id: 'asset.actions.assign_system.no_permission' }));
      } else {
        notifier.showError(api.translateError(error));
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  const disableForm = !subscription || !subscription.hasAddon('fermentation') || (onlyOne && systems.length >= 1);
  onAssigned(systems.length >= 1);

  return (
    <div id="assigned-systems">
      <h2>
        <FormattedMessage id={headerName} />
      </h2>
      <AssignSystemForm
        disabled={disableForm}
        onSubmit={handleOnSubmit}
        systemsToIgnore={systems}
        accessRights={accessRights}
      />
      {!fetching ? renderAssignedSystems() : null}
    </div>
  );
}

AssignedSystems.propTypes = {
  api: apiShape.isRequired,
  backend: backendShape.isRequired,
  intl: intlShape.isRequired,
  onlyOne: PropTypes.bool.isRequired,
  subscription: subscriptionShape,
  accessRights: accessRightsShape.isRequired,
  onAssigned: PropTypes.func,
  notifier: notifierShape.isRequired,
  headerName: PropTypes.string,
  object: PropTypes.shape({ id: PropTypes.number, itemType: PropTypes.string }),
};
AssignedSystems.defaultProps = {
  headerName: 'systems.header',
  onAssigned: () => {},
  subscription: {},
};
export default withIntl(withBackend(withApi(withNotifier(withSubscription(AssignedSystems)))));
