import { ApplicationRules } from 'lcm-iot-commons';

class IdRules extends ApplicationRules {
  acceptConnectUsers() {
    return true;
  }

  hasConnectedAssetAddon() {
    return false;
  }
}

export default IdRules;
