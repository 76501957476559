import PropTypes from 'prop-types';

export const cardPaymentDetailsShape = PropTypes.shape({
  method: PropTypes.string,
  status: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  expiryMonth: PropTypes.number,
  expiryYear: PropTypes.number,
  type: PropTypes.string,
  autoCollection: PropTypes.string,
  identifier: PropTypes.string,
  valid: PropTypes.bool,
});
