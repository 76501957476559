const ADDON_MAPPING = {
  sgc200: 'sgc200',
  sgc500: 'sgc500',
};

export function edgeDeviceTypeMapping(edgeDeviceType) {
  const type = {
    id: edgeDeviceType.id,
    name: edgeDeviceType.name,
    code: edgeDeviceType.code,
  };

  if (type.code.toLowerCase().includes('sgc200')) {
    type.hasAddon = ADDON_MAPPING.sgc200;
  } else if (type.code.toLowerCase().includes('sgc500')) {
    type.hasAddon = ADDON_MAPPING.sgc500;
  }

  return type;
}
