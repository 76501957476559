import React from 'react';
import {
  intlShape,
  backendShape,
  notifierShape,
  withIntl,
  withBackend,
  withNotifier,
  Container,
  Heading,
  htmlButton,
} from 'lcm-iot-commons';
import SignUpBreadcrumb from './SignUpBreadcrumb';
import SignUpStepExplanation from './SignUpStepExplanation';
import {
  withSignUp,
  signUpShape,
} from '../../context/SignUpContext';

export function SignUpIdentification({
  intl, backend, notifier, signUp,
}) {
  const resendConfirmation = async (email) => {
    try {
      await backend.post('/users/resend_confirmation', { email });
      notifier.showSuccess(intl.formatMessage({ id: 'signup.identification.resend_email.success' }));
    } catch (error) {
      notifier.showError(backend.translateError(error));
    }
  };

  const step2Explanation = (
    <div id="identification-email-resend-description">
      <span>{intl.formatMessage({ id: 'signup.identification.step_2.description_1' })}</span>
      <div />
      <span id="identification-resend-message">
        {intl.formatMessage(
          { id: 'signup.identification.step_2.description_2' },
          { button: htmlButton(() => resendConfirmation(signUp.signUpDetails.email), { className: 'btn-link btn-primary step-explanation-text-button' }) },
        )}
      </span>
    </div>
  );

  return (
    <Container>
      <SignUpBreadcrumb current="identification" action="signup" />
      <Heading id="sign-up-identification-header" title={intl.formatMessage({ id: 'signup.identification.header' })} />
      <p className="space-after">{intl.formatMessage({ id: 'signup.identification.message' })}</p>
      <div className="step-explanation">
        <SignUpStepExplanation
          id="step-1"
          text={intl.formatMessage({ id: 'signup.identification.step_1.text' })}
          description={intl.formatMessage({ id: 'signup.identification.step_1.description' })}
          step="1"
        />
        <SignUpStepExplanation
          id="step-2"
          text={intl.formatMessage({ id: 'signup.identification.step_2.text' })}
          description={step2Explanation}
          step="2"
        />
        <SignUpStepExplanation
          id="step-3"
          text={intl.formatMessage({ id: 'signup.identification.step_3.text' })}
          description={intl.formatMessage({ id: 'signup.identification.step_3.description' })}
          step="3"
        />
      </div>
    </Container>
  );
}

SignUpIdentification.propTypes = {
  intl: intlShape.isRequired,
  backend: backendShape.isRequired,
  notifier: notifierShape.isRequired,
  signUp: signUpShape.isRequired,
};

export default withIntl(withNotifier(withBackend(withSignUp(SignUpIdentification))));
